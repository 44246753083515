import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Divider, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';

import { useEvents } from '@hooks';
import { LIST_EVENT_SORT_BY } from '@constants/real-event';
import ListComponent from '@components/list';
import Ticket from '../tickets';

import { WrapperStyled, TitleStyled, TextSortStyled } from './styled';
import EventNoData from '../event-no-data';

export const ListEventClosed: React.FC = () => {
  const { endEvents, endEventsPaginate, getEndedEventsAction, isLoadingForEndEvent } = useEvents();
  const [sortBy, setSortBy] = useState(LIST_EVENT_SORT_BY.LASTEST);
  const { t } = useTranslation();
  const { common, listEvent } = translations;

  useEffect(() => {
    getEndedEventsAction({ perPage: 15, sortBy: LIST_EVENT_SORT_BY.LASTEST });
  }, []);

  const onChange = (value: number, sortByValue?: string) => {
    getEndedEventsAction({ perPage: 15, page: value, sortBy: `${sortByValue || sortBy}` });
    if (sortByValue) {
      setSortBy(sortByValue);
    }
  };

  return (
    <WrapperStyled>
      <Row>
        <Col span={24} className="col-title">
          <TitleStyled>{t(common.event.end_event)}</TitleStyled>

          <Typography className="text-sort">
            <TextSortStyled
              onClick={() => {
                onChange(endEventsPaginate.current_page, LIST_EVENT_SORT_BY.LASTEST);
              }}
              className={sortBy === LIST_EVENT_SORT_BY.LASTEST ? 'active' : ''}
            >
              {t(listEvent.latest_event)}
            </TextSortStyled>
            <Divider type="vertical" />
            <TextSortStyled
              onClick={() => {
                onChange(endEventsPaginate.current_page, LIST_EVENT_SORT_BY.OLDEST);
              }}
              className={sortBy === LIST_EVENT_SORT_BY.OLDEST ? 'active' : ''}
            >
              {t(listEvent.old_event)}
            </TextSortStyled>
          </Typography>
        </Col>

        <Col span={24}>
          <Spin spinning={isLoadingForEndEvent}>
            <ListComponent
              listProps={{
                dataSource: endEvents,
                grid: { gutter: 16, xs: 1, sm: 2, md: 2, lg: 2, xl: 3, xxl: 3 },
                renderItem: (item) => <Ticket data={item} />,
                locale: {
                  emptyText: <EventNoData text={t(listEvent.message_list_event_close_no_data)} />,
                },
              }}
              paginationProps={{
                total: endEventsPaginate?.total,
                current: endEventsPaginate?.current_page,
                pageSize: 15,
              }}
              pages={endEventsPaginate?.last_page}
              onChange={onChange}
              pagination
            />
          </Spin>
        </Col>
      </Row>
    </WrapperStyled>
  );
};
