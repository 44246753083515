import {
  checkPassword,
  createComment,
  deleteComment,
  editComment,
  getChapterDetail,
  getDonatePackage,
  getLiveStreamDetail,
  getPackageDetail,
  getTopic,
  getTopicList,
  getTopicTop,
  getVideoComment,
  getVideoPackage,
  sendDonate,
  getAgoraToken,
} from '@modules/topics/store/actions';
import reducer from '@modules/topics/store/reducer';
import saga from '@modules/topics/store/saga';
import { makeSelectTopics } from '@modules/topics/store/selectors';
import { useInjectReducer, useInjectSaga } from '@stores';
import { TopicsState } from '@type/Store/topics';
import { useDispatch, useSelector } from 'react-redux';

export const useTopics = () => {
  useInjectSaga({ key: 'topics', saga });
  useInjectReducer({ key: 'topics', reducer });
  const dispatch = useDispatch();

  const state: TopicsState = useSelector(makeSelectTopics());

  const getTopicListAction = (payload: { page: number; perPage: number }) => dispatch(getTopicList(payload));
  const getTopicAction = (payload: { topicId: number }) => dispatch(getTopic(payload));
  const getTopicTopAction = () => dispatch(getTopicTop());
  const checkPasswordAction = (payload: { id: number; password: string }) => dispatch(checkPassword(payload));
  const getLiveStreamDetailAction = (payload: { roomId: string; user_id: string | number }) =>
    dispatch(getLiveStreamDetail(payload));

  const getDonatePackageAction = (payload: string | number) => dispatch(getDonatePackage(payload));
  const sendDonateAction = (payload) => dispatch(sendDonate(payload));
  const getVideoCommentAction = (payload: { id: number; page: number }) => dispatch(getVideoComment(payload));
  const createCommentAction = (payload) => dispatch(createComment(payload));
  const editCommentAction = (payload) => dispatch(editComment(payload));
  const getChapterDetailAction = (payload: { id: number }) => dispatch(getChapterDetail(payload));
  const deleteCommentAction = (payload) => dispatch(deleteComment(payload));
  const getVideoPackageAction = (payload: { id: number }) => dispatch(getVideoPackage(payload));
  const getPackageDetailAction = (payload: { topicId: number; packageId: number }) =>
    dispatch(getPackageDetail(payload));

  const getAgoraTokenAction = (payload: { user_id: number | string; channel_name: string }) => {
    dispatch(getAgoraToken(payload));
  };

  return {
    ...state,
    getTopicList: getTopicListAction,
    getTopic: getTopicAction,
    getTopicTop: getTopicTopAction,
    checkPassword: checkPasswordAction,
    getLiveStreamDetail: getLiveStreamDetailAction,
    getDonatePackage: getDonatePackageAction,
    sendDonate: sendDonateAction,
    getVideoComment: getVideoCommentAction,
    createComment: createCommentAction,
    getChapterDetail: getChapterDetailAction,
    editComment: editCommentAction,
    deleteComment: deleteCommentAction,
    getVideoPackage: getVideoPackageAction,
    getPackageDetail: getPackageDetailAction,
    getAgoraToken: getAgoraTokenAction,
  };
};
