import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@components';
import { translations } from '@i18n/translations';
import { ICON_COIN } from '@assets';
import { Banks, Coin, Companies, PaymentAllows as Wrapper } from '../../styles';
import { CARDS, KONBINIS } from '../../constants';

type IProps = {
  isPreOrder: boolean;
  onClick: () => void;
};

const PaymentAllows: React.FC<IProps> = ({ isPreOrder, onClick }) => {
  const { t } = useTranslation();

  const { common } = translations;

  return (
    <>
      {!isPreOrder && (
        <Wrapper>
          <Banks>
            {CARDS.map((item) => (
              <img src={item.src} alt={item.alt} key={item.alt} />
            ))}
          </Banks>
          <Companies>
            {KONBINIS.map(({ isIcon, src, alt, Icon }) => {
              if (isIcon) return <Icon key={alt} />;
              return <img src={src} alt={alt} key={alt} />;
            })}
          </Companies>
          <Coin>
            <ICON_COIN />
            Coin
          </Coin>
        </Wrapper>
      )}
      <Button className={isPreOrder ? 'btn-pre-order' : 'btn-purchase'} htmlType="button" onClick={onClick}>
        {isPreOrder ? t(common.button.pre_order) : t(common.button.purchase)}
      </Button>
    </>
  );
};
export default PaymentAllows;
