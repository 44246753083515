import { HEART_LIVE } from '@assets';
import Media from '@themes/media';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 1;
  font-family: 'Noto Sans JP';
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${Media.lessThan(Media.SIZE.LG)} {
  }

  span {
    position: absolute;
    pointer-events: none;
    animation: moveShape 2s linear infinite;
    background: url(${HEART_LIVE});
    background-size: contain;
    background-repeat: no-repeat;
    filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.5));
    bottom: 0;
    right: 0px;
  }

  @keyframes moveShape {
    0% {
      transform: translate(0, 0) rotate(${Math.floor(Math.random() * 20)}deg);
      opacity: 1;
    }
    25% {
      transform: translate(${Math.floor(Math.random() * -60)}px, -80px) rotate(${Math.floor(Math.random() * -40)}deg);
      opacity: 1;
    }
    50% {
      transform: translate(${Math.floor(Math.random() * 60)}px, -120px) rotate(${Math.floor(Math.random() * 40)}deg);
      opacity: 0.5;
    }
    75% {
      transform: translate(${Math.floor(Math.random() * -60)}px, -150px) rotate(${Math.floor(Math.random() * -40)}deg);
      opacity: 0.5;
    }
    100% {
      transform: translate(0, 0) rotate(${Math.floor(Math.random() * 20)}deg);
      opacity: 0.2;
    }
  }
`;
