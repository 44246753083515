import { database, off, onChildAdded, onValue, query, ref } from '@apis/firebase';
import { useEffect, useState } from 'react';

interface IParams {
  roomId?: string | null;
}

export const LIVE_STATUS = {
  PREPARE: 'prepare',
  LIVE: 'live',
  END: 'end',
};

type LiveStatus = 'live' | 'end' | 'prepare';

export const useLiveStreamStatus = ({ roomId }: IParams) => {
  const [status, setStatus] = useState<LiveStatus>('live');
  const [error, setError] = useState<any>(null);
  const [isDonate, setIsDonate] = useState<boolean>(true);

  useEffect(() => {
    const queryDonate = query(ref(database, `topic/${roomId}/show_web_donate_animation`));
    let childOnvalueHandler;
    if (roomId) {
      onValue(
        queryDonate,
        (snapshots) => {
          if (snapshots.exists()) {
            if (error) {
              setError(() => null);
            }
            setIsDonate(snapshots.val());
          }
        },
        (err) => setError(() => err), // on error
      );
    }
    return () => {
      off(queryDonate, 'value', childOnvalueHandler);
    };
  }, [roomId]);

  useEffect(() => {
    let childAddedHandler;
    const queryLikes = query(ref(database, `/live/${roomId}/status_official`));
    if (roomId) {
      onChildAdded(
        queryLikes,
        (snapshot) => {
          if (snapshot.exists()) {
            setStatus(snapshot.val() || 'live');
          }
        },
        (err) => setError(() => err), // on error
        {
          onlyOnce: false,
        },
      );
    }

    return () => {
      off(queryLikes, 'child_added', childAddedHandler);
    };
  }, [roomId]);

  return {
    status,
    error,
    isDonate,
  };
};
