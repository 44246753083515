import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider, useController } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'antd';
import ButtonComponent from '@components/button';
import { FormInput, FormPassword, FormLabel } from '@components';
import { useAuth } from '@hooks';
import { Wrapper, InputLogin } from '@themes/facit';
import { LoginScheme } from '@utils/messageValdate';
import { LOGO_LOGIN } from '@assets';
import { RoutesName } from '@modules/auth/routes';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Title } from './styled';

type HistoryLocation = {
  navigateTo?: string;
};

const LoginScreen = () => {
  const { t } = useTranslation(['common']);
  const {
    i18n: { language },
  } = useTranslation();
  const form = useForm({
    defaultValues: { email: '', password: '' },
    reValidateMode: 'onSubmit',
    mode: 'onSubmit',
    resolver: yupResolver(LoginScheme(t)),
  });
  const [paymentField, setPaymentField] = useState({
    email: '',
    password: '',
  });
  const {
    handleSubmit,
    formState: { isValid },
    control,
  } = form;
  const { loginAction, isLoading } = useAuth();
  const history = useHistory();
  const location = useLocation<HistoryLocation>();
  const { field: email } = useController({
    control,
    name: 'email',
  });
  const { field: password } = useController({
    control,
    name: 'password',
  });

  const handleCardDetailsChange = (event: any) => {
    const btnSummit = document.getElementById('btn-submit');
    if (event.target) {
      setPaymentField({
        ...paymentField,
        [event.target.name]: event.target.value,
      });
      if (event.target.name === 'email') email.onChange(event.target.value); // send data to hook form
      if (event.target.name === 'password') password.onChange(event.target.value); // send data to hook form
    }
    if (isValid) {
      if (btnSummit) btnSummit.style.opacity = 'unset';
    } else if (btnSummit) btnSummit.style.opacity = '0.5';
  };

  const onSubmit = useCallback(
    (data) => {
      loginAction({
        email: data.email,
        password: data.password,
        language,
      });
      if (location?.state?.navigateTo) {
        history.push(location?.state?.navigateTo);
      }
    },
    [loginAction, language],
  );

  return (
    <Wrapper>
      <div className="form-login">
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="logo-header">
              {' '}
              <LOGO_LOGIN />
            </div>
            <div className="label-header">
              <label>{t('login')}</label>
            </div>
            <Row className="form-controls-group">
              <Title>
                <FormLabel title={t('email')} star="*" />
              </Title>
              <InputLogin>
                <FormInput
                  value={paymentField.email}
                  name="email"
                  placeholder={t('placeholder_email')}
                  onChange={handleCardDetailsChange}
                />
              </InputLogin>
            </Row>
            <Row className="form-controls-group">
              <Title>
                <FormLabel title={t('password')} star="*" />
              </Title>
              <InputLogin>
                <FormPassword
                  value={paymentField.password}
                  name="password"
                  placeholderValue={t('placeholder_password')}
                  onChange={handleCardDetailsChange}
                />
              </InputLogin>
            </Row>
            <div className="forget-password">
              <label>
                {' '}
                {t('login_note')}
                <span className="click-span" onClick={() => history.push(RoutesName.SEND_EMAIL)}>
                  {t('login_note_underline')}
                </span>
              </label>
            </div>
            <div className="form-action-group">
              <ButtonComponent
                type="default"
                loading={isLoading}
                className="btn-login"
                id="btn-submit"
                htmlType="submit"
                style={{ opacity: isValid ? 'unset' : 0.5 }}
              >
                {t('login')}
              </ButtonComponent>
              <Button className="btn-register" htmlType="button" onClick={() => history.push(RoutesName.REGISTER)}>
                {t('register')}
              </Button>
              <div className="register">
                <label>{t('register_note')}</label>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </Wrapper>
  );
};

export default LoginScreen;
