import { LOCATION_CHANGE } from 'connected-react-router';
import { createReducer, updateObject, REQUEST, SUCCESS, FAILURE } from '@stores';
import { Action } from '@type/Store';
import { AuthState } from '@type/Store/auth';
import {
  LOAD_PROFILE,
  LOGIN,
  LOGOUT,
  VERIFICATION,
  RESET_PASSWORD,
  REGISTER,
  VERIFY_REGISTER,
  RESEND_CODE,
  RESET_AUTH,
} from './constants';

export const initialState: AuthState = {
  isLoading: false,
  error: null,
  authenticated: null,
  profile: null,
  resetPwStatus: {},
  email: null,
  isSubmitting: false,
  verifyPass: {
    data: null,
    error: null,
  },
  isLogoutSuccess: false,
};

/**
 * login
 * @param state
 */
function login(state: AuthState) {
  return updateObject(state, {
    isLoading: true,
    isLogoutSuccess: false,
  });
}

/**
 * loginSuccess
 * @param state
 * @param payload
 */
function loginSuccess(state: AuthState, { payload }: Action) {
  const { profile } = payload;
  return updateObject(state, {
    isLoading: false,
    authenticated: true,
    profile,
    error: {},
  });
}

/**
 * loginFailure
 * @param state
 * @param error
 */
function loginFailure(state: AuthState, { error }: Action) {
  return updateObject(state, {
    error,
    authenticated: false,
    isLoading: false,
  });
}

function register(state: AuthState) {
  return updateObject(state, {
    isLoading: true,
    isLogoutSuccess: false,
  });
}

function registerSuccess(state: AuthState) {
  return updateObject(state, {
    isLoading: false,
    error: {},
  });
}

function registerFailure(state: AuthState, { error }: Action) {
  return updateObject(state, {
    error,
    isLoading: false,
  });
}

function resendCode(state: AuthState) {
  return updateObject(state, {
    isLoading: true,
    isLogoutSuccess: false,
  });
}

function resendCodeSuccess(state: AuthState) {
  return updateObject(state, {
    isLoading: false,
    error: {},
  });
}

function resendCodeFailure(state: AuthState, { error }: Action) {
  return updateObject(state, {
    error,
    isLoading: false,
  });
}

function verifyRegister(state: AuthState) {
  return updateObject(state, {
    isLoading: true,
    isLogoutSuccess: false,
  });
}

function verifyRegisterSuccess(state: AuthState) {
  return updateObject(state, {
    isLoading: false,
    error: {},
  });
}

function verifyRegisterFailure(state: AuthState, { error }: Action) {
  return updateObject(state, {
    error,
    isLoading: false,
  });
}

/**
 * verification
 * @param state
 */
function verification(state: AuthState) {
  return updateObject(state, {
    isLoading: true,
    isLogoutSuccess: false,
  });
}

/**
 * verificationSuccess
 * @param state
 * @param payload
 */
function verificationSuccess(state: AuthState, { payload }: Action) {
  const { verifyPass, email } = payload;
  return updateObject(state, {
    isLoading: false,
    verifyPass,
    email,
    error: {},
  });
}

/**
 * verificationFailure
 * @param state
 * @param error
 */
function verificationFailure(state: AuthState, { error }: Action) {
  return updateObject(state, {
    error,
    isLoading: false,
    verifyPass: {
      data: null,
      error,
    },
  });
}

/**
 * resetPassword
 * @param state
 */
function resetPassword(state: AuthState) {
  return updateObject(state, {
    isLoading: true,
    isLogoutSuccess: false,
  });
}

/**
 * resetPasswordSuccess
 * @param state
 * @param payload
 */
function resetPasswordSuccess(state: AuthState, { payload }: Action) {
  const { resetPwStatus } = payload;
  return updateObject(state, {
    isLoading: false,
    resetPwStatus,
    error: {},
  });
}

/**
 * resetPasswordFailure
 * @param state
 * @param error
 */
function resetPasswordFailure(state: AuthState, { error }: Action) {
  return updateObject(state, {
    error,
    isLoading: false,
    resetPwStatus: error,
  });
}

/**
 * Logout
 * @param state
 */
function logout(state: AuthState) {
  return updateObject(state, {
    isLoading: true,
    isLogoutSuccess: false,
  });
}

/**
 * logoutSuccess
 * @param state
 * @param payload
 */
function logoutSuccess(state: AuthState) {
  return updateObject(state, {
    ...initialState,
    isLogoutSuccess: true,
  });
}

/**
 * logoutFailure
 * @param state
 * @param error
 */
function logoutFailure(state: AuthState, { error }: Action) {
  return updateObject(state, {
    ...initialState,
    error,
  });
}

function loadProfile(state: AuthState) {
  return updateObject(state, {
    isLoading: true,
    isLogoutSuccess: false,
  });
}

function profileLoaded(state: AuthState, { payload }: Action) {
  const { profile } = payload;
  return updateObject(state, {
    isLoading: false,
    authenticated: true,
    profile: { ...profile, coins: Math.floor(profile.coins || 0) },
  });
}

function profileLoadingError(state: AuthState, { error }: Action) {
  return updateObject(state, {
    error,
    isLoading: false,
    authenticated: false,
  });
}

function locationStateChange(state: AuthState) {
  return updateObject(state, {
    error: null,
    verifyPass: {
      data: null,
      error: null,
    },
  });
}

function resetState(state: AuthState) {
  return updateObject(state, initialState);
}

// Slice reducer
export default createReducer(initialState, {
  [REQUEST(LOGIN)]: login,
  [SUCCESS(LOGIN)]: loginSuccess,
  [FAILURE(LOGIN)]: loginFailure,

  [REQUEST(REGISTER)]: register,
  [SUCCESS(REGISTER)]: registerSuccess,
  [FAILURE(REGISTER)]: registerFailure,

  [REQUEST(VERIFY_REGISTER)]: verifyRegister,
  [SUCCESS(VERIFY_REGISTER)]: verifyRegisterSuccess,
  [FAILURE(VERIFY_REGISTER)]: verifyRegisterFailure,

  [REQUEST(RESEND_CODE)]: resendCode,
  [SUCCESS(RESEND_CODE)]: resendCodeSuccess,
  [FAILURE(RESEND_CODE)]: resendCodeFailure,

  [REQUEST(LOGOUT)]: logout,
  [SUCCESS(LOGOUT)]: logoutSuccess,
  [FAILURE(LOGOUT)]: logoutFailure,

  [REQUEST(VERIFICATION)]: verification,
  [SUCCESS(VERIFICATION)]: verificationSuccess,
  [FAILURE(VERIFICATION)]: verificationFailure,

  [REQUEST(RESET_PASSWORD)]: resetPassword,
  [SUCCESS(RESET_PASSWORD)]: resetPasswordSuccess,
  [FAILURE(RESET_PASSWORD)]: resetPasswordFailure,

  [REQUEST(LOAD_PROFILE)]: loadProfile,
  [SUCCESS(LOAD_PROFILE)]: profileLoaded,
  [FAILURE(LOAD_PROFILE)]: profileLoadingError,
  [RESET_AUTH]: resetState,

  [LOCATION_CHANGE]: locationStateChange,
});
