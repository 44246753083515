import React, { useMemo } from 'react';
import { TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';
import { isMobile, isTablet } from 'react-device-detect';
import { Wrapper, Tabs } from './styled';
import TopRanking from '../top-ranking';
import UserList from '../users-list';
import ChatBox from '../chatbox';

interface IProps {
  roomId?: string | null;
}

export const Tabbar: React.FC<IProps> = ({ roomId }) => {
  const { t, i18n } = useTranslation();
  const { livestream } = translations;

  const items: TabsProps['items'] = useMemo(() => {
    if (isMobile || isTablet)
      return [
        {
          key: '1',
          label: t(livestream.chat),
          children: <ChatBox roomId={roomId} />,
        },
        {
          key: '2',
          label: t(livestream.donate_ranking),
          children: <TopRanking roomId={roomId} />,
        },
        {
          key: '3',
          label: t(livestream.viewers),
          children: <UserList roomId={roomId} />,
        },
      ];

    return [
      {
        key: '1',
        label: t(livestream.donate_ranking),
        children: <TopRanking roomId={roomId} />,
      },
      {
        key: '2',
        label: t(livestream.viewers),
        children: <UserList roomId={roomId} />,
      },
    ];
  }, [isMobile, isTablet, i18n.language]);

  return (
    <Wrapper>
      <Tabs defaultActiveKey="1" items={items} />
    </Wrapper>
  );
};

export default Tabbar;
