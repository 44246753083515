import { translations } from '@i18n/translations';
import moment from 'moment';
import * as yup from 'yup';

const { realEventPayment } = translations;
const { payment_validate } = realEventPayment;
const scheme = yup.object().shape({
  cardholder: yup
    .string()
    .trim()
    .required(payment_validate.required)
    .test('cardholder', payment_validate.cardholder, (value) => {
      if (!value) return true;
      const pattern = /^[a-zA-Z0-9 .,/-]{0,50}$/;

      return pattern.test(value);
    }),
  card_number: yup
    .string()
    .trim()
    .required(payment_validate.required)
    .test('card_number', payment_validate.cardnumber, (value) => {
      if (!value) return true;
      const pattern = /^[0-9]{0,16}$/;
      const number = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');

      return pattern.test(number);
    }),
  cvc: yup
    .string()
    .trim()
    .required(payment_validate.required)
    .test('cvc', payment_validate.cvc_format, (value) => {
      if (!value) return true;
      const pattern = /^[0-9]{3,4}$/;

      return pattern.test(value);
    }),
  email: yup
    .string()
    .trim()
    .required(payment_validate.required)
    .email(payment_validate.email_incorrect)
    .max(200, payment_validate.email_max_length),
  phone_number: yup
    .string()
    .trim()
    .required(payment_validate.required)
    .test('phone_number', payment_validate.phoneNumber_format, (value = '') => {
      if (!value) return true;
      const pattern = /^[0-9]{0,13}$/;
      return pattern.test(value);
    }),
  date_expiry: yup
    .string()
    .trim()
    .required(payment_validate.required)
    .test('date_expiry', payment_validate.dateExpiry_format, (value) => {
      if (!value) return true;
      const pattern = /^\d{2}\/\d{2}$/;
      if (!pattern.test(value)) return false;

      const [YY, MM] = value.split('/');
      if (Number(MM) > 12 || Number(MM) < 1 || Number(YY) > 99) return false;
      return pattern.test(value);
    })
    .test('date_expiry', payment_validate.dateExpiry, (value = '') => {
      if (!value) return true;
      const pattern = /^\d{2}\/\d{2}$/;
      if (!pattern.test(value)) return true;
      const [YY] = value.split('/');
      if (Number(YY) > 68) return true;

      return moment().isSameOrBefore(moment(value, 'YY/MM'), 'month');
    }),
});

export default scheme;
