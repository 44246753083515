import Media from '@themes/media';
import styled from 'styled-components';

export const WrapperList = styled.div`
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    .ant-pagination {
      li {
        border-radius: 8px;
        border: none;

        &:hover {
          a {
            color: #000000;
          }
        }

        button {
          border-radius: 8px;
          border: none;

          &:hover {
            color: #000000;
          }
        }
      }

      .ant-pagination-item-active {
        background-color: #e3007f;
        a {
          color: #ffffff;
        }
      }
    }
  }
  ${Media.lessThan(Media.SIZE.XS)} {
    .ant-pagination {
      .ant-pagination-prev {
        min-width: auto;
      }
      .ant-pagination-item,
      .ant-pagination-total-text {
        min-width: 24px;
        height: auto;
        margin-right: 4px;
      }
    }
  }
`;

export const ButtonPaginationStyled = styled.button`
  border-radius: 8px;
  cursor: pointer;
  background-color: #ffffff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  &.btn-left {
    margin-right: 8px;
  }

  &.btn-right {
    margin-left: 8px;
  }
`;
