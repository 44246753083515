import Media from '@themes/media';
import styled from 'styled-components';

export const EventTopicStyle = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  height: auto;
  max-width: 384px;
  margin: 12px;
  text-align: start;
  position: relative;

  .topic-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .cover-img {
    object-fit: cover;
    border-radius: 15px 15px 0px 0px;
    width: 100%;
    position: absolute;
    top: 0px;
    object-fit: cover;
    height: 100%;
  }

  .image-container {
    position: relative;
    aspect-ratio: 16 / 9;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .content {
    flex: 1;
    flex-wrap: wrap;
    padding: 16px;

    .title,
    .description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      font-size: ${({ theme }) => theme.size_14};
      color: ${({ theme }) => theme.time};
      overflow-wrap: break-word;
    }

    .title {
      font-weight: ${({ theme }) => theme.fw_700};
      font-size: ${({ theme }) => theme.size_16};
      color: ${({ theme }) => theme.dark};
      /* margin-top: 1rem; */
    }
    .description {
      margin-top: 4px;
    }
  }
  .tag-live {
    border: none;
    position: absolute;
    top: 15px;
    left: 15px;
    background-color: ${({ theme }) => theme.bg_red};
    border-radius: 12px;
    font-size: ${({ theme }) => theme.size_12};
    font-weight: ${({ theme }) => theme.fw_700};
    color: white;
    z-index: 10;
  }
  .tag-topic {
    border: none;
    background-color: ${({ theme }) => theme.brand};
    border-radius: 12px;
    font-size: ${({ theme }) => theme.size_12};
    font-weight: ${({ theme }) => theme.fw_700};
    color: white;
    margin-top: 24px;
  }

  time {
    color: ${({ theme }) => theme.time};
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    height: auto;
    border-radius: 10px;

    .image-container {
      position: relative;
    }

    .cover-img {
      border-radius: 10px 10px 0px 0px;
    }

    .content {
      padding: 10px 12px;
      .title {
        font-size: ${({ theme }) => theme.size_12};
        font-weight: ${({ theme }) => theme.fw_700};
      }
      .tag-topic {
        margin-top: 0;
      }
    }

    time {
      font-size: ${({ theme }) => theme.size_12};
      padding: 0 0.25rem;
    }
  }
`;

export const CancelMark = styled.div`
  position: absolute;
  padding: 6px 25px;
  background: #d80027;
  color: white;
  top: 10px;
  left: 10px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;

  ${Media.lessThan(Media.SIZE.SM)} {
    padding: 2px 7px;
    font-size: 12px;
  }
`;
