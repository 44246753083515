import { Col, Spin, Typography } from 'antd';
import styled from 'styled-components';

import Media from '@themes/media';

const { Text } = Typography;

export const SpinStyled = styled(Spin)`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  max-height: initial !important;
  font-family: 'Noto Sans JP';
`;
export const TicketInforStyle = styled.div`
  border-top: 1px solid #e5e6eb;
  padding: 15px 0;
`;
export const UserInforStyle = styled.div`
  padding: 15px 0;
`;
export const Group = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: 400;
  font-family: 'Noto Sans JP';
  margin-bottom: 16px;

  label {
    color: ${({ theme }) => theme._787878};
    font-size: 18px;
    width: 320px;

    white-space: nowrap;
    ${Media.lessThan(Media.SIZE.MD)} {
      font-size: ${({ theme }) => theme.size_14};
      padding-right: 5px;
      margin-bottom: 0;
      width: 160px;
      white-space: pre-wrap;
    }
  }

  p {
    margin: 0;
    padding: 0;
  }
  p,
  div {
    flex: 2;
    font-size: ${({ theme }) => theme.size_18};
    color: ${({ theme }) => theme.dark};

    ${Media.lessThan(Media.SIZE.SM)} {
      font-size: ${({ theme }) => theme.size_14};
    }

    a {
      text-decoration: underline;
    }

    .price {
      color: #0076e3;
      font-weight: ${({ theme }) => theme.fw_700};
    }

    .yen {
      color: #e3007f;
      display: flex;
      align-items: center;
      font-weight: ${({ theme }) => theme.fw_700};
    }

    .d-flex {
      display: flex;
      margin: 0;
    }

    span + span {
      padding-left: 45px;
    }

    span {
      svg {
        margin-right: 5px;
      }
    }
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    margin-bottom: 8px;
  }
`;

export const Wrapper = styled.div`
  padding: 40px;
  justify-content: center;
  width: 100%;
  font-family: 'Noto Sans JP';
  align-items: center;
  display: flex;

  .info {
    display: flex;

    .ant-typography {
      font-size: 18px;
    }

    .info-label {
      margin-right: 8px;
      min-width: 180px;
      ${Media.lessThan(Media.SIZE.SM)} {
        min-width: 140px;
      }
    }
  }

  .info-text {
    .link-ticket {
      text-decoration: underline;
    }
    .custom-style {
      margin-bottom: 0;
    }

    .tag-live {
      border: none;
      margin-left: 20px;
      background-color: ${({ theme }) => theme.bg_red};
      border-radius: 12px;
      font-size: ${({ theme }) => theme.size_10};
      font-weight: ${({ theme }) => theme.fw_700};
      color: white;
    }

    .live-end {
      border: none;
      margin-left: 20px;
      background-color: #787878;
      border-radius: 12px;
      font-size: ${({ theme }) => theme.size_10};
      font-weight: ${({ theme }) => theme.fw_700};
      color: white;
    }

    .tag-comming {
      border: none;
      margin-left: 20px;
      background-color: #e3007f;
      border-radius: 12px;
      font-size: ${({ theme }) => theme.size_10};
      font-weight: ${({ theme }) => theme.fw_700};
      color: white;
    }

    .total-text {
      display: flex;
      align-items: center;

      &.coin-total {
        color: #10ab52;
      }
    }
  }

  /* ${Media.lessThan(Media.SIZE.XXL)} {
    padding: 50px 114px;
  }

  ${Media.lessThan(Media.SIZE.XL)} {
    padding: 50px 114px;
  }

  ${Media.lessThan(Media.SIZE.LG)} {
    padding: 50px 114px;
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    padding: 25px 20px;
  } */
`;

export const Content = styled.div`
  background-color: #ffffff;
  max-width: 960px;
  border-radius: 16px;
  padding: 16px 32px;
  font-family: 'Noto Sans JP';
  flex-direction: column;

  ${Media.lessThan(Media.SIZE.LG)} {
    /* padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center; */
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    /* padding: 10px; */
  }
`;

export const Title = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  margin-top: 26px;
  font-family: 'Noto Sans JP';
`;

export const ColRightStyled = styled(Col)`
  font-family: 'Noto Sans JP';
  .title {
    font-size: 16px;
    font-weight: 700;
    font-family: 'Noto Sans JP';
  }

  ${Media.lessThan(Media.SIZE.LG)} {
    width: 100%;
    align-items: center;
    border-top: 2px solid #d9d9d9;
    margin-top: 20px;

    .title {
      margin-top: 20px;
    }
  }
`;

export const LabelStyled = styled(Text)`
  font-size: 18px;
  color: #787878;
  font-family: 'Noto Sans JP';
`;

export const BoxCodeStyled = styled.div`
  width: 100%;
  padding: 22px 0;
  margin-top: 16px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f5f8fc;
  border-radius: 5px;
  font-size: 24px;
  font-family: 'Noto Sans JP';

  .code {
    color: #0076e3;
  }
`;

export const CancelMark = styled.div`
  position: absolute;
  padding: 6px 25px;
  background: #d80027;
  color: white;
  top: 10px;
  left: 10px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Noto Sans JP';

  ${Media.lessThan(Media.SIZE.SM)} {
    padding: 2px 7px;
    font-size: 12px;
  }
`;
