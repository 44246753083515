import { translations } from '@i18n/translations';
import { Modal as ModalAntd, ModalProps, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from '@components';
import React from 'react';
import Media from '@themes/media';

export const Modal = styled(ModalAntd)`
  .ant-modal-content {
    border-radius: 24px;
    width: auto;
  }

  .ant-modal-body {
    /* padding: 82px 58px 45px 58px; */
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .ant-btn {
      height: 44px;
    }
  }
  .btn-group {
    gap: 16px;
  }

  ${Media.lessThan(Media.SIZE.MD)} {
    .ant-modal-body {
      .ant-btn {
        height: 36px;
      }
    }
  }
`;

interface ModalConfirmProps extends Omit<ModalProps, 'okText' | 'onOk' | 'title' | 'cancelText'> {
  cancelText?: string;
  textBtnOk?: string;
  isLoading?: boolean;
  onConfirm?: () => void;
  isHaveCancel?: boolean;
}

const ModalConfirm: React.FC<ModalConfirmProps> = ({
  cancelText,
  textBtnOk,
  isLoading,
  onConfirm,
  children,
  onCancel,
  isHaveCancel = true,
  ...rest
}) => {
  const { t } = useTranslation();
  const { common } = translations;

  return (
    <Modal centered closeIcon={null} footer={false} {...rest}>
      {children}
      <Row className="btn-group">
        {isHaveCancel && (
          <Button onClick={onCancel} type="cancel" loading={isLoading}>
            {cancelText || t(common.cancel)}
          </Button>
        )}
        <Button type="primary" onClick={onConfirm} loading={isLoading}>
          {textBtnOk || t(common.ok)}
        </Button>
      </Row>
    </Modal>
  );
};

export { ModalConfirm };
