import React, { useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { isMobile } from 'react-device-detect';
import { getEnv } from '@config/env';

import { TextInput, Button } from '@components';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { useAuth, useEvents, useRealEventPayment } from '@hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { translations } from '@i18n/translations';
import { useTranslation } from 'react-i18next';
import useExitPrompt from '@hooks/useExitPrompt';
import { ERROR_MESSAGE_REAL_EVENT_PURCHASE } from '@constants/real-event';
import { convertStringToHalfWidth } from '@utils/string';
import Header from '../components/header';
import { FormWrapper, Form, Wrapper } from '../styled';
import { FORM_CREDIT_CARD } from '../constants';
import { ContentStyled } from '../coin/styled';
import BoxPayment from '../coin/components/box-payment';
import PaymentCoinConfirm from '../components/payment-confirm';
import { ModalStyled, TextComfirmStyled } from '../components/payment-confirm/styled';
import PaymentFail from '../coin/components/payment-coin-fail';
import PaymentCoinSuccess from '../coin/components/payment-coin-success';
import scheme from './schema';

interface FormValues {
  email: string;
  cardholder: string;
  dateExpiry: string;
  cvc: string;
  phonenumber: string;
  cardnumber: string;
}
interface Params {
  id?: string;
}

interface TokenObject {
  isSecurityCodeSet: boolean;
  maskedCardNo: string;
  toBeExpiredAt: string;
  token: string[];
}

const GMO_SHOP_ID = getEnv('GMO_SHOP_ID', null);

type PaymentStatus = 'INPUT_PAYMENT' | 'CONFIRM_PAYMENT' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILURE';
export const CreditPaymentScreen: React.FC = () => {
  const { id } = useParams<Params>();
  const { profile } = useAuth();
  const { purchaseWithCreditCard, error, paidCredit, balanceCode, cardInfo, setCardInfo, clearCardInfo } =
    useRealEventPayment();
  const history = useHistory();
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>('INPUT_PAYMENT');
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isShowCreditCard, setIsShowCreditCard] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null | undefined>('');
  const [tokenObject, setTokenObject] = useState<TokenObject | null | undefined>(null);
  const { t } = useTranslation();
  const { realEventPayment, common, error_message } = translations;
  useExitPrompt(true);
  const { ticketsData, eventDetail, reset } = useEvents();

  const form = useForm({
    defaultValues: {
      email: '',
      cardholder: '',
      dateExpiry: '',
      cvc: '',
      phonenumber: '',
      // cardnumber: '4100000000100009',
      cardnumber: '',
    },
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    resolver: yupResolver(scheme),
  });
  const { handleSubmit, setValue, watch } = form;

  const [cardholder, cardnumber, cvc, dateExpiry, email, phonenumber] = watch([
    'cardholder',
    'cardnumber',
    'cvc',
    'dateExpiry',
    'email',
    'phonenumber',
  ]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, name) => {
    const { target } = e;
    const { value } = target;
    const convertHaflWidth = convertStringToHalfWidth(value || '');

    setValue(name, convertHaflWidth);
  };
  useEffect(() => {
    window?.Multipayment.init(GMO_SHOP_ID);
  }, []);

  useEffect(() => {
    if (profile && profile.email) {
      setValue('email', profile.email);
    }

    if (ticketsData?.phoneNumber || (profile && profile.phone)) {
      const convertNumber = (profile?.phone || '').replace(/\D/g, '');
      const convertHaflWidth = convertStringToHalfWidth(convertNumber);
      setValue('phonenumber', ticketsData?.phoneNumber || convertHaflWidth);
    }
  }, [profile, ticketsData]);

  useEffect(() => {
    if (cardInfo.cardnumber) {
      setValue('cardnumber', cardInfo.cardnumber);
    }
    if (cardInfo.cardholder) {
      setValue('cardholder', cardInfo.cardholder);
    }
  }, [profile, cardInfo, ticketsData]);

  useEffect(() => {
    if (error) {
      if (
        error.message &&
        error.message.length > 0 &&
        error.message[0] === ERROR_MESSAGE_REAL_EVENT_PURCHASE.TICKET_SOLD_OUT
      ) {
        setIsShowModal(true);
        setErrorMsg(t(realEventPayment.number_of_ticket_insufficent));
      } else if (
        error.message &&
        error.message.length > 0 &&
        error.message[0] === ERROR_MESSAGE_REAL_EVENT_PURCHASE.REAL_EVENT_NOT_FOUND
      ) {
        setIsShowModal(true);
        setErrorMsg(t(error_message.REAL_EVENT_NOT_FOUND));
      } else if (
        error.message &&
        error.message.length > 0 &&
        error.message[0] === ERROR_MESSAGE_REAL_EVENT_PURCHASE.REAL_EVENT_CANCEL
      ) {
        setIsShowModal(true);
        setErrorMsg(t(error_message.REAL_EVENT_NOT_FOUND));
      } else {
        setPaymentStatus('PAYMENT_FAILURE');
      }
    }
  }, [error]);

  useEffect(() => {
    if (paidCredit) {
      reset();
      setPaymentStatus('PAYMENT_SUCCESS');
      setTokenObject(null);
    }
  }, [paidCredit]);

  const onSubmit: SubmitHandler<FormValues> = () => {
    const [YY, MM] = dateExpiry.split('/');
    const cardno = cardnumber.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    window.Multipayment.getToken(
      {
        cardno,
        expire: `${YY}${MM}`,
        securitycode: cvc,
        holdername: cardholder,
        tokennumber: 1,
      },
      (response) => {
        if (response.resultCode !== '000') {
          setIsShowCreditCard(true);
        } else {
          setPaymentStatus('CONFIRM_PAYMENT');
          setTokenObject(response.tokenObject);
        }
      },
    );
  };

  const onCancel = () => {
    setIsShowModal(false);
  };

  const tickets = useMemo(() => {
    if (ticketsData && ticketsData.tickets) {
      return ticketsData.tickets
        .filter((f) => !!f.quantity)
        .map((m) => ({ quantity: m.quantity, ticketId: m.ticketId, name: m.name }));
    }
    return [];
  }, [ticketsData]);

  const onPurchase = () => {
    purchaseWithCreditCard({
      pre_order: 0,
      real_event_id: id,
      pay_unit: 1,
      payment_method: 1,
      ticket: tickets.map((m) => ({ ticket_type_id: m.ticketId, quantity: m.quantity })),
      amount: Number(ticketsData?.priceTotal || 0),
      card: {
        token: tokenObject?.token[0],
        email,
        phone_number: phonenumber,
      },
      memo: ticketsData?.memo,
      address: ticketsData?.address,
      zip_code: ticketsData?.zipcode,
      phone_number: ticketsData?.phoneNumber,
      first_name: ticketsData?.firstKanji,
      last_name: ticketsData?.lastKanji,
    });
  };

  useEffect(() => {
    if (!ticketsData && paymentStatus !== 'PAYMENT_SUCCESS') {
      history.replace(`/events/${id}`);
    }
  }, [ticketsData, paymentStatus]);

  const onNavigateError = () => {
    if (error?.message?.[0] === ERROR_MESSAGE_REAL_EVENT_PURCHASE.TICKET_SOLD_OUT) {
      setCardInfo({
        cardnumber,
        cardholder,
      });
      history.push(`/events/${id}`);
    } else {
      reset();
      clearCardInfo();
      history.push('/list-event');
    }
  };
  if (paymentStatus === 'CONFIRM_PAYMENT') {
    return (
      <Wrapper>
        <BoxPayment title={t(common.event.confirm_popup_title)} routeBack={`/events/${id}`} isShowIconBack>
          <ContentStyled>
            <PaymentCoinConfirm
              totalCoinPrice={Number(ticketsData?.priceTotal)}
              eventDetail={eventDetail}
              totalTicket={Number(ticketsData?.totalTicket)}
              tickets={tickets}
              email={email}
              phonenumber={phonenumber}
              name={cardholder}
              setPaymentStatus={setPaymentStatus}
              onCancel={() => setTokenObject(null)}
              onConfirm={onPurchase}
              paymentMethod={t(translations.myTicket.my_ticket_detail.payment_method.credit_card)}
              yenFee={ticketsData?.yenFee}
              memo={ticketsData?.memo}
              cardNumber={cardnumber}
              dateExpiry={dateExpiry}
            />
          </ContentStyled>
        </BoxPayment>
        <ModalStyled centered onCancel={onCancel} open={isShowModal} footer={false}>
          <TextComfirmStyled>{errorMsg}</TextComfirmStyled>
          <Button onClick={onNavigateError}>
            {error?.message?.[0] === ERROR_MESSAGE_REAL_EVENT_PURCHASE.TICKET_SOLD_OUT
              ? t(realEventPayment.re_select)
              : t(realEventPayment.go_to_list_event)}
          </Button>
        </ModalStyled>

        <Prompt when={!!ticketsData && !error} message={t(realEventPayment.goback)} />
      </Wrapper>
    );
  }

  if (paymentStatus === 'PAYMENT_FAILURE') {
    return (
      <Wrapper>
        <BoxPayment title={t(realEventPayment.payment_coin_fail)} isShowIconBack={false}>
          <ContentStyled>
            <PaymentFail
              gmo_error_code={error?.gmo_error_code}
              onClick={() => {
                history.replace(`/events/${id}`);
                setCardInfo({
                  cardnumber,
                  cardholder,
                });
              }}
            />
          </ContentStyled>
        </BoxPayment>
      </Wrapper>
    );
  }

  if (paymentStatus === 'PAYMENT_SUCCESS') {
    return (
      <Wrapper>
        <BoxPayment title={t(realEventPayment.payment_coin_completed)} isShowIconBack={false}>
          <ContentStyled>
            <PaymentCoinSuccess
              totalCoinPrice={ticketsData?.priceTotal}
              onClick={() => {
                clearCardInfo();
                history.push(`/histories/ticket/${id}/${balanceCode}`);
              }}
            />
          </ContentStyled>
        </BoxPayment>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <FormProvider {...form}>
        <FormWrapper>
          <Header
            title={t(realEventPayment.credit.title)}
            onClick={() => {
              setCardInfo({
                cardholder,
                cardnumber,
              });
              history.replace(`/events/${id}`);
            }}
          />
          <Form>
            {FORM_CREDIT_CARD.map((item) => (
              <TextInput
                width={isMobile ? '100%' : '49%'}
                name={item.name}
                placeholder={item.placeholder}
                label={t(item.label)}
                translateField={translations.realEventPayment.fieldName[item.name]}
                inputType="required"
                cvvInfo={item.name === 'cvc'}
                key={item.name}
                inputFormat={item.inputFormat}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, item.name)}
              />
            ))}
            <div style={{ fontSize: 16, fontWeight: 400, color: '#787878', marginTop: 4 }}>
              {t(realEventPayment.credit.note)}
            </div>
          </Form>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 24 }}>
            <Button onClick={handleSubmit(onSubmit)}>{t(realEventPayment.continue)}</Button>
          </div>
        </FormWrapper>
      </FormProvider>
      <Prompt
        message={() => {
          return ticketsData ? t(realEventPayment.goback) : true;
        }}
      />

      <ModalStyled centered onCancel={() => setIsShowCreditCard(false)} open={isShowCreditCard} footer={false}>
        <TextComfirmStyled>購入処理中にエラーが発生しました</TextComfirmStyled>
        <Button onClick={() => setIsShowCreditCard(false)}>OK</Button>
      </ModalStyled>
    </Wrapper>
  );
};

export default CreditPaymentScreen;
