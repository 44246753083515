import styled from 'styled-components';

export const PaymentCoinSuccessStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;

  .text-message {
    text-align: center;
    font-size: 20px;
    margin-top: 24px;
    white-space: pre-wrap;
  }

  .box-btn {
    margin-top: 50px;
    display: flex;
    gap: 16px;
  }
`;
