import React, { useMemo } from 'react';
import Image from '@components/imageFallback';
import { convertSecondsToHMS_V2, convertToCurrentTimeZone } from '@utils/time';
import { FORMAT_TIME } from '@constants';
import { isMobile } from 'react-device-detect';
import { Seemore } from '@components';
import { ImageChapters, DateTime, Wrapper, Info, LeftInfo, RightInfo } from './styled';

interface Chapter {
  description: string;
  id: number;
  public_end: string | null;
  public_start: string | null;
  thumbnail: string;
  title: string;
  topic_id: number;
  duration: number;
}
interface Props {
  chapter: Chapter;
  onVideoClick: () => void;
}
export const ChapterPackageComponent: React.FC<Props> = ({ chapter, onVideoClick }) => {
  const startTime = useMemo(() => {
    if (chapter.public_start) {
      return convertToCurrentTimeZone(chapter?.public_start, FORMAT_TIME.FULL_DATE);
    }

    return '';
  }, [chapter]);

  const endTime = useMemo(() => {
    if (chapter.public_end) {
      return ` ~ ${convertToCurrentTimeZone(chapter?.public_end, FORMAT_TIME.FULL_DATE)}`;
    }
    return '';
  }, [chapter]);

  return (
    <Wrapper onClick={() => onVideoClick()}>
      <ImageChapters>{chapter?.thumbnail && <Image src={chapter?.thumbnail} alt="Image topic" />}</ImageChapters>
      <Info>
        <LeftInfo>
          <DateTime>
            <div className="date">
              {startTime}
              {endTime}
            </div>
          </DateTime>
          <div className="chapter-content">{chapter.title}</div>
          <div className="time">{!!chapter.duration && convertSecondsToHMS_V2(Number(chapter.duration))}</div>
          {isMobile && chapter?.description && (
            <Seemore className="description" rows={2} content={chapter?.description} />
          )}
        </LeftInfo>
        <RightInfo>
          <div className="hide" style={{ height: 22 }} />
        </RightInfo>
      </Info>
      {!isMobile && chapter?.description && <Seemore className="description" rows={2} content={chapter.description} />}
    </Wrapper>
  );
};
