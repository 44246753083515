import { purchase, realEventPurchase } from '@apis';
import { FAILURE, REQUEST, SUCCESS } from '@stores';
import { Action } from '@type/Store';
import { put, takeLatest } from 'redux-saga/effects';
import { PURCHASE_WITH_CREDIT_CARD, PURCHASE_WITH_KOBINI, REAL_EVENT_PURCHASE } from './constants';

export function* realEventPurchaseSaga({ payload }: Action) {
  const { data } = payload;

  try {
    const res = yield realEventPurchase(data);
    yield put({
      type: SUCCESS(REAL_EVENT_PURCHASE),
      payload: res?.data?.data,
    });
  } catch (error) {
    yield put({
      type: FAILURE(REAL_EVENT_PURCHASE),
      error,
    });
  }
}

export function* purchaseWithCreditCard({ payload }: Action) {
  const {
    pre_order,
    real_event_id,
    pay_unit,
    payment_method,
    ticket,
    amount,
    card,
    memo,
    address,
    zip_code,
    first_name,
    last_name,
  } = payload;
  const { email, phone_number, token } = card;
  try {
    const { data } = yield realEventPurchase({
      pre_order,
      real_event_id,
      pay_unit,
      payment_method,
      ticket,
      memo,
      address,
      zip_code,
      first_name,
      last_name,
      phone_number,
    });

    yield purchase({
      balance_code: data.data,
      real_event_id,
      type: 1,
      order_id: Date.now(),
      amount,
      email,
      phone_number,
      token,
    });

    yield put({
      type: SUCCESS(PURCHASE_WITH_CREDIT_CARD),
      payload: data.data || '',
    });
  } catch (error) {
    yield put({
      type: FAILURE(REAL_EVENT_PURCHASE),
      error,
    });
  }
}

export function* purchaseWithKobini({ payload }: Action) {
  const {
    pre_order,
    real_event_id,
    pay_unit,
    payment_method,
    ticket,
    amount,
    card,
    memo,
    address,
    zip_code,
    first_name,
    last_name,
  } = payload;
  const { email, customer_name, phone_number, convenience, customer_kana } = card;

  try {
    const { data } = yield realEventPurchase({
      pre_order,
      real_event_id,
      pay_unit,
      payment_method,
      ticket,
      memo,
      address,
      zip_code,
      first_name,
      last_name,
      phone_number,
    });

    const { data: purchaseData } = yield purchase({
      balance_code: data.data,
      real_event_id,
      type: 2,
      order_id: Date.now(),
      amount,
      email,
      customer_name,
      phone_number,
      customer_kana,
      convenience,
    });

    yield put({
      type: SUCCESS(PURCHASE_WITH_KOBINI),
      payload: {
        balanceCode: data.data || '',
        receiptNo: purchaseData.data.ReceiptNo,
      },
    });
  } catch (error) {
    yield put({
      type: FAILURE(REAL_EVENT_PURCHASE),
      error,
    });
  }
}

export default function* realEventSaga() {
  yield takeLatest(REQUEST(REAL_EVENT_PURCHASE), realEventPurchaseSaga);
  yield takeLatest(REQUEST(PURCHASE_WITH_CREDIT_CARD), purchaseWithCreditCard);
  yield takeLatest(REQUEST(PURCHASE_WITH_KOBINI), purchaseWithKobini);
}
