/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { DIAMOND, PACKAGE_SUCCESS_ICON } from '@assets';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './styled';

interface IProps {
  data: any;
  isActive: any;
  handleClick: any;
  currentValue: any;
}

const DiamondItem: React.FC<IProps> = ({ data, isActive, handleClick, currentValue }) => {
  const { t, i18n } = useTranslation(['common', 'error_message']);
  const actived = React.useMemo(() => currentValue === data.id, [currentValue]);
  return (
    <Wrapper key={data?.id} actived={actived} onClick={() => handleClick(data)}>
      <div className="status-package">
        <img src={PACKAGE_SUCCESS_ICON} alt="package-success" />
      </div>
      <div className="amount-diamond">
        <img src={DIAMOND} alt="diamond" />
        <span>
          &ensp;
          {data?.price_diamond}
        </span>
      </div>
      <div className="price-diamond">
        {data?.price}
        {isActive === '2' ? 'PRX' : t('unit_currency')}
        {i18n.language === 'en' || data.currency === 4 ? <p>{t('text_currency')}</p> : t('text_currency')}
      </div>
      <div className="purchase-diamond">{t('text_diamon_purchase')}</div>
    </Wrapper>
  );
};

export default DiamondItem;
