import { put, takeLatest } from 'redux-saga/effects';

import { REQUEST, SUCCESS, FAILURE } from '@stores';
import { createSignature, eventTransfer } from '@apis';

import { Action } from '@type/Store';
import { SIGNATURE, EVENT_TRANSFER, CONNECT_WALLET, ERC20_APPROVED } from './constants';
import { erc20Contract as erc20 } from '../../../ABI';

export function* createSignatureSaga({ payload }: Action) {
  try {
    const { wallet_address, package_id, erc20Contract, iLiveContract, web3 } = payload;
    const { data: signature } = yield createSignature({
      wallet_address,
      package_id,
    });
    const estimatedGas1: any = yield erc20Contract.methods
      .approve(erc20.approveSenderAddress, signature.amount)
      .estimateGas({ from: wallet_address });

    const balance = yield web3.eth.getBalance(wallet_address);

    const gasPrice = yield web3.eth.getGasPrice();

    if (Number(balance) < Number(gasPrice * estimatedGas1)) {
      throw new Error('error_message:low_balance');
    }
    const PRXBalance = yield erc20Contract.methods.balanceOf(wallet_address).call();

    if (Number(PRXBalance) < signature.amount) {
      throw new Error('error_message:low_balance');
    }
    const dataApprove = yield erc20Contract.methods
      .approve(erc20.approveSenderAddress, signature.amount)
      .send({ from: wallet_address });
    if (dataApprove === null) {
      throw new Error('error_message:max_time_execution');
    }

    yield put({ type: REQUEST(ERC20_APPROVED) });

    const estimatedGas2 = yield iLiveContract.methods
      .transferByWalletUser(
        signature.packageId,
        signature.userId,
        signature.amount,
        signature.nonce,
        signature.signature,
      )
      .estimateGas({ from: wallet_address });
    const balance2 = yield web3.eth.getBalance(wallet_address);
    const gasPrice2 = yield web3.eth.getGasPrice();
    if (Number(balance2) < Number(gasPrice2 * estimatedGas2)) {
      throw new Error('error_message:low_balance');
    }

    const { transactionHash }: any = yield iLiveContract.methods
      .transferByWalletUser(
        signature.packageId,
        signature.userId,
        signature.amount,
        signature.nonce,
        signature.signature,
      )
      .send({ from: wallet_address });
    yield put({
      type: SUCCESS(SIGNATURE),
      payload: {
        signature,
        transactionHash,
      },
    });
  } catch (error) {
    yield put({
      type: FAILURE(SIGNATURE),
      error,
    });
  }
}

export function* eventTransferSaga({ payload }: Action) {
  try {
    const { data } = yield eventTransfer(payload);
    yield put({
      type: SUCCESS(EVENT_TRANSFER),
      payload: {
        signature: data,
      },
    });
  } catch (error) {
    yield put({
      type: FAILURE(EVENT_TRANSFER),
      error,
    });
  }
}

export function* connetWalletSaga({ payload }: Action) {
  try {
    yield put({
      type: SUCCESS(CONNECT_WALLET),
      payload,
    });
  } catch (error) {
    yield put({
      type: FAILURE(CONNECT_WALLET),
      error,
    });
  }
}

export default function* signatureSaga() {
  yield takeLatest(REQUEST(SIGNATURE), createSignatureSaga);
  yield takeLatest(REQUEST(EVENT_TRANSFER), eventTransferSaga);
  yield takeLatest(REQUEST(CONNECT_WALLET), connetWalletSaga);
}
