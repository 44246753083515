export const PAYMENT_STATUS = {
  SUCCESS: 1,
  FAIL: 2,
  PRE_ORDER: 3,
  PROCESSING: 4,
  REFUND: 5,
};

export const PAYMENT_METHOD = {
  CREDIT_CARD: 1,
  KONBINI: 2,
  COIN: 3,
  DIAMOND: 4,
};

export const CONBINI_CODE = {
  SEVEN_ELEVEN: '00007',
  LAWSON: '10001',
  FAMILY_MART: '10002',
  MINISTOP: '10005',
  SEIKO_MART: '10008',
};

export const WEEK_DAYS = {
  ZH_CN: '星期天_星期一_星期二_星期三_星期四_星期五_星期六',
  EN: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat',
  JA: '日_月_火_水_木_金_土',
};
