import { REQUEST } from '@stores';
import { Payload } from '@type/Store';
import {
  LOGIN,
  REGISTER,
  LOAD_PROFILE,
  LOGOUT,
  VERIFICATION,
  RESET_PASSWORD,
  VERIFY_REGISTER,
  RESEND_CODE,
  RESET_AUTH,
  CHANGE_LANGUAGE,
} from './constants';

export function login(payload: Payload) {
  return {
    type: REQUEST(LOGIN),
    payload,
  };
}

export function register(payload: Payload) {
  return {
    type: REQUEST(REGISTER),
    payload,
  };
}

export function verifyRegister(payload: Payload) {
  return {
    type: REQUEST(VERIFY_REGISTER),
    payload,
  };
}

export function reSendCode(payload: Payload) {
  return {
    type: REQUEST(RESEND_CODE),
    payload,
  };
}

export function logout(payload: Payload) {
  return {
    type: REQUEST(LOGOUT),
    payload,
  };
}

export function verification(payload: Payload) {
  return {
    type: REQUEST(VERIFICATION),
    payload,
  };
}

export function resetPassword(payload: Payload) {
  return {
    type: REQUEST(RESET_PASSWORD),
    payload,
  };
}

export function loadProfile() {
  return {
    type: REQUEST(LOAD_PROFILE),
  };
}

export function resetAuth() {
  return {
    type: RESET_AUTH,
  };
}

export function changeLaguage(payload: { language: string }) {
  return {
    type: REQUEST(CHANGE_LANGUAGE),
    payload,
  };
}
