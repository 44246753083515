import { Layout } from 'antd';
import styled from 'styled-components';

import Media from '@themes/media';

const { Content } = Layout;

export const TopicDetailStyle = styled(Content)`
  max-width: 1300px;
  padding: 40px 50px 0px 50px;
  margin: 0 auto;
  .title {
    font-size: ${({ theme }) => theme.size_20};
    font-weight: ${({ theme }) => theme.fw_700};
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 8px;
    ${Media.lessThan(Media.SIZE.MD)} {
      font-size: ${({ theme }) => theme.size_14};
    }
  }

  .content-top {
    padding: 20px 16px 16px 16px;
    background: #ffffff;
    width: 100%;
    height: auto;
    border-radius: 0px 0px 10px 10px;
    .content {
      /* line-height: 20px; */
      color: #787878;
      font-size: ${({ theme }) => theme.size_20};
      ${Media.lessThan(Media.SIZE.MD)} {
        font-size: ${({ theme }) => theme.size_12};
      }
    }
  }
  .chapters {
    padding: 30px 0px;
  }

  /* .chapter-detail {
    background-color: ${({ theme }) => theme.white};
    padding: 30px;
    .chapter-item {
      width: 100%;
    }
    .container {
      display: block;
      .chapter-center {
        padding: 20px 0px 0px 0px;
        ${Media.greaterThan(Media.SIZE.MD)} {
          .date,
          .time {
            font-size: ${({ theme }) => theme.size_14};
          }
          .chapter-content {
            font-size: ${({ theme }) => theme.size_16};
          }
        }
      }
    }
    .title {
      padding-bottom: 16px;
    }
    ${Media.lessThan(Media.SIZE.LG)} {
      padding: 10px;
      .container {
        display: flex;
        .chapter-center {
          padding: 0px 8px;
        }
        .chapter-right {
          text-align: end;
        }
      }
    }
  } */

  ${Media.lessThan(Media.SIZE.LG)} {
    padding: 16px 12px;
    padding-bottom: 32px;
  }

  .col-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .text-sort {
      display: flex;
      align-items: center;

      .ant-divider-vertical {
        height: 28px;
      }
    }
  }
`;

export const ImageBannerTop = styled.div`
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  aspect-ratio: 16 / 9;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    border-radius: 10px;
    margin-bottom: 24px;
  }
`;

export const LoadingPage = styled.div`
  height: 400px;
`;
