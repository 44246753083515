import Media from '@themes/media';
import styled from 'styled-components';

export const Wrapper = styled.footer`
  width: 100%;

  .icon-footer {
    cursor: pointer;
  }

  .logo-icon {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .icon-footer-img {
    cursor: pointer;
  }

  .col-top {
    max-width: 1300px;
    padding: 0 50px;
    margin: 28px auto;
    background-color: ${({ theme }) => theme.white};
    .app-info {
      border-bottom: 1px solid #d9d9d9;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .app-info__right {
        display: flex;
        align-items: center;
        justify-content: center;

        .app-info__store {
          margin-right: 32px;

          .app-info__store__icon {
            margin-top: 16px;
            display: flex;
            justify-content: flex-end;
            gap: 8px;
          }
        }
        .logo-icon {
          margin: 20px 0px;
        }

        .app-info__store__img {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 26px;
        }
      }
    }

    .web-info {
      padding-top: 16px;

      .web-info__policy {
        display: flex;
        align-items: center;
        .ant-typography {
          font-weight: 500;
          cursor: pointer;
        }
      }
      .block-logo {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
      }
      .web-info__login {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 75px;
        font-weight: 500;
      }
    }

    ${Media.lessThan(Media.SIZE.SM)} {
      padding: 40px 10px 20px 10px;

      .app-info {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .icon-footer {
          margin-bottom: 10px;
        }

        .app-info__right {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          .app-info__store {
            margin-right: initial;

            .app-info__store__icon {
              display: flex;
              justify-content: center;
              margin-bottom: 10px;
            }
          }
        }
      }

      .web-info {
        display: flex;
        flex-direction: column;

        .web-info__policy {
          justify-content: center;
          margin-bottom: 8px;
        }
        .web-info__login {
          flex-direction: column;
          gap: 10px;
        }
      }
    }
  }

  .col-bottom {
    background-color: ${({ theme }) => theme.dark};
    .copyright {
      max-width: 1300px;
      padding: 28px 50px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .buy-diamond {
        cursor: pointer;
      }

      .ant-typography {
        color: ${({ theme }) => theme.white};

        &.buy-diamond_title {
          margin: 0px 8px;
          font-weight: 700;
        }
      }

      ${Media.lessThan(Media.SIZE.SM)} {
        flex-direction: column-reverse;
        .buy-diamond {
          margin-bottom: 10px;
        }
      }
    }
  }

  .margin-right {
    margin-right: 32px;

    ${Media.lessThan(Media.SIZE.LG)} {
      margin-right: 0px;
    }
  }
  .custom-footer {
    display: flex;
    align-items: center;
    text-align: center;
    ${Media.lessThan(Media.SIZE.XL)} {
      margin-bottom: 16px;
    }
    ${Media.lessThan(Media.SIZE.MD)} {
      flex-direction: column;
      margin-bottom: 20px;
    }
    .web-info__policy {
      width: 100%;
      justify-content: space-evenly;
      ${Media.lessThan(Media.SIZE.MD)} {
        column-gap: 30px;
      }
    }
    .text-description {
      width: 100%;
      text-align: start;
      ${Media.lessThan(Media.SIZE.MD)} {
        text-align: center;
      }
    }
  }
`;
