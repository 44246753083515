import React from 'react';
import { BACK_ICON } from '@assets';
import styled from 'styled-components';
import Media from '@themes/media';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 42px 36px 30px 36px;
  gap: 12px;
  border-bottom: 1px solid #e5e6eb;
  font-family: 'Noto Sans JP';

  img {
    cursor: pointer;
    margin-right: 8px;
  }
  h3 {
    margin: 0;
    font-size: ${({ theme }) => theme.size_20};
    font-weight: ${({ theme }) => theme.fw_500};
    color: ${({ theme }) => theme.dark};
  }

  ${Media.lessThan(Media.SIZE.MD)} {
    gap: 0;
    padding: 16px 10px;
  }
`;

const Header: React.FC<{ title: string; onClick?: () => void }> = ({ title, onClick }) => {
  return (
    <Wrapper>
      <div onClick={onClick} className="back-icon">
        <img className="logo" src={BACK_ICON} alt="logo" />
      </div>
      <h3>{title}</h3>
    </Wrapper>
  );
};

export default Header;
