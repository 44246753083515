import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ICON_COIN, ICON_MINUS, ICON_PLUS_ROUNDED, ICON_PLUS_DISABLED, ICON_MINUS_DISABLED } from '@assets';
import { translations } from '@i18n/translations';
import { Ticket } from '@type/Store/event';
import { useForm, useWatch } from 'react-hook-form';
import { TicketFormGroup } from '@type/Form/BuyTicketForm';
import { numberWithCommas } from '@utils/number';
import { TicketCounter } from '../../styles';

type TicketProps = {
  ticket: Ticket;
  values: TicketFormGroup;
  index: number;
  update: any;
  control: any;
};

const TicketType: React.FC<TicketProps> = ({ ticket, values, index, update, control }) => {
  const { t } = useTranslation();
  const { common } = translations;

  const { register, setValue, getValues } = useForm<TicketFormGroup>({
    defaultValues: values,
  });

  const data = useWatch({
    control,
    name: `tickets.${index}`,
  });

  const updateData = (counter) => {
    update(index, {
      ...data,
      quantity: counter,
    });
    setValue('quantity', counter);
  };

  const minusCounter = () => {
    const quantity = +getValues('quantity');
    if (quantity === 0) return;
    const counter = data.quantity - 1;
    updateData(counter);
  };

  const incrementCounter = () => {
    const quantity = +getValues('quantity');
    if (quantity === ticket.pay_limit) return;
    const counter = quantity + 1;
    updateData(counter);
  };

  const onChangeQuantityTicket = (value) => {
    const convertNumber = value.replace(/\D/g, '');
    const minValue = Math.min(Number(convertNumber || '0'), ticket.quantity, ticket.pay_limit);
    updateData(minValue);
  };

  const textField = register('quantity', { required: true, onChange: (e) => onChangeQuantityTicket(e.target.value) });

  const checkRenderPriceTax = useMemo(() => {
    if (ticket?.price_yen_tax === null || ticket?.price_yen_tax === undefined) return false;
    if (ticket?.price_coin_tax === null || ticket?.price_coin_tax === undefined) return false;

    return (
      ticket?.price_yen_tax !== null &&
      ticket?.price_yen_tax !== undefined &&
      ticket.price_coin_tax !== null &&
      ticket.price_coin_tax !== undefined
    );
  }, [ticket?.price_coin_tax, ticket?.price_yen_tax]);

  const isShowFee = useMemo(() => {
    if (values.coinPriceIsNil) {
      return values.yenFee > 0;
    }

    if (values.yenPriceIsNil) {
      return values.coinFee > 0;
    }
    if (!values.yenPriceIsNil && !values.coinPriceIsNil) {
      return values.coinFee > 0 || values.yenFee > 0;
    }
    return true;
  }, [values.coinPriceIsNil, values.yenPriceIsNil, values.coinFee, values.yenFee]);

  return (
    <TicketCounter>
      <div className="content-ticket">
        <label className="ticket-name">{ticket.name}</label>
        <div className="price">
          {ticket?.price_yen_tax !== null && ticket?.price_yen_tax !== undefined && (
            <span className="currency">￥{numberWithCommas(Number(ticket.price_yen_tax), ',')}</span>
          )}
          {checkRenderPriceTax && '/'}
          {ticket.price_coin_tax !== null && ticket.price_coin_tax !== undefined && (
            <span className="coin">
              <ICON_COIN />
              {numberWithCommas(Number(ticket.price_coin_tax), ',')}
            </span>
          )}
          {isShowFee && <span className="tax-title">{t(common.event.including_fees)}</span>}
        </div>
      </div>
      <div className="ticket-action">
        <div className="counter">
          {Number(getValues('quantity')) > 0 ? (
            <ICON_MINUS onClick={() => minusCounter()} />
          ) : (
            <ICON_MINUS_DISABLED style={{ cursor: 'not-allowed' }} />
          )}

          <input
            className="quantity"
            {...textField}
            style={{ color: data.quantity > ticket.pay_limit ? 'red' : 'black' }}
          />
          {Number(getValues('quantity')) === Number(ticket.pay_limit) ? (
            <ICON_PLUS_DISABLED style={{ cursor: 'not-allowed' }} />
          ) : (
            <ICON_PLUS_ROUNDED onClick={() => incrementCounter()} />
          )}
        </div>
        <p className="original_quantity">
          {t(common.event.available_quantity)}: {ticket.pay_limit}
        </p>
      </div>
    </TicketCounter>
  );
};

export default TicketType;
