import en from 'antd/lib/locale/en_US';
import jp from 'antd/lib/locale/ja_JP';
import vi from 'antd/lib/locale/vi_VN';

export * from './topic';
export * from './agora';
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const ANTD_ORDER_TO_API_FIELD = {
  descend: 'DESC',
  ascend: 'ASC',
};

export const LOCALES: any = {
  vi,
  jp,
  en,
};

export const FORMAT_TIME = {
  YEAR_MONTH_DATE: 'YYYY/MM/DD',
  DATE_HOUR_MINUTES: 'YYYY/MM/DD HH:mm',
  DATE_HOUR_MINUTES_SECOND: 'YYYY-MM-DD HH:mm:ss',
  HOUR_MINUTES: 'HH:mm',
  YEAR_MONTH_DATE_LOWER: 'YYYY-MM-DD',
  HOUR_MINUES_LOWER: 'HH:mm',
  HOUR_MINUES_SECOND: 'HH:mm:ss',
  MINUES_SECOND_LOWER: 'mm:ss',
  FULL_DATE: 'YYYY/MM/DD HH:mm',
  DATE_HOUR_MINUTES_DAY_OF_WEEK: 'YYYY/MM/DD (dddd) HH:mm',
};

export const PAY_METHOD = {
  CREDIT: '1',
  METAMASK: '2',
};

export enum MODAL_TYPE {
  DEFAULT = 'DEFAULT',
  CONFIRM = 'CONFIRM',
  INPUT = 'INPUT',
  TEXT_AREA = 'TEXT_AREA',
}

export enum STATUS_TYPE {
  SUCCESS = 'SUCCESS',
  REQUEST = 'REQUEST',
  FAIL = 'FAIL',
}

export const CHAT_TYPE = {
  DONATE: 1,
  NORMAL: 0,
};

// export const LIVE_STATUS = {
//   PREPARE: "prepare",
//   LIVE: "live",
//   END: "end",
// }

export const LIVE_STATUS = {
  COMING: 1,
  LIVE: 2,
  END: 3,
};

export type LiveStatus = 0 | 1 | 2 | 3;

export type ChatType = 1 | 0;
