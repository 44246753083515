/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React from 'react';
import Modal from 'react-modal';
import { Spin, Button, ConfigProvider } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import Media from '@themes/media';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const customStyles = {
  overlay: {
    // backdropFilter: 'blur(0.5rem)',
    // willChange: 'backdrop-filter',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1200,
  },
};

const ModalWrapper = styled(Modal)`
  box-sizing: border-box;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: 'tabular-nums';
  line-height: 1.5715;
  list-style: 'none';
  font-feature-settings: 'tnum', 'tnum';
  pointer-events: none;
  position: relative;
  top: 30vh;
  max-width: 40vh;
  margin: 0 auto;
  padding-bottom: 24px;
  backdrop-filter: unset !important;
  backdrop-filter: blur;

  ${Media.lessThan(Media.SIZE.SM)} {
    .btn-confirm {
      width: 12vh !important;
    }
    .ant-modal-body {
      height: 17.5vh !important;
      font-size: 12px !important;
      padding: 0px 20px !important;
    }
    .ReactModal__Content {
      width: 80% !important;
    }
    .ant-modal-content {
      width: 35vh !important;
      right: 5vh;
    }
  }

  ${Media.lessThan(Media.SIZE.XXS)} {
    .btn-confirm {
      width: 15vh !important;
    }
    .ant-modal-body {
      height: 17.5vh !important;
      font-size: 12px !important;
      padding: 0px 20px !important;
    }

    .ant-modal-content {
      width: 35vh !important;
      right: 5vh;
    }
    .button__cancel {
      width: 13.5vh !important;
      text-align: center;
    }
  }

  width: ${(theme: any) => {
    switch (theme.size) {
      case 'large':
        return '100%';
      default:
        return '55%';
    }
  }};

  .success-icon {
    right: 20px;
    text-align: center;
  }
  .content-modal {
    text-align: center;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    color: #787878;
  }

  .btn-confirm {
    background: #e3007f;
    border-radius: 24px;
    width: 12vh;
    height: 4.8vh;
    color: #ffffff;
  }
  .ant-modal-content {
    backdrop-filter: unset !important;
    border-radius: 1rem;
    border: 1px solid #787878;
    max-height: 80vh;
    overflow: ${(theme: any) => theme.overflow || 'auto'};
    .ant-modal-header {
      height: 20px;
      border-bottom: unset;
    }
    .ant-modal-body {
      //  overflow-x: ${(theme: any) => theme.overflow || 'auto'};
      // max-height: calc(80vh - 60vh)
      height: 12vh;
      padding: 10px 20px !important;
    }
    .ant-modal-footer {
      display: flex;
      justify-content: center;
      height: 10vh;
      border-top: unset;
      .button__cancel {
        margin-right: 10px;
        background: #d9d9d9;
        border-radius: 24px;
        width: 12vh;
        height: 4.8vh;
        color: #787878;
      }
    }
  }
`;

const Error = styled.div`
  background-color: ${({ theme }) => theme.bg_error};
  border: 1px solid ${({ theme }) => theme.bd_error};
  padding: 8px 16px;
`;

const PaymentConfirmComponent = ({
  title,
  visible,
  onCancel,
  children,
  onSubmit,
  disabledSubmit,
  onSubmitText,
  onCancelText,
  type,
  confirm = true,
  cancel = true,
  loadingSubmit = false,
  isLoadingSubmit,
  confirmClick,
  isNotFooterButton = false,
  onClose,
  ...rest
}: any) => {
  const { t } = useTranslation(['common']);
  const renderModalBodyBaseOnType = () => {
    switch (type) {
      case 'error':
        return <Error>{children}</Error>;
      default:
        return (
          <>
            <div className="success-icon" />
            <br />
            <div className="content-modal">{t('confirm_payment_modal')}</div>
          </>
        );
    }
  };

  const renderButtonSubmit = () => {
    if (confirm) {
      return (
        <div id="modal-confirm-popup-container">
          <ConfigProvider getPopupContainer={(): any => document.getElementById('modal-confirm-popup-container')}>
            <Button
              className="btn-confirm"
              htmlType="button"
              onClick={confirmClick}
              disabled={disabledSubmit}
              loading={isLoadingSubmit}
            >
              {onSubmitText || t('ok')}
            </Button>
          </ConfigProvider>
        </div>
      );
    }
    if (loadingSubmit) {
      return (
        <Spin spinning={isLoadingSubmit} wrapperClassName="button">
          <Button type="primary" htmlType="button" disabled={disabledSubmit} onClick={onSubmit}>
            {onSubmitText || t('ok')}
          </Button>
        </Spin>
      );
    }
    return (
      <Button type="primary" htmlType="button" disabled={disabledSubmit} onClick={onSubmit}>
        {onSubmitText || t('ok')}
      </Button>
    );
  };

  return (
    <ModalWrapper isOpen={visible} onRequestClose={onClose} style={customStyles} ariaHideApp={false} {...rest}>
      <form>
        <div className="ant-modal-content">
          <div className="ant-modal-header">
            <button className="ant-modal-close" onClick={onClose || onCancel} type="button">
              {/* <div className="ant-modal-close-x"> */}
              {/*	<div className="anticon anticon-close ant-modal-close-icon"> */}
              {/*		<CloseOutlined /> */}
              {/*	</div> */}
              {/* </div> */}
            </button>
            {title && (
              <div
                style={{
                  height: 36,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: 0,
                  fontSize: 24,
                }}
              >
                <EditOutlined />
                <span
                  style={{
                    fontSize: 24,
                    fontWeight: 700,
                    padding: 0,
                    lineHeight: 1.2,
                    marginTop: 5,
                  }}
                >
                  &nbsp;
                  {title}
                </span>
              </div>
            )}
          </div>
          <div className="ant-modal-body">{renderModalBodyBaseOnType()}</div>

          {!isNotFooterButton && (
            <div className="ant-modal-footer">
              {cancel && (
                <button type="button" onClick={onCancel} className="ant-btn button__cancel">
                  <span>{onCancelText || t('cancel')}</span>
                </button>
              )}
              {renderButtonSubmit()}
            </div>
          )}
        </div>
      </form>
    </ModalWrapper>
  );
};

export default PaymentConfirmComponent;
