import React, { useMemo } from 'react';

import { GIF_BOX, RUBY } from '@assets';
import { Button } from '@components';
import { translations } from '@i18n/translations';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  onClose: () => void;
}

const Wrapper = styled.div`
  position: relative;
  width: 315px;
  display: flex;
`;

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 94px;
  z-index: 1;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  position: absolute;
  top: 48px;
  left: 0px;
  border-radius: 16px;
  align-items: center;
  flex-direction: column;
  background: white;
  height: 260px;

  .title {
    font-size: 16px;
    font-weight: 500;
    color: #272828;
    text-align: center;
  }

  .guider {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    color: #787878;

    span {
      font-weight: 700;
      color: #e80054;
    }
  }
`;

export const GifGuider: React.FC<Props> = ({ onClose }) => {
  const { t, i18n } = useTranslation();
  const { livestream, common } = translations;
  const guider2 = useMemo(() => {
    if (i18n.language === 'ja')
      return (
        <>
          <img src={RUBY} width={16} alt="ruby" />
          <span>{` 5 ~ `}</span>
          <img src={RUBY} width={16} alt="ruby" />
          <span>{` 20`}</span>
          {t(livestream.get_ruby.guider2)}
        </>
      );

    return (
      <>
        {t(livestream.get_ruby.guider2)}
        <img src={RUBY} width={16} alt="ruby" />
        <span>{` 5 ~ `}</span>
        <img src={RUBY} width={16} alt="ruby" />
        <span>{` 20 `}</span>
      </>
    );
  }, [i18n.language]);

  return (
    <Wrapper>
      <ImageWrapper>
        <img width={94} height={94} style={{ objectFit: 'contain' }} src={GIF_BOX} alt="" />
      </ImageWrapper>
      <Content>
        <div className="title">{t(livestream.get_ruby.guider_title)}</div>
        <div className="guider">{t(livestream.get_ruby.guider1)}</div>
        <div className="guider">{guider2}</div>
        <div className="guider">{t(livestream.get_ruby.guider3)}</div>
        <Button style={{ marginTop: 16 }} type="primary" onClick={onClose}>
          {t(common.ok)}
        </Button>
      </Content>
    </Wrapper>
  );
};
