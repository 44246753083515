import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'antd';
import ButtonComponent from '@components/button';
import { FormInput, FormLabel } from '@components';
import { useAuth } from '@hooks';
import { Wrapper, InputLogin } from '@themes/facit';
import { SendMailScheme } from '@utils/messageValdate';
import SendEmailForm from '@type/Form/SendEmailForm';
import { LOGO_LOGIN } from '@assets';
import { useHistory } from 'react-router-dom';
import { RoutesName } from '@modules/auth/routes';
import ConfirmComponent from '@components/modal/confirmModal';
import { translations } from '@i18n/translations';
import { Row, Title } from './styled';

const SEND_TYPE = {
  PHONE: 'sent_to_phone',
  EMAIL: 'sent_to_email',
};
const SendEmailScreen: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const form = useForm<SendEmailForm>({
    defaultValues: {
      email: '',
    },
    reValidateMode: 'onSubmit',
    mode: 'onSubmit',
    resolver: yupResolver(SendMailScheme(t)),
  });
  const { handleSubmit } = form;
  const { verificationAction, verifyPass, isLoading } = useAuth();
  const [failureMailVisible, setIsfailureMailVisible] = useState(false);
  const [sucessMailVisible, setIssucessMailVisible] = useState(false);
  const [disableButton, setIsDisableButton] = useState<boolean>(false);
  const history = useHistory();
  const onSubmit = useCallback(
    (data) => {
      setIsDisableButton(true);
      verificationAction({ email: data.email, language });
    },
    [language, verificationAction],
  );

  const cancel = () => {
    history.push(RoutesName.LOGIN);
  };

  const resetPasswordOk = () => {
    history.push(RoutesName.RESET_PASSWORD);
  };

  useEffect(() => {
    if (verifyPass?.error && verifyPass.error?.message) {
      setIsfailureMailVisible(true);
    } else {
      setIsfailureMailVisible(false);
    }
    if (verifyPass?.data) {
      setIssucessMailVisible(true);
    } else {
      setIssucessMailVisible(false);
    }
    setIsDisableButton(false);
  }, [verifyPass]);

  const failurePaymentConfirm = () => {
    setIsfailureMailVisible(false);
  };
  const { common } = translations;
  return (
    <Wrapper>
      <div className="form-login">
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="logo-header">
              {' '}
              <LOGO_LOGIN />
            </div>
            <div className="label-header">
              <label>{t(common.password_reset)}</label>
            </div>
            <br />
            <ConfirmComponent
              visible={failureMailVisible}
              cancel={false}
              confirmClick={failurePaymentConfirm}
              subText={t(`api_error:${verifyPass.error?.message}`).split(',,')[0]}
              // subTextLoginSubTextForgot={t('error_message:'+ errors?.message).split(',,')[1]}
              statusConfirm={false}
            />
            <Row>
              <Title>
                <FormLabel title={t(common.email)} star="*" />
              </Title>
              <InputLogin>
                <FormInput name="email" placeholder={t(common.placeholder_email)} />
              </InputLogin>
            </Row>
            <div className="form-action-group">
              <ButtonComponent
                type="default"
                htmlType="submit"
                loading={isLoading}
                className="btn-login"
                disabled={disableButton}
              >
                {t(common.send_email)}
              </ButtonComponent>
              <br />
              <br />
              <Button className="btn-cancel" onClick={cancel}>
                {t(common.cancel)}
              </Button>
            </div>
            <ConfirmComponent
              visible={sucessMailVisible}
              cancel={false}
              confirmClick={resetPasswordOk}
              textContent={
                verifyPass.data?.sent_from === SEND_TYPE.PHONE
                  ? t(common.alert_send_phone_success_parent)
                  : t(common.alert_send_email_success_parent, { email: verifyPass?.data?.target })
              }
              subTextContent={
                verifyPass?.data?.sent_from === SEND_TYPE.PHONE
                  ? t(common.alert_send_phone_success_chil)
                  : t(common.alert_send_email_success_chil)
              }
            />
          </form>
        </FormProvider>
      </div>
    </Wrapper>
  );
};

export default SendEmailScreen;
