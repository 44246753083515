import {
  AMERICAN_EXPRESS,
  DINERS_CLUB,
  FAMILY_MART,
  ICON_COMPANY_MINI_STOP,
  JCB_BANK,
  LAWSON,
  MASTER_CARD_ICON,
  // SECOMA,
  // SEVEN_ELEVEN,
  VISA_ICON,
} from '@assets';
import { translations } from '@i18n/translations';

export const CARDS = [
  {
    src: VISA_ICON,
    alt: 'Visa',
  },
  {
    src: MASTER_CARD_ICON,
    alt: 'Master Card Icon',
  },
  {
    src: AMERICAN_EXPRESS,
    alt: 'American Express',
  },
  {
    src: JCB_BANK,
    alt: 'JCB BANK',
  },
  {
    src: DINERS_CLUB,
    alt: 'DINERS CLUB',
  },
];

const { realEventPayment } = translations;
const { kobini } = realEventPayment;

export const KONBINIS = [
  // {
  //   src: SEVEN_ELEVEN,
  //   alt: 'SEVEN_ELEVEN',
  //   isIcon: false,
  //   Icon: null,
  //   label: kobini.seven,
  // },
  {
    src: FAMILY_MART,
    alt: 'FAMILY_MART',
    isIcon: false,
    Icon: null,
    label: kobini.family,
  },
  {
    src: LAWSON,
    alt: 'LAWSON',
    isIcon: false,
    Icon: null,
    label: kobini.lawson,
  },
  {
    src: '',
    alt: 'ICON_COMPANY_MINI_STOP',
    isIcon: true,
    Icon: ICON_COMPANY_MINI_STOP as any,
    label: kobini.miniStop,
  },
  // {
  //   src: SECOMA,
  //   alt: 'SECOMA CLUB',
  //   isIcon: false,
  //   Icon: null,
  // },
];
