import Media from '@themes/media';
import styled from 'styled-components';

export const Content = styled.div`
  padding: 36px;
  margin-bottom: 30px;
  border-radius: 30px;
  background: ${({ theme }) => theme.white};
  box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.1);

  h1 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 12px;
    color: ${({ theme }) => theme.dark};
  }
  .title {
    font-size: 20px;
    font-weight: 700;
    color: #282727;
  }

  .content-top {
    margin-top: 20px;
  }

  .content {
    margin-bottom: 12px;
    font-size: 20px;
    color: #282727;
    margin-bottom: 0;
    font-weight: 400;
    .ant-typography-expand {
      color: #0076e3;
      text-decoration-line: underline;
    }

    .time {
      font-size: 16px;
      font-weight: 700;
      color: #787878;
      opacity: 0.8;
    }
  }

  .content-child {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 2rem;
    margin-top: 20px;

    .btn-cancel {
      width: 319px;
      height: 88px;
      padding: 15px 25px;
      border-radius: 5px;
      white-space: pre-wrap;
      font-weight: ${({ theme }) => theme.fw_700};
      font-size: ${({ theme }) => theme.size_20};
      background: #d80027;
      color: ${({ theme }) => theme.white};
      margin: 100px 0;
    }

    .btn-purchase {
      max-width: 456px;
      width: 100%;
      height: 50px;
      font-weight: ${({ theme }) => theme.fw_700};
      font-size: ${({ theme }) => theme.size_16};
    }

    .btn-pre-order {
      max-width: 456px;
      width: 100%;
      height: 50px;
      font-weight: ${({ theme }) => theme.fw_700};
      font-size: ${({ theme }) => theme.size_16};
      background-color: #00cebc;
    }

    .content-border-dash {
      width: 100%;
      text-align: center;
      border: 2px dashed #d9d9d9;
      margin: 1.5rem 0 0.5rem 0;

      .message {
        padding: 65px 0;
        color: #bababa;
        font-size: 20px;
        p {
          margin: 0;
        }
      }
    }
  }
  ${Media.lessThan(Media.SIZE.LG)} {
    border-radius: 10px;
    margin-bottom: 24px;
    padding: 10px;
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    border-radius: 10px;
    margin-bottom: 24px;
    padding: 10px;

    h1 {
      font-size: ${({ theme }) => theme.size_16};
    }
    .content {
      font-size: ${({ theme }) => theme.size_14};
      .time {
        font-size: 12px;
      }
    }
    .content-child {
      gap: 1rem;
      .btn-purchase {
        height: 45px;
      }
      .btn-cancel {
        white-space: initial;
        padding: initial;
        width: 100%;
        margin: 28px 0 16px 0;
        height: 60px;
        font-size: ${({ theme }) => theme.size_16};
        box-sizing: border-box;
      }
    }
  }
`;
