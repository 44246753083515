import Media from '@themes/media';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px 8px;
  justify-items: flex-start;
  gap: 4px;
  ${Media.lessThan(Media.SIZE.MD)} {
  }
`;

export const Message = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  margin-top: 10px;
  color: #282727;
  font-size: 13px;
  font-weight: 400;
  white-space: pre-wrap;
  ${Media.lessThan(Media.SIZE.MD)} {
  }
`;

export const UserName = styled.span`
  margin: 0;
  padding: 0;
  color: #0076e3;
  font-size: 13px;
  font-weight: 500;
  margin-right: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 144px;
  ${Media.lessThan(Media.SIZE.MD)} {
    max-width: 170px;
  }
`;

export const TimeComment = styled.div`
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: right;
  ${Media.lessThan(Media.SIZE.MD)} {
  }
`;

export const Infor = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 4px 0;
  justify-content: space-between;
  gap: 4px;
  ${Media.lessThan(Media.SIZE.MD)} {
  }
`;

export const ButtonGroup = styled.div`
  font-size: 12px;
  display: flex;
  padding: 4px 0;
  gap: 8px;
  ${Media.lessThan(Media.SIZE.MD)} {
  }
`;

export const BtnEdit = styled.div`
  cursor: pointer;
  color: #0076e3;
  ${Media.lessThan(Media.SIZE.MD)} {
  }
`;

export const BtnDelete = styled.div`
  cursor: pointer;
  color: #d80027;
  ${Media.lessThan(Media.SIZE.MD)} {
  }
`;
