import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTopicPayment } from '@hooks/useTopicPayment';

import { useAuth } from '@hooks';
import { translations } from '@i18n/translations';
import { PAYMENT_STATUS } from '@constants';
import PaymentAllowsLogin from '../components/payment-allows-login';
import { TopicPaymentStyle } from './styled';
import TopicContent from '../components/topic-content';
import PaymentAllows from '../components/payment-allows';

interface Params {
  topicId?: string;
  packageId?: string;
}

export const TopicPayment: React.FC<Params> = () => {
  const { t } = useTranslation();
  const { realEventPayment } = translations;
  const { authenticated } = useAuth();
  const { topicId, packageId } = useParams<Params>();
  const history = useHistory();
  const { getPaymentInfo, setDeeplink, topic_info } = useTopicPayment();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');
  const deeplink = queryParams.get('deeplink');

  const handlePurchased = (type: string | null, topicId?: string, packageId?: string) => {
    switch (type) {
      case 'TOPIC':
        return history.push(`/topics/watch-video-chapter/${packageId}`);
      case 'PACKAGE':
        return history.push(`/topics/${topicId}/video-package/${packageId}`);
      case 'LIVE':
        return history.push(`/topics/watch-livestream?room=${topic_info.live_key}`, { state: null });
      default:
        return null;
    }
  };

  useEffect(() => {
    if (type && topicId) {
      getPaymentInfo({ type, topicId, packageId });
    }
  }, [type, topicId, packageId]);

  useEffect(() => {
    setDeeplink(deeplink || '');
  }, [deeplink]);

  useEffect(() => {
    if (topic_info.payment_status === PAYMENT_STATUS.SUCCEEDED) {
      handlePurchased(type, topicId, packageId);
    }
    if (topic_info.payment_status === PAYMENT_STATUS.PROCESSING) {
      const params = type === 'PACKAGE' ? 'packages' : 'episodes';
      history.replace(`/topics/${topicId}?page=${params}`);
    }
  }, [topic_info]);

  const navigateLogin = () => {
    history.push('/login', { navigateTo: `/topics/${topicId}/payment/${packageId}?type=${type}&deeplink=${deeplink}` });
  };

  return (
    <TopicPaymentStyle>
      <TopicContent>
        <div style={{ width: '100%' }}>
          <div style={{ width: '100%', height: 2, borderRadius: 10, background: '#F6F6F6', marginBottom: 14 }} />
          <p style={{ fontSize: 14, fontWeight: 700, color: '#282727' }}>{t(realEventPayment.accept_payment)}</p>
          <p style={{ fontSize: 12, fontWeight: 400, color: '#787878' }}>{t(realEventPayment.select_payment)}</p>
        </div>
        {authenticated ? <PaymentAllowsLogin /> : <PaymentAllows onClick={navigateLogin} />}
      </TopicContent>
    </TopicPaymentStyle>
  );
};
