/* eslint-disable no-console */

import React, { useEffect, useMemo } from 'react';
import useAgora from '@hooks/useAgora';

import { useAuth, useSearchParams, useTopics } from '@hooks';
import { LIVESTREAM_STATUS, AGORA_CONNECTION_STATUS } from '@constants';
import { Spin } from 'antd';
import { useJoinLeftRoom } from '@hooks/useUserJoinTopic';
import { useLiveStreamStatus } from '@hooks/useLiveStreamStatus';
import { useHistory } from 'react-router-dom';
import { database, off, onValue, query, ref } from '@apis/firebase';
import { Avatar } from '@components';
import { translations } from '@i18n/translations';
import { useTranslation } from 'react-i18next';
import { Wrapper, Livestream, Video, VideoStream, BlurBackground, EndLive } from './styled';
import { Sider } from './components';
import ViewLayer from './components/view-layer';
import { TopicInfo } from '../components';
import ShowGif from './components/show-gif';
import ShowHearts from './components/show-heart';

interface SearchParams {
  room?: string | null;
}

const WatchLiveStream: React.FC = () => {
  const { t } = useTranslation();

  const { joinChannel, leaveChannel, connectionState, networkQuality } = useAgora();
  const { room } = useSearchParams<SearchParams>();
  const { getLiveStreamDetail, liveStream, password } = useTopics();
  const { status, isDonate } = useLiveStreamStatus({ roomId: room });
  const { data } = liveStream;
  const { replace } = useHistory();
  const { profile, isUser } = useAuth();
  const [liveStatus, setLiveStatus] = React.useState<null | 'live' | 'end' | 'prepare'>('live');
  const { livestream: liveStreamTranslate } = translations;

  useJoinLeftRoom(room);

  useEffect(() => {
    if (data && data.password_flag && !password.chapter_id && data.program_id) {
      replace(`/topics/${data.program_id}?page=episodes`);
    }

    if (data && !data.password_flag && !data.purchased && Number(data.amount) && isUser) {
      replace(`/topics/${data.program_id}/payment/${data.id}?type=TOPIC`);
    }
  }, [data, password, isUser]);

  useEffect(() => {
    if (
      !!liveStream &&
      liveStream.data?.status === LIVESTREAM_STATUS.LIVE &&
      !!room &&
      (liveStream.data?.purchased ||
        Number(liveStream.data?.amount === 0) ||
        (liveStream.data?.password_flag && password.chapter_id && liveStream.data.program_id))
    ) {
      joinChannel({ channel: room, roomToken: liveStream.roomToken || '' });
    } else {
      leaveChannel();
    }

    return () => {
      leaveChannel();
    };
  }, [liveStream, room, password]);

  useEffect(() => {
    if (room && profile?.id) {
      getLiveStreamDetail({ roomId: room, user_id: profile.id });
    }
  }, [room, profile?.id]);

  const muted = useMemo(() => connectionState !== AGORA_CONNECTION_STATUS.CONNECTED, [connectionState]);

  const loading = useMemo(() => networkQuality > 5 || networkQuality < 1, [connectionState, status, networkQuality]);

  useEffect(() => {
    let childChanged;
    if (room) {
      childChanged = onValue(
        query(ref(database, `/topic/${room}/status_official`)),
        (snapshot) => {
          if (snapshot.exists() && snapshot.val()) {
            setLiveStatus(snapshot.val());
          } else {
            setLiveStatus('end');
          }
        },
        (err) => console.log('======> ERROR: ', err), // on error
        {
          onlyOnce: false,
        },
      );
    }
    if (!room) {
      replace('/');
    }
    return () => {
      off(query(ref(database, `/topic/${room}/status_official`)), 'value', childChanged);
    };
  }, [room]);

  return (
    <Wrapper>
      <Livestream>
        {liveStatus === 'live' ? (
          <Spin spinning={loading} size="large">
            <VideoStream>
              <Video controls={false} autoPlay muted={muted} id="remote-video" />
              <ViewLayer roomId={room} />
              <ShowGif liveKey={room} />
              <ShowHearts liveKey={room} />
            </VideoStream>
          </Spin>
        ) : (
          <VideoStream>
            <Video controls={false} autoPlay={false} muted id="remote-video" />
            <BlurBackground background={liveStream.data?.program?.images || ''} />
            <ViewLayer roomId={room} />
            <EndLive>
              <Avatar width={180} isBorder={false} src={liveStream.data?.program?.images || ''} />
              <p>{liveStream.data?.program?.title}</p>
              <p>{t(liveStreamTranslate.endLive)}</p>
            </EndLive>
          </VideoStream>
        )}
        <TopicInfo
          roomId={room}
          title={liveStream.data?.title}
          description={liveStream.data?.description}
          name={liveStream.data?.program?.title}
          src={liveStream.data?.program?.images}
          topicId={liveStream?.data?.program_id}
          isShowSocial
          isDonate={isDonate}
        />
      </Livestream>
      <Sider roomId={room} />
    </Wrapper>
  );
};

export default WatchLiveStream;
