import styled from 'styled-components';
import { Typography } from 'antd';

import Media from '@themes/media';
import Colors from '@themes/colors';

export const CardTicketStyled = styled.div`
  width: 100%;
  min-height: 331px;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 16px;
  cursor: pointer;
  box-shadow: rgba(255, 255, 255, 0.1);
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .ant-image {
    margin-bottom: 16px;
    position: relative;
    aspect-ratio: 5 / 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;

    .ant-image-img {
      border-radius: 15px 15px 0px 0px;
      width: 100%;
      position: absolute;
      top: 0px;
      object-fit: cover;
      height: 100%;
    }
  }
`;

export const TitleCardStyled = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const TimeCardStyled = styled(Typography)`
  color: ${Colors.text_date_time};

  &.text-time-end {
    text-align: right;
  }
`;

export const CancelMark = styled.div`
  position: absolute;
  padding: 6px 25px;
  background: #d80027;
  color: white;
  top: 10px;
  left: 10px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;

  ${Media.lessThan(Media.SIZE.SM)} {
    padding: 2px 7px;
    font-size: 12px;
  }
`;
