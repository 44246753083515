import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { translations } from '@i18n/translations';
import { EventDetail } from '@type/Store/event';

import EventContent from '../event-content';
import EventDescription from '../event-description';

type EventProps = {
  event: EventDetail;
};

const EventCancel: React.FC<EventProps> = ({ event }) => {
  const { t } = useTranslation();
  const { common } = translations;

  return (
    <>
      <EventContent event={event}>
        <Button className="btn-cancel">{t(common.event.event_cancel)}</Button>
      </EventContent>
      <EventDescription event={event} />
    </>
  );
};
export default EventCancel;
