import { Clickable } from '@components';
import React, { useEffect, useState, useRef, FC } from 'react';
import styled from 'styled-components';
import { GIF_BOX } from '@assets';
import { sec2Time } from '@utils/time';
import { LIVE_STATUS, LiveStatus } from '@constants';
import { useAuth } from '@hooks';
import { getPointFree } from '@apis';
import { GifGuider } from '../modal/gif-guider';
import { PopupBoxFree } from '../modal/box-free';
import Modal from '../modal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 6px;
`;

const CountDown = styled.span`
  font-size: 10px;
  font-weight: 700;
  margin-left: 4px;
  color: white;
  margin: 0;
`;

interface Props {
  receivedFree?: boolean;
  liveStatus: LiveStatus; // 1 chua dien tra 2 dang dien ra 3 ket thuc
  roomId: string;
}
export const BoxFree: FC<Props> = ({ receivedFree, liveStatus, roomId }) => {
  const [countdown, setCountdown] = useState(600);
  const timerRef = useRef(countdown);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<'guider' | 'getBox'>('guider');
  const [showBox, setShowBox] = useState(false);
  const { isUser, loadProfileAction } = useAuth();
  const [pointFree, setPointFree] = useState(0);
  useEffect(() => {
    setShowBox(!receivedFree && isUser);
  }, [receivedFree, isUser]);

  useEffect(() => {
    let countId;

    if (showBox) {
      countId = setInterval(() => {
        timerRef.current -= 1;
        if (liveStatus === LIVE_STATUS.END) {
          clearInterval(countId);
          setShowBox(false);
        } else if (timerRef.current < 1) {
          clearInterval(countId);
          setShowBox(false);
          setOpen(true);
          setType('getBox');
          getPointFree(roomId).then(({ data, status }) => {
            const { data: response } = data;
            if (status === 200) {
              setPointFree(response.points_free);
              loadProfileAction();
            }
          });
        } else {
          setCountdown(timerRef.current);
        }
      }, 1000);
    }
    return () => {
      clearInterval(countId);
    };
  }, [liveStatus, showBox]);

  const onClose = () => setOpen(false);

  return (
    <Wrapper>
      {showBox && liveStatus === LIVE_STATUS.LIVE && isUser && (
        <>
          <Clickable onClick={() => setOpen(true)}>
            <img width={48} style={{ objectFit: 'contain' }} src={GIF_BOX} alt="" />
          </Clickable>
          <CountDown>{sec2Time(countdown)}</CountDown>
        </>
      )}
      {isUser && (
        <Modal open={open} footer={null} centered closeIcon={<div />}>
          {type === 'guider' ? (
            <GifGuider onClose={onClose} />
          ) : (
            <PopupBoxFree pointFree={pointFree} onClose={onClose} />
          )}
        </Modal>
      )}
    </Wrapper>
  );
};
