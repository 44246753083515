import { REQUEST } from '@stores';
import { FormTicketData } from '@type/Form/BuyTicketForm';

import { TicketsData } from '@type/Store/event';
import {
  LOAD_HIGHT_LIGHT_EVENTS,
  LOAD_EVENTS,
  LOAD_ENDED_EVENTS,
  LOAD_EVENT_DETAIL,
  SAVE_USER_SELECT_TICKET,
  CLEAR_USER_SELECT_TICKET,
  PRE_ORDER_PURCHASE,
  RESET,
  SEARCH_EVENT,
} from './constants';

export function getHighLightEvents() {
  return {
    type: REQUEST(LOAD_HIGHT_LIGHT_EVENTS),
  };
}

export function reset() {
  return {
    type: REQUEST(RESET),
  };
}
export function getEvents(payload: { perPage?: number; page?: number; sortBy?: string }) {
  return {
    type: REQUEST(LOAD_EVENTS),
    payload,
  };
}
export function getEndedEvents(payload: { perPage?: number; page?: number; sortBy?: string }) {
  return {
    type: REQUEST(LOAD_ENDED_EVENTS),
    payload,
  };
}
export function getEventDetail(payload: { id?: number | string | null }) {
  return {
    type: REQUEST(LOAD_EVENT_DETAIL),
    payload,
  };
}
export function saveCurrentSelectionTicket(payload: TicketsData) {
  return {
    type: REQUEST(SAVE_USER_SELECT_TICKET),
    payload,
  };
}
export function clearCurrentSelectionTicket() {
  return {
    type: REQUEST(CLEAR_USER_SELECT_TICKET),
  };
}

export function preOrderPurchase(payload: FormTicketData) {
  return {
    type: REQUEST(PRE_ORDER_PURCHASE),
    payload,
  };
}

export function searchEvents(payload: { perPage?: string; page?: string; sort?: string; search: string }) {
  return {
    type: REQUEST(SEARCH_EVENT),
    payload,
  };
}
