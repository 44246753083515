import React from 'react';
import { useSwiper } from 'swiper/react';
import { NEXT_ICON, PREV_ICON } from '@assets';

const NavigatorCustom: React.FC = () => {
  const swiper = useSwiper();

  const handleClickPrev = () => {
    if (swiper.allowSlidePrev) {
      swiper.slidePrev();
    }
  };

  const handleClickNext = () => {
    if (swiper.allowSlideNext) {
      swiper.slideNext();
    }
  };

  return (
    <>
      <div className="custom-slider-prev" onClick={() => handleClickPrev()}>
        <PREV_ICON />
      </div>
      <div className="custom-slider-next" onClick={() => handleClickNext()}>
        <NEXT_ICON />
      </div>
    </>
  );
};
export default NavigatorCustom;
