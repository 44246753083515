import { Typography } from 'antd';
import styled from 'styled-components';

import Media from '@themes/media';

export const Wrapper = styled.div`
  padding: 15px 0;
  .thumbnail {
    position: relative;
  }
  .ant-image {
    width: 100%;
    aspect-ratio: 5 / 2;

    .ant-image-img {
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }
  }

  .error {
    color: #d80027;
    font-size: 18px;
    font-weight: 400;
  }

  .receipt_code {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 16px;
    color: #0076e3;
    font-size: 24px;
    background-color: #f5f8fc;
  }

  .tag {
    border-radius: 12px;
    color: white;
    border: none;
    font-size: ${({ theme }) => theme.size_10};
    font-weight: ${({ theme }) => theme.fw_700};
    margin-left: 8px;
  }

  .tag-end {
    background: #787878;
  }

  .tag-chapter {
    background-color: #e3007f;
  }

  .tag-live {
    background-color: ${({ theme }) => theme.bg_red};
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    padding: 0;
  }
`;

export const Title = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  margin-top: 26px;
  font-family: 'Noto Sans JP';
`;

export const CancelMark = styled.div`
  position: absolute;
  padding: 6px 25px;
  background: #d80027;
  color: white;
  top: 10px;
  left: 10px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Noto Sans JP';

  ${Media.lessThan(Media.SIZE.SM)} {
    padding: 2px 7px;
    font-size: 12px;
  }
`;
