import { Button } from '@components';
import { useRoot } from '@hooks';
import { translations } from '@i18n/translations';
import Media from '@themes/media';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Noto Sans JP';
  width: 100%;
  height: 60vh;
  padding: 32px;

  ${Media.lessThan(Media.SIZE.MD)} {
    padding: 0px 15px;
    min-width: 320px;
    height: 50vh;
  }
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  max-width: 1080px;
  border-radius: 20px;
  /* height: 480px; */
  padding: 32px;
  height: 80%;
  justify-content: center;
  align-items: center;

  ${Media.lessThan(Media.SIZE.MD)} {
    min-width: 540px;
    /* height: 250px; */
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    min-width: 320px;
  }
`;

const Title = styled.p`
  margin: 0;
  padding: 0;
  font-size: 36px;
  color: #282727;
  text-align: center;
  font-family: 'Noto Sans JP';

  ${Media.lessThan(Media.SIZE.MD)} {
    font-size: 24px;
  }
  ${Media.lessThan(Media.SIZE.SM)} {
    font-size: 18px;
  }
`;
const NotFoundPage: React.FC = () => {
  const history = useHistory();
  const { common } = translations;
  const { t } = useTranslation();
  const { set404 } = useRoot();
  React.useEffect(() => {
    set404(false);
  }, []);
  return (
    <Wrapper>
      <ContentBox>
        <Title>{t(common.PAGE_NOT_FOUND)}</Title>
        <Button style={{ marginTop: 32 }} onClick={() => history.push('/')}>
          {t(common.go_home)}
        </Button>
      </ContentBox>
    </Wrapper>
  );
};
export default NotFoundPage;
