import React, { useMemo } from 'react';
import { Typography } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';

import { Button } from '@components';
import { FAILURE_ICON_SVG } from '@assets';
import { Wrapper } from './styled';

interface IProps {
  gmo_error_code?: string[] | null;
  topicId?: string | null;
  packageId?: string | null;
}
const PaymentFailure: React.FC<IProps> = ({ gmo_error_code, topicId, packageId }) => {
  const { replace } = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { realEventPayment, gmo_payment } = translations;

  const failed_message = useMemo(() => {
    if (gmo_error_code && gmo_error_code.length > 0) {
      return gmo_error_code.map((code) => t(gmo_payment[code])).join('\n');
    }

    return t(realEventPayment.payment_coin_mess_fail);
  }, [gmo_error_code, language]);

  const goBack = () => replace(`/topics/${topicId}/payment/${packageId}?type=${type}`);
  return (
    <Wrapper>
      <FAILURE_ICON_SVG style={{ marginBottom: '24px' }} />

      <Typography className="text-message">{failed_message}</Typography>
      <div className="box-btn">
        <Button onClick={goBack}>{t(realEventPayment.back)}</Button>
      </div>
    </Wrapper>
  );
};

export default PaymentFailure;
