import { Payload } from '@type/Store';
import AxiosClient from './api';
import END_POINT from './constants';

enum typeEvent {
  highlight = 'highlight',
  list = 'list',
  ended = 'ended',
}

function getHightLightEvent() {
  return AxiosClient.get(`${END_POINT.REAL_EVENT}?type=${typeEvent.highlight}`).then((res) => res.data);
}

function getEvents(payload) {
  return AxiosClient.get(
    `${END_POINT.REAL_EVENT}?type=${typeEvent.list}&per_page=${payload?.perPage ?? ''}&page=${
      payload?.page ?? ''
    }&order=${payload?.sortBy ?? ''}`,
  ).then((res) => res.data);
}

function getEndedEvents(payload) {
  return AxiosClient.get(
    `${END_POINT.REAL_EVENT}?type=${typeEvent.ended}&per_page=${payload?.perPage ?? ''}&page=${
      payload?.page ?? ''
    }&order=${payload?.sortBy ?? ''}`,
  ).then((res) => res.data);
}

function getEventDetail({ id }: Payload) {
  return AxiosClient.get(`${END_POINT.REAL_EVENT}/${id}`).then((res) => res.data);
}

interface ISearchParams {
  page?: string;
  perPage?: string;
  search?: string;
  sort?: 'oldest' | 'latest';
}

function searchEvent({ page = '1', perPage = '15', search = '', sort = 'latest' }: ISearchParams) {
  const q = new URLSearchParams({
    page,
    per_page: perPage,
    q: search,
    order: sort,
  }).toString();

  return AxiosClient.get(`${END_POINT.REAL_EVENT}?${q}`).then((res) => res.data);
}
interface IPurchase {
  pre_order?: 0 | 1;
  real_event_id?: number;
  pay_unit?: 1 | 2;
  payment_method?: number;
  ticket?: any[];
  memo?: string | null;
  phone_number?: string | null;
  zip_code?: string | null;
  address?: string | null;
  first_name?: string | null;
  last_name?: string | null;
}

function preOrderPurchase(body: IPurchase) {
  return AxiosClient.post(`${END_POINT.PURCHASE}`, body, { isHideError: true }).then((res) => {
    return res.data;
  });
}

export { getHightLightEvent, getEvents, getEndedEvents, getEventDetail, preOrderPurchase, searchEvent };
