import { put, takeLatest } from 'redux-saga/effects';

import {
  buyChapter,
  getChapterDetail,
  getPackageDetail,
  buyTopicPayment,
  buyPackagePayment,
  buyVideoPackage,
} from '@apis';
import { REQUEST, SUCCESS } from '@stores';

import { Action } from '@type/Store';
import { BUY_CHAPTER, GET_PAYMENT_INFO, PURCHASED, PAYMENT_CHAPTER } from './constants';
import { sagaCustomize } from './sagaCustomize';

export function* getPaymentInfoSaga({ payload }: Action) {
  const { topicId, packageId, type } = payload;
  yield sagaCustomize(function* () {
    if (type === 'PACKAGE') {
      const { data } = yield getPackageDetail({ topicId, packageId });
      yield put({
        type: SUCCESS(GET_PAYMENT_INFO),
        payload: {
          diamond_price: Number(data.diamond_price || 0),
          yen_price: Number(data.yen_price || 0),
          title: data.title,
          description: data.description,
          topic_title: data.topic_title,
          topic_description: data.topic_description,
          thumbnail: data.thumbnail,
          topic_thumbnail: data.topic_thumbnail,
          live_key: null,
          isLive: false,
          payment_status: data.payment_status,
        },
      });
    }

    if (type === 'TOPIC' || type === 'LIVE') {
      const { data } = yield getChapterDetail({ id: Number(packageId) });
      yield put({
        type: SUCCESS(GET_PAYMENT_INFO),
        payload: {
          diamond_price: Number(data.amount || 0),
          yen_price: Number(data.price || 0),
          title: data.title,
          description: data.description,
          topic_title: data.topic_title,
          topic_description: data.topic_description,
          thumbnail: data.thumbnail,
          topic_thumbnail: data.topic_thumbnail,
          live_key: data.live_key,
          isLive: type === 'LIVE' && data.chapter_status === 2,
          payment_status: data.payment_status,
        },
      });
    }
  });
}

export function* buyChapterSaga({ payload }: Action) {
  let balance_code = '';
  const { topicId, packageId, params, topic_type } = payload;

  yield sagaCustomize(function* () {
    if (topic_type === 'PACKAGE') {
      const { data } = yield buyVideoPackage({ ...params, packageId, topicId });
      balance_code = data || '';
    } else {
      const { data } = yield buyChapter({ ...params, id: packageId });
      balance_code = data || '';
    }
    yield put({
      type: SUCCESS(PURCHASED),
      payload: { balance_code, receiptNo: '' },
    });
  });
}

export function* paymentChapterSaga({ payload }: Action) {
  const { topicId, packageId, params, topic_type } = payload;

  yield sagaCustomize(function* () {
    let balance_code = '';
    let receiptNo = '';
    if (topic_type === 'PACKAGE') {
      const { data } = yield buyPackagePayment({ topicId, packageId }, params);
      balance_code = data.balance_code || '';
      receiptNo = data.ReceiptNo || '';
    } else {
      const { data } = yield buyTopicPayment({ topicId, packageId }, params);
      balance_code = data.balance_code || '';
      receiptNo = data.ReceiptNo || '';
    }

    yield put({
      type: SUCCESS(PURCHASED),
      payload: { balance_code, receiptNo },
    });
  });
}

export default function* sagas() {
  yield takeLatest(REQUEST(GET_PAYMENT_INFO), getPaymentInfoSaga);
  yield takeLatest(REQUEST(BUY_CHAPTER), buyChapterSaga);
  yield takeLatest(REQUEST(PAYMENT_CHAPTER), paymentChapterSaga);
}
