/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { LOGO_LOGIN } from '@assets';
import { useParams } from 'react-router-dom';

import { Wrapper } from './styled';

const PoliceScreen = () => {
  const { language: languagePram }: any = useParams();

  return (
    <Wrapper id="intro">
      <div className="row h-fluid-min-100 intro-0-2-5">
        <div className="logo-page">
          <LOGO_LOGIN style={{ width: '7rem' }} />
        </div>
        {languagePram === 'en' && (
          <>
            <div className="header-page">
              <h3>
                <b>Policy</b>
              </h3>
            </div>
            <div>
              <div>
                <br />
                <h1 className="content-page">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In et est nec nisl congue commodo. Fusce
                    sit amet fermentum ante. Suspendisse gravida ut nisi maximus malesuada. Fusce a laoreet mauris, vel
                    dapibus nisl. Mauris at dapibus est. Suspendisse commodo aliquam sollicitudin. Praesent pulvinar
                    ante nec ipsum pulvinar, sit amet convallis orci tincidunt. Vivamus cursus neque sit amet purus
                    consectetur, in scelerisque lectus consequat. Suspendisse gravida ut nisi
                  </p>

                  <p>Donec lobortis ex justo, quis dapibus urna cursus at.</p>
                  <p>
                    Donec et imperdiet velit, in scelerisque eros. Cras ultrices neque sed enim suscipit euismod et quis
                    lorem. Curabitur vel pretium ligula, at consectetur massa. Morbi vitae imperdiet leo. Pellentesque
                    non tempus est. Integer neque dui, aliquam vel co. aecenas eleifend, risus eu sagittis sollicitudin,
                    ante enim mattis mi, vitae venenatis lorem odio ut ante. Nullam sodales lacinia tempus. Nulla
                    egestas quis libero eu ornare.
                  </p>
                </h1>
              </div>
            </div>
          </>
        )}
        {languagePram === 'ja' && (
          <>
            <div className="header-page">
              <h3>
                <b>プライバシーポリシー</b>
              </h3>
            </div>
            <div>
              <div>
                <br />
                <h1 className="content-page">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In et est nec nisl congue commodo. Fusce
                    sit amet fermentum ante. Suspendisse gravida ut nisi maximus malesuada. Fusce a laoreet mauris, vel
                    dapibus nisl. Mauris at dapibus est. Suspendisse commodo aliquam sollicitudin. Praesent pulvinar
                    ante nec ipsum pulvinar, sit amet convallis orci tincidunt. Vivamus cursus neque sit amet purus
                    consectetur, in scelerisque lectus consequat. Suspendisse gravida ut nisi
                  </p>

                  <p>Donec lobortis ex justo, quis dapibus urna cursus at.</p>
                  <p>
                    Donec et imperdiet velit, in scelerisque eros. Cras ultrices neque sed enim suscipit euismod et quis
                    lorem. Curabitur vel pretium ligula, at consectetur massa. Morbi vitae imperdiet leo. Pellentesque
                    non tempus est. Integer neque dui, aliquam vel co. aecenas eleifend, risus eu sagittis sollicitudin,
                    ante enim mattis mi, vitae venenatis lorem odio ut ante. Nullam sodales lacinia tempus. Nulla
                    egestas quis libero eu ornare.
                  </p>
                </h1>
              </div>
            </div>
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default PoliceScreen;
