import React, { useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Skeleton, Spin } from 'antd';

import { useTopics } from '@hooks';
import { Clickable } from '@components';
import { MODAL_TYPE, STATUS_TYPE } from '@constants';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';
import { Send } from '@modules/topics/watch-livestream/components/chatbox/styled';
import { ModalConfirm } from '@modules/topics/components';
import { VideoComment } from '@type/Store/topics';
import ChatUser from '../chat-user';
import { ChatList, Wrapper, SendMessage, SendMessageBox } from './styled';

const LIMIT_CHAT_ITEM = 25;

export const ChatBox: React.FC = () => {
  const infiniteScrollRef = useRef(null);
  const [showModal, setShowModal] = useState<'edit' | 'delete' | false>(false);
  const [msgSelected, setMsgSelected] = useState<VideoComment | null>(null);
  const [value, setValue] = useState<string>('');
  const { getVideoComment, createComment, chapter, comments, commentStatus, editStatus, editComment, deleteComment } =
    useTopics();
  const { data } = comments;
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const { topic, common } = translations;
  const total = data?.length || 0;

  const videoId = useMemo(() => {
    if (chapter?.video_id) {
      return chapter.video_id;
    }

    return 0;
  }, [chapter.video_id]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (videoId) {
      getVideoComment({ id: videoId, page: 1 });
    }
  }, [videoId]);

  useEffect(() => {
    if (commentStatus.status === STATUS_TYPE.SUCCESS) {
      getVideoComment({ id: videoId, page: 1 });
      const scrollable = document.getElementById('scrollableDiv');
      if (scrollable) {
        scrollable.scrollTo(0, 0);
      }
    }
  }, [commentStatus]);

  const onPressEnter = () => {
    if (value.trim()) {
      createComment({
        data: { parent_id: 0, video_id: videoId, type: 'App\\Models\\Video', content: value.trim() },
      });
      setValue('');
    }
  };

  const loadMoreData = () => {
    setPage(page + 1);
    getVideoComment({ id: videoId, page: page + 1 });
  };
  const onDelete = () => {
    deleteComment({
      data: { id: msgSelected?.id, videoId },
    });
  };
  const onEdit = (comment) => {
    editComment({
      data: { id: msgSelected?.id, content: comment || '' },
    });
  };
  const onCancel = () => {
    setShowModal(false);
    setMsgSelected(null);
  };

  useEffect(() => {
    if (editStatus.status === STATUS_TYPE.SUCCESS) {
      setShowModal(false);
    }
  }, [editStatus.status]);

  return (
    <Wrapper>
      <ChatList id="scrollableDiv">
        <InfiniteScroll
          ref={infiniteScrollRef}
          dataLength={total}
          next={loadMoreData}
          style={{ display: 'flex', flexDirection: 'column-reverse', height: '100%', overflow: 'hiden' }}
          inverse
          hasMore={total >= LIMIT_CHAT_ITEM && total < Number(comments?.paginate?.total)}
          loader={<Skeleton avatar paragraph={{ rows: 1 }} />}
          endMessage={null}
          scrollableTarget="scrollableDiv"
        >
          {commentStatus?.isLoading && <Spin />}
          {data?.map((item) => (
            <ChatUser
              onDeleteMsg={() => {
                setMsgSelected(item);
                setShowModal('delete');
              }}
              onEditMsg={() => {
                setShowModal('edit');
                setMsgSelected(item);
              }}
              key={item?.id}
              {...item}
            />
          ))}
        </InfiniteScroll>
      </ChatList>
      <SendMessageBox>
        <SendMessage
          placeholder={t(topic.enter_comment)}
          value={value}
          maxLength={500}
          bordered={false}
          onChange={onChange}
          onPressEnter={onPressEnter}
          suffix={
            <Clickable onClick={onPressEnter} disabled={!value.trim()}>
              <Send isactive={!!value.trim()} />
            </Clickable>
          }
        />
      </SendMessageBox>
      <ModalConfirm
        type={showModal === 'edit' ? MODAL_TYPE.TEXT_AREA : MODAL_TYPE.DEFAULT}
        title={showModal === 'edit' ? t(common.btn_edit) : t(topic.delete_comment)}
        onCancel={onCancel}
        onConfirm={onDelete}
        open={!!showModal}
        onSubmit={onEdit}
        defaultValues={msgSelected?.content || ''}
      />
    </Wrapper>
  );
};

export default ChatBox;
