import {
  database,
  query,
  ref,
  orderByChild,
  onValue,
  limitToFirst,
  increment,
  updateItem,
  createItem,
  serverTimestamp,
  equalTo,
  removeItem,
  onDisconnect,
  off,
} from '@apis/firebase';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from './auth';
// import { useLocation } from 'react-router-dom';

interface IParams {
  roomId?: string | null;
}

const LIMIT_USER_JOINED = 100;
interface IUser {
  id: number;
  name: string;
  donate: number;
  joinTime: number;
  avatar?: string | null;
}

export const useUserJoinTopic = ({ roomId = '' }: IParams) => {
  const [members, setMembers] = useState<IUser[]>([]);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    let childOnvalueHandler;
    const queryUserJoined = query(
      ref(database, `topic/${roomId}/members`),
      orderByChild('joinTime'),
      limitToFirst(LIMIT_USER_JOINED),
    );
    if (roomId) {
      onValue(
        queryUserJoined,
        (snapshots) => {
          // on success
          if (snapshots.exists()) {
            const data: IUser[] = [];
            snapshots.forEach((item) => {
              if (item.exists()) {
                data.unshift(item.val());
              }
            });
            setMembers(data);
            if (error) {
              setError(() => null);
            }
          }
        },
        (err) => {
          setError(() => err);
        }, // on error
      );
    }

    return () => {
      off(queryUserJoined, 'value', childOnvalueHandler);
    };
  }, [roomId]);

  return {
    members,
    error,
  };
};

export const useTotalUserJoinTopic = ({ roomId = '' }: IParams) => {
  const [total, setTotal] = useState<number>(0);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const queryTotal = query(ref(database, `topic/${roomId}/total`));
    let childOnvalueHandler;

    if (roomId) {
      /** listen data */
      onValue(
        queryTotal,
        (snapshots) => {
          // on success
          if (snapshots.exists()) {
            if (error) {
              setError(() => null);
            }
            setTotal(snapshots.val());
          }
        },
        (err) => setError(() => err), // on error
      );
    }

    return () => {
      off(queryTotal, 'value', childOnvalueHandler);
    };
  }, [roomId]);

  return {
    total,
    error,
  };
};

export const useJoinLeftRoom = (roomId) => {
  const [error, setError] = useState<any>(null);
  const { profile } = useAuth();
  const [userKey, setUserKey] = useState<string>('');
  const [userCount, setUserCount] = useState<number>(0);
  // const { total } = useTotalUserJoinTopic({ roomId });
  const { pathname } = useLocation();
  useEffect(() => {
    let childOnvalueHandler;
    const queryUser = query(
      ref(database, `topic/${roomId}/members`),
      orderByChild('id'),
      equalTo(profile?.id || false),
    );

    if (roomId && profile && profile.id) {
      onValue(
        queryUser,
        (snapshots) => {
          // on success
          if (snapshots.exists() && snapshots.size) {
            snapshots.forEach((item) => {
              setUserKey(item.key);
              setUserCount(item.val().numberCount && item.val().numberCount > 0 ? item.val().numberCount : 0);
              updateItem(`topic/${roomId}/members/${item.key}`, {
                numberCount: increment(1),
              });
            });
          } else {
            createItem(`topic/${roomId}/members`, {
              id: profile.id,
              joinTime: serverTimestamp(),
              name: profile.name,
              user_id: profile.id,
              numberCount: increment(1),
              avatar: profile.avatar || null,
            }).then((snapshot) => {
              setUserKey(snapshot.key || '');
            });
          }
        },
        (err) => {
          setError(() => err);
        }, // on error
        { onlyOnce: true },
      );

      onValue(
        queryUser,
        (snapshots) => {
          snapshots.forEach((item) => {
            setUserKey(item.key);
            setUserCount(item.val().numberCount && item.val().numberCount > 0 ? item.val().numberCount : 0);
          });
        },
        (err) => setError(err),
        { onlyOnce: false },
      );
    }

    return () => {
      if (roomId && profile && profile.id) {
        onValue(
          queryUser,
          (snapshots) => {
            // on success
            if (snapshots.exists() && snapshots.size) {
              snapshots.forEach((item) => {
                const data = item.val();
                setUserKey('');

                if (data.numberCount > 1) {
                  updateItem(`topic/${roomId}/members/${item.key}`, {
                    numberCount: increment(-1),
                  });
                } else {
                  removeItem(`topic/${roomId}/members/${item.key}`);
                }
              });
            }
          },
          (err) => {
            setError(() => err);
          }, // on error
          { onlyOnce: true },
        );

        off(queryUser, 'value', childOnvalueHandler);
      }
    };
  }, [roomId, profile?.id]);

  useEffect(() => {
    if (roomId && userKey && pathname.includes('watch-livestream')) {
      if (userCount > 1) {
        const refCount = ref(database, `topic/${roomId}/members/${userKey}`);
        onDisconnect(refCount).update({
          numberCount: increment(-1),
        });
      } else {
        onDisconnect(ref(database, `topic/${roomId}/members/${userKey}`)).update({
          numberCount: null,
          id: null,
          user_id: null,
          avatar: null,
          joinTime: null,
          name: null,
        });
      }
    }

    return () => {
      onDisconnect(ref(database, `topic/${roomId}/members/${userKey}`)).cancel();
      onDisconnect(ref(database, `topic/${roomId}`)).cancel();
    };
  }, [roomId, userKey, userCount, pathname]);

  return {
    error,
  };
};
