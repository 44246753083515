import React, { useEffect } from 'react';
import { Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';
import { isMobile, isTablet } from 'react-device-detect';

import { Button } from '@components';
import { numberWithCommas } from '@utils/number';
import { SUCCESS_ICON_SVG, DIAMOND } from '@assets';
import { useOpenApp } from '@hooks/useOpenApp';
import { useTopicPayment } from '@hooks/useTopicPayment';
import { Wrapper } from './styled';
import { TextCoin } from '../../styled';

type Props = {
  isPayDiamond?: boolean;
};

const Purchased: React.FC<Props> = ({ isPayDiamond }) => {
  const { replace } = useHistory();
  const { t } = useTranslation();
  const { topic_info, deeplink, reset } = useTopicPayment();

  const { topic, topic_payment } = translations;
  const { openApp } = useOpenApp();

  useEffect(() => {
    if (isMobile || isTablet) {
      const section = document.querySelector('#scroll-view');
      section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  const handlePaymentSuccess = () => {
    if (deeplink) {
      openApp({
        deeplink,
      });
    } else {
      reset();
      replace(`/histories/topic/${topic_info.balance_code}`);
    }
  };

  const gotoTopic = () => {
    reset();
    replace('/topics');
  };

  return (
    <Wrapper id="scroll-view">
      <SUCCESS_ICON_SVG style={{ marginBottom: '24px' }} />
      {isPayDiamond && (
        <TextCoin className="text-coin-bottom">
          <img src={DIAMOND} alt="diamond" width={35} />
          {numberWithCommas(topic_info.diamond_price, ',')}
        </TextCoin>
      )}
      <Typography className="text-message">{t(topic.payment_mess_success)}</Typography>
      <div className="box-btn">
        <Button onClick={gotoTopic} type="outline">
          {t(topic_payment.goto_topic)}
        </Button>
        <Button onClick={handlePaymentSuccess}> {t(topic_payment.goto_histories)}</Button>
      </div>
    </Wrapper>
  );
};

export default Purchased;
