import React, { useEffect, useMemo } from 'react';
import { FormProvider, useController, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router-dom';

import { SALE_TYPE } from '@constants/real-event';
import { useAuth, useEvents } from '@hooks';
import { translations } from '@i18n/translations';
import { FormTicketData } from '@type/Form/BuyTicketForm';
import { EventDetail } from '@type/Store/event';
import useExitPrompt from '@hooks/useExitPrompt';
import { TicketContainer, TicketList } from '../../styles';
import EventContent from '../event-content';
import TicketType from '../ticket-type';
import EventDescription from '../event-description';
import PaymentAllows from '../payment-allows';
import PaymentAllowsLogin from '../payment-allows-login';

type EventProps = {
  event: EventDetail;
};

const EventShowing: React.FC<EventProps> = ({ event }) => {
  const { t } = useTranslation();

  const { common, realEventPayment } = translations;
  const { authenticated } = useAuth();
  const history = useHistory();
  const { ticketsData, saveCurrentSelectionAction } = useEvents();
  const isPreOrder = useMemo(() => event.sale_type === SALE_TYPE.PRE_ORDER, [event.sale_type]);

  const initFormData = useMemo(() => {
    return (
      ticketsData || {
        tickets: event?.ticket?.map((item) => {
          return {
            quantity: 0,
            ticketId: item?.ticket_id,
            priceCoinTax: Number(item.price_coin_tax || 0),
            priceYenTax: Number(item.price_yen_tax || 0),
            payLimit: item?.pay_limit,
            name: item.name,
            onlyPriceCoin:
              item.price_coin_tax !== null &&
              item.price_coin_tax !== undefined &&
              (item.price_yen_tax === null || item.price_yen_tax === undefined),
            onlyPriceYen:
              item.price_yen_tax !== null &&
              item.price_yen_tax !== undefined &&
              (item.price_coin_tax === null || item.price_coin_tax === undefined),
            coinPriceIsNil: item.price_coin_tax === null || item.price_coin_tax === undefined,
            yenPriceIsNil: item.price_yen_tax === null || item.price_yen_tax === undefined,
            coinFee: Number(item.payment_fee_coin || 0),
            coinPrice: Number(item.price_coin || 0),
            yenFee: Number(item.payment_fee_yen || 0),
            yenPrice: Number(item.price_yen || 0),
          };
        }),
      }
    );
  }, [event]);

  const form = useForm<FormTicketData>({
    defaultValues: {
      ...initFormData,
      memo: '',
      lastKanji: '',
      firstKanji: '',
      phoneNumber: '',
      zipcode: '',
      address: '',
    },
  });
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isValid },
    getValues,
    control,
  } = form;

  const { showExitPrompt, setShowExitPrompt } = useExitPrompt(false);

  const { fields: ticketWatch, update } = useFieldArray({
    control,
    name: 'tickets',
  });

  const {
    field: { onChange, value },
  } = useController({ name: 'paymentMethod', control, rules: { required: !isPreOrder } });

  const [paymentMethod] = watch(['paymentMethod']);

  const ticketSelected = useMemo(() => {
    const tickets = ticketWatch.filter((f) => !!f.quantity);
    const totals = tickets.reduce(
      (
        { totalYenPrice, totalCoinPrice, totalTicket, totalCoinTicket, totalYenTicket, taxCoinFee, taxYenFee },
        { priceYenTax, priceCoinTax, quantity, coinPriceIsNil, yenPriceIsNil, coinFee, yenFee },
      ) => ({
        totalYenPrice: totalYenPrice + priceYenTax * quantity,
        totalCoinPrice: totalCoinPrice + priceCoinTax * quantity,
        totalTicket: totalTicket + quantity,
        totalCoinTicket: !coinPriceIsNil ? totalCoinTicket + quantity : totalCoinTicket,
        totalYenTicket: !yenPriceIsNil ? totalYenTicket + quantity : totalYenTicket,
        taxCoinFee: taxCoinFee + coinFee * quantity,
        taxYenFee: taxYenFee + yenFee * quantity,
      }),
      {
        totalYenPrice: 0,
        totalCoinPrice: 0,
        totalTicket: 0,
        totalCoinTicket: 0,
        totalYenTicket: 0,
        taxCoinFee: 0,
        taxYenFee: 0,
      },
    );
    const ticketOnlyYen = tickets.filter((f) => f.onlyPriceYen);
    const ticketOnlyCoin = tickets.filter((f) => f.onlyPriceCoin);

    return {
      ...totals,
      tickets,
      ticketOnlyYen,
      ticketOnlyCoin,
      isDisableCoin: totals.totalCoinTicket <= 0,
      isDisablePayment: totals.totalYenTicket <= 0,
    };
  }, [ticketWatch]);

  const isErrorFieldInput: boolean = useMemo(() => {
    if (event && event.ticket && event.ticket.length > 0) {
      return event.ticket.reduce((isError, { pay_limit, ticket_id }) => {
        const findTicket = ticketWatch.find((t) => t.ticketId === ticket_id);

        if (findTicket) {
          return isError || findTicket.quantity > pay_limit;
        }
        return isError;
      }, false);
    }
    return false;
  }, [ticketWatch, event]);

  useEffect(() => {
    if (ticketSelected.isDisableCoin && paymentMethod === 3) {
      setValue('paymentMethod', undefined);
    }
    if (ticketSelected.isDisablePayment && (paymentMethod === 1 || paymentMethod === 2)) {
      setValue('paymentMethod', undefined);
    }
  }, [ticketSelected, paymentMethod]);

  useEffect(() => {
    if (ticketsData?.memo) {
      setValue('memo', ticketsData.memo);
    }
    if (ticketsData?.address) {
      setValue('address', ticketsData.address);
    }
    if (ticketsData?.firstKanji) {
      setValue('firstKanji', ticketsData.firstKanji);
    }
    if (ticketsData?.lastKanji) {
      setValue('lastKanji', ticketsData.lastKanji);
    }
    if (ticketsData?.zipcode) {
      setValue('zipcode', ticketsData.zipcode);
    }
    if (ticketsData?.phoneNumber) {
      setValue('phoneNumber', ticketsData.phoneNumber);
    }
  }, [ticketsData]);

  const onSubmit = handleSubmit((data) => {
    setShowExitPrompt(false);
    saveCurrentSelectionAction({
      tickets: data.tickets,
      paymentMethod: data.paymentMethod,
      priceTotal: ticketSelected.totalYenPrice,
      totalCoin: ticketSelected.totalCoinPrice,
      totalTicket: ticketSelected.totalTicket,
      coinFee: ticketSelected.taxCoinFee,
      yenFee: ticketSelected.taxYenFee,
      memo: data.memo,
      lastKanji: data.lastKanji,
      firstKanji: data.firstKanji,
      phoneNumber: data.phoneNumber,
      zipcode: data.zipcode,
      address: data.address,
    });

    if (isPreOrder && authenticated) {
      history.push(`/order-confirm/${event?.id}`, {
        data,
        event,
        totalTicket: ticketSelected.totalTicket,
        priceTotal: ticketSelected.totalYenPrice,
        coinTotal: ticketSelected.totalCoinPrice,
        memo: data.memo,
      });
    }

    if (!isPreOrder && authenticated) {
      if (data.paymentMethod === 3) {
        history.push({ pathname: `/payment-coin/${event.id}` });
      }

      if (data.paymentMethod === 1) {
        history.push(`/payment-credit/${event.id}`);
      }

      if (data.paymentMethod === 2) {
        history.push(`/payment-kobini/${event.id}`);
      }
    }
  });

  const isInValidSubmit = () => {
    if (isPreOrder && ticketSelected.totalTicket) return false;
    if (authenticated && !isValid) return true;
    if (!ticketSelected.totalTicket) return true;
    if (paymentMethod === undefined) return true;
    return false;
  };

  const handleUserNotLoggedIn = () => {
    saveCurrentSelectionAction({
      tickets: getValues().tickets,
      paymentMethod: getValues().paymentMethod,
      totalCoin: ticketSelected.totalCoinPrice,
      priceTotal: ticketSelected.totalYenPrice,
      totalTicket: ticketSelected.totalTicket,
      coinFee: ticketSelected.taxCoinFee,
      yenFee: ticketSelected.taxYenFee,
    });
    history.push('/login', { navigateTo: `/events/${event.id}` });
  };

  useEffect(() => {
    if (ticketWatch.filter((f) => !!f.quantity).length > 0) {
      setShowExitPrompt(true);
    } else {
      setShowExitPrompt(false);
    }
  }, [ticketWatch]);

  useEffect(() => {
    return () => {
      setShowExitPrompt(false);
    };
  }, []);

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        <EventContent event={event}>
          <TicketContainer>
            <h5 className="ticket-title">{t(common.event.ticket_type)}</h5>
            <TicketList>
              {ticketWatch.map((field, index) => (
                <TicketType
                  key={field.ticketId}
                  values={field}
                  ticket={event.ticket[index]}
                  index={index}
                  update={update}
                  control={control}
                />
              ))}
            </TicketList>
          </TicketContainer>
          {!isPreOrder && authenticated && (
            <div style={{ width: '100%' }}>
              <div style={{ width: '100%', height: 2, borderRadius: 10, background: '#F6F6F6', marginBottom: 14 }} />
              <p style={{ fontSize: 14, fontWeight: 700, color: '#282727' }}>{t(realEventPayment.accept_payment)}</p>
              <p style={{ fontSize: 12, fontWeight: 400, color: '#787878' }}>{t(realEventPayment.select_payment)}</p>
            </div>
          )}
          {!authenticated && event?.ticket?.length > 0 && (
            <PaymentAllows onClick={handleUserNotLoggedIn} isPreOrder={isPreOrder} />
          )}

          {authenticated && event?.ticket?.length > 0 && (
            <PaymentAllowsLogin
              control={control}
              event={event}
              isPreOrder={isPreOrder}
              onChange={onChange}
              value={value}
              paymentMethod={paymentMethod}
              coinFee={ticketSelected.taxCoinFee}
              yenFee={ticketSelected.taxYenFee}
              totalPrice={ticketSelected.totalYenPrice}
              disableCoin={ticketSelected.isDisableCoin}
              totalCoin={ticketSelected.totalCoinPrice}
              ticketOnlyYen={ticketSelected.ticketOnlyYen}
              ticketOnlyCoin={ticketSelected.ticketOnlyCoin}
              isDisablePayment={ticketSelected.isDisablePayment}
              disableBtn={isInValidSubmit() || Number(ticketSelected.totalTicket) < 1 || isErrorFieldInput}
            />
          )}
        </EventContent>
        <EventDescription event={event} />
        <Prompt
          message={(location, action) => {
            const isShowPopup = () => {
              if (showExitPrompt && action === 'POP') return true;

              if (showExitPrompt && action === 'PUSH')
                return (
                  !location.pathname.includes('/order-confirm') &&
                  !location.pathname.includes('/payment-credit') &&
                  !location.pathname.includes('/payment-kobini') &&
                  !location.pathname.includes('/payment-coin') &&
                  !location.pathname.includes('/login')
                );

              return false;
            };

            return isShowPopup() ? t(realEventPayment.goback) : true;
          }}
        />
      </form>
    </FormProvider>
  );
};
export default EventShowing;
