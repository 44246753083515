import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Image from '@components/imageFallback';
import { useEvents } from '@hooks';
import Loading from '@components/loading';

import { translations } from '@i18n/translations';
import { useTranslation } from 'react-i18next';
import EventShowing from './components/event-showing';
import { CancelMark, Container, ImageBanner } from './styles';
import EventActive from './components/event-active';
import EventFinish from './components/event-finish';
import EventCancel from './components/event-cancel';
import EventEndSale from './components/event-end-sale';

type EventParams = {
  id: string;
};
enum EventStatus {
  'ACTIVE' = 1,
  'SHOWING' = 2,
  'FINISH' = 3,
  'CANCEL' = 4,
}

export const EventDetail: React.FC = () => {
  const { id } = useParams<EventParams>();
  const { getEventDetailAction, eventDetail, isLoading } = useEvents();
  const { realEventPayment } = translations;
  const { t } = useTranslation();

  useEffect(() => {
    getEventDetailAction({ id });
  }, [id]);

  const isEndSale = useMemo(() => {
    if (eventDetail?.end_sale_at) {
      return Number(eventDetail?.end_sale_at) * 1000 < Date.now();
    }
    return false;
  }, [eventDetail?.end_sale_at]);

  const isBeforeSale = useMemo(() => {
    if (eventDetail?.start_sale_at) {
      return Number(eventDetail?.start_sale_at) * 1000 > Date.now();
    }
    return false;
  }, [eventDetail?.start_sale_at]);

  const eventType = () => {
    switch (eventDetail.status) {
      case EventStatus.ACTIVE:
        if (isEndSale) return <EventEndSale event={eventDetail} />;
        if (isBeforeSale) return <EventActive event={eventDetail} />;
        return <EventShowing event={eventDetail} />;
      case EventStatus.SHOWING:
        if (isEndSale) return <EventEndSale event={eventDetail} />;
        if (isBeforeSale) return <EventActive event={eventDetail} />;
        return <EventShowing event={eventDetail} />;
      case EventStatus.FINISH:
        return <EventFinish event={eventDetail} />;
      case EventStatus.CANCEL:
        return <EventCancel event={eventDetail} />;
      default:
        return undefined;
    }
  };

  return !isLoading ? (
    <Container>
      <ImageBanner>
        <Image src={eventDetail?.thumbnail} alt="Image event" width={1010} height={404} />

        {eventDetail.status === EventStatus.CANCEL && <CancelMark>{t(realEventPayment.Cancelled)}</CancelMark>}
      </ImageBanner>
      {eventType()}
    </Container>
  ) : (
    <Loading />
  );
};

export default EventDetail;
