import { LOCATION_CHANGE } from 'connected-react-router';
import { createReducer, updateObject, REQUEST, SUCCESS, FAILURE } from '@stores';
import { Action, IAction } from '@type/Store';
import { CardInfo, RealEventPaymentState } from '@type/Store/real-event-purchase';
import {
  PURCHASE_WITH_CREDIT_CARD,
  PURCHASE_WITH_KOBINI,
  REAL_EVENT_PURCHASE,
  SET_CARD_INFO,
  CLEAR_CARD_INFO,
} from './constants';

export const initialState: RealEventPaymentState = {
  isLoading: false,
  error: null,
  isCoinPaymentSuccess: false,
  dataPaymentSuccess: '',
  isPurchaseSuccess: false,
  paidCredit: false,
  paidKobini: false,
  balanceCode: null,
  receiptNo: null,
  cardInfo: {
    cardholder: '',
    cardnumber: '',
  },
};

function realEventPurchase(state: RealEventPaymentState) {
  return updateObject(state, {
    isLoading: true,
    isCoinPaymentSuccess: false,
    paidCredit: false,
  });
}

function realEventPurchaseSuccess(state: RealEventPaymentState, { payload }: Action) {
  return updateObject(state, {
    isLoading: false,
    isCoinPaymentSuccess: true,
    dataPaymentSuccess: payload,
    isPurchaseSuccess: true,
  });
}

function realEventPurchaseFailure(state: RealEventPaymentState, { error }: Action) {
  return updateObject(state, {
    error,
    isLoading: false,
  });
}

function purchaseWithCreditCard(state: RealEventPaymentState) {
  return updateObject(state, {
    isLoading: true,
    paidCredit: false,
  });
}

function purchaseWithCreditCardSuccess(state: RealEventPaymentState, { payload }) {
  return updateObject(state, {
    isLoading: false,
    paidCredit: true,
    balanceCode: payload,
    cardInfo: initialState.cardInfo,
  });
}

function purchaseWithKobini(state: RealEventPaymentState) {
  return updateObject(state, {
    isLoading: true,
    paidKobini: false,
  });
}

function purchaseWithKobiniSuccess(state: RealEventPaymentState, { payload }) {
  return updateObject(state, {
    isLoading: false,
    paidKobini: true,
    receiptNo: payload.receiptNo,
    balanceCode: payload.balanceCode,
  });
}

function setCardInfo(state: RealEventPaymentState, { payload }: IAction<CardInfo>) {
  return updateObject(state, {
    cardInfo: payload,
  });
}

function clearCardInfo(state: RealEventPaymentState) {
  return updateObject(state, {
    cardInfo: initialState.cardInfo,
  });
}

function resetState(state: RealEventPaymentState) {
  return updateObject(state, {
    ...initialState,
    cardInfo: state.cardInfo,
  });
}

// Slice reducer
export default createReducer(initialState, {
  [REQUEST(REAL_EVENT_PURCHASE)]: realEventPurchase,
  [SUCCESS(REAL_EVENT_PURCHASE)]: realEventPurchaseSuccess,
  [FAILURE(REAL_EVENT_PURCHASE)]: realEventPurchaseFailure,

  [REQUEST(PURCHASE_WITH_CREDIT_CARD)]: purchaseWithCreditCard,
  [SUCCESS(PURCHASE_WITH_CREDIT_CARD)]: purchaseWithCreditCardSuccess,

  [REQUEST(PURCHASE_WITH_KOBINI)]: purchaseWithKobini,
  [SUCCESS(PURCHASE_WITH_KOBINI)]: purchaseWithKobiniSuccess,

  [REQUEST(SET_CARD_INFO)]: setCardInfo,
  [REQUEST(CLEAR_CARD_INFO)]: clearCardInfo,

  [LOCATION_CHANGE]: resetState,
});
