import { FAMILY, LAWSON_3X, MINISTOP } from '@assets';
import { translations } from '@i18n/translations';

type InputFormat = 'none' | 'credit_card' | 'date_expire' | 'custom_format';
const { realEventPayment } = translations;
const { fieldName, kobini } = realEventPayment;
export const FORM_CREDIT_CARD = [
  {
    name: 'cardholder',
    placeholder: 'YAMADA TAROU',
    label: fieldName.cardholder,
    inputFormat: 'none' as InputFormat,
  },
  {
    name: 'card_number',
    placeholder: '1234  5678  9101  1121',
    label: fieldName.cardnumber,
    inputFormat: 'credit_card' as InputFormat,
  },
  {
    name: 'date_expiry',
    placeholder: 'YY/MM',
    label: fieldName.dateExpiry,
    inputFormat: 'date_expire' as InputFormat,
  },
  {
    name: 'cvc',
    placeholder: '123',
    label: fieldName.cvc,
    inputFormat: 'none' as InputFormat,
  },
  {
    name: 'email',
    placeholder: 'eemirisuzuhar@gmail.com',
    label: fieldName.email,
    inputFormat: 'none' as InputFormat,
  },
  {
    name: 'phone_number',
    placeholder: '0905111222',
    label: fieldName.phonenumber,
    inputFormat: 'none' as InputFormat,
  },
];

export const FORM_KONBINI = [
  {
    name: 'last_name',
    placeholder: '山田',
    label: fieldName.lastKanji,
    isFullWidth: true,
  },
  {
    name: 'first_name',
    placeholder: '太郎',
    label: fieldName.firstKanji,
    isFullWidth: true,
  },
  {
    name: 'last_name_kana',
    placeholder: 'ヤマダ',
    label: fieldName.lastKana,
    isFullWidth: true,
  },
  {
    name: 'first_name_kana',
    placeholder: 'タロウ',
    label: fieldName.firstKana,
    isFullWidth: true,
  },
  {
    name: 'email',
    placeholder: 'mail@example.com',
    label: fieldName.email,
    isFullWidth: false,
  },
  {
    name: 'phone_number',
    placeholder: '0905111222',
    label: fieldName.phonenumber,
    isFullWidth: false,
  },
];

export const KOBINI_LIST = [
  {
    src: FAMILY,
    label: kobini.family,
    value: '10002',
  },
  {
    src: LAWSON_3X,
    label: kobini.lawson,
    value: '10001',
  },
  {
    src: MINISTOP,
    label: kobini.miniStop,
    value: '10005',
  },
];

export const FORM_USER_INFO = [
  {
    name: 'last_name',
    placeholder: 'lastKanji_placeholder',
    label: fieldName.lastKanji,
  },
  {
    name: 'first_name',
    placeholder: 'firstKanji_placeholder',
    label: fieldName.firstKanji,
  },
  {
    name: 'phone_number',
    placeholder: 'phone_placeholder',
    label: fieldName.phonenumber,
    inputFormat: 'number' as InputFormat,
  },
  {
    name: 'zip_code',
    placeholder: 'post_code_placeholder',
    label: fieldName.zipcode,
    inputFormat: 'number' as InputFormat,
  },
  {
    name: 'address',
    placeholder: 'address_placeholder',
    label: fieldName.address,
    inputFormat: 'none' as InputFormat,
    isFullWidth: true,
  },
];

export enum DIAMOND_PAYMENT_STATUS {
  ENOUGH = 'ENOUGH',
  NOT_ENOUGH = 'NOT_ENOUGH',
  CONFIRM = 'CONFIRM',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  LOADING = 'LOADING',
}
