import React, { useEffect, useRef, useState } from 'react';
import { Controller, EffectFade, Pagination, Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { useTranslation } from 'react-i18next';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';

import SliderWrapper from '@components/sliderWrapper';
import Image from '@components/imageFallback';
import { Event } from '@type/Store/event';
import { formatDateTimeOfWeek } from '@utils/time';
import { STATUS } from '@constants/real-event';
import { CancelMark } from '@modules/history/ticket-detail/styled';
import { translations } from '@i18n/translations';
import { BannerSlickSlider, SlickText } from './styled';

type BannerSliderProps = {
  data: Event[];
};

const AutoSwiper: React.FC = () => {
  const swiper = useSwiper();
  useEffect(() => {
    const timer = setInterval(() => swiper.slideNext(), 5000);

    return () => clearInterval(timer);
  }, []);

  return <div />;
};
const BannerSlider: React.FC<BannerSliderProps> = ({ data }) => {
  const { i18n, t } = useTranslation();
  const [firstSwiper, setFirstSwiper] = useState<SwiperType>();
  const [secondSwiper, setSecondSwiper] = useState<SwiperType>();
  const swiperRef = useRef<SwiperType>();

  const { realEventPayment } = translations;
  return (
    <>
      <BannerSlickSlider>
        {data && data.length > 0 && (
          <Swiper
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            onSwiper={setFirstSwiper}
            controller={{ control: secondSwiper }}
            slidesPerView={1.3}
            spaceBetween={30}
            loop
            centeredSlides
            pagination={{
              clickable: true,
            }}
            autoplay={false}
            modules={[Pagination, Controller]}
            breakpoints={{
              1024: {
                slidesPerView: 1.5,
              },
            }}
          >
            {data?.map((ticket) => (
              <SwiperSlide key={`banner-${ticket.id}`}>
                <SliderWrapper ticketId={ticket.id}>
                  <Image src={ticket?.thumbnail} alt={ticket?.title} />
                  {ticket.status === STATUS.CANCELED && <CancelMark>{t(realEventPayment.Cancelled)}</CancelMark>}
                </SliderWrapper>
              </SwiperSlide>
            ))}
            <AutoSwiper />
          </Swiper>
        )}
      </BannerSlickSlider>
      <SlickText>
        {data && data.length > 0 && (
          <Swiper
            onSwiper={setSecondSwiper}
            controller={{ control: firstSwiper }}
            slidesPerView={1.5}
            centeredSlides
            spaceBetween={30}
            loop
            autoplay={false}
            effect="fade"
            fadeEffect={{
              crossFade: true,
            }}
            modules={[Controller, EffectFade]}
          >
            {data?.map((ticket) => (
              <SwiperSlide key={`banner-${ticket.id}`}>
                <div className="slick-text">
                  <h3 className="tittle-event">{ticket?.title}</h3>
                  <time>{formatDateTimeOfWeek(ticket?.start_at, i18n.language)}</time>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </SlickText>
    </>
  );
};

export default BannerSlider;
