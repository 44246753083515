import * as yup from 'yup';
import { translations } from '@i18n/translations';

const { realEventPayment } = translations;
const { payment_validate } = realEventPayment;
const scheme = yup.object().shape({
  firstKanji: yup
    .string()
    .trim()
    .required(payment_validate.required)
    .test('firstKanji', payment_validate.kanji_name, (value, context) => {
      if (!value) return true;
      const { parent } = context;
      return value.length + parent.lastKanji.length <= 20;
    }),
  lastKanji: yup
    .string()
    .trim()
    .required(payment_validate.required)
    .test('lastKanji', '', (value, context) => {
      if (!value) return true;
      const { parent } = context;

      return value.length + parent.firstKanji.length <= 20;
    }),
  firstKana: yup
    .string()
    .trim()
    .required(payment_validate.required)
    .test('firstKana', payment_validate.kana_name, (value, context) => {
      if (!value) return true;
      const { parent } = context;

      return value.length + parent.lastKana.length <= 20;
    }),
  lastKana: yup
    .string()
    .trim()
    .required(payment_validate.required)
    .test('lastKana', '', (value, context) => {
      if (!value) return true;
      const { parent } = context;

      return value.length + parent.firstKana.length <= 20;
    }),
  email: yup
    .string()
    .trim()
    .required(payment_validate.required)
    .email(payment_validate.email_incorrect)
    .max(200, payment_validate.email_max_length),
  phonenumber: yup
    .string()
    .trim()
    .required(payment_validate.required)
    .test('phonenumber', payment_validate.phoneNumber_format, (value = '') => {
      if (!value) return true;
      const pattern = /^[0-9]{0,13}$/;
      return pattern.test(value);
    }),
});

export default scheme;
