import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLocalStorage, STORAGE } from '@utils';
import en from './translate/en';
import ja from './translate/jp';
import cn from './translate/cn';
import { convertLanguageJsonToObject } from './translations';

const resources = {
  cn: {
    ...cn,
    translation: cn,
  },
  en: {
    ...en,
    translation: en,
  },
  ja: {
    ...ja,
    translation: ja,
  },
};

const INIT_LANGUAGE = getLocalStorage(STORAGE.LANGUAGE) || 'ja';

convertLanguageJsonToObject(en);

i18next.use(initReactI18next).init({
  resources,
  ns: ['common', 'menu', 'error_message', 'translation'],
  defaultNS: 'translation',
  backend: {
    loadPath: './{{lng}}.json',
  },
  react: {
    useSuspense: true,
  },
  lng: INIT_LANGUAGE,
  fallbackLng: INIT_LANGUAGE,
  preload: [INIT_LANGUAGE],
  // keySeparator: false,
  interpolation: { escapeValue: false },
});

i18next.off('languageChanged');
i18next.on('languageChanged', (language) => {
  window.localStorage.setItem('LANGUAGE', language);
});

export default i18next;
