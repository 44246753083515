import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, Prompt } from 'react-router-dom';
import { Spin } from 'antd';
import { translations } from '@i18n/translations';

import { useAuth, useEvents, useRealEventPayment } from '@hooks';
import { Button, Seemore } from '@components';
import {
  STATUS_PAYMENT_COIN,
  PAYMENT_UNIT,
  PAYMENT_METHOD,
  ERROR_MESSAGE_REAL_EVENT_PURCHASE,
} from '@constants/real-event';
import { ICON_COIN, MAP_ICON } from '@assets';
import { numberWithCommas } from '@utils/number';
import { formatDateTimeOfWeek } from '@utils/time';
import { TicketFormGroup } from '@type/Form/BuyTicketForm';
import { EventDetail } from '@type/Store/event';
import { Group, TicketDetailStyle, PaymentCoinConfirmStyled, ModalStyled, TextComfirmStyled } from './styled';

type PaymentConfirmProps = {
  eventDetail?: EventDetail;
  tickets?: TicketFormGroup[];
  totalCoinPrice?: number;
  totalTicket?: number;
  coinFee?: number;
  setStatusPayment: Dispatch<SetStateAction<STATUS_PAYMENT_COIN>>;
  memo?: string | null;
  name?: string | null;
};

interface Params {
  eventId: string;
}

const PaymentCoinConfirm: React.FC<PaymentConfirmProps> = ({
  totalCoinPrice,
  totalTicket,
  eventDetail,
  tickets,
  setStatusPayment,
  coinFee,
  memo,
  name,
}) => {
  const { profile, loadProfileAction } = useAuth();
  const { realEventPayment, isLoading, isPurchaseSuccess, error } = useRealEventPayment();
  const { ticketsData } = useEvents();

  const params: Params = useParams();
  const history = useHistory();
  const { i18n } = useTranslation();
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const { realEventPayment: realEventPaymentTrans } = translations;

  const onCancel = () => {
    setIsShowModal(false);
  };

  useEffect(() => {
    if (isPurchaseSuccess) {
      setStatusPayment(STATUS_PAYMENT_COIN.SUCCESS);
      loadProfileAction();
    }
  }, [isPurchaseSuccess]);

  useEffect(() => {
    if (error) {
      if (error?.message?.[0] === ERROR_MESSAGE_REAL_EVENT_PURCHASE.TICKET_SOLD_OUT) {
        setIsShowModal(true);
      } else {
        setStatusPayment(STATUS_PAYMENT_COIN.FAILURE);
      }
    }
  }, [error]);

  const onSubmit = () => {
    const listPayment = tickets?.filter((item) => item.quantity > 0) || [];
    const ticket = listPayment.map((item) => ({ ticket_type_id: item.ticketId, quantity: item?.quantity }));
    realEventPayment({
      data: {
        pre_order: 0,
        real_event_id: params?.eventId,
        ticket,
        pay_unit: PAYMENT_UNIT.COIN,
        payment_method: PAYMENT_METHOD.COIN,
        memo,
        address: ticketsData?.address,
        zip_code: ticketsData?.zipcode,
        phone_number: ticketsData?.phoneNumber,
        first_name: ticketsData?.firstKanji,
        last_name: ticketsData?.lastKanji,
      },
    });
  };

  return (
    <PaymentCoinConfirmStyled>
      <Spin spinning={isLoading}>
        <TicketDetailStyle>
          <Group>
            <label>{t(realEventPaymentTrans.ticket_good)}</label>
            <p className="custom-width">{eventDetail?.title}</p>
          </Group>
          <Group>
            <label>{t(realEventPaymentTrans.event_date)}</label>
            <p>{formatDateTimeOfWeek(eventDetail?.start_at || 0, i18n.language)}</p>
          </Group>
          {!!eventDetail?.url && (
            <Group>
              <label>{t(realEventPaymentTrans.address)}</label>
              {eventDetail?.url && (
                <p>
                  <a href={eventDetail?.url} target="_blank" rel="noreferrer">
                    {t(realEventPaymentTrans.here)}
                  </a>
                  <MAP_ICON style={{ marginLeft: '5px' }} />
                </p>
              )}
            </Group>
          )}
          <Group>
            <label>{t(realEventPaymentTrans.full_name)}</label>
            <p>{name && name.length > 1 ? name : profile?.name}</p>
          </Group>
          <Group>
            <label>{t(realEventPaymentTrans.email)}</label>
            <p>{profile?.email}</p>
          </Group>
          {!!ticketsData?.phoneNumber && (
            <Group>
              <label>{t(realEventPaymentTrans.phone)}</label>
              <p>{ticketsData?.phoneNumber}</p>
            </Group>
          )}
          {!!ticketsData?.zipcode && (
            <Group>
              <label>{t(realEventPaymentTrans.fieldName.zipcode)}</label>
              <p>{ticketsData?.zipcode}</p>
            </Group>
          )}
          {!!ticketsData?.address && (
            <Group>
              <label>{t(realEventPaymentTrans.fieldName.address)}</label>
              <p className="custom-width">{ticketsData?.address}</p>
            </Group>
          )}
          {!!memo && (
            <Group>
              <label>{t(realEventPaymentTrans.note)}</label>
              <Seemore rows={3} content={memo || ''} className="custom-width" />
            </Group>
          )}
          <Group>
            <label>{t(realEventPaymentTrans.ticket_type)}</label>
            <div>
              {tickets?.map((ticket) =>
                ticket.quantity > 0 ? (
                  <div className="ticket-type">
                    <div>{ticket.name}</div>
                    <div>
                      {Number(ticket.quantity)}
                      {t(realEventPaymentTrans.sheet)}
                    </div>
                  </div>
                ) : (
                  <div />
                ),
              )}
            </div>
          </Group>
          <Group>
            <label>{t(realEventPaymentTrans.number_of_tickets)}</label>
            <p>
              {Number(totalTicket)} {t(realEventPaymentTrans.number_of_tickets)}
            </p>
          </Group>
          <Group>
            <label>{t(realEventPaymentTrans.payment_method)}</label>
            <p>{t(realEventPaymentTrans.coin)}</p>
          </Group>
          {!!coinFee && (
            <>
              <Group>
                <label>{t(realEventPaymentTrans.subtotal)}</label>
                <p>
                  <span className="coin">
                    <ICON_COIN style={{ width: '22px', height: '22px' }} />
                    {numberWithCommas((totalCoinPrice || 0) - (coinFee || 0), ',')}
                  </span>
                </p>
              </Group>
              <Group>
                <label>{t(realEventPaymentTrans.fee)}</label>
                <p>
                  <span className="coin">
                    <ICON_COIN style={{ width: '22px', height: '22px' }} />
                    {numberWithCommas(coinFee, ',')}
                  </span>
                </p>
              </Group>
            </>
          )}
          <Group>
            <label>{t(realEventPaymentTrans.total_amount_notFee)}</label>
            <p>
              <span className="coin">
                <ICON_COIN style={{ width: '22px', height: '22px' }} />
                {numberWithCommas(totalCoinPrice, ',')}
              </span>
            </p>
          </Group>
          <div className="box-btn">
            <Button
              onClick={() => {
                history.push(`/events/${params?.eventId}`);
              }}
              type="cancel"
            >
              {t(realEventPaymentTrans.cancel)}
            </Button>
            <Button onClick={onSubmit}>{t(realEventPaymentTrans.continue)}</Button>
          </div>
        </TicketDetailStyle>
      </Spin>

      <ModalStyled onCancel={onCancel} open={isShowModal} centered footer={false}>
        <TextComfirmStyled>{t(realEventPaymentTrans.number_of_ticket_insufficent)}</TextComfirmStyled>
        <Button
          onClick={() => {
            history.push(`/events/${params?.eventId}`);
          }}
        >
          {t(realEventPaymentTrans.re_select)}
        </Button>
      </ModalStyled>
      <Prompt when={!error} message={t(realEventPaymentTrans.goback)} />
    </PaymentCoinConfirmStyled>
  );
};
export default PaymentCoinConfirm;
