import { STATUS_TYPE } from '@constants';
import { FAILURE, REQUEST, SUCCESS, createReducer, updateObject } from '@stores';
import { Action } from '@type/Store';
import { PaymentState } from '@type/Store/payment';
import { PAYMENT_LIST_HISTORY, PAYMENT_LIST_PACKAGE, PAYMENT_PACKAGE, VERIFICATION } from './constants';
// import { LOCATION_CHANGE } from 'connected-react-router'

export const initialState: PaymentState = {
  isLoading: false,
  error: null,
  paymentIntent: {},
  paymentStatus: {},
  packageList: {},
  packageListHistory: {},
};

/**
 * getPaymentIntent
 * @param state
 */
function getPaymentIntent(state: PaymentState) {
  return updateObject(state, {
    isLoading: true,
    status: STATUS_TYPE.REQUEST,
  });
}

function getPaymentIntentSuccess(state: PaymentState, { payload }: Action) {
  const { paymentIntent, paymentStatus } = payload;
  return updateObject(state, {
    isLoading: false,
    paymentIntent,
    paymentStatus,
    status: STATUS_TYPE.SUCCESS,
  });
}

function getPaymentIntentError(state: PaymentState, { error }: Action) {
  return updateObject(state, {
    error,
    isLoading: false,
    status: STATUS_TYPE.FAIL,
  });
}

/**
 * createPaymentPackage
 * @param state
 */
function createPaymentPackage(state: PaymentState) {
  return updateObject(state, {
    isLoading: false,
    error: null,
  });
}

function createPaymentPackageSuccess(state: PaymentState, { payload }: Action) {
  const { paymentStatus } = payload;
  return updateObject(state, {
    isLoading: false,
    paymentStatus,
  });
}

function createPaymentPackageError(state: PaymentState, { error }: Action) {
  return updateObject(state, { error });
}

/**
 * getPaymentListPackage
 * @param state
 */
function getPaymentListPackage(state: PaymentState) {
  return updateObject(state, {
    isLoading: true,
  });
}

function getPaymentListPackageSuccess(state: PaymentState, { payload }: Action) {
  const { packageList } = payload;
  return updateObject(state, {
    isLoading: false,
    packageList,
  });
}

function getPaymentListPackageError(state: PaymentState, { error }: Action) {
  return updateObject(state, {
    error,
    isLoading: false,
  });
}

/**
 * getPaymentListHistory
 * @param state
 */
function getPaymentListHistory(state: PaymentState) {
  return updateObject(state, {
    isLoading: true,
  });
}

function getPaymentListHistorySuccess(state: PaymentState, { payload }: Action) {
  const { packageListHistory } = payload;
  return updateObject(state, {
    isLoading: false,
    packageListHistory,
  });
}

function getPaymentListHistoryError(state: PaymentState, { error }: Action) {
  return updateObject(state, {
    error,
    isLoading: false,
  });
}

// function resetState(state: PaymentState) {
// 	return updateObject(state, {
// 		paymentStatus:{}
// 	})
// }

export default createReducer(initialState, {
  [REQUEST(VERIFICATION)]: getPaymentIntent,
  [SUCCESS(VERIFICATION)]: getPaymentIntentSuccess,
  [FAILURE(VERIFICATION)]: getPaymentIntentError,

  [REQUEST(PAYMENT_PACKAGE)]: createPaymentPackage,
  [SUCCESS(PAYMENT_PACKAGE)]: createPaymentPackageSuccess,
  [FAILURE(PAYMENT_PACKAGE)]: createPaymentPackageError,

  [REQUEST(PAYMENT_LIST_PACKAGE)]: getPaymentListPackage,
  [SUCCESS(PAYMENT_LIST_PACKAGE)]: getPaymentListPackageSuccess,
  [FAILURE(PAYMENT_LIST_PACKAGE)]: getPaymentListPackageError,

  [REQUEST(PAYMENT_LIST_HISTORY)]: getPaymentListHistory,
  [SUCCESS(PAYMENT_LIST_HISTORY)]: getPaymentListHistorySuccess,
  [FAILURE(PAYMENT_LIST_HISTORY)]: getPaymentListHistoryError,

  // [LOCATION_CHANGE]: resetState
});
