import React, { useState } from 'react';

import { EventDetail } from '@type/Store/event';
import { formatDateTimeOfWeek } from '@utils/time';
import { Content } from '@modules/tickets-goods/detail/styles';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';

type EventProps = {
  event: EventDetail;
};

const EventContent: React.FC<EventProps> = ({ event, children }) => {
  const { t, i18n } = useTranslation();
  const { common } = translations;
  const [expandable, setExpandable] = useState(true);
  return (
    <Content>
      <h1>{event?.title}</h1>
      {expandable && (
        <Paragraph
          className="content"
          ellipsis={{
            rows: 3,
            expandable: true,
            symbol: t(common.see_more),
            onExpand: () => setExpandable(false),
          }}
        >
          {event?.content}
        </Paragraph>
      )}
      {!expandable && (
        <Paragraph className="content" ellipsis={false}>
          {event?.content}
          <span onClick={() => setExpandable(true)} className="ant-typography-expand">
            {t(common.see_less)}
          </span>
        </Paragraph>
      )}
      <p>
        <time className="time">
          {formatDateTimeOfWeek(event?.start_at, i18n.language)} - {formatDateTimeOfWeek(event?.end_at, i18n.language)}
        </time>
      </p>
      <div className="content-child">{children}</div>
    </Content>
  );
};
export default EventContent;
