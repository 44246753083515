import React from 'react';
import { DEFAULT_EVENT_THUMBNAIL } from '@assets';

type ImageProps = {
  src: string;
  alt: string;
  className?: string;
  height?: number;
  width?: number;
};
const Image: React.FC<ImageProps> = ({ src, alt, className, width, height }) => {
  const handleImgLoadingError = (e) => {
    e.target.src = DEFAULT_EVENT_THUMBNAIL;
  };

  return (
    <img
      src={src || DEFAULT_EVENT_THUMBNAIL}
      alt={alt}
      onError={(e) => handleImgLoadingError(e)}
      className={className}
      width={width}
      height={height}
    />
  );
};

export default Image;
