import { database, query, ref, onValue, limitToFirst, orderByChild, off } from '@apis/firebase';
import { useState, useEffect } from 'react';

interface IParams {
  roomId?: string | null;
}

interface IRanking {
  name: string | null;
  donate?: number | null;
  id: number | null;
  avatar?: string | null;
}

const TOP_RANKING = 100;
export const useTopRanking = ({ roomId = '' }: IParams) => {
  const [rankings, setRankings] = useState<IRanking[]>([]);
  const [error, setError] = useState<any>(null);
  useEffect(() => {
    let childOnvalueHandler;

    const queryDonate = query(
      ref(database, `topic/${roomId}/donate`),
      orderByChild('donate'),
      limitToFirst(TOP_RANKING),
    );

    if (roomId) {
      onValue(
        queryDonate,
        (snapshots) => {
          // on success
          if (snapshots.exists() && snapshots.size) {
            const data: any[] = [];
            snapshots.forEach((item) => {
              if (item.exists()) {
                data.unshift(item.val());
              }
            });
            const listDonates = data
              .filter((f) => !!f.donate)
              .sort((a, b) => {
                if (b.donate - a.donate > 0) {
                  return 1;
                }
                if (b.donate === a.donate) {
                  return (a.joinTime || 0) - (b.joinTime || 0);
                }
                return -1;
              });

            setRankings(listDonates);
          }
        },
        (err) => {
          setError(() => err);
        }, // on error
        { onlyOnce: false },
      );
    }

    return () => {
      off(queryDonate, 'value', childOnvalueHandler);
    };
  }, [roomId]);

  return {
    rankings,
    roomId,
    error,
  };
};
