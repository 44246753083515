import { useDispatch, useSelector } from 'react-redux';

import saga from '@modules/topic-payment/store/saga';
import {
  getPaymentInfo,
  setKonbiniInfo,
  setCreditCardInfo,
  setUserInfo,
  buyChapter,
  paymentChapter,
  resetData,
  setDeeplink,
} from '@modules/topic-payment/store/actions';
import { useInjectSaga, useInjectReducer } from '@stores';
import reducer, { makeSelectTopics } from '@modules/topic-payment/store/reducer';
import { CreditCardInfo, KobiniInfo, TopicPaymentState, UserInfo } from '@type/Store/topic-payment';

export const useTopicPayment = () => {
  useInjectSaga({ key: 'topicPayment', saga });
  useInjectReducer({ key: 'topicPayment', reducer });

  const state: TopicPaymentState = useSelector(makeSelectTopics());

  const dispatch = useDispatch();

  const setUserInfoAction = (payload: { user_info: UserInfo; payment_method: number }) =>
    dispatch(setUserInfo(payload));

  const setKonbiniInfoAction = (payload: { konbini_info: KobiniInfo; payment_method: number }) =>
    dispatch(setKonbiniInfo(payload));

  const setCreditCardInfoAction = (payload: { credit_card_info: CreditCardInfo; payment_method: number }) =>
    dispatch(setCreditCardInfo(payload));

  const getPaymentInfoAction = (payload: { type: string | null; topicId?: string; packageId?: string }) =>
    dispatch(getPaymentInfo(payload));

  const buyChapterAction = (payload) => dispatch(buyChapter(payload));
  const paymentChapterAction = (payload) => dispatch(paymentChapter(payload));
  const setDeeplinkAction = (payload) => dispatch(setDeeplink(payload));

  const reset = () => dispatch(resetData());
  return {
    ...state,
    getPaymentInfo: getPaymentInfoAction,
    setDeeplink: setDeeplinkAction,
    setUserInfo: setUserInfoAction,
    setKonbiniInfo: setKonbiniInfoAction,
    setCreditCardInfo: setCreditCardInfoAction,
    buyChapter: buyChapterAction,
    paymentChapter: paymentChapterAction,
    reset,
  };
};
