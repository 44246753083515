import Media from '@themes/media';
import { Radio } from 'antd';
import styled from 'styled-components';

export const PaymentAllows = styled.div`
  width: 456px;
  margin: 1rem auto 0 auto;

  ${Media.lessThan(Media.SIZE.SM)} {
    width: 100%;
  }
`;

export const Banks = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  img,
  svg {
    width: 45px;
    height: 30px;
  }
`;

export const Companies = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin-top: 1rem;
  flex-direction: row;
  background: ${({ disabled }) => (disabled ? '#EFEEF3' : 'white')};
`;

export const Diamond = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin-top: 1rem;
  color: #0076e3;
  font-size: ${({ theme }) => theme.size_16};
  font-weight: ${({ theme }) => theme.fw_500};
`;

export const RadioStyled = styled(Radio)`
  .ant-radio-checked {
    &:after {
      border: 1px solid #e3007f;
    }
    .ant-radio-inner {
      border-color: #e3007f;
      &:after {
        background-color: #e3007f;
      }
    }
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 320px;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  width: 100%;
  font-family: 'Noto Sans JP';

  .fee {
    font-size: 12px;
    font-weight: 400;
    color: #787878;
  }

  .group-payment-methods {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1;
    gap: 24px;
  }

  ${Banks}, ${Companies}, ${Diamond} {
    font-family: 'Noto Sans JP';
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 5px;
    border: 1px solid #b4b4b4;
    /* background: ${({ theme }) => theme.white}; */
    padding: 12px;
    margin: 0;
    gap: 0.75rem;

    .methods {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .descriptions {
      font-size: ${({ theme }) => theme.size_10};
      text-align: center;
      color: #787878;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      /* white-space: pre-wrap; */
    }

    .price {
      font-size: ${({ theme }) => theme.size_16};
      font-weight: ${({ theme }) => theme.fw_700};
      color: #e3007f;
    }

    .kobini-note {
      font-size: ${({ theme }) => theme.size_14};
      font-weight: ${({ theme }) => theme.fw_700};
      color: #d80027;
      text-align: center;
    }

    .price-coin {
      font-size: ${({ theme }) => theme.size_16};
      font-weight: ${({ theme }) => theme.fw_700};
      svg {
        margin-right: 5px;
      }
    }
  }

  ${Media.lessThan(Media.SIZE.LG)} {
    .group-payment-methods {
      flex-direction: column;
      gap: 0.75rem;
    }
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    .group-payment-methods {
      flex-direction: column;
      gap: 0.75rem;
    }
  }
`;
