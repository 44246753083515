import styled from 'styled-components';
import Media from '@themes/media';

export const Wrapper = styled.div`
  min-height: calc(100vh - 5.4rem - 60px);
  padding: 0 1rem;
  margin-bottom: 50px;

  ${Media.lessThan(Media.SIZE.BL)} {
    .payment-btn {
      margin-top: 8vh !important;
    }
    .form-group {
      height: 30vh !important;
    }
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    .intro-0-2-5 {
      width: 100% !important;
    }
    .payment-btn {
      margin-top: 8vh !important;
    }
  }

  ${Media.lessThan(Media.SIZE.XXL)} {
    .intro-0-2-5 {
      .payment-btn {
        margin-top: 8vh !important;
      }
      .form-group {
        height: 30vh !important;
      }
    }
  }

  ${Media.lessThan(Media.SIZE.XXS)} {
    .ant-modal-body {
      height: 22vh !important;
    }
  }

  .intro-0-2-5 {
    width: 45vh;
    height: 85vh;
    margin: 0 auto;
    z-index: 200;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    background-color: #ffffff;
    .introImageWrapper-0-2-6 {
      z-index: 1;
      width: 50%;

      .introImage-0-2-7 {
        transform: translateX(10vw);
        background-position: center right;
        height: 100%;
        background-size: contain;
        background-image: url('https://facit-modern.omtanke.studio/static/media/richie.44ff154e0275feeae986.webp');
        background-repeat: no-repeat;
      }
    }
    .payment-content {
      text-align: center;
      width: 100%;
    }
    .payment-top {
      text-align: left;
      padding: 2.5vh 1.5vh;
    }
    .payment-diamond-icon {
      margin-right: 5px;
      margin-bottom: 8px;
    }
    .payment-header {
      text-align: center;
      padding: 10px;
    }
    .payment-amount {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      color: #0076e3;
      flex: none;
      order: 1;
      flex-grow: 0;
    }
    .payment-price-yen {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 38px;
      text-align: center;
      color: #e3007f;
    }
    .back-icon {
      text-align: left;
      cursor: pointer;
    }
    .ant-input {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #282727;
    }
    .ElementsApp .InputElement.is-invalid {
    }
    .payment-title {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #282727;
    }
    .payment-icon {
      margin-left: 25px;
    }
    .payment-btn {
      margin-top: 5vh;
      text-align: center;
    }
    .CardBrandIcon img,
    .CardBrandIcon svg {
      left: 20rem !important;
    }
    .btn-login {
      color: #ffffff;
      background-color: #e3007f;
      opacity: 0.5;
      border-radius: 24px;
      width: 100%;
      height: 50px;
    }
    .form-group {
      height: 39vh;
      padding-bottom: 20vh;
    }

    .btn-cancel {
      color: #787878;
      background: #d9d9d9;
      border-radius: 24px;
      width: 100%;
      height: 50px;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.002em;
      color: #787878;
    }
    .payment-btn-cancel {
      padding: 2vh 0px;
    }
    .ant-radio-inner:after {
      background-color: #e3007f;
    }
    .ant-radio-checked .ant-radio-inner {
      border-color: #e3007f;
    }

    .cvv-icon {
      position: absolute;
      padding: 8px 0px;
      right: 20px;
      cursor: pointer;
    }
    .btn-login:hover {
      opacity: unset;
      border-color: #e3007f;
    }
    .introContentWrapper-0-2-8 {
      z-index: 2;
      width: 50%;
      .display-1 {
        font-size: calc(1.5rem + 4.5vw);
        font-weight: 300;
        line-height: 1.2;
        img {
          width: 250px;
        }
      }
      .display-6 {
        font-size: calc(1.375rem + 1.5vw);
        font-weight: 300;
        line-height: 1.2;
      }
      .h2 {
        font-size: calc(1.325rem + 0.9vw);
        .text-info {
          color: #4e68f9;
        }
      }
    }
  }
`;
