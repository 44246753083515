/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import WalletConnectProvider from '@walletconnect/web3-provider';
import { providers } from 'ethers';
import Web3Modal from 'web3modal';
import { AbiItem } from 'web3-utils';
import Web3 from 'web3';
import { isMobile, isTablet } from 'react-device-detect';
import { getLocalStorage, STORAGE } from '@utils';
import { getEnv } from '@config/env';
import saga from '@modules/diamond-purchase/store/saga';
import reducer from '@modules/diamond-purchase/store/reducer';
import { useInjectSaga, useInjectReducer } from '@stores';
import { connectWallet as connectWalletAction, resetState } from '@modules/diamond-purchase/store/action';
import { makeSelectSignature } from '@modules/diamond-purchase/store/selectors';
import { erc20Contract, iLiveContract } from '@abi/index';

export const WALLET_LIST = [
  {
    name: 'Metamask',
    link: 'https://metamask.app.link',
  },
];

export function useWeb3() {
  useInjectSaga({ key: 'signature', saga });
  useInjectReducer({ key: 'signature', reducer });
  const dispatch = useDispatch();
  const data = useSelector(makeSelectSignature());

  const infuraId = useMemo(() => getEnv('INFURA_ID', null), []);

  const generateDappUrl = () => {
    const item = { link: 'https://metamask.app.link', name: 'Metamask' };
    const url = item.link;
    if (url) {
      if (item.name === 'Metamask') {
        const dappUrl = `${window.location.href}`.trim().replace('https://', '').replace('/', '');
        const token = getLocalStorage(STORAGE.USER_TOKEN);
        const pageUrl = `${url}/dapp/${dappUrl}?token=${token}`;
        window.location.href = `${pageUrl}`;
      }
    }
  };

  const web3Modal = useMemo(() => {
    if (!window.ethereum && !isMobile && !isTablet) {
      if (typeof window !== 'undefined') {
        const providerOptions = {
          walletconnect: {
            package: WalletConnectProvider,
            options: {
              infuraId,
            },
          },
        };
        return new Web3Modal({
          cacheProvider: false,
          providerOptions, // required
        });
      }
    }
    return null;
  }, [window.ethereum, isMobile, isTablet]);

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const web3Instance = await new Web3(window.ethereum);

        await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        const ilive = await new web3Instance.eth.Contract(iLiveContract.abi as AbiItem[], iLiveContract.address);
        const erc20 = await new web3Instance.eth.Contract(erc20Contract.abi as AbiItem[], erc20Contract.address);
        const account = await web3Instance.eth.getAccounts();
        dispatch(
          connectWalletAction({
            web3: web3Instance,
            ilive,
            erc20,
            walletAdress: account[0],
          }),
        );
      } catch (error: any) {
        message.error(error?.message);
      }
    }
    if (isMobile || isTablet) {
      generateDappUrl();
    }
    if (!window.ethereum && !(isMobile || isTablet) && web3Modal) {
      const provider = await web3Modal.connect();
      const web3js = new Web3(provider);
      const web3Provider = new providers.Web3Provider(provider);
      const signer = web3Provider.getSigner();
      const account = await signer.getAddress();
      const ilive = await new web3js.eth.Contract(iLiveContract.abi as AbiItem[], iLiveContract.address);
      const erc20 = await new web3js.eth.Contract(erc20Contract.abi as AbiItem[], erc20Contract.address);
      dispatch(
        connectWalletAction({
          web3: web3js,
          ilive,
          erc20,
          walletAdress: account,
        }),
      );
    }
  };
  const getContract = (web3, abi, address) => {
    if (web3) {
      return new web3.eth.Contract(abi, address);
    }
    return null;
  };
  const resetStateAction = () => dispatch(resetState());
  return {
    ...data,
    connectWallet,
    resetStateAction,
    getContract,
  };
}
