import { put } from 'redux-saga/effects';
import { setError, setLoading } from './actions';

export function* sagaCustomize(callbackAction) {
  try {
    yield put(setLoading(true));
    yield callbackAction();
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setError(error));
    yield put(setLoading(false));
  }
}
