/* eslint-disable no-console */

import AgoraRTC, {
  ConnectionDisconnectedReason,
  ConnectionState,
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
  AudienceLatencyLevelType,
  NetworkQuality,
  RemoteStreamType,
  IDataChannelConfig,
} from 'agora-rtc-sdk-ng';
import { useEffect, useState } from 'react';
import { getEnv } from '@config/env';
import { QualityType } from '@constants';
import { useAuth } from './auth';

const AGORA_APP_ID = getEnv('AGORA_KEY', null);
export const useAgora = () => {
  const client: IAgoraRTCClient = AgoraRTC.createClient({
    mode: 'live',
    codec: 'vp8',
    role: 'audience',
    clientRoleOptions: {
      level: AudienceLatencyLevelType.AUDIENCE_LEVEL_LOW_LATENCY,
    },
  });
  const { profile } = useAuth();

  const [isJoined, setIsJoined] = useState(false);
  const [connectionState, setConnectionState] = useState<ConnectionState>('DISCONNECTED');
  const [remoteUid, setRemoteUid] = useState<string | number>(0);
  const [networkQuality, setNetworkQuality] = useState<number>(0);

  const onUserPublish = async (
    user: IAgoraRTCRemoteUser,
    mediaType: 'video' | 'audio' | 'datachannel',
    config?: IDataChannelConfig,
  ) => {
    console.log('========> onUserPublish user: ', { user, mediaType, config });
    setRemoteUid(user.uid);
    if (mediaType === 'video') {
      // const remoteVideo = document.getElementById('remote-video');
      const remoteTrack: IRemoteVideoTrack = await client.subscribe(user, mediaType);
      // if(remoteVideo) {
      //   remoteVideo?.mute = false
      // }
      remoteTrack.play('remote-video');
      // remoteTrack.play('remote-video', { mirror: true, fit: 'fill' });
      // remoteTrack
      // remoteTrack.setVolume(100)
    }
    if (mediaType === 'audio') {
      const remoteTrack: IRemoteAudioTrack = await client.subscribe(user, mediaType);
      remoteTrack.play();
      remoteTrack.setVolume(100);
    }
  };

  const leaveChannel = async () =>
    client.leave().then(() => {
      setIsJoined(false);
    });

  const joinChannel = async ({ channel, roomToken }: { channel: string; roomToken: string }) => {
    if (isJoined) {
      await leaveChannel();
    }

    client.join(AGORA_APP_ID, channel, roomToken, profile?.id || 0).then(() => {
      setIsJoined(true);
    });
  };

  useEffect(() => {
    if (client) {
      client.on('user-published', onUserPublish);

      client.on(
        'user-unpublished',
        (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video' | 'datachannel', config?: IDataChannelConfig) => {
          console.log('========> user-unpublished: ', { user, mediaType, config });
        },
      );

      client.on('user-info-updated', (uid, msg) => {
        console.log('user-info-updated!', { uid, msg });
      });

      client.on('autoplay-failed', (info) => {
        console.log('Autoplay failed!', info.state, info.device);
      });

      client.on(
        'connection-state-change',
        (curState: ConnectionState, revState: ConnectionState, reason?: ConnectionDisconnectedReason) => {
          console.log('========> ConnectionState: ', {
            curState,
            revState,
            reason,
          });
          setConnectionState(curState);
        },
      );

      client.on('live-streaming-error', (url, error) => {
        console.log('======> ERROR: ', { error, url });
      });

      client.on('live-streaming-warning', (url, warning) => {
        console.log('======> warning: ', { warning, url });
      });

      client.on('channel-media-relay-event', (event) => {
        console.log('======> channel-media-relay-event: ', event);
      });

      client.on('channel-media-relay-state', (state, code) => {
        console.log('======> channel-media-relay-state: ', { state, code });
      });

      client.on('crypt-error', () => {
        // console.log('======> crypt-error: ');
      });

      client.on('exception', (exception) => {
        console.log('======> exception: ', { exception });
      });

      client.on('media-reconnect-end', (uid) => {
        console.log('======> media-reconnect-end: ', { uid });
      });

      client.on('media-reconnect-start', (uid) => {
        console.log('======> media-reconnect-start: ', { uid });
      });

      client.on('network-quality', (stats: NetworkQuality) => {
        setNetworkQuality(stats.downlinkNetworkQuality);
        if (
          stats.downlinkNetworkQuality === QualityType.QualityExcellent ||
          stats.downlinkNetworkQuality === QualityType.QualityGood
        ) {
          client.setRemoteVideoStreamType(remoteUid || 0, RemoteStreamType.HIGH_STREAM);
        }
        if (stats.downlinkNetworkQuality > 2 || stats.downlinkNetworkQuality === QualityType.QualityUnknown) {
          client.setRemoteVideoStreamType(remoteUid || 0, RemoteStreamType.LOW_STREAM);
        }
      });

      client.on('user-joined', (user: IAgoraRTCRemoteUser) => {
        console.log('========> user-joined: ', user);
      });

      client.on('published-user-list', (user: IAgoraRTCRemoteUser) => {
        console.log('========> published-user-list: ', user);
      });

      client.on('stream-type-changed', (uid, streamType) => {
        console.log('======> stream-type-changed: ', { uid, streamType });
      });

      client.on('token-privilege-will-expire', async function () {
        console.log('======> token-privilege-will-expire');
        // After requesting a new token
        // await client.renewToken(token);
      });

      client.on('user-left', (user: IAgoraRTCRemoteUser, reason) => {
        console.log('========> user-joined: ', { user, reason });
      });

      client.on('volume-indicator', function (result) {
        result.forEach(function (volume, index) {
          console.log(`${index} UID ${volume.uid} Level ${volume.level}`);
        });
      });

      client.on('audio-context-state-changed', (currState, prevState) => {
        console.log('========> audio-context-state-changed: ', { currState, prevState });
      });

      client.on('token-privilege-did-expire', async () => {
        console.log('======> token-privilege-did-expire');
      });
    }

    return () => {
      // client.off('user-published', () => {})
      // client.off('autoplay-failed', () => {})
      // client.off('connection-state-change', () => {})
      // client.off('channel-media-relay-event', () => {})
      // client.off('channel-media-relay-state', () => {})
      // client.off('crypt-error', () => {})
      // client.off('exception', () => {})
      // client.off('media-reconnect-end', () => {})
      // client.off('network-quality', () => {})
      // client.off('user-joined', () => {})
      // client.off('published-user-list', () => {})
      // client.off('stream-type-changed', () => {})
      // client.off('token-privilege-will-expire', () => {})
      // client.off('user-left', () => {})
      // client.off('volume-indicator', () => {})
      // client.off('live-streaming-error', () => {})
      // client.off('live-streaming-warning', () => {})
      // client.off('live-streaming-warning', () => {});
    };
  }, [client]);

  useEffect(() => {
    return () => {
      client.removeAllListeners();
    };
  }, []);

  return {
    joinChannel,
    leaveChannel,
    isJoined,
    connectionState,
    networkQuality,
  };
};

export default useAgora;
