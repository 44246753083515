export const LOGIN = '@AUTH/LOGIN';
export const REGISTER = '@AUTH/REGISTER';
export const VERIFY_REGISTER = '@AUTH/REGISTER_VERIFY';
export const RESEND_CODE = '@AUTH/RESEND_CODE';
export const LOGOUT = '@AUTH/LOGOUT';
export const VERIFICATION = '@AUTH/VERIFICATION';
export const RESET_PASSWORD = '@AUTH/RESET_PASSWORD';
export const LOAD_PROFILE = '@AUTH/LOAD_PROFILE';
export const RESET_AUTH = '@AUTH/RESET_AUTH';
export const CHANGE_LANGUAGE = '@AUTH/CHANGE_LANGUAGE';
