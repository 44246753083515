import Media from '@themes/media';
import styled from 'styled-components';

export const Wrapper = styled.div`
  .line {
    margin: 13px 0px;
  }
  ${Media.lessThan(Media.SIZE.MD)} {
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 16px;
  color: #282727;
  font-size: 14px;
  font-weight: 500;
  justify-items: flex-start;
  gap: 4px;
  ${Media.lessThan(Media.SIZE.MD)} {
  }
`;

export const MessageIcon = styled.img`
  white-space: pre-wrap;
  ${Media.lessThan(Media.SIZE.MD)} {
  }
`;
