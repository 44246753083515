import React from 'react';
import { Image } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';

import { timeStampToDate, formatDateTimeOfWeek } from '@utils/time';
import { FORMAT_TIME } from '@constants';
import PaymentStatus from '@modules/history/ticket-detail/components/payment-status';

import { DEFAULT_EVENT_THUMBNAIL } from '@assets';
import { STATUS } from '@constants/real-event';
import { CardTicketStyled, TitleCardStyled, TimeCardStyled, CancelMark } from './styled';

interface CardTicketProps {
  thumbnail: string;
  title: string;
  timeAt: number;
  timeEnd: number;
  balanceCode: string;
  realEventId: number;
  paymentStatus: number;
  purchaseAt: number;
  status?: number;
}

const CardTicket: React.FC<CardTicketProps> = ({
  thumbnail,
  title,
  timeAt,
  timeEnd,
  balanceCode,
  realEventId,
  paymentStatus,
  purchaseAt,
  status,
}: CardTicketProps) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { myTicket, realEventPayment } = translations;

  const timeAtFormat = formatDateTimeOfWeek(timeAt, i18n.language);
  const timeEndFormat = formatDateTimeOfWeek(timeEnd, i18n.language);
  const timePurchaseAt = timeStampToDate(purchaseAt, FORMAT_TIME.DATE_HOUR_MINUTES);

  const handeClick = () => history.push(`/histories/ticket/${realEventId}/${balanceCode}`);

  return (
    <CardTicketStyled onClick={handeClick}>
      <div>
        <div style={{ position: 'relative' }}>
          <Image preview={false} alt="thumb-nail" src={thumbnail || DEFAULT_EVENT_THUMBNAIL} />
          {status === STATUS.CANCELED && <CancelMark>{t(realEventPayment.Cancelled)}</CancelMark>}
        </div>
        <TitleCardStyled>{title}</TitleCardStyled>
        <TimeCardStyled>
          {timeAtFormat} - {timeEndFormat}
        </TimeCardStyled>
        <PaymentStatus status={paymentStatus} />
      </div>

      <TimeCardStyled className="text-time-end">
        {t(myTicket.my_ticket_detail.date_of_acquisition)}：{timePurchaseAt}
      </TimeCardStyled>
    </CardTicketStyled>
  );
};

export default CardTicket;
