import React, { useEffect, useState } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { isMobile } from 'react-device-detect';
import {
  ModalStyled,
  TextComfirmStyled,
} from '@modules/tickets-goods/real-event-payment/components/payment-confirm/styled';
import { TextInput, Button } from '@components';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { translations } from '@i18n/translations';
import { useTranslation } from 'react-i18next';
import useExitPrompt from '@hooks/useExitPrompt';
import { convertStringToHalfWidth } from '@utils/string';
import Header from '@modules/tickets-goods/real-event-payment/components/header';
import { Form, FormWrapper } from '@modules/tickets-goods/real-event-payment/styled';
import { useTopicPayment } from '@hooks/useTopicPayment';
import { FORM_CREDIT_CARD } from '@modules/topic-payment/constants';
import scheme from './schema';

interface FormValues {
  email: string;
  cardholder: string;
  date_expiry: string;
  cvc: string;
  phone_number: string;
  card_number: string;
}

interface Props {
  onSubmitForm: () => void;
}
const defaultValues = {
  email: '',
  cardholder: '',
  date_expiry: '',
  cvc: '',
  phone_number: '',
  // cardnumber: '4100000000100009',
  card_number: '',
};

interface Params {
  packageId?: string;
  topicId?: string;
}

export const InputPayment: React.FC<Props> = ({ onSubmitForm }) => {
  const [isShowCreditCard, setIsShowCreditCard] = useState<boolean>(false);
  const { user_info, setCreditCardInfo, setUserInfo, payment_method, credit_card_info } = useTopicPayment();
  const { t } = useTranslation();
  const { search } = useLocation();
  const { topicId, packageId } = useParams<Params>();
  const queryParams = new URLSearchParams(search);
  const type = queryParams.get('type');
  const { replace } = useHistory();

  const { realEventPayment } = translations;
  useExitPrompt(true);

  const form = useForm({
    defaultValues,
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    resolver: yupResolver(scheme),
  });
  const { handleSubmit, setValue } = form;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, name) => {
    const { target } = e;
    const { value } = target;
    const convertHaflWidth = convertStringToHalfWidth(value || '');

    setValue(name, convertHaflWidth);
  };

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const { date_expiry, cvc, card_number, cardholder, phone_number } = data;
    const [YY, MM] = date_expiry.split('/');
    const cardno = card_number.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    window.Multipayment.getToken(
      {
        cardno,
        expire: `${YY}${MM}`,
        securitycode: cvc,
        holdername: cardholder,
        tokennumber: 1,
      },
      (response) => {
        if (response.resultCode !== '000') {
          setIsShowCreditCard(true);
        } else {
          const { tokenObject } = response;
          setCreditCardInfo({
            payment_method,
            credit_card_info: {
              date_expiry,
              cvc,
              card_number,
              cardholder,
              token: tokenObject.token[0],
            },
          });
          setUserInfo({
            user_info: {
              ...user_info,
              phone_number,
            },
            payment_method,
          });
          onSubmitForm();
        }
      },
    );
  };

  useEffect(() => {
    const keys = Object.keys(defaultValues) as Array<keyof FormValues>;
    keys.forEach((key) => {
      if (Object.keys(credit_card_info).includes(key)) setValue(key, credit_card_info[key]);
      else setValue(key, user_info[key] || '');
    });
  }, [user_info, credit_card_info]);

  return (
    <FormProvider {...form}>
      <FormWrapper>
        <Header
          title={t(realEventPayment.credit.title)}
          onClick={() => replace(`/topics/${topicId}/payment/${packageId}?type=${type}`)}
        />
        <Form>
          {FORM_CREDIT_CARD.map((item) => (
            <TextInput
              width={isMobile ? '100%' : '49%'}
              name={item.name}
              placeholder={item.placeholder}
              label={t(item.label)}
              translateField={translations.realEventPayment.fieldName[item.name]}
              inputType="required"
              cvvInfo={item.name === 'cvc'}
              key={item.name}
              inputFormat={item.inputFormat}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, item.name)}
            />
          ))}
          <div style={{ fontSize: 16, fontWeight: 400, color: '#787878', marginTop: 4 }}>
            {t(realEventPayment.credit.note)}
          </div>
        </Form>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 24 }}>
          <Button onClick={handleSubmit(onSubmit)}>{t(realEventPayment.continue)}</Button>
        </div>
      </FormWrapper>
      <ModalStyled centered onCancel={() => setIsShowCreditCard(false)} open={isShowCreditCard} footer={false}>
        <TextComfirmStyled>購入処理中にエラーが発生しました</TextComfirmStyled>
        <Button onClick={() => setIsShowCreditCard(false)}>OK</Button>
      </ModalStyled>
    </FormProvider>
  );
};
