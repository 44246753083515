import styled from 'styled-components';

const Wrapper = styled.div``;

export const Row = styled.div`
  display: grid;
  flex-direction: row;
`;

export const Title = styled.div`
  display: flex;
  white-space: pre;
`;
export { Wrapper };
