import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@components';
import { translations } from '@i18n/translations';
import { DIAMOND } from '@assets';
import { CARDS, KONBINIS } from '@modules/tickets-goods/detail/constants';
import { Banks, Diamond, Companies, PaymentAllows as Wrapper } from '../payment-allows-login/styled';

type IProps = {
  onClick: () => void;
};

const PaymentAllows: React.FC<IProps> = ({ onClick }) => {
  const { t } = useTranslation();

  const { common } = translations;

  return (
    <>
      <Wrapper>
        <Banks>
          {CARDS.map((item) => (
            <img src={item.src} alt={item.alt} key={item.alt} />
          ))}
        </Banks>
        <Companies>
          {KONBINIS.map(({ isIcon, src, alt, Icon }) => {
            if (isIcon) return <Icon key={alt} />;
            return <img src={src} alt={alt} key={alt} />;
          })}
        </Companies>
        <Diamond>
          <img src={DIAMOND} alt="diamond" width={22} />
          Diamond
        </Diamond>
      </Wrapper>
      <Button className="btn-purchase" htmlType="button" onClick={onClick}>
        {t(common.button.purchase)}
      </Button>
    </>
  );
};
export default PaymentAllows;
