import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useHistories } from '@hooks/hisories';
import { Wrapper, Content, Spin } from './styled';
import TopicInfo from './components/topic-info';
import UserInfo from './components/user-info';

const TopicHistory: React.FC = () => {
  const { balanceCode }: { balanceCode: string } = useParams();
  const { getTopicDetail, isLoading } = useHistories();
  useEffect(() => {
    getTopicDetail({ balanceCode });
  }, []);

  return (
    <Spin spinning={isLoading}>
      <Wrapper>
        <Content>
          <TopicInfo />
          <UserInfo />
        </Content>
      </Wrapper>
    </Spin>
  );
};

export default TopicHistory;
