import React from 'react';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { getEnv } from '@config/env';
import { Elements } from '@stripe/react-stripe-js';
import PaymentInformationScreen from './payment';

const stripePromise = loadStripe(getEnv('STRIPE_PK', null));

const PaymentScreen: React.FC = () => {
  const { i18n } = useTranslation();
  return (
    <Elements
      options={{
        locale: i18n.language === 'ja' ? 'ja' : 'en',
      }}
      stripe={stripePromise}
    >
      <PaymentInformationScreen />
    </Elements>
  );
};

export default PaymentScreen;
