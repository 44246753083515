import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';

import { Button } from '@components';
import { translations } from '@i18n/translations';
import { numberWithCommas } from '@utils/number';
import { useTopicPayment } from '@hooks/useTopicPayment';
import { Group, PaymentInfoStyle, TicketDetailStyle, Wrapper, Title } from './styled';

const { realEventPayment, topic } = translations;

type Props = {
  onCancel: () => void;
  onPurchase: () => void;
};

const Confirm: React.FC<Props> = ({ onCancel, onPurchase }) => {
  const { t } = useTranslation();
  const { user_info, credit_card_info, topic_info, loading } = useTopicPayment();
  const { card_number, date_expiry } = credit_card_info;

  return (
    <Wrapper>
      <Spin spinning={loading}>
        <TicketDetailStyle>
          <Title> {t(realEventPayment.purchase_information)} </Title>
          <Group>
            <label>{t(topic.topics)}</label>
            <p>{topic_info.topic_title}</p>
          </Group>
          <Group>
            <label>{t(topic.episode)}</label>
            <p>{topic_info.title}</p>
          </Group>
          {(user_info.last_name + user_info.first_name)?.length > 0 && (
            <Group>
              <label>{t(realEventPayment.user_name)}</label>
              <p>{`${user_info.last_name} ${user_info.first_name}`}</p>
            </Group>
          )}
          <Group>
            <label>{t(realEventPayment.total_amount_notFee)}</label>
            <p>
              <span className="yen">￥{numberWithCommas(Number(topic_info.yen_price), ',')}</span>
            </p>
          </Group>
        </TicketDetailStyle>
        <PaymentInfoStyle>
          <Title> {t(realEventPayment.payment_information)} </Title>
          <Group>
            <label>{t(realEventPayment.payment_method)}</label>
            <p>{t(translations.myTicket.my_ticket_detail.payment_method.credit_card)}</p>
          </Group>
          <Group>
            <label>{t(realEventPayment.name)}</label>
            <p>{credit_card_info.cardholder}</p>
          </Group>
          {!!card_number && (
            <Group>
              <label>{t(realEventPayment.fieldName.cardnumber)}</label>
              <p>{card_number}</p>
            </Group>
          )}
          {!!date_expiry && (
            <Group>
              <label>{t(realEventPayment.fieldName.dateExpiry)}</label>
              <p>{date_expiry}</p>
            </Group>
          )}
          <Group>
            <label>{t(realEventPayment.email)}</label>
            <p className="custom-width">{user_info.email}</p>
          </Group>
          <Group>
            <label>{t(realEventPayment.phone)}</label>
            <p>{user_info.phone_number}</p>
          </Group>
          <div className="box-btn">
            <Button onClick={onCancel} type="cancel" loading={loading}>
              {t(realEventPayment.cancel)}
            </Button>
            <Button onClick={onPurchase} loading={loading}>
              {t(realEventPayment.continue)}
            </Button>
          </div>
        </PaymentInfoStyle>
      </Spin>
    </Wrapper>
  );
};
export default Confirm;
