import React, { useState, useMemo, useEffect } from 'react';
import { Typography, Spin } from 'antd';
import { useHistory, useParams, Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';

import { useAuth, useEvents, useRealEventPayment } from '@hooks';
import { COIN_ICON } from '@assets';
import { numberWithCommas } from '@utils/number';
import { STATUS_PAYMENT_COIN } from '@constants/real-event';
import PaymentCoinEnough from './components/payment-coin-enough';
import PaymentCoinConfirm from './components/payment-coin-confirm';
import PaymentCoinNotEnough from './components/payment-coin-not-enough';
import PaymentCoinSuccess from './components/payment-coin-success';
import PaymentCoinFail from './components/payment-coin-fail';
import BoxPayment from './components/box-payment';

import { PaymentCoinStyled, ContentStyled, TextCoin } from './styled';

interface Params {
  eventId: string;
}

const { Text } = Typography;

export const PaymentCoin: React.FC = () => {
  const { ticketsData, eventDetail } = useEvents();

  const { profile } = useAuth();
  const [statusPayment, setStatusPayment] = useState<STATUS_PAYMENT_COIN>(STATUS_PAYMENT_COIN.LOADING);
  const history = useHistory();
  const params: Params = useParams();
  const { t } = useTranslation();
  const { error } = useRealEventPayment();

  const { realEventPayment, common } = translations;

  useEffect(() => {
    if (ticketsData) {
      if (Number(profile?.coins || 0) >= ticketsData.totalCoin) {
        setStatusPayment(STATUS_PAYMENT_COIN.ENOUGH);
      } else {
        setStatusPayment(STATUS_PAYMENT_COIN.NOT_ENOUGH);
      }
    } else {
      history.replace(`/events/${params.eventId}`);
    }
  }, [ticketsData]);

  const checkShowBalance =
    statusPayment === STATUS_PAYMENT_COIN.ENOUGH || statusPayment === STATUS_PAYMENT_COIN.NOT_ENOUGH;

  const renderByStatus = useMemo(() => {
    switch (statusPayment) {
      case STATUS_PAYMENT_COIN.NOT_ENOUGH:
        return (
          <BoxPayment title={t(realEventPayment.pay_by_coin)} routeBack={`/events/${params?.eventId}`} isShowIconBack>
            <ContentStyled>
              {checkShowBalance && (
                <Typography className="text-balance">
                  <Text className="label">{t(realEventPayment.coin_held)}</Text>
                  <TextCoin>
                    <COIN_ICON style={{ width: '22px', height: '22px', marginRight: '5px' }} />
                    {numberWithCommas(profile?.coins, ',')}
                  </TextCoin>
                </Typography>
              )}
              <PaymentCoinNotEnough totalCoin={ticketsData?.totalCoin} />
            </ContentStyled>
          </BoxPayment>
        );

      case STATUS_PAYMENT_COIN.SUCCESS:
        return (
          <BoxPayment title={t(realEventPayment.payment_coin_completed)} isShowIconBack={false}>
            <ContentStyled>
              <PaymentCoinSuccess totalCoinPrice={ticketsData?.totalCoin} isPayCoin />
            </ContentStyled>
          </BoxPayment>
        );

      case STATUS_PAYMENT_COIN.FAILURE:
        return (
          <BoxPayment title={t(realEventPayment.payment_coin_fail)} isShowIconBack={false}>
            <ContentStyled>
              <PaymentCoinFail />
            </ContentStyled>
          </BoxPayment>
        );

      case STATUS_PAYMENT_COIN.ENOUGH:
        return (
          <BoxPayment title={t(realEventPayment.pay_by_coin)} routeBack={`/events/${params?.eventId}`} isShowIconBack>
            <ContentStyled>
              {checkShowBalance && (
                <Typography className="text-balance">
                  <Text className="label">{t(realEventPayment.coin_held)} </Text>
                  <TextCoin>
                    <COIN_ICON style={{ width: '22px', height: '22px', marginRight: '5px' }} />
                    {numberWithCommas(profile?.coins, ',')}
                  </TextCoin>
                </Typography>
              )}
              <PaymentCoinEnough totalCoin={ticketsData?.totalCoin} setStatusPayment={setStatusPayment} />
              <Prompt when={!error} message={t(realEventPayment.goback)} />
            </ContentStyled>
          </BoxPayment>
        );

      case STATUS_PAYMENT_COIN.CONFIRM:
        return (
          <BoxPayment
            title={t(common.event.confirm_popup_title)}
            routeBack={`/events/${params?.eventId}`}
            isShowIconBack
          >
            <ContentStyled>
              <PaymentCoinConfirm
                totalCoinPrice={ticketsData?.totalCoin}
                tickets={ticketsData?.tickets}
                eventDetail={eventDetail}
                totalTicket={ticketsData?.totalTicket}
                setStatusPayment={setStatusPayment}
                coinFee={ticketsData?.coinFee}
                memo={ticketsData?.memo}
                name={`${ticketsData?.lastKanji} ${ticketsData?.firstKanji}`}
              />
            </ContentStyled>
          </BoxPayment>
        );
      default:
        return <Spin spinning />;
    }
  }, [statusPayment, t]);

  return <PaymentCoinStyled>{renderByStatus}</PaymentCoinStyled>;
};
