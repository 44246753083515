import { useDispatch, useSelector } from 'react-redux';

import saga from '@modules/tickets-goods/real-event-payment/store/saga';
import reducer from '@modules/tickets-goods/real-event-payment/store/reducer';
import {
  realEventPaymentAction,
  purchaseWithCreditCard,
  purchaseWithKobini,
  setCardInfo,
  clearCardInfo,
} from '@modules/tickets-goods/real-event-payment/store/actions';
import { useInjectSaga, useInjectReducer } from '@stores';
import { CardInfo, RealEventPaymentState } from '@type/Store/real-event-purchase';
import { makeSelectRealEventPayment } from '@modules/tickets-goods/real-event-payment/store/selectors';

export const useRealEventPayment = () => {
  useInjectSaga({ key: 'realEventPayment', saga });
  useInjectReducer({ key: 'realEventPayment', reducer });

  const state: RealEventPaymentState = useSelector(makeSelectRealEventPayment());

  const dispatch = useDispatch();
  const realEventPayment = (payload?: any) => dispatch(realEventPaymentAction(payload));
  const purchaseWithCreditCardAction = (payload) => dispatch(purchaseWithCreditCard(payload));
  const purchaseWithKobiniAction = (payload) => dispatch(purchaseWithKobini(payload));
  const setCardInfoAction = (payload: CardInfo) => dispatch(setCardInfo(payload));
  const clearCardInfoAction = () => dispatch(clearCardInfo());

  return {
    ...state,
    realEventPayment,
    purchaseWithCreditCard: purchaseWithCreditCardAction,
    purchaseWithKobini: purchaseWithKobiniAction,
    setCardInfo: setCardInfoAction,
    clearCardInfo: clearCardInfoAction,
  };
};
