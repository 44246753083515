import React from 'react';

import { useTopics } from '@hooks';
import Image from '@components/imageFallback';
import { Col, Row, Spin, Tag } from 'antd';
import { isMobile, isTablet } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';
import { ImageBannerTop, WrapperStyled } from './styled';
import { ListChapters } from '../list-chapters';

const TopicTop: React.FC = () => {
  const { topicTop, loading } = useTopics();
  const history = useHistory();
  const { t } = useTranslation();
  const { topic } = translations;

  return (
    <WrapperStyled>
      <Row>
        <Col xl={16} xs={24}>
          <ImageBannerTop onClick={() => history.push(`/topics/${topicTop?.id}`)}>
            {topicTop && <Image src={topicTop.images} alt="Image topic" />}
            {topicTop?.is_living && <Tag className="tag-live">{t(topic.now_on_live)}</Tag>}
          </ImageBannerTop>
        </Col>
        <Col xl={8} xs={24}>
          <Spin spinning={loading}>
            <div className="content-top">
              <div className="title"> {topicTop?.title}</div>
              <div className="content"> {topicTop?.description}</div>
              {!isMobile && !isTablet && (
                <div className="chapters">
                  <div className="chapters-list">
                    <ListChapters isTopList topicDetail={topicTop} />
                  </div>
                </div>
              )}
            </div>
          </Spin>
        </Col>
      </Row>
    </WrapperStyled>
  );
};

export { TopicTop };
