export const NAVBAR = [
  {
    key: 'topics',
    pathName: '/topics',
    trans: 'topics',
    subRoute: ['', '/'],
  },
  {
    key: 'list-event',
    pathName: '/events',
    trans: 'list_event',
    subRoute: ['list-event', 'list-event-closed', 'events', 'payment-coin', 'payment-kobini', 'payment-credit'],
  },
  {
    key: 'diamond-purchase',
    pathName: '/diamond-purchase',
    trans: 'diamond_purchase',
    subRoute: [],
  },
  {
    key: 'histories',
    pathName: '/histories',
    trans: 'history',
    subRoute: [],
  },
];

export const NAVBAR_MOBILE = [
  {
    key: 'topics',
    pathName: '/topics',
    trans: 'topics',
    subRoute: ['', '/'],
  },
  {
    key: 'events',
    pathName: '/events',
    trans: 'list_event',
    subRoute: ['list-event', 'list-event-closed', 'events', 'payment-coin', 'payment-kobini', 'payment-credit'],
  },
  {
    key: 'diamond-purchase',
    pathName: '/diamond-purchase',
    trans: 'diamond_purchase',
    subRoute: [],
  },
  {
    key: 'histories',
    pathName: '/histories',
    trans: 'history',
    subRoute: [],
  },
];
