import { SEND } from '@assets';
import Media from '@themes/media';
import { Input } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  /* width: 300px; */
  height: 360px;
  border-radius: 0px 0px 10px 10px;
  flex-direction: column;
  ${Media.lessThan(Media.SIZE.LG)} {
    height: 100%;
    border-radius: 0;
    /* width: calc(100vw - 16px); */
  }

  .flatlist {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    width: 300px;

    ${Media.lessThan(Media.SIZE.LG)} {
      width: calc(100vw - 16px);
    }
  }
`;

export const ChatList = styled.div`
  display: flex;
  flex: 1;
  width: 300px;
  border-radius: 0px 0px 10px 10px;
  flex-direction: column-reverse;
  overflow-x: hidden;
  padding: 8px;

  ${Media.lessThan(Media.SIZE.LG)} {
    width: 100vw;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: none;
    border-radius: 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const SendMessage = styled(Input)`
  height: 36px;
  background: #f3f3f3;
  border: none;
  width: 93%;
  margin: auto;
  margin-bottom: 12px;
  &:focus,
  &:hover {
    border: none;
    box-shadow: none;
    background: #f3f3f3;
  }

  .ant-input {
    padding: 0;
    border: none;
    background: transparent;

    box-shadow: none;
    &:focus,
    &:hover {
      padding: 0;
      border: none;
      box-shadow: none;
    }

    ::placeholder {
      color: #787878;
      font-size: 14px;
      font-family: 'Noto Sans JP';
    }
  }
`;

export const Send = styled(SEND)<{ isactive?: boolean }>`
  fill: ${({ isactive }) => (isactive ? '#E3007F' : '#AAAAAA')};
`;
