import AxiosClient from './api';
import END_POINT from './constants';

function getPaymentIntent({ packageId, actionFrom }: any) {
  return AxiosClient.get(
    `${END_POINT.PAYMENT_INTENT}${packageId}?package_id=${packageId}&action_from=${actionFrom}`,
  ).then((res) => res.data);
}

function getListPackage({ isActive, type }: any) {
  return AxiosClient.get(`${END_POINT.PAYMENT_LIST_PACKAGE}?is_active=${isActive}&type=${type}`).then((res) => res);
}

function getListPaymentHistory({ perPage, page }: any) {
  return AxiosClient.get(`${END_POINT.PAYMENT_HISTORY}?per_page=${perPage}&page=${page}`).then((res) => res);
}

function createPaymentPackage({ transaction_code }) {
  return AxiosClient.get(`${END_POINT.PAYMENT_PACKAGE}?transaction_code=${transaction_code}`).then((res) => res.data);
}
export { createPaymentPackage, getListPackage, getListPaymentHistory, getPaymentIntent };
