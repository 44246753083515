/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useMemo } from 'react';
import {
  APP_HEADER_ICON,
  APP_STORE_ICON,
  APP_GOOGLE_PLAY_ICON,
  APP_BACKGROUND_ICON,
  APP_JA_ICON,
  APP_CN_ICON,
  APP_US_ICON,
} from '@assets';
import { Button, Menu, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import i18next from '@i18n';
import { isMobile, isTablet } from 'react-device-detect';
import { getEnv } from '@config/env';

import { translations } from '@i18n/translations';
import { Wrapper } from './styled';

const PageRequestDeleteScreen = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { common } = translations;
  const WEB_URL = getEnv('WEB_DOWNLOAD', null);
  const languageIcon = useMemo(() => {
    switch (language) {
      case 'en':
        return (
          <>
            <img src={APP_US_ICON} className="us-icon" alt="english" />
            &nbsp;&nbsp;EN
          </>
        );
      case 'ja':
        return (
          <>
            <img src={APP_JA_ICON} className="ja-icon" alt="japane" />
            &nbsp;&nbsp;JP
          </>
        );
      case 'cn':
        return (
          <>
            <img src={APP_CN_ICON} className="cn-icon" alt="china" />
            &nbsp;&nbsp;CN
          </>
        );
      default:
        return null;
    }
  }, [language]);

  const changeLanguage = (lang) => {
    i18next.changeLanguage(lang);
  };

  const menu = (
    <Menu mode="horizontal" style={{ display: 'flex', padding: 'unset' }}>
      <Menu.Item key="0" onClick={() => changeLanguage('en')}>
        <img src={APP_US_ICON} className="us-icon" alt="english" />
      </Menu.Item>
      <Menu.Item key="1" onClick={() => changeLanguage('ja')}>
        <img src={APP_JA_ICON} className="ja-icon" alt="japane" />
      </Menu.Item>
      <Menu.Item key="2" onClick={() => changeLanguage('cn')}>
        <img src={APP_CN_ICON} className="cn-icon" alt="china" />
      </Menu.Item>
    </Menu>
  );

  return (
    <Wrapper
      className="wrapper-content"
      style={{
        backgroundImage: `url(${APP_BACKGROUND_ICON})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="page-content">
        <div className="language-switch">
          <Dropdown overlay={menu} trigger={['click']}>
            <button
              type="button"
              className="btn-action dropdown-toggle"
              aria-label="Change language"
              data-tour="lang-selector"
              aria-expanded="false"
            >
              <b>{languageIcon}</b>
            </button>
          </Dropdown>
        </div>
        <div className="logo-page">
          <img src={APP_HEADER_ICON} className="logo-app" alt="app" />
        </div>
        <div className="content">
          {isMobile || isTablet ? (
            <>
              <div className="text-content">
                <p>{t(common.text_page_app_donwload_1)}</p>
                <p>{t(common.text_page_app_donwload_2)}</p>
                <p>{t(common.text_page_app_donwload_3)}</p>
                <p>{t(common.text_page_app_donwload_4)}</p>
              </div>
              <div className="note">
                <p>{t(common.note_page_download_1)}</p>
                <p>{t(common.note_page_download_2)}</p>
              </div>
              <div className="install-app-icon">
                {/* <img src={APP_GOOGLE_PLAY_ICON} className='google-play-icon'  alt='google play' /> */}
                {/* <img src={APP_STORE_ICON} alt='app store' /> */}
                <a href={getEnv('GOOGLE_PLAY', null)}>
                  <img src={APP_GOOGLE_PLAY_ICON} className="google-play-icon" alt="google play" />
                </a>
                <a href={getEnv('APP_STORE', null)}>
                  <img src={APP_STORE_ICON} alt="app store" />
                </a>
              </div>
              <br />
              <div className="note">
                <p>{t(common.note_page_download_3)}</p>
              </div>
              <div className="btn-button">
                <Button className="btn-app" id="btn-submit" htmlType="button" href={`${WEB_URL}request-delete-account`}>
                  <span className="lable-btn-app">{t(common.title_app_submit)}</span>
                </Button>
              </div>
            </>
          ) : (
            <div className="text-content">
              <p>{t(common.text_page_donwload_1)}</p>
              <p>{t(common.text_page_donwload_2)}</p>
              <p>{t(common.text_page_donwload_3)}</p>
              <p>{t(common.text_page_donwload_4)}</p>
              <p>{t(common.text_page_donwload_5)}</p>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default PageRequestDeleteScreen;
