import END_POINT from './constants';
import request from './service';

const signInTwitter = async () => {
  const url = `${END_POINT.TOPICS.SIGN_IN_TWITTER}`;
  try {
    const response = await request.get(url);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const signInTwitterWithCallback = async ({ oauth_token = '', oauth_verifier = '', userToken }) => {
  const url = `${END_POINT.TOPICS.SIGN_IN_TWITTER_CALLBACK}?oauth_token=${oauth_token}&oauth_verifier=${oauth_verifier}`;
  const headers = userToken ? { Authorization: `Bearer ${userToken}` } : {};
  try {
    const response = await request.get(url, { headers });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export { signInTwitter, signInTwitterWithCallback };
