import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';

import React, { CSSProperties } from 'react';
import { UseControllerProps, useController, useFormContext } from 'react-hook-form';
import { Wrapper } from './styled';

const { TextArea } = Input;

interface FormInputProps extends UseControllerProps {
  wrapperStyle?: CSSProperties;
  width?: number | string;
}

const FormTextArea: React.FC<FormInputProps & TextAreaProps> = ({
  name,
  rules,
  defaultValue = '',
  wrapperStyle = {},
  width,
  ...rest
}) => {
  const { control } = useFormContext();
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  return (
    <Wrapper style={wrapperStyle} width={width}>
      <TextArea status={error ? 'error' : ''} name={name} onChange={onChange} onBlur={onBlur} value={value} {...rest} />
    </Wrapper>
  );
};

export default FormTextArea;
