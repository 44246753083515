import styled from 'styled-components';

export const PaymentProcessStyled = styled.div`
  padding-left: 34px;

  .receipt-link {
    font-weight: 700;

    a {
      text-decoration: underline;
    }
  }

  .text-fail {
    font-size: 20px;
    color: #d80027;
    text-align: center;
    white-space: pre-wrap;
  }
`;
