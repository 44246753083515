/* eslint-disable react/prop-types */
import { SearchOutlined } from '@ant-design/icons';
import Media from '@themes/media';
import { Input, InputProps } from 'antd';
import React, { CSSProperties, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 375px;

  ${Media.lessThan(Media.SIZE.XXXL)} {
    padding: 0 275px;
  }

  ${Media.lessThan(Media.SIZE.XXL)} {
    padding: 0 200px;
  }

  ${Media.lessThan(Media.SIZE.LG)} {
    padding: 0 120px;
  }

  ${Media.lessThan(Media.SIZE.MD)} {
    padding: 0 16px;
  }
`;

const SearchStyled = styled(Input)`
  width: 100%;
  color: #323232;
  font-size: 14px;
  font-weight: 500;
  border: none !important;
  background-color: ${({ theme }) => theme.white};
  border-radius: 23px;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  box-shadow: none;
  height: 46px;
  padding: 4px 16px;
  .ant-input-affix-wrapper {
    border-color: none !important;
    border-radius: 10px;
    &:focus,
    &:hover {
      color: #323232;
      border: none !important;
      outline: 0;
      box-shadow: none;
    }
  }
  &:focus,
  &:hover {
    color: #323232;
    border: none !important;
    outline: 0;
    box-shadow: none;
  }

  .ant-input {
    border: none;
    box-shadow: none;
    border-radius: 0;
    &:focus,
    &:hover {
      color: #323232;
      border: none;
      outline: 0;
      box-shadow: none;
    }
  }
  .ant-input-suffix {
    .search {
      padding-left: 8px;
      padding-right: 8px;
      cursor: pointer;
    }
  }
`;

interface FormInputProps {
  wrapperStyle?: CSSProperties;
  onSearch: (data) => void;
  defaulValue?: string;
}

interface IFormInput {
  search: string;
}

const Search: React.FC<FormInputProps & InputProps> = ({ wrapperStyle = {}, onSearch, defaulValue = '', ...rest }) => {
  const { handleSubmit, control, setValue, watch } = useForm<IFormInput>({
    defaultValues: {
      search: '',
    },
  });
  const [search] = watch(['search']);
  useEffect(() => {
    setValue('search', defaulValue);
  }, [defaulValue]);

  const onSubmit: SubmitHandler<IFormInput> = (data) => onSearch({ search: data.search.trim() });
  const onKeyPress = () => onSearch({ search: search.trim() });

  return (
    <Controller
      control={control}
      render={({ field: { onChange, onBlur, value } }) => (
        <Wrapper className="search-wrapper" style={wrapperStyle}>
          <SearchStyled
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            type="text"
            suffix={<SearchOutlined className="search" onClick={handleSubmit(onSubmit)} />}
            onPressEnter={onKeyPress}
            {...rest}
          />
        </Wrapper>
      )}
      name="search"
    />
  );
};

export default Search;
