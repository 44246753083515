import React from 'react';
import { Image, Tag } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';

import { convertToCurrentTimeZone } from '@utils/time';
import { FORMAT_TIME } from '@constants';

import { DEFAULT_EVENT_THUMBNAIL, DIAMOND } from '@assets';
import { numberWithCommas } from '@utils/number';
import { Topic } from '@type/Store/histories';
import { Wrapper, Title, TimeCardStyled, Descriptions } from './styled';
import PaymentStatus from '../payment-status';

interface Props {
  data: Topic;
}

type TopicType = 'video' | 'package' | 'end_live' | 'live';
const CardTopic: React.FC<Props> = ({ data }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { histories } = translations;
  const {
    thumbnail,
    payment_date,
    balance_code,
    payment_method,
    diamond_price,
    yen_price,
    title,
    description,
    payment_status,
    type,
    refund_status,
  } = data;

  const timePurchaseAt = convertToCurrentTimeZone(payment_date, FORMAT_TIME.FULL_DATE);

  const handeClick = () => history.push(`/histories/topic/${balance_code}`);

  const renderPrice = () => {
    if (payment_method === 4) {
      return (
        <div className="price">
          <img src={DIAMOND} alt="diamond" width={13} />
          <span style={{ color: '#0076E3', fontWeight: 'bold' }}>
            &ensp;{numberWithCommas(Number(diamond_price), ',')}
          </span>
        </div>
      );
    }

    return <span className="yen">￥{numberWithCommas(Number(yen_price), ',')}</span>;
  };

  const renderMarker = (type: TopicType) => {
    switch (type) {
      case 'video':
        return <Tag className="tag tag-chapter">{t(histories.topic_mark.video)}</Tag>;
      case 'live':
        return <Tag className="tag tag-live">{t(histories.topic_mark.live)}</Tag>;
      case 'end_live':
        return <Tag className="tag tag-end">{t(histories.topic_mark.end_live)}</Tag>;
      case 'package':
        return <Tag className="tag tag-chapter">{t(histories.topic_mark.package)}</Tag>;
      default:
        return <div />;
    }
  };

  return (
    <Wrapper onClick={handeClick}>
      <div>
        <div style={{ position: 'relative' }}>
          <Image preview={false} alt="thumb-nail" src={thumbnail || DEFAULT_EVENT_THUMBNAIL} />
          {renderMarker(type as TopicType)}
        </div>
        <div style={{ padding: '0px 16px 16px 16px' }}>
          <Title>{title}</Title>
          <Descriptions>{description}</Descriptions>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <PaymentStatus status={payment_status} refund_status={refund_status} />
            {renderPrice()}
          </div>
          <TimeCardStyled className="text-time-end">
            {t(histories.date_of_acquisition)}：{timePurchaseAt}
          </TimeCardStyled>
        </div>
      </div>
    </Wrapper>
  );
};

export default CardTopic;
