/* eslint-disable no-restricted-globals */
import { useDispatch, useSelector } from 'react-redux';
import { BroadcastChannel } from 'broadcast-channel';

import {
  toggleSidebar,
  hoverSidebar,
  set404Status,
  setIsWebView,
  setAuthBroadcastChannel,
} from '@modules/store/actions';
import { makeSelectGlobal } from '@modules/store/selectors';
import { GlobalState } from '@type/Store/global';

export const useRoot = () => {
  const dispatch = useDispatch();

  const state: GlobalState = useSelector(makeSelectGlobal());

  const toggleSidebarAction = (payload?: any) => dispatch(toggleSidebar(payload));
  const hoverSidebarAction = (payload?: any) => dispatch(hoverSidebar(payload));
  const set404 = (payload: boolean) => dispatch(set404Status(payload));
  const setIsWebViewAction = (payload: boolean) => dispatch(setIsWebView(payload));
  const setAuthBroadcastChannelAction = (payload: BroadcastChannel | null) =>
    dispatch(setAuthBroadcastChannel(payload));

  return {
    ...state,
    toggleSidebarAction,
    hoverSidebarAction,
    set404,
    setIsWebView: setIsWebViewAction,
    setAuthBroadcastChannel: setAuthBroadcastChannelAction,
  };
};
