/* eslint-disable no-console */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';
import { useAuth } from '@hooks';
import Image from '@components/imageFallback';
import { Tag } from 'antd';
import { convertSecondsToHMS_V2, convertToCurrentTimeZone } from '@utils/time';
import { CHAPTER_FEE_TYPE, CHAPTER_TYPE, FORMAT_TIME, LIVESTREAM_STATUS, PAYMENT_STATUS } from '@constants';
import { DIAMOND } from '@assets';
import { Chapter } from '@type/Store/topics';
import { isMobile } from 'react-device-detect';
import { Seemore } from '@components';
import { numberWithCommas } from '@utils/number';
import {
  ImageChapters,
  DateTime,
  Wrapper,
  Info,
  LeftInfo,
  RightInfo,
  TopListWrapper,
  Banner,
  TopInfo,
  TopLeftInfo,
  TopLeftRight,
} from './styled';

interface Props {
  chapter: Chapter;
  handleShowModal: (chapter: Chapter) => void;
  isTopList?: boolean;
}

export const ChapterComponent: React.FC<Props> = ({ chapter, handleShowModal, isTopList }) => {
  const { t } = useTranslation();
  const { topic, topic_payment } = translations;
  const { isUser, authenticated } = useAuth();

  const startTime = useMemo(() => {
    if (chapter.chapter_type === CHAPTER_TYPE.LIVESTREAM && chapter.chapter_status === LIVESTREAM_STATUS.LIVE) {
      return convertToCurrentTimeZone(chapter?.public_start_real || '', FORMAT_TIME.FULL_DATE);
    }

    return chapter?.public_start ? convertToCurrentTimeZone(chapter?.public_start || '', FORMAT_TIME.FULL_DATE) : '';
  }, [chapter]);

  const endTime = useMemo(() => {
    if (chapter.chapter_type === CHAPTER_TYPE.LIVESTREAM && !!chapter?.public_end_real) {
      return ` ~ ${convertToCurrentTimeZone(chapter?.public_end_real || '', FORMAT_TIME.FULL_DATE)}`;
    }

    if (chapter.chapter_type === CHAPTER_TYPE.LIVESTREAM && !chapter?.public_end_real && !!chapter.public_end) {
      return ` ~ ${convertToCurrentTimeZone(chapter?.public_end || '', FORMAT_TIME.FULL_DATE)}`;
    }

    if (chapter.chapter_type === CHAPTER_TYPE.VIDEO && !!chapter.public_end) {
      return ` ~ ${convertToCurrentTimeZone(chapter?.public_end || '', FORMAT_TIME.FULL_DATE)}`;
    }

    return '';
  }, [chapter]);

  const renderPrice = () => {
    if (chapter.amount === 0) return <div className="some-free">{t(topic?.free)}</div>;
    return (
      <div className="wrapper-price">
        {chapter?.price ? (
          <span className="yen-price">
            ￥{numberWithCommas(Number(chapter?.price), ',')} <span className="slash">/ </span>{' '}
          </span>
        ) : (
          <span />
        )}
        <span className="price">
          <div className="content">
            <img src={DIAMOND} alt="diamond" width={13} />
            <span>{numberWithCommas(Number(chapter?.amount), ',')}</span>
          </div>
        </span>
      </div>
    );
  };

  const renderFreePrice = () => {
    return (
      <>
        <div className="some-free">{t(topic?.some_free)}</div>
        {renderPrice()}
      </>
    );
  };
  const handleRenderChapter = () => {
    if (chapter.password_flag) {
      return <div className="password">{t(topic.enter_password)}</div>;
    }

    if (isUser || !authenticated) {
      if (chapter.payment_status === PAYMENT_STATUS.SUCCEEDED) {
        return <Tag className="tag-topic">{t(topic.purchased)}</Tag>;
      }
      if (chapter.payment_status === PAYMENT_STATUS.PROCESSING) {
        return (
          <Tag style={{ background: '#F49A00' }} className="tag-topic">
            {t(topic_payment.processing)}
          </Tag>
        );
      }

      if (chapter.chapter_fee_type === CHAPTER_FEE_TYPE.MUST_PAID) {
        return renderPrice();
      }

      if (!!chapter.amount && chapter.chapter_type === CHAPTER_TYPE.LIVESTREAM) {
        return renderPrice();
      }

      if (chapter.chapter_fee_type === CHAPTER_FEE_TYPE.FREE) {
        return <div className="some-free">{t(topic?.free)}</div>;
      }

      if (chapter.chapter_fee_type === CHAPTER_FEE_TYPE.FREE_A_PATH) {
        return renderFreePrice();
      }
    }
    return <div />;
  };

  if (isTopList) {
    return (
      <TopListWrapper onClick={() => handleShowModal(chapter)}>
        <Banner>
          {chapter.thumbnail && <Image src={chapter.thumbnail} alt="Image topic" />}
          {chapter.live_key && chapter.chapter_status === LIVESTREAM_STATUS.LIVE && (
            <Tag className="tag-live">{t(topic.now_on_live)}</Tag>
          )}
        </Banner>
        <TopInfo>
          <TopLeftInfo>
            <DateTime>
              <div className="date">
                {startTime}
                {endTime}
              </div>
            </DateTime>
            <div className="chapter-content">{chapter.title}</div>
            <div className="time">
              {chapter.chapter_type === CHAPTER_TYPE.VIDEO && convertSecondsToHMS_V2(Number(chapter.duration))}
            </div>
          </TopLeftInfo>
          <TopLeftRight>
            <div className="hide" style={{ height: 16 }} />
            {handleRenderChapter()}
          </TopLeftRight>
        </TopInfo>
      </TopListWrapper>
    );
  }
  return (
    <Wrapper onClick={() => handleShowModal(chapter)}>
      <ImageChapters>
        {chapter?.thumbnail && <Image src={chapter?.thumbnail} alt="Image topic" />}
        {chapter.live_key && chapter.chapter_status === LIVESTREAM_STATUS.LIVE && (
          <Tag className="tag-live">{t(topic.now_on_live)}</Tag>
        )}
      </ImageChapters>
      <Info>
        <LeftInfo>
          <DateTime>
            <div className="date">
              {startTime}
              {endTime}
            </div>
          </DateTime>
          <div className="chapter-content">{chapter.title}</div>
          <div className="time">
            {chapter.chapter_type === CHAPTER_TYPE.VIDEO && convertSecondsToHMS_V2(Number(chapter.duration))}
          </div>
          {isMobile && chapter?.description && (
            <Seemore className="description" rows={2} content={chapter.description} />
          )}
        </LeftInfo>
        <RightInfo>
          <div className="hide" style={{ height: 16 }} />
          {handleRenderChapter()}
        </RightInfo>
      </Info>
      {!isMobile && chapter?.description && <Seemore className="description" rows={2} content={chapter.description} />}
    </Wrapper>
  );
};
