/* eslint-disable arrow-parens */
/* eslint-disable implicit-arrow-linebreak */

/**
 * The global state selectors
 */

import { Store } from '@type/Store';
import { PaymentState } from '@type/Store/payment';
import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectRegistrationPayment = (store: Store) => store.payment || initialState;

const makeSelectRegistrationPayment = () => createSelector(selectRegistrationPayment, (state: PaymentState) => state);

export { selectRegistrationPayment, makeSelectRegistrationPayment };
