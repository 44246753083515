import React from 'react';
import PublicRoute from '@components/route/publicRoute';

import LoginScreen from '@modules/auth/login';
import SendEmailScreen from '@modules/auth/reset-password/send-email';
import ResetPasswordScreen from '@modules/auth/reset-password';
import Register from '@modules/auth/register';

import HomeLayout from '@layouts/home';

export const RoutesName = {
  LOGIN: '/login',
  REGISTER: '/register',
  SEND_EMAIL: '/send-email',
  RESET_PASSWORD: '/reset-password',
  TERM_OF_USER: '/term-of-user',
  POLICY: '/policy',
  WEB_DOWNLOAD: '/page/',
};

export const ROUTES = [
  {
    path: RoutesName.LOGIN,
    component: LoginScreen,
    layout: HomeLayout,
  },
  {
    path: RoutesName.REGISTER,
    component: Register,
    layout: HomeLayout,
  },
  {
    path: RoutesName.SEND_EMAIL,
    component: SendEmailScreen,
    layout: HomeLayout,
  },
  {
    path: RoutesName.RESET_PASSWORD,
    component: ResetPasswordScreen,
    layout: HomeLayout,
  },
];

const AuthRoutes: React.FC = () => {
  return (
    <>
      {ROUTES.map((routeConfig) => (
        <PublicRoute key={routeConfig.path} {...routeConfig} />
      ))}
    </>
  );
};

export default AuthRoutes;
