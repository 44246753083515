import React from 'react';
import { Row, Col, Typography, Image } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';

import { getEnv } from '@config/env';

import {
  LOGO_FOOTER,
  APP_STORE,
  GOOGLE_PLAY,
  APP_IMG_FOOTER1,
  APP_IMG_FOOTER2,
  TWITTER_ICON,
  DIAMOND_ICON,
  ARROW_ICON,
  LOGO_ILIVE,
} from '@assets';
import { isMobile } from 'react-device-detect';
import { Wrapper } from './styled';

const { Text } = Typography;

const Footer: React.FC = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { common, register } = translations;
  const googlePlay = getEnv('GOOGLE_PLAY', null);
  const appStore = getEnv('APP_STORE', null);
  const twitter = getEnv('TWITTER', null);
  const s3Bucket = getEnv('S3_BUCKET', null);

  const logoFooter = () => {
    return (
      <div className="logo-icon">
        <Image
          style={{ width: 40, height: 40 }}
          className="icon-footer-img"
          src={LOGO_ILIVE}
          preview={false}
          alt="logo-icon"
        />
        <div>
          <div>JASRAC許諾番号</div>
          <div>9039449001Y45037</div>
        </div>
      </div>
    );
  };

  return (
    <Wrapper>
      <Row>
        <Col span={24} className="col-top">
          <div>
            <Row>
              <Col span={24}>
                <div className="app-info">
                  <div className="logo-info">
                    <Image
                      onClick={() => {
                        history.push('/');
                      }}
                      className="icon-footer"
                      src={LOGO_FOOTER}
                      alt="logo-footer"
                      preview={false}
                    />
                  </div>
                  <div className="app-info__right">
                    <div className="app-info__store">
                      <div>
                        <Typography style={{ textAlign: 'end', whiteSpace: 'pre-wrap' }}>
                          {t(common.footer.message_dowload_metalive)}
                        </Typography>
                      </div>
                      <div className="app-info__store__icon">
                        <Image
                          className="google-play-icon icon-footer"
                          onClick={() => {
                            window.open(googlePlay, '_blank');
                          }}
                          src={GOOGLE_PLAY}
                          alt="google-play"
                          preview={false}
                        />
                        <Image
                          className="icon-footer"
                          onClick={() => {
                            window.open(appStore, '_blank');
                          }}
                          src={APP_STORE}
                          alt="app-store"
                          preview={false}
                        />
                      </div>
                    </div>

                    <div className="app-info__store__img">
                      <Image src={APP_IMG_FOOTER1} alt="app-image" preview={false} />
                      <Image src={APP_IMG_FOOTER2} alt="app-image" preview={false} />
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div>
                  <Row className="web-info">
                    <Col sm={24} lg={12} className="custom-footer">
                      <Col className="web-info__policy">
                        <Text
                          onClick={() => {
                            window.open(
                              `${s3Bucket}/static_page/METALIVE_termOfUse_20230816_${i18n.language}.pdf`,
                              '_blank',
                            );
                          }}
                        >
                          {t(register.terms_of_service)}
                        </Text>
                        <Text
                          onClick={() => {
                            window.open(
                              `${s3Bucket}/static_page/METALIVE_policy_20230816_${i18n.language}.pdf`,
                              '_blank',
                            );
                          }}
                        >
                          {t(register.privacy_policy)}
                        </Text>
                      </Col>
                      <Text
                        className="text-description"
                        onClick={() => {
                          window.open(
                            `${s3Bucket}/static_page/METALIVE_notification_based_on_the_act_on_specified_commercial_transactions_version_20230821_${i18n.language}.pdf`,
                            '_blank',
                          );
                        }}
                      >
                        {t(register.act_transaction)}
                      </Text>
                    </Col>
                    {isMobile && <div className="block-logo">{logoFooter()}</div>}
                    <Col sm={24} lg={12} className="web-info__login">
                      {!isMobile && logoFooter()}
                      {/* {!authenticated && (
                        <div>
                          <Text style={{ marginRight: '7px' }}>METALIVE for WEB</Text>
                          <ButtonComponent
                            type="default"
                            onClick={() => {
                              history.push('/login');
                            }}
                          >
                            {t(common.login)}
                          </ButtonComponent>
                        </div>
                      )} */}
                      <div>
                        <Text style={{ marginRight: '13px' }}>Follow us on Twitter</Text>
                        <Image
                          onClick={() => {
                            window.open(twitter, '_blank');
                          }}
                          style={{ width: 36, height: 36 }}
                          className="icon-footer"
                          src={TWITTER_ICON}
                          preview={false}
                          alt="twitter-icon"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={24} className="col-bottom">
          <div className="copyright">
            <Text>Copyright METALIVE Co., Ltd. All reserved.</Text>

            <div
              className="buy-diamond"
              onClick={() => {
                history.push('/diamond-purchase');
              }}
            >
              <Image src={DIAMOND_ICON} preview={false} alt="diamond-icon" />
              <Text className="buy-diamond_title">{t(common.footer.diamond_purchase)}</Text>
              <Image className="arrow-icon" src={ARROW_ICON} preview={false} alt="arrow-icon" />
            </div>
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Footer;
