import { put, takeLatest, select } from 'redux-saga/effects';
import { REQUEST, SUCCESS, FAILURE } from '@stores';
import {
  login,
  register,
  verifyRegister,
  logout,
  verification,
  resetPassword,
  getUserProfile,
  reSendCodeVerify,
  changeLanguage,
} from '@apis';
import { setLocalStorage, STORAGE } from '@utils';
import { Action, Store } from '@type/Store';
import i18next from '@i18n';
import { removeCacheData } from '@utils/utils';
import {
  LOAD_PROFILE,
  LOGIN,
  REGISTER,
  VERIFY_REGISTER,
  LOGOUT,
  VERIFICATION,
  RESET_PASSWORD,
  RESEND_CODE,
  CHANGE_LANGUAGE,
} from './constants';

i18next.loadNamespaces(['common']);

export function* loginSaga({ payload }: Action) {
  try {
    const { data: result } = yield login(payload);
    const { user } = result.data;
    setLocalStorage(STORAGE.USER_TOKEN, result.data.token);

    const {
      global: { authBoardcastChannel },
    }: Store = yield select();

    if (authBoardcastChannel) {
      authBoardcastChannel.postMessage('LOGIN');
    }
    yield put({
      type: SUCCESS(LOGIN),
      payload: {
        profile: user,
      },
    });
  } catch (error) {
    yield put({
      type: FAILURE(LOGIN),
      error,
    });
  }
}

export function* registerSaga({ payload }: Action) {
  const { data, callback } = payload;
  const { done, fail } = callback;

  try {
    yield register(data);
    done();

    yield put({
      type: SUCCESS(REGISTER),
      payload: {},
    });
  } catch (error) {
    yield put({
      type: FAILURE(REGISTER),
      error,
    });

    fail(error);
  }
}

export function* verifyRegisterSaga({ payload }: Action) {
  const { data, callback } = payload;
  const { done, fail } = callback;
  try {
    yield verifyRegister(data);

    done();

    yield put({
      type: SUCCESS(VERIFY_REGISTER),
      payload: {},
    });
  } catch (error) {
    yield put({
      type: FAILURE(VERIFY_REGISTER),
      error,
    });

    fail(error);
  }
}

export function* reSendCodeSaga({ payload }: Action) {
  const { data, callback } = payload;
  const { done, fail } = callback;
  try {
    yield reSendCodeVerify(data);

    done();

    yield put({
      type: SUCCESS(RESEND_CODE),
      payload: {},
    });
  } catch (error) {
    yield put({
      type: FAILURE(RESEND_CODE),
      error,
    });
    fail();
  }
}

export function* loadProfileSaga() {
  try {
    const { data: result } = yield getUserProfile();
    yield put({
      type: SUCCESS(LOAD_PROFILE),
      payload: {
        profile: result.data,
      },
    });
  } catch (error) {
    yield put({
      type: FAILURE(LOAD_PROFILE),
      error,
    });
  }
}

export function* logoutSaga({ payload }: Action) {
  try {
    const { data: result } = yield logout(payload);
    removeCacheData();
    const {
      global: { authBoardcastChannel },
    }: Store = yield select();

    if (authBoardcastChannel) {
      authBoardcastChannel.postMessage('LOGOUT');
    }

    yield put({
      type: SUCCESS(LOGOUT),
      payload: {
        dataLogout: result,
      },
    });
    // window.location.replace(RoutesName.LOGIN);
  } catch (error) {
    yield put({
      type: FAILURE(LOGOUT),
      error,
    });
  }
}

export function* verificationSaga({ payload }: Action) {
  try {
    const params = new URLSearchParams();
    params.append('email', payload.email);
    params.append('language', payload.language);
    const { data } = yield verification(params);
    yield put({
      type: SUCCESS(VERIFICATION),
      payload: {
        verifyPass: {
          data: data.data || null,
          error: null,
        },
        email: payload.email,
      },
    });
  } catch (error) {
    yield put({
      type: FAILURE(VERIFICATION),
      error,
    });
  }
}

export function* resetPasswordSaga({ payload }: Action) {
  try {
    const params = new URLSearchParams();
    params.append('password', payload.password);
    params.append('email', payload.email);
    const { data: result } = yield resetPassword(payload.authenticationCode, params);
    yield put({
      type: SUCCESS(RESET_PASSWORD),
      payload: {
        resetPwStatus: result,
      },
    });
  } catch (error) {
    yield put({
      type: FAILURE(RESET_PASSWORD),
      error,
    });
  }
}

export function* changeLanguageSaga({ payload }: Action) {
  try {
    const { language } = payload;
    yield changeLanguage({ language });
    yield put({
      type: REQUEST(LOAD_PROFILE),
    });
  } catch (error) {
    yield put({
      type: FAILURE(RESET_PASSWORD),
      error,
    });
  }
}

export default function* authSaga() {
  yield takeLatest(REQUEST(LOGIN), loginSaga);
  yield takeLatest(REQUEST(REGISTER), registerSaga);
  yield takeLatest(REQUEST(VERIFY_REGISTER), verifyRegisterSaga);
  yield takeLatest(REQUEST(RESEND_CODE), reSendCodeSaga);
  yield takeLatest(REQUEST(LOGOUT), logoutSaga);
  yield takeLatest(REQUEST(VERIFICATION), verificationSaga);
  yield takeLatest(REQUEST(RESET_PASSWORD), resetPasswordSaga);
  yield takeLatest(REQUEST(LOAD_PROFILE), loadProfileSaga);
  yield takeLatest(REQUEST(CHANGE_LANGUAGE), changeLanguageSaga);
}
