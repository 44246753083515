import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';

import { useRealEventPayment } from '@hooks';
import { Button } from '@components';
import { numberWithCommas } from '@utils/number';
// import { EventDetail } from '@type/Store/event';
import { translations } from '@i18n/translations';
import { useHistory } from 'react-router-dom';
import { Group, PaymentInfoStyle, TicketDetailStyle, UserInfoStyle, Wrapper } from './styled';

interface Ticket {
  quantity: number;
  ticketId: number | string | undefined;
  name: string;
}

type PaymentConfirmProps = {
  // eventDetail?: EventDetail;
  tickets: Ticket[];
  totalPrice?: number;
  totalTicket?: number;
  email: string;
  phonenumber: string;
  name: string;
  onConfirm: () => void;
  receiptNo?: string | null;
  storeName?: string;
  yenFee?: number;
};

const KobiniSuccess: React.FC<PaymentConfirmProps> = ({
  totalPrice,
  totalTicket,
  tickets = [],
  email = '',
  phonenumber = '',
  name = '',
  onConfirm,
  receiptNo,
  storeName,
  yenFee,
}) => {
  const history = useHistory();
  const { isLoading } = useRealEventPayment();
  const { t } = useTranslation();
  const { realEventPayment } = translations;

  return (
    <Wrapper>
      <Spin spinning={isLoading}>
        <TicketDetailStyle>
          <p>{t(realEventPayment.kobini.purchase_noti)}</p>
        </TicketDetailStyle>
        <UserInfoStyle>
          <Group>
            <label>{t(realEventPayment.user_name)}</label>
            <span>{name}</span>
          </Group>
          <Group>
            <label>{t(realEventPayment.email)}</label>
            <span className="custom-width">{email}</span>
          </Group>
          <Group>
            <label>{t(realEventPayment.phone)}</label>
            <span>{phonenumber}</span>
          </Group>
        </UserInfoStyle>
        <PaymentInfoStyle>
          <Group>
            <label>{t(realEventPayment.ticket_type)}</label>
            <div>
              {tickets
                .filter((f) => !!f.quantity)
                .map((ticket) => (
                  <div key={ticket.ticketId} className="ticket-type">
                    <div>{ticket.name}</div>
                    <div>
                      {ticket.quantity}
                      {t(realEventPayment.sheet)}
                    </div>
                  </div>
                ))}
            </div>
          </Group>
          <Group>
            <label>{t(realEventPayment.number_of_tickets)}</label>
            <span>
              {totalTicket} {t(realEventPayment.number_of_tickets)}
            </span>
          </Group>
          <Group>
            <label>{t(realEventPayment.payment_method)}</label>
            <span>{t(realEventPayment.kobini.title)}</span>
          </Group>
          {storeName && (
            <Group>
              <label>{t(realEventPayment.storeName)}</label>
              <span>{storeName}</span>
            </Group>
          )}
          {!!yenFee && (
            <>
              <Group>
                <label>{t(realEventPayment.subtotal)}</label>
                <p>
                  <span className="yen" style={{ color: '#282727' }}>
                    ￥{numberWithCommas((totalPrice || 0) - (yenFee || 0), ',')}
                  </span>
                </p>
              </Group>
              <Group>
                <label>{t(realEventPayment.fee)}</label>
                <p>
                  <span className="yen" style={{ color: '#282727' }}>
                    ￥{numberWithCommas(yenFee, ',')}
                  </span>
                </p>
              </Group>
            </>
          )}
          <Group>
            <label>{t(realEventPayment.total_amount_notFee)}</label>
            <span className="yen">￥{numberWithCommas(totalPrice, ',')}</span>
          </Group>

          <Group>
            <label>{t(realEventPayment.kobini.transfer_slip)}</label>
            <span style={{ fontWeight: 700, color: '#0076E3' }}>{receiptNo}</span>
          </Group>

          <div className="box-btn">
            <Button
              onClick={() => {
                history.replace('/list-event');
              }}
              type="outline"
            >
              {t(realEventPayment.go_to_list_event)}
            </Button>
            <Button onClick={onConfirm}> {t(realEventPayment.see_ticket_detail)}</Button>
          </div>
        </PaymentInfoStyle>
      </Spin>
    </Wrapper>
  );
};
export default KobiniSuccess;
