import React, { useEffect, useMemo, useState } from 'react';
import { getEnv } from '@config/env';

import { Prompt, useHistory, useLocation, useParams } from 'react-router-dom';
import { translations } from '@i18n/translations';
import { useTranslation } from 'react-i18next';
import useExitPrompt from '@hooks/useExitPrompt';
import { ContentStyled } from '@modules/tickets-goods/real-event-payment/coin/styled';
import BoxPayment from '@modules/tickets-goods/real-event-payment/coin/components/box-payment';
import { Wrapper } from '@modules/tickets-goods/real-event-payment/styled';
import { useTopicPayment } from '@hooks/useTopicPayment';
import { Spin } from 'antd';
import PaymentFailure from '../components/payment-fail';
import { InputPayment } from './components/input-payment';
import Confirm from './components/confirm';
import Purchased from './components/success';

interface Params {
  packageId?: string;
  topicId?: string;
}

const GMO_SHOP_ID = getEnv('GMO_SHOP_ID', null);

type PaymentStatus = 'INPUT_PAYMENT' | 'CONFIRM_PAYMENT' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILURE';
export const CreditPaymentTopicScreen: React.FC = () => {
  const { topicId, packageId } = useParams<Params>();
  const { user_info, credit_card_info, payment_method, purchased, error, paymentChapter } = useTopicPayment();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');

  const { replace } = useHistory();
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>('INPUT_PAYMENT');
  const { t } = useTranslation();
  const { realEventPayment, common } = translations;
  useExitPrompt(true);

  useEffect(() => {
    window?.Multipayment.init(GMO_SHOP_ID);
  }, []);

  useEffect(() => {
    if (error) {
      setPaymentStatus('PAYMENT_FAILURE');
    }
  }, [error]);

  useEffect(() => {
    if (purchased) {
      setPaymentStatus('PAYMENT_SUCCESS');
    }
  }, [purchased]);

  useEffect(() => {
    if (!payment_method) {
      replace(`/topics/${topicId}/payment/${packageId}?type=${type}`);
    }
  }, [payment_method]);

  const onPurchase = () => {
    const params = {
      order_id: Date.now(),
      token: credit_card_info.token || '',
      type: 1,
      ...user_info,
    };
    paymentChapter({
      topicId: Number(topicId),
      packageId: Number(packageId),
      params,
      topic_type: type,
    });
  };

  const promptWhen = useMemo(
    () => !!payment_method && paymentStatus !== 'PAYMENT_FAILURE' && paymentStatus !== 'PAYMENT_SUCCESS',
    [payment_method, paymentStatus],
  );

  const renderByStatus = useMemo(() => {
    switch (paymentStatus) {
      case 'CONFIRM_PAYMENT':
        return <Confirm onCancel={() => setPaymentStatus('INPUT_PAYMENT')} onPurchase={onPurchase} />;
      case 'PAYMENT_SUCCESS':
        return <Purchased />;
      case 'PAYMENT_FAILURE':
        return <PaymentFailure packageId={packageId} topicId={topicId} />;
      default:
        return <Spin spinning />;
    }
  }, [paymentStatus]);

  const title = useMemo(() => {
    if (paymentStatus === 'CONFIRM_PAYMENT') return t(common.event.confirm_popup_title);
    if (paymentStatus === 'PAYMENT_SUCCESS') return t(realEventPayment.payment_coin_completed);
    if (paymentStatus === 'PAYMENT_FAILURE') return t(realEventPayment.payment_coin_fail);

    return '';
  }, [paymentStatus]);
  const pathBack = useMemo(() => {
    switch (paymentStatus) {
      case 'INPUT_PAYMENT':
      case 'CONFIRM_PAYMENT':
        return `/topics/${topicId}/payment/${packageId}?type=${type}`;
      default:
        return undefined;
    }
  }, [paymentStatus, topicId, packageId, type]);

  const renderUI = useMemo(() => {
    if (paymentStatus === 'INPUT_PAYMENT') {
      return <InputPayment onSubmitForm={() => setPaymentStatus('CONFIRM_PAYMENT')} />;
    }
    return (
      <BoxPayment title={title} routeBack={pathBack} isShowIconBack={!!pathBack}>
        <ContentStyled>{renderByStatus}</ContentStyled>
      </BoxPayment>
    );
  }, [paymentStatus]);

  return (
    <Wrapper>
      {renderUI}
      <Prompt message={() => (promptWhen ? t(realEventPayment.goback) : true)} />
    </Wrapper>
  );
};
