import Media from '@themes/media';
import styled from 'styled-components';

export const BannerSlickSlider = styled.div`
  .swiper {
    width: 100%;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    overflow: visible;
    ${Media.lessThan(Media.SIZE.SM)} {
      height: 200px;
    }
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
    width: 100%;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-slide-active {
    div {
      width: 100%;
      height: 100%;
      padding: 40px 0;
    }
  }
  .swiper-slide-next,
  .swiper-slide-prev {
    div {
      transform: scale(1, 0.8);
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        opacity: 0.5;
      }
      ${Media.lessThan(Media.SIZE.SM)} {
        border-radius: 5%;
        transform: scale(1, 0.5);
      }
    }
  }
  .swiper-pagination-bullet-active {
    color: ${({ theme }) => theme.brand};
    background: ${({ theme }) => theme.brand};
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    max-height: 320px;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  .swiper {
    margin-left: auto;
    margin-right: auto;
  }
`;
export const SlickText = styled.div`
  .swiper {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    overflow: visible;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
    width: 100%;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
  }
  .swiper-slide-active {
    visibility: visible;
  }

  .slick-text {
    width: 660px;
    min-height: 100px;

    .tittle-event {
      font-size: ${({ theme }) => theme.size_24};
      font-weight: ${({ theme }) => theme.fw_700};
    }
    time {
      font-size: ${({ theme }) => theme.size_20};
      font-weight: ${({ theme }) => theme.fw_400};
      opacity: 0.8;
    }
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    .slick-text {
      min-height: unset;

      .tittle-event {
        font-size: ${({ theme }) => theme.size_16};
      }

      time {
        font-size: ${({ theme }) => theme.size_14};
      }
    }
  }
`;
