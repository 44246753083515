import { createItem, database, off, onChildAdded, orderByChild, query, ref, startAt } from '@apis/firebase';
import { useState, useEffect } from 'react';

interface IParams {
  roomId?: string | null;
}

export interface Heart {
  createdAt: number;
  id: string;
  senderName?: string | null;
  senderAvatar?: string | null;
}

export const useHearts = ({ roomId }: IParams) => {
  const [hearts, setHearts] = useState<Heart[]>([]);
  const [error, setError] = useState<any>(null);
  const removeHeart = (id: string) => setHearts((state) => state.filter((f) => f.id !== id));

  useEffect(() => {
    let childAddedHandler;
    const current = Date.now();
    const queryLikes = query(ref(database, `/likes/${roomId}`), orderByChild('createdAt'), startAt(current));
    if (roomId) {
      onChildAdded(
        queryLikes,
        (snapshot) => {
          if (snapshot.exists()) {
            setHearts((state) => [
              ...state,
              {
                ...snapshot.val(),
                id: snapshot.key,
              },
            ]);
          }
        },
        (err) => setError(() => err), // on error
        {
          onlyOnce: false,
        },
      );
    }

    return () => {
      off(queryLikes, 'child_added', childAddedHandler);
    };
  }, [roomId]);

  return {
    hearts,
    error,
    removeHeart,
  };
};

export const sendLike = (roomId, data) => createItem(`likes/${roomId}`, data);
