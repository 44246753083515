import React, { useEffect, useState } from 'react';
import { useEvents } from '@hooks';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Spin } from 'antd';

import { translations } from '@i18n/translations';
import { BACK_ICON, ICON_COIN, MAP_ICON } from '@assets';
import { TicketFormGroup } from '@type/Form/BuyTicketForm';
import { ConfirmOrderStyle, Group, TicketDetailStyle, WrapperAction } from '@modules/tickets-goods/detail/styles';

import { formatDateTimeOfWeek } from '@utils/time';
import { Button, Seemore } from '@components';
import { ERROR_MESSAGE_REAL_EVENT_PURCHASE, SALE_TYPE } from '@constants/real-event';
import PaymentConfirmComponent from '@components/modal/paymentConfirmModal';
import BoxPayment from '@modules/tickets-goods/real-event-payment/coin/components/box-payment';
import { ContentStyled } from '@modules/tickets-goods/real-event-payment/coin/styled';
import PaymentCoinSuccess from '@modules/tickets-goods/real-event-payment/coin/components/payment-coin-success';
import PaymentCoinFail from '@modules/tickets-goods/real-event-payment/coin/components/payment-coin-fail';
import useExitPrompt from '@hooks/useExitPrompt';
import {
  ModalStyled,
  TextComfirmStyled,
} from '@modules/tickets-goods/real-event-payment/components/payment-confirm/styled';

interface Params {
  eventId?: string;
}

export const PaymentConfirm: React.FC = () => {
  const [isVisiblePopupConfirm, setIsVisiblePopupConfirm] = useState<boolean>();
  const { t, i18n } = useTranslation();
  const { eventId } = useParams<Params>();
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null | undefined>('');

  const history = useHistory();
  const {
    preOrderPurchaseAction,
    isLoading,
    isPaymentPreOrder,
    error,
    dataPaymentSuccess,
    ticketsData,
    eventDetail,
    reset,
  } = useEvents();
  const { setShowExitPrompt } = useExitPrompt(true);

  const { common, myTicket, realEventPayment, error_message } = translations;
  const isPreOrder = eventDetail?.sale_type === SALE_TYPE.PRE_ORDER;

  const paymentMethodName = {
    1: 'CREDIT CARD',
    2: 'KONBINI',
    3: 'COIN',
    4: 'DIAMOND',
  };

  const hasTicket = (ticket: TicketFormGroup) => {
    return !!ticket.quantity;
  };

  const handlePaymentProcess = () => {
    setIsVisiblePopupConfirm(false);
  };

  const triggerVisiblePopup = () => {
    setIsVisiblePopupConfirm(!isVisiblePopupConfirm);
  };

  const handleBackPage = () => {
    history.push(`/events/${eventId}`);
  };
  const onSubmit = () => {
    if (isPreOrder) {
      preOrderPurchaseAction({
        tickets: ticketsData?.tickets.filter((f) => !!f.quantity) || [],
        paymentMethod: 3,
        unit: 2,
        id: Number(eventId) || 0,
        preOrder: 1,
        memo: ticketsData?.memo,
        address: ticketsData?.address,
        zip_code: ticketsData?.zipcode,
        phone_number: ticketsData?.phoneNumber,
        first_name: ticketsData?.firstKanji,
        last_name: ticketsData?.lastKanji,
      });
    }
  };

  const onCancel = () => {
    setIsShowModal(false);
    setErrorMsg('');
  };

  const onNavigateError = () => {
    if (error?.message?.[0] === ERROR_MESSAGE_REAL_EVENT_PURCHASE.TICKET_SOLD_OUT) {
      history.push(`/events/${eventId}`);
    } else {
      reset();
      history.push('/list-event');
    }
  };

  useEffect(() => {
    if (!ticketsData) {
      history.replace(`/events/${eventId}`);
      setShowExitPrompt(false);
    }
  }, [ticketsData]);

  useEffect(() => {
    if (isPaymentPreOrder) {
      setShowExitPrompt(false);
    }

    if (error) {
      setShowExitPrompt(false);
    }
  }, [isPaymentPreOrder, error]);

  useEffect(() => {
    if (error) {
      if (error.message.length > 0 && error?.message?.[0] === ERROR_MESSAGE_REAL_EVENT_PURCHASE.TICKET_SOLD_OUT) {
        setIsShowModal(true);
        setErrorMsg(t(realEventPayment.number_of_ticket_insufficent));
      } else if (
        error.message.length > 0 &&
        error?.message?.[0] === ERROR_MESSAGE_REAL_EVENT_PURCHASE.REAL_EVENT_NOT_FOUND
      ) {
        setIsShowModal(true);
        setErrorMsg(t(error_message.REAL_EVENT_PREODER_CANCEL));
      } else if (
        error.message.length > 0 &&
        error?.message?.[0] === ERROR_MESSAGE_REAL_EVENT_PURCHASE.REAL_EVENT_CANCEL
      ) {
        setIsShowModal(true);
        setErrorMsg(t(error_message.REAL_EVENT_PREODER_CANCEL));
      }
    }
  }, [error]);

  if (isPaymentPreOrder) {
    return (
      <ConfirmOrderStyle>
        <BoxPayment title={t(realEventPayment.payment_coin_completed)} isShowIconBack={false}>
          <ContentStyled>
            <PaymentCoinSuccess
              isPayPre
              totalCoinPrice={ticketsData?.totalCoin || 0}
              paymentPreCode={dataPaymentSuccess || ''}
            />
          </ContentStyled>
        </BoxPayment>
      </ConfirmOrderStyle>
    );
  }

  if (
    error &&
    error.message.length > 0 &&
    error?.message?.[0] !== ERROR_MESSAGE_REAL_EVENT_PURCHASE.REAL_EVENT_CANCEL &&
    error?.message?.[0] !== ERROR_MESSAGE_REAL_EVENT_PURCHASE.REAL_EVENT_NOT_FOUND &&
    error?.message?.[0] !== ERROR_MESSAGE_REAL_EVENT_PURCHASE.TICKET_SOLD_OUT
  ) {
    return (
      <ConfirmOrderStyle>
        <BoxPayment title={t(realEventPayment.payment_coin_fail)} isShowIconBack={false}>
          <ContentStyled>
            <PaymentCoinFail />
          </ContentStyled>
        </BoxPayment>
      </ConfirmOrderStyle>
    );
  }

  return (
    <Spin spinning={isLoading}>
      <ConfirmOrderStyle>
        <div className="header-title">
          <div className="back-icon">
            <img className="logo" src={BACK_ICON} alt="logo" onClick={handleBackPage} />
          </div>
          <div style={{ fontSize: 20, fontWeight: 500 }}>
            {isPreOrder ? t(common.event.confirm_pre_popup_title) : t(common.event.confirm_popup_title)}
          </div>
        </div>
        <TicketDetailStyle>
          <Group>
            <label>{t(common.event.ticket)}</label>
            <p className="custom-width">{eventDetail.title}</p>
          </Group>
          <Group>
            <label>{t(myTicket.my_ticket_detail.event_date)}</label>
            <p>{formatDateTimeOfWeek(eventDetail.start_at, i18n.language)}</p>
          </Group>
          {!!eventDetail.url && (
            <Group>
              <label>{t(myTicket.my_ticket_detail.venue)}</label>
              {eventDetail.url && (
                <p>
                  <a href={eventDetail.url} target="_blank" rel="noreferrer">
                    {t(realEventPayment.here)}
                  </a>
                  <MAP_ICON style={{ marginLeft: '5px' }} />
                </p>
              )}
            </Group>
          )}
          {(!!ticketsData?.firstKanji || !!ticketsData?.lastKanji) && (
            <Group>
              <label>{t(realEventPayment.user_name)}</label>
              <p>{`${ticketsData?.lastKanji} ${ticketsData?.firstKanji}`}</p>
            </Group>
          )}
          {!!ticketsData?.phoneNumber && (
            <Group>
              <label>{t(realEventPayment.phone)}</label>
              <p>{ticketsData?.phoneNumber}</p>
            </Group>
          )}
          {!!ticketsData?.zipcode && (
            <Group>
              <label>{t(realEventPayment.fieldName.zipcode)}</label>
              <p>{ticketsData?.zipcode}</p>
            </Group>
          )}
          {!!ticketsData?.address && (
            <Group>
              <label>{t(realEventPayment.fieldName.address)}</label>
              <p className="custom-width">{ticketsData?.address}</p>
            </Group>
          )}
          {!!ticketsData?.memo && (
            <Group>
              <label>{t(realEventPayment.note)}</label>
              <Seemore rows={3} content={ticketsData?.memo || ''} className="custom-width" />
            </Group>
          )}
          <Group>
            <label>{t(common.event.ticket_type)}</label>
            <div>
              {ticketsData?.tickets?.filter(hasTicket)?.map((ticket) => (
                <div className="ticket-type" key={ticket.ticketId}>
                  <div>{ticket.name}</div>
                  <div>{Number(ticket.quantity)}枚</div>
                </div>
              ))}
            </div>
          </Group>
          <Group>
            <label>{t(myTicket.my_ticket_detail.number_of_sheets)}</label>
            <p>
              {Number(ticketsData?.totalTicket)} {t(myTicket.my_ticket_detail.number_of_sheets)}
            </p>
          </Group>
          <Group>
            <label>{t(myTicket.my_ticket_detail.payment_method_label)}</label>

            <p className={ticketsData?.paymentMethod ? '' : 'status-pre'}>
              {ticketsData?.paymentMethod
                ? paymentMethodName[ticketsData?.paymentMethod]
                : t(myTicket.my_ticket_detail.payment_method.unpaid)}
            </p>
          </Group>
          <Group>
            <label>{t(realEventPayment.total_amount)}</label>
            <Row>
              <span className="price">￥ {ticketsData?.priceTotal}</span>/
              <span className="coin">
                <ICON_COIN />
                {ticketsData?.totalCoin}
              </span>
            </Row>
          </Group>
        </TicketDetailStyle>
        <WrapperAction>
          <Button
            htmlType="button"
            type="cancel"
            onClick={() => {
              history.push(`/events/${eventDetail?.id}`);
            }}
          >
            {t(common.cancel)}
          </Button>
          <Button className={isPreOrder ? 'btn-pre-order' : 'btn-purchase'} htmlType="button" onClick={onSubmit}>
            {t(realEventPayment.continue)}
          </Button>
        </WrapperAction>
      </ConfirmOrderStyle>
      <PaymentConfirmComponent
        visible={isVisiblePopupConfirm}
        onCancel={triggerVisiblePopup}
        confirmClick={handlePaymentProcess}
      />
      <ModalStyled centered onCancel={onCancel} open={isShowModal} footer={false}>
        <TextComfirmStyled>{errorMsg}</TextComfirmStyled>
        <Button onClick={onNavigateError}>
          {error?.message?.[0] === ERROR_MESSAGE_REAL_EVENT_PURCHASE.TICKET_SOLD_OUT
            ? t(realEventPayment.re_select)
            : t(realEventPayment.go_to_list_event)}
        </Button>
      </ModalStyled>
    </Spin>
  );
};
export default PaymentConfirm;
