import { useSearchParams } from '@hooks';
import { useTwitter } from '@hooks/useTwitter';
import Media from '@themes/media';
import { Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Noto Sans JP';
  width: 100%;
  height: 60vh;
  padding: 32px;

  ${Media.lessThan(Media.SIZE.MD)} {
    padding: 0px 15px;
    min-width: 320px;
    height: 50vh;
  }
`;

interface SearchParams {
  oauth_token?: string | null;
}

const TwitterMobile: React.FC = () => {
  const { oauth_token } = useSearchParams<SearchParams>();
  const { loginTwitterWithMobile } = useTwitter();
  React.useEffect(() => {
    if (oauth_token) {
      loginTwitterWithMobile({ userToken: oauth_token });
    }
  }, [oauth_token]);

  return (
    <Spin spinning>
      <Wrapper />
    </Spin>
  );
};
export default TwitterMobile;
