import styled from 'styled-components';
import { Divider as DividerAntd } from 'antd';
import { Link } from 'react-router-dom';
import Media from '@themes/media';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  overflow: auto;
  color: ${({ theme }) => theme.text_primary};
  background-color: #f0f2f5;
  .anticon-search {
    margin-right: 0;
  }
  .ant-input {
    color: #282727 !important;
  }

  .form-controls-group + .form-controls-group {
    padding-top: 24px;
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    .form-login {
      form {
        width: 100% !important;
      }
    }
    .ant-input {
      /* padding-left: 10px !important; */
      color: #282727;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
    }
  }
  .logo-header {
    text-align: center;
    padding: 69px 2rem 55px 2rem;
  }
  .btn-login {
    color: ${({ theme }) => theme.white};
    background-color: #e3007f;
    opacity: 0.5;
    border-radius: 24px;
    width: 95%;
    height: 50px;
  }
  .error-login {
    margin-left: 2vh;
    width: 94%;
  }
  .btn-login:hover {
    opacity: unset;
    border-color: #e3007f;
  }
  .btn-register {
    color: #e3007f;
    background-color: ${({ theme }) => theme.white};
    border: 1px solid #e3007f;
    border-radius: 24px;
    width: 95%;
    height: 50px;
  }
  .btn-cancel {
    color: #787878;
    background-color: #d9d9d9;
    border-radius: 24px;
    width: 95%;
    height: 50px;
    letter-spacing: 0.002em;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
  }
  .click-span {
    color: #e3007f;
    text-decoration: underline;
    cursor: pointer;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: bold;
  }
  .label-header {
    text-align: center;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700 !important;
    font-size: 24px;
    line-height: 32px;
    color: #282727;
    margin-bottom: 2.5rem;
  }
  .forget-password {
    text-align: right;
    left: 5px;
    margin-right: 15px;
    margin-top: 10px;
  }

  .form-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: ${({ theme }) => theme.white};

    form {
      width: 70%;
    }

    .form-action-group {
      margin: 1rem 0;
      text-align: center;
    }
  }

  .form-login {
    display: flex;
    max-width: 1024px;
    width: 100%;
    background-color: ${({ theme }) => theme.white};
    justify-content: center;
    margin: 40px auto;

    form {
      max-width: 368px;
      width: 100%;
    }

    .form-action-group {
      margin: 1.5rem 0 4rem 0;
      text-align: center;
      .btn-register {
        margin-top: 1.2rem;
        margin-bottom: 0.5rem;
      }
    }
    .ant-input {
      padding: 0.5rem 1rem;
      /* color: #b1b6bb; */
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }
  }
`;

// column table
export const Action = styled.div`
  display: flex;
  justify-content: space-around;

  .action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e7eef8;
    border-color: #e7eef8;
    border-radius: 0.75rem;
    &:hover {
      color: ${({ theme }) => theme.white};
      background-color: #1f2128;
      border-color: #1f2128;
    }
    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

// detail screen
export const Block = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 0.75rem;
  margin: 1rem 0;
  padding: 1rem;
  .block-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
`;

export const Table = styled.table`
  width: 70%;
  margin: 2rem auto;
  box-shadow: 0 0.8rem 3rem rgba(0, 0, 0, 0.075);
`;

export const TBody = styled.tbody``;

export const Tr = styled.tr``;

export const Th = styled.td`
  background-color: #d3dafe;
  border: none;
  width: 200px;
  padding: 1rem;
  font-weight: 600;
  border-radius: 0.75rem 0;
`;

export const Td = styled.td`
  background-color: #e7eef8;
  border: none;
  padding: 1rem;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
  border-radius: 0.75rem 0;
`;
// detail screen

// create/edit divider
export const Divider = styled(DividerAntd)`
  height: 2px;
  padding: 0;
  margin: 0;
`;
// create/edit RightWrapper
export const Right = styled.div`
  display: flex;
  width: 70%;
  padding: 16px;
  flex-direction: column;

  .ant-form-item {
    margin-bottom: 0;
  }

  &.form-editor-content-question {
    .ant-row {
      margin-bottom: 0 !important;
    }
    p {
      margin-bottom: 0;
    }
  }
`;

// create/edit inputLogin
export const InputLogin = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 10px 0 10px;
  flex-direction: column;

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-input {
    /* border: 1px solid #787878 !important; */
    /* border-radius: 10px !important; */
    background-color: ${({ theme }) => theme.white};
  }

  &.form-editor-content-question {
    .ant-row {
      margin-bottom: 0 !important;
    }
    p {
      margin-bottom: 0;
    }
  }
`;

// back to home page
export const BackButton = styled(Link)`
  color: #4d69fa;
  & svg {
    margin-right: 0.5rem;
  }
  &:hover {
    color: #4d69fa;
    background-color: #edf0ff;
    padding: 0.4rem 0.85rem;
    border-radius: 0.75rem;
  }
`;
