import React from 'react';
import styled from 'styled-components';
import { Button as BaseButton, ButtonProps } from 'antd';
import { ButtonType as BaseButtonType } from 'antd/lib/button';
import Media from '@themes/media';

const ButtonStyled = styled(BaseButton)`
  border-radius: 50px;
  min-width: 148px;
  &:hover {
    color: #323232;
    border-color: rgba(120, 120, 120, 0.3);
    outline: 0;
    box-shadow:
      inset 0 1px 1px rgb(0 0 0 / 8%),
      0 0 0 0.1rem rgb(120 120 120 / 25%);
  }

  &.ant-btn-default {
    background-color: #e3007f;
    border: none;
    color: ${({ theme }) => theme.white};

    &:hover,
    &:disabled {
      background-color: #e3007f !important;
      color: ${({ theme }) => theme.white};
      opacity: 0.5;
      border: none;
    }
  }

  &.ant-btn-primary {
    background-color: #e3007f;
    border: none;
    color: ${({ theme }) => theme.white};

    &:hover,
    &:disabled {
      background-color: #e3007f !important;
      color: ${({ theme }) => theme.white};
      border: none;
      opacity: 0.5;
    }
  }

  &.ant-btn-outline {
    background-color: ${({ theme }) => theme.white};

    border: 1px solid #e3007f;
    color: ${({ theme }) => theme.btn_primary};

    &:hover,
    &:disabled {
      background-color: ${({ theme }) => theme.white} !important;
      color: ${({ theme }) => theme.btn_primary};
      opacity: 0.5;
    }
  }

  &.ant-btn-cancel {
    background-color: ${({ theme }) => theme._d9d9d9};
    border: none;
    color: ${({ theme }) => theme._787878};

    &:disabled {
      color: ${({ theme }) => theme._787878};
      border: none;
      opacity: 0.5;
    }
  }

  &.ant-btn-preorder {
    background-color: ${({ theme }) => theme._00CEBC};
    border: none;
    color: ${({ theme }) => theme.white};

    &:hover,
    &:disabled {
      background-color: ${({ theme }) => theme._00CEBC};
      color: ${({ theme }) => theme.white};
      border: none;
      opacity: 0.5;
    }
  }
  &.ant-btn-icon {
    background-color: ${({ theme }) => theme._00CEBC};
    border: none;
    color: ${({ theme }) => theme.white};
    width: 100%;
    min-width: 100%;
    &:hover,
    &:disabled {
      background-color: ${({ theme }) => theme._00CEBC};
      color: ${({ theme }) => theme.white};
      border: none;
      opacity: 0.5;
    }
  }

  ${Media.lessThan(Media.SIZE.MD)} {
    min-width: 120px;
    height: 36px;
  }
`;

type ButtonType = BaseButtonType | 'outline' | 'cancel' | 'preorder' | 'icon';
const Button: React.FC<Omit<ButtonProps, 'type'> & React.RefAttributes<HTMLElement> & { type?: ButtonType }> = ({
  type,
  ...props
}) => <ButtonStyled type={type as any} {...props} />;
export default Button;
