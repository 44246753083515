/* eslint-disable react/prop-types */
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

const Label: any = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: ${({ width }: any) => `${width}%`};
  min-height: 100%;
  padding: 0 10px;
  color: #282727;
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  flex: 1;

  p {
    display: flex;
    flex: 1;
    font-size: 13px;
    font-weight: 500;
    margin: 0;
  }
`;

const Description: any = styled.span`
  color: ${({ theme }) => theme.white};
  background: ${({ theme, description }: any) => (description === 'Optional' ? theme.greyHight : theme.bg_primary)};
  padding: 3px 8px;
  border-radius: 1rem;
  font-weight: 600;
  font-size: 10px;
`;

const FormLabel: React.FC<any> = ({
  title,
  classCss,
  width = 30,
  fontSize = 'size_14',
  fontWeight = 'fw_600',
  description,
  star,
}: any) => {
  const { t } = useTranslation(['common']);
  return (
    <Label className={classCss} width={width}>
      <p style={{ fontWeight, fontSize }}>
        {title} <span style={{ color: 'red' }}>{star}</span>{' '}
      </p>
      {description && (
        <Description description={description}>
          {description === 'Optional' ? t('label_optional') : t('label_required')}
        </Description>
      )}
    </Label>
  );
};

export default FormLabel;
