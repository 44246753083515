import { USER_ROLE } from '@constants/auth';
import HomeLayout from '@layouts/home';

import BlankLayout from '@layouts/blank';
import PageDownloadScreen from '@modules/auth/page-download';
import PageOpenProfileScreen from '@modules/auth/page-open-profile';
import PageRequestDeleteScreen from '@modules/auth/page-request-delete';
import policeScreen from '@modules/auth/static-page/policy';
import termOfUserScreen from '@modules/auth/static-page/term_of_user';
import DiamondPurchase from '@modules/diamond-purchase';
import ListPackageDiamondScreen from '@modules/diamond-purchase/list-package-diamond';
import MyTicket from '@modules/history/list';
import PaymentScreen from '@modules/payment';
import TicketDetail from '@modules/history/ticket-detail';
import TopicHistoryDetail from '@modules/history/topic-detail';
import {
  TopicDetail,
  Topics,
  VideoPackageDetail,
  WatchLiveStream,
  WatchVideoChapter,
  WatchVideoPackage,
} from '@modules/topics';
import Twitter from '@modules/twitter';
import TwitterMobile from '@modules/twitter-mobile';
import {
  TopicPayment,
  PaymentDiamond,
  CreditPaymentTopicScreen,
  KonbiniPaymentTopicScreen,
} from '@modules/topic-payment';
import {
  ListEvent,
  ListEventClosed,
  ListEventOpening,
  EventDetail,
  SearchEvent,
  PaymentCoin,
  CreditPaymentScreen,
  KonbiniPaymentScreen,
  PaymentConfirm,
} from '@modules/tickets-goods';

const RoutesName = {
  WEB_DOWNLOAD: '/download',
  REQUEST_DELETE: '/request-delete-account',
  WEB_OPEN_PROFILE: '/profile',
  LIST_DIAMOND: '/diamond-purchase/list-diamond/:isActive',
  PAYMENT: '/diamond-purchase/payment/:packageId/',
  DIAMOND_PURCHASE: '/diamond-purchase',
  TERM_OF_USER: '/term-of-user/:language',
  POLICY: '/policy/:language',
  TWITTER: '/twitter/callback',
  TWITTER_MOBILE: '/twitter-signin',
  DEFAULT: '/',
  TICKETS_GOODS: {
    LIST: '/events',
    LIST_OPENING: '/list-event',
    LIST_CLOSED: '/list-event-closed',
    SEARCH: '/search-event',
    PAYMENT_COIN: '/payment-coin/:eventId',
    PAYMENT_CREDIT: '/payment-credit/:id',
    PAYMENT_KOBINI: '/payment-kobini/:id',
    EVENT_DETAIL: '/events/:id',
    ORDER_CONFIRM: '/order-confirm/:eventId',
  },
  TOPICS: {
    LIST: '/topics',
    DETAIL: '/topics/:id',
    WATCH_LIVESTREAM: '/topics/watch-livestream',
    WATCH_VIDEO: '/topics/watch-video-chapter/:id',
    WATCH_VIDEO_PACKAGE: '/topics/watch-video-package/:topicId/:packageId/:chapterId',
    PAYMENT: '/topics/:topicId/payment/:packageId',
    PACKAGE_PAYMENT: '/topics/:topicId/video-package/:packageId/payment',
    PACKAGE_PAYMENT_CREDIT: '/topics/:topicId/payment/:packageId/credit',
    PAYMENT_DIAMOND: '/topics/:topicId/payment/:packageId/diamond',
    PAYMENT_KOBINI: '/topics/:topicId/payment/:packageId/kobini',
    VIDEO_PACKAGE_DETAIL: '/topics/:topicId/video-package/:packageId',
  },
  HISTORIES: {
    LIST: '/histories',
    TICKETS_GOODS: '/histories/ticket/:realEventId/:balanceCode',
    TOPICS: '/histories/topic/:balanceCode',
  },
};

export const PRIVATE_ROUTES = [
  {
    path: RoutesName.PAYMENT,
    component: PaymentScreen,
    layout: HomeLayout,
    rules: [USER_ROLE.USER, 'ADMIN'],
    exact: true,
  },
  {
    path: RoutesName.LIST_DIAMOND,
    component: ListPackageDiamondScreen,
    layout: HomeLayout,
    rules: [USER_ROLE.USER],
    exact: true,
  },
  {
    path: RoutesName.HISTORIES.LIST,
    component: MyTicket,
    layout: HomeLayout,
    rules: [USER_ROLE.USER],
    exact: true,
  },
  {
    path: RoutesName.HISTORIES.TICKETS_GOODS,
    component: TicketDetail,
    layout: HomeLayout,
    rules: [USER_ROLE.USER],
    exact: true,
  },
  {
    path: RoutesName.HISTORIES.TOPICS,
    component: TopicHistoryDetail,
    layout: HomeLayout,
    rules: [USER_ROLE.USER],
    exact: true,
  },
  {
    path: RoutesName.TICKETS_GOODS.ORDER_CONFIRM,
    component: PaymentConfirm,
  },
  {
    path: RoutesName.TICKETS_GOODS.PAYMENT_COIN,
    component: PaymentCoin,
    layout: HomeLayout,
    rules: [USER_ROLE.USER],
    exact: true,
  },
  {
    path: RoutesName.TICKETS_GOODS.PAYMENT_CREDIT,
    component: CreditPaymentScreen,
    layout: HomeLayout,
    rules: [USER_ROLE.USER],
    exact: true,
  },
  {
    path: RoutesName.TICKETS_GOODS.PAYMENT_KOBINI,
    component: KonbiniPaymentScreen,
    layout: HomeLayout,
    rules: [USER_ROLE.USER],
    exact: true,
  },
  {
    path: RoutesName.TOPICS.PAYMENT_KOBINI,
    component: KonbiniPaymentTopicScreen,
    layout: HomeLayout,
    rules: [USER_ROLE.USER],
    exact: true,
  },
  {
    path: RoutesName.TOPICS.WATCH_LIVESTREAM,
    component: WatchLiveStream,
    layout: HomeLayout,
    exact: true,
  },
  {
    path: RoutesName.TOPICS.WATCH_VIDEO,
    component: WatchVideoChapter,
    layout: HomeLayout,
    exact: true,
  },
  {
    path: RoutesName.TOPICS.WATCH_VIDEO_PACKAGE,
    component: WatchVideoPackage,
    layout: HomeLayout,
    exact: true,
  },
  {
    path: RoutesName.TOPICS.PACKAGE_PAYMENT,
    component: TopicPayment,
    layout: HomeLayout,
    exact: true,
  },
  {
    path: RoutesName.TOPICS.PACKAGE_PAYMENT_CREDIT,
    component: CreditPaymentTopicScreen,
    layout: HomeLayout,
    exact: true,
  },
  {
    path: RoutesName.TOPICS.PAYMENT_DIAMOND,
    component: PaymentDiamond,
    layout: HomeLayout,
    exact: true,
  },
];

export const CUSTOME_ROUTE = [
  {
    path: RoutesName.TICKETS_GOODS.LIST,
    component: ListEvent,
    newRules: true,
    exact: true,
    layout: HomeLayout,
  },
  {
    path: RoutesName.WEB_DOWNLOAD,
    component: PageDownloadScreen,
    newRules: true,
    layout: BlankLayout,
  },
  {
    path: RoutesName.WEB_OPEN_PROFILE,
    component: PageOpenProfileScreen,
    newRules: true,
    layout: BlankLayout,
  },
  {
    path: RoutesName.REQUEST_DELETE,
    component: PageRequestDeleteScreen,
    newRules: true,
    layout: BlankLayout,
  },
  {
    path: RoutesName.DEFAULT,
    component: Topics,
    newRules: true,
    exact: true,
    layout: HomeLayout,
  },

  {
    path: RoutesName.TICKETS_GOODS.LIST_OPENING,
    component: ListEventOpening,
    newRules: true,
    exact: true,
    layout: HomeLayout,
  },

  {
    path: RoutesName.TICKETS_GOODS.LIST_CLOSED,
    component: ListEventClosed,
    newRules: true,
    exact: true,
    layout: HomeLayout,
  },

  {
    path: RoutesName.DIAMOND_PURCHASE,
    component: DiamondPurchase,
    newRules: true,
    exact: true,
    layout: HomeLayout,
  },
  {
    path: RoutesName.TICKETS_GOODS.EVENT_DETAIL,
    component: EventDetail,
    newRules: true,
    layout: HomeLayout,
  },
  {
    path: RoutesName.TERM_OF_USER,
    component: termOfUserScreen,
    layout: BlankLayout,
  },
  {
    path: RoutesName.POLICY,
    component: policeScreen,
    layout: BlankLayout,
  },
  {
    path: RoutesName.TICKETS_GOODS.SEARCH,
    component: SearchEvent,
    layout: HomeLayout,
  },
  {
    path: RoutesName.TOPICS.LIST,
    component: Topics,
    layout: HomeLayout,
    exact: true,
  },
  {
    path: RoutesName.TOPICS.DETAIL,
    component: TopicDetail,
    layout: HomeLayout,
    exact: true,
  },
  {
    path: RoutesName.TWITTER,
    component: Twitter,
    layout: HomeLayout,
    exact: true,
  },
  {
    path: RoutesName.TWITTER_MOBILE,
    component: TwitterMobile,
    layout: HomeLayout,
    exact: true,
  },
  {
    path: RoutesName.TOPICS.VIDEO_PACKAGE_DETAIL,
    component: VideoPackageDetail,
    layout: HomeLayout,
    exact: true,
  },
  {
    path: RoutesName.TOPICS.PAYMENT,
    component: TopicPayment,
    layout: HomeLayout,
    exact: true,
  },
];

export default RoutesName;
