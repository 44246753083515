import Media from '@themes/media';
import styled from 'styled-components';

export const PaymnetCoinEnoughStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-family: 'Noto Sans JP' !important;

  .box-message {
    max-width: 100%;
    min-width: 50%;
    background-color: #f0f2f4;
    padding: 19px 44px;
    border-radius: 9px;
    margin-bottom: 100px;
    font-family: 'Noto Sans JP' !important;
    color: #282727;
    ${Media.lessThan(Media.SIZE.SM)} {
      padding: 19px 22px;
    }

    .text-message {
      font-size: 24px;
      display: flex;
      align-items: center;
      flex-direction: column;
      font-family: 'Noto Sans JP' !important;
      color: #282727 !important;

      .text-message-top {
        /* display: flex; */
        align-items: center;
        font-family: 'Noto Sans JP';
        font-weight: 400;
        color: #282727 !important;
        font-size: 24px;
        svg {
          vertical-align: middle;
        }
        ${Media.lessThan(Media.SIZE.MD)} {
          font-size: 18px;
        }
      }

      ${Media.lessThan(Media.SIZE.SM)} {
        font-size: 16px;

        .text-message-bottom {
          font-size: 16px;
          font-family: 'Noto Sans JP' !important;
          font-weight: 400;
          color: #282727 !important;
        }
      }
    }
  }

  .box-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
`;
