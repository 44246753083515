// 2134a3cb2249421f8d26b0acd2103881
// aa3c869fb8044352a1c6a13f265cb257 ==> Tuyen
export const ENV: { [index: string]: any } = {
  DEV: {
    REACT_APP_API_SERVER: 'https://dev-api.meta-live.jp/',
    ILIVE_CONTRACT_ADDRESS: '0xd382050F0a0c58118B44562Cf8E359CeEB9B5C0A',
    ERC20_CONTRACT_ADDRESS: '0x0a9Acc08776D2f8DC050BC0856AA82eDBfD6B31C',
    INFURA_ID: 'aa3c869fb8044352a1c6a13f265cb257',
    STRIPE_PK:
      'pk_test_51LRstgBNKWiQhXgjhQqgSuF2P2vVHzkNm2CDxKsOUM2mDZheGAbhSKegp71sOxU3wbDliaCchvth0x3bJhSC9hxy009dpRH7hw',
    WEB_DOWNLOAD: 'jp.app.metalive.dev://',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=jp.app.metalive',
    APP_STORE: 'https://apps.apple.com/jp/app/metalive/id6443916867',
    TWITTER: 'https://twitter.com/metalive_jp?s=21&t=LJPiNI20RfKXQsSq7PP9-A',
    GMO_SHOP_ID: 'tshop00061263',
    S3_BUCKET: 'https://ilive-prod.s3.ap-northeast-1.amazonaws.com',
    AGORA_KEY: '26de5a61550d404eab30771eed491ccf',
    WEB_URL: 'https://dev-user.meta-live.jp',
    // firebase
    FIRE_BASE_API_KEY: 'AIzaSyC_MKe6z59Wf5XEkA29Q1TA0DFWm6emvB8',
    FIRE_BASE_DOMAIN: 'ilive-dev.firebaseapp.com',
    DATABASE_URL: 'https://ilive-dev-default-rtdb.asia-southeast1.firebasedatabase.app',
    PROJECT_ID: 'ilive-dev',
    STOGRAGE_BUCKET: 'ilive-dev.appspot.com',
    MESSAGEGING_SENDER_ID: '382889599712',
    APP_ID: '1:382889599712:web:62ec237cb0669851d9a685',
    MEASUREMENT_ID: 'G-EYBPBBJ4ZJ',
  },
  STG: {
    REACT_APP_API_SERVER: 'https://stg-api.meta-live.jp/',
    ILIVE_CONTRACT_ADDRESS: '0xd382050F0a0c58118B44562Cf8E359CeEB9B5C0A',
    ERC20_CONTRACT_ADDRESS: '0x0a9Acc08776D2f8DC050BC0856AA82eDBfD6B31C',
    STRIPE_PK:
      'pk_test_51LRstgBNKWiQhXgjhQqgSuF2P2vVHzkNm2CDxKsOUM2mDZheGAbhSKegp71sOxU3wbDliaCchvth0x3bJhSC9hxy009dpRH7hw',
    WEB_DOWNLOAD: 'jp.app.metalive.stg://',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=jp.app.metalive',
    APP_STORE: 'https://apps.apple.com/jp/app/metalive/id6443916867',
    TWITTER: 'https://twitter.com/metalive_jp?s=21&t=LJPiNI20RfKXQsSq7PP9-A',
    GMO_SHOP_ID: 'tshop00062364',
    S3_BUCKET: 'https://ilive-prod.s3.ap-northeast-1.amazonaws.com',
    AGORA_KEY: '26de5a61550d404eab30771eed491ccf',
    WEB_URL: 'https://stg-user.meta-live.jp',
    // firebase

    FIRE_BASE_API_KEY: 'AIzaSyA2yO5OU0lz3GUmXPEUAhaWnHyHsZG4xK8',
    FIRE_BASE_DOMAIN: 'metalive-stg.firebaseapp.com"',
    DATABASE_URL: 'https://metalive-stg-default-rtdb.asia-southeast1.firebasedatabase.app',
    PROJECT_ID: 'metalive-stg"',
    STOGRAGE_BUCKET: 'metalive-stg.appspot.com',
    MESSAGEGING_SENDER_ID: '945063862918',
    APP_ID: '1:945063862918:web:92c5beebe6adf2f89a6707',
    MEASUREMENT_ID: 'G-CD87KVGLY2',
  },
  PRO: {
    REACT_APP_API_SERVER: 'https://api.meta-live.jp/',
    ILIVE_CONTRACT_ADDRESS: '',
    ERC20_CONTRACT_ADDRESS: '',
    STRIPE_PK:
      'pk_live_51LRstgBNKWiQhXgjfhf4N07Y0eDcBtYFfJeGNdnjD5aq3KTftu73emZAi1bhpoJeRnAXOOOhGT5gQJGh7Xq3tzBm00hYnJAZcX',
    WEB_DOWNLOAD: 'jp.app.metalive://',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=jp.app.metalive',
    APP_STORE: 'https://apps.apple.com/jp/app/metalive/id6443916867',
    TWITTER: 'https://twitter.com/metalive_jp?s=21&t=LJPiNI20RfKXQsSq7PP9-A',
    GMO_SHOP_ID: '9200006403435',
    S3_BUCKET: 'https://ilive-prod.s3.ap-northeast-1.amazonaws.com',
    AGORA_KEY: '26de5a61550d404eab30771eed491ccf',
    WEB_URL: 'https://user.meta-live.jp',
    // firebase

    FIRE_BASE_API_KEY: 'AIzaSyBS2FTePmjutUAIxX63GqRpjHhb7EDIsHE',
    FIRE_BASE_DOMAIN: 'metalive-89648.firebaseapp.com',
    DATABASE_URL: 'https://metalive-89648-default-rtdb.asia-southeast1.firebasedatabase.app',
    PROJECT_ID: 'metalive-89648',
    STOGRAGE_BUCKET: 'metalive-89648.appspot.com',
    MESSAGEGING_SENDER_ID: '939539597087',
    APP_ID: '1:939539597087:web:da77cd752299d5ad5ed22a',
    MEASUREMENT_ID: 'G-EYBPBBJ4ZJ',
  },
};

const config = ENV[process.env.REACT_APP_STAGE || 'DEV'];

export const getEnv = (name: any, defaultValue: any) => process.env[name] || config[name] || defaultValue;
