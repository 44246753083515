/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-param-reassign */
/* eslint-disable symbol-description */

import axios from 'axios';
import JSONBig from 'json-bigint';
import { notification } from 'antd';

import { isEmpty, assign } from 'lodash';
import { STORAGE, getLocalStorage } from '@utils';
import { getEnv } from '@config/env';
import i18next from 'i18next';
import { translations } from '@i18n/translations';
import { removeCacheData } from '@utils/utils';
import { store } from '../index';

const singletonEnforcer = Symbol();
const BASE_URL = getEnv('REACT_APP_API_SERVER', null);
class AxiosClient {
  axiosClient: any;

  static axiosClientInstance: AxiosClient;

  constructor(enforcer: any) {
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot initialize Axios client single instance');
    }

    this.axiosClient = axios.create({
      baseURL: BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Requested-with': 'XMLHttpRequest',
        'X-XSRF-TOKEN': '   ',
      },
      withCredentials: true,
    });

    this.getExistTokenOnLocalStorage();

    this.axiosClient.defaults.transformResponse = (data: any) => JSONBig.parse(data);

    this.axiosClient.interceptors.request.use(
      (configure: any) => {
        const token = getLocalStorage(STORAGE.USER_TOKEN);
        if (token) {
          configure.headers.Authorization = `Bearer ${token}`;
        }
        return configure;
      },
      (error: any) => Promise.reject(error),
    );

    this.axiosClient.interceptors.response.use(
      (response: any) => {
        const { status, data } = response;
        return {
          status,
          data,
        };
      },
      (error: any) => {
        if (error.response) {
          const { data, status } = error.response;

          if (status === 404) {
            store.dispatch({
              type: '@GLOBAL/IS404',
              payload: true,
            });
          }

          if (status === 401) {
            removeCacheData();
            store.dispatch({
              type: '@AUTH/RESET_AUTH',
            });
          }

          if (status < 500 && status !== 401 && status !== 404) {
            // API errors
            let message = i18next.t(translations.common.networkError);
            if (data.message) {
              if (typeof data.message === 'string') {
                message = data.message;
              } else {
                const arr: string[] = data.message;
                message = arr.map((mess) => i18next.t(`error_message:${mess}`)).join('\n');
              }
            }
            if (!error.response?.config?.isHideError && status !== 404) {
              notification.error({
                message: i18next.t(translations.common.error),
                description: message,
                duration: 4,
              });
            }
          }

          if (status === 503) {
            // Server maintanance
            notification.error({
              message: i18next.t(translations.common.error),
              description: data?.message ?? 'Server error',
              duration: 4,
            });
          }

          throw data;
        } else {
          throw error;
        }
      },
    );
  }

  static get instance() {
    if (!this.axiosClientInstance) {
      this.axiosClientInstance = new AxiosClient(singletonEnforcer);
    }

    return this.axiosClientInstance;
  }

  async getExistTokenOnLocalStorage() {
    const userToken: any = await getLocalStorage(STORAGE.USER_TOKEN);
    if (userToken) {
      this.setHeader(userToken);
    }
  }

  setHeader = (userToken: any) => {
    this.axiosClient.defaults.headers.common.Authorization = `Bearer ${userToken}`;
  };

  get = async (resource: any, slug = '', config = {}) => {
    slug += '';
    const requestURL = isEmpty(slug) ? `${resource}` : `${resource}/${slug}`;
    return this.axiosClient.get(requestURL, {}, assign(config, this.axiosClient.defaults.headers));
  };

  post = async (resource: any, data: any, config = {}) => {
    return this.axiosClient.post(`${resource}`, data, assign(config, this.axiosClient.defaults.headers));
  };

  update = async (resource: any, data: any, config = {}) =>
    this.axiosClient.put(`${resource}`, data, assign(config, this.axiosClient.defaults.headers));

  put = async (resource: any, data: any, config = {}) =>
    this.axiosClient.put(`${resource}`, data, assign(config, this.axiosClient.defaults.headers));

  patch = async (resource: any, data: any, config = {}) =>
    this.axiosClient.patch(`${resource}`, data, assign(config, this.axiosClient.defaults.headers));

  delete = async (resource: any, data?: any, config = {}) =>
    this.axiosClient.delete(`${resource}`, {
      data,
      ...assign(config, { headers: this.axiosClient.defaults.headers }),
    });
}

export default AxiosClient.instance;
