import React from 'react';
import { Typography } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';

import { numberWithCommas } from '@utils/number';
import { Button } from '@components';
import { DIAMOND } from '@assets';
import { useAuth } from '@hooks';
import { useTopicPayment } from '@hooks/useTopicPayment';
import { Wrapper } from './styled';
import { TextCoin } from '../../styled';

const { Text } = Typography;

interface Props {
  onContinue: () => void;
  topicId: string | null;
  packageId: string | null;
}

const DiamondEnough: React.FC<Props> = ({ onContinue, topicId, packageId }) => {
  const { replace } = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');
  const { profile } = useAuth();
  const { t } = useTranslation();
  const { realEventPayment, common } = translations;
  const { topic_info } = useTopicPayment();

  const goBack = () => replace(`/topics/${topicId}/payment/${packageId}?type=${type}`);

  return (
    <>
      <Typography className="text-balance">
        <Text className="label">{t(common.label_diamond_header)}</Text>
        <TextCoin>
          <div className="price">
            <img src={DIAMOND} alt="diamond" width={22} />
            <span>
              &ensp;
              {numberWithCommas(profile?.diamond, ',')}
            </span>
          </div>
        </TextCoin>
      </Typography>
      <Wrapper>
        <div className="box-message">
          <Typography className="text-message">
            <Text className="text-message-top">
              {t(realEventPayment.would_you_like_pay_coin)} <img src={DIAMOND} alt="diamond" width={35} />
              <span style={{ color: '#0076e3', margin: 4, fontWeight: 'bold', minWidth: 16 }}>
                {numberWithCommas(topic_info.diamond_price, ',')}
              </span>{' '}
              {t(realEventPayment.is_this_good_br)}
            </Text>
          </Typography>
        </div>
        <div className="box-btn">
          <Button type="cancel" onClick={goBack}>
            {t(realEventPayment.cancel)}
          </Button>
          <Button onClick={onContinue}>{t(realEventPayment.continue)}</Button>
        </div>
      </Wrapper>
    </>
  );
};

export default DiamondEnough;
