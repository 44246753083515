import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { TextInput, Button } from '@components';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { STICK } from '@assets';
import { yupResolver } from '@hookform/resolvers/yup';
import { translations } from '@i18n/translations';
import { useTranslation } from 'react-i18next';
import useExitPrompt from '@hooks/useExitPrompt';
import { convertStringToHalfWidth } from '@utils/string';
import { isMobile } from 'react-device-detect';
import Header from '@modules/tickets-goods/real-event-payment/components/header';

import { useTopicPayment } from '@hooks/useTopicPayment';
import { FORM_KONBINI, KOBINI_LIST } from '@modules/topic-payment/constants';
import scheme from './schema';
import { Form, FormWrapper, KobiniItem, KobiniListWrapper, KobiniLogo } from './styled';

interface FormValues {
  email: string;
  phone_number: string;
  first_name: string;
  last_name: string;
  first_name_kana: string;
  last_name_kana: string;
}
interface Params {
  id?: string;
  packageId?: string;
  topicId?: string;
}
interface Convenience {
  src: string;
  label: string;
  value: string;
}

type FieldName = keyof FormValues;

interface IProps {
  onChangeStatus: () => void;
}

const defaultValues = {
  email: '',
  phone_number: '',
  first_name: '',
  last_name: '',
  first_name_kana: '',
  last_name_kana: '',
};

export const InputPayment: React.FC<IProps> = ({ onChangeStatus }) => {
  const { topicId, packageId } = useParams<Params>();
  const { user_info, setKonbiniInfo, setUserInfo, payment_method, konbini_info } = useTopicPayment();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');
  const history = useHistory();
  const [convenience, setConvenience] = useState<Convenience>({
    label: '',
    value: '',
    src: '',
  });

  const { t } = useTranslation();
  const { realEventPayment } = translations;
  useExitPrompt(true);

  const form = useForm({
    defaultValues,
    reValidateMode: 'onChange',
    resolver: yupResolver(scheme),
  });
  const { handleSubmit, setValue } = form;

  const onSubmit = handleSubmit((data) => {
    const { first_name, last_name, phone_number, email, first_name_kana, last_name_kana } = data;
    setKonbiniInfo({
      konbini_info: {
        ...konbini_info,
        first_name_kana,
        last_name_kana,
        convenience: convenience.value,
      },

      payment_method,
    });
    setUserInfo({
      user_info: {
        ...user_info,
        first_name,
        last_name,
        phone_number,
        email,
      },
      payment_method,
    });
    onChangeStatus();
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, name: FieldName) => {
    const { target } = e;
    const { value } = target;
    const convertHaflWidth = convertStringToHalfWidth(value || '');
    setValue(name, convertHaflWidth);
  };

  useEffect(() => {
    const keys = Object.keys(defaultValues) as Array<keyof FormValues>;
    keys.forEach((key) => {
      if (Object.keys(konbini_info).includes(key)) setValue(key, konbini_info[key]);
      else setValue(key, user_info[key] || '');
    });
  }, [user_info, konbini_info]);

  return (
    <FormProvider {...form}>
      <FormWrapper onSubmit={onSubmit}>
        <Header
          title={t(realEventPayment.kobini.title)}
          onClick={() => history.replace(`/topics/${topicId}/payment/${packageId}?type=${type}`)}
        />
        <Form>
          {FORM_KONBINI.map((item) => (
            <TextInput
              width={isMobile ? '100%' : '49%'}
              name={item.name}
              placeholder={item.placeholder}
              label={t(item.label)}
              inputType="required"
              key={item.name}
              translateField={realEventPayment.fieldName[item.name]}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, item.name as FieldName)}
            />
          ))}
          <div style={{ fontSize: 16, fontWeight: 400, color: '#272828', marginTop: 12 }}>
            {t(realEventPayment.kobini.note)}
          </div>
        </Form>
        <KobiniListWrapper>
          {KOBINI_LIST.map((item) => (
            <KobiniItem key={item.value} onClick={() => setConvenience(item)}>
              <KobiniLogo>
                <img
                  alt="ICON"
                  src={item.src}
                  style={{
                    width: 140,
                    border: convenience?.value === item.value ? '1px solid #E3007F' : '1px solid #D9D9D9',
                    borderRadius: 4,
                    padding: 1,
                  }}
                />
                {convenience?.value === item.value && (
                  <img
                    alt="stick"
                    src={STICK}
                    style={{ width: 18, height: 18, position: 'absolute', top: 4, right: 4 }}
                  />
                )}
              </KobiniLogo>
              <p>{t(item.label)}</p>
            </KobiniItem>
          ))}
        </KobiniListWrapper>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 24, marginTop: 24 }}>
          <Button disabled={!convenience?.value} htmlType="submit">
            {t(realEventPayment.kobini.next)}
          </Button>
        </div>
      </FormWrapper>
    </FormProvider>
  );
};
