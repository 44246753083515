import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio, Typography, Input } from 'antd';

import { Button, TextInput } from '@components';
import { translations } from '@i18n/translations';
import { ICON_COIN } from '@assets';
import { numberWithCommas } from '@utils/number';
import { EventDetail } from '@type/Store/event';
import { TicketFormGroup } from '@type/Form/BuyTicketForm';
import { Controller } from 'react-hook-form';
import { FORM_CREDIT_CARD_CUSTOM } from '@modules/tickets-goods/real-event-payment/constants';
import { isMobile } from 'react-device-detect';
import { Banks, Coin, Companies, PaymentAllowsLogin as Wrapper, RadioStyled, Form } from '../../styles';
import { CARDS, KONBINIS } from '../../constants';

const { Text } = Typography;
const { TextArea } = Input;

type EventProps = {
  isPreOrder: boolean;
  onChange: (...event: any[]) => void;
  value?: number | null;
  totalPrice: number;
  disableCoin: boolean;
  totalCoin: number;
  coinFee: number;
  yenFee: number;
  disableBtn: boolean;
  event: EventDetail;
  isDisablePayment: boolean;
  paymentMethod?: number;
  ticketOnlyYen: TicketFormGroup[];
  ticketOnlyCoin: TicketFormGroup[];
  control: any;
};
const PaymentAllowsLogin: React.FC<EventProps> = ({
  isPreOrder,
  onChange,
  value,
  totalPrice,
  disableCoin,
  totalCoin,
  disableBtn,
  event,
  isDisablePayment,
  paymentMethod,
  ticketOnlyYen,
  ticketOnlyCoin,
  coinFee = 0,
  yenFee = 0,
  control,
}) => {
  const { t } = useTranslation();

  const { common, realEventPayment } = translations;
  const isShowPayment: boolean = useMemo(
    () => event.ticket.filter((f) => f.price_yen_tax !== null && f.price_yen_tax !== undefined).length > 0,
    [event.ticket],
  );

  const isShowCoinPayment: boolean = useMemo(
    () => event.ticket.filter((t) => t.price_coin_tax !== null && t.price_coin_tax !== undefined).length > 0,
    [event.ticket],
  );
  const disabled = useMemo(() => {
    if (paymentMethod === 3 && ticketOnlyYen.length > 0) {
      return true;
    }
    if ((paymentMethod === 1 || paymentMethod === 2) && ticketOnlyCoin.length > 0) return true;

    return disableBtn;
  }, [ticketOnlyYen, ticketOnlyCoin, paymentMethod, disableBtn]);
  return (
    <>
      {!isPreOrder && (
        <Wrapper>
          <Radio.Group onChange={onChange} value={value} className="group-payment-methods">
            {isShowPayment && (
              <>
                <Banks>
                  <div>
                    <RadioStyled disabled={isDisablePayment} value={1}>
                      {t(common.credit_card)}
                    </RadioStyled>
                  </div>
                  {!!yenFee && <span className="fee">{t(common.event.including_fees)}</span>}
                  <div className="price">￥ {numberWithCommas(totalPrice, ',')}</div>
                  <div className="methods">
                    {CARDS.map((item) => (
                      <img src={item.src} alt={item.alt} key={item.alt} />
                    ))}
                  </div>
                  <div className="descriptions">
                    <p>{t(common.event.payment_credit_card)}</p>
                  </div>
                </Banks>
                <Companies>
                  <div>
                    <RadioStyled disabled={isDisablePayment} value={2}>
                      {t(common.event.company_payment)}
                    </RadioStyled>
                  </div>
                  {!!yenFee && <span className="fee">{t(common.event.including_fees)}</span>}
                  <div className="price">￥ {numberWithCommas(totalPrice, ',')}</div>
                  <div className="methods">
                    {KONBINIS.map(({ isIcon, src, alt, Icon }) => {
                      if (isIcon) return <Icon key={alt} />;
                      return <img src={src} alt={alt} key={alt} />;
                    })}
                  </div>
                  <div className="descriptions">
                    {KONBINIS.map(({ label }, index) => {
                      if (index < KONBINIS.length - 1) return <p key={label}>{t(label)}、</p>;
                      return <p key={label}>{t(label)}</p>;
                    })}
                  </div>
                </Companies>
              </>
            )}
            {isShowCoinPayment && (
              <Coin>
                <RadioStyled disabled={disableCoin} value={3}>
                  {t(common.event.coin_payment)}
                </RadioStyled>
                {!!coinFee && <span className="fee">{t(common.event.including_fees)}</span>}
                <div className="price-coin">
                  <ICON_COIN />
                  {numberWithCommas(totalCoin, ',')}
                </div>
                <div className="methods">Coin</div>
                <div className="descriptions">
                  <p>{t(common.event.get_free_coin)}</p>
                </div>
              </Coin>
            )}
          </Radio.Group>
        </Wrapper>
      )}
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
        <Form>
          {FORM_CREDIT_CARD_CUSTOM.map((item) => (
            <TextInput
              width={isMobile || item.isFullWidth ? '100%' : '49%'}
              name={item.name}
              placeholder={t(realEventPayment[item.placeholder])}
              label={t(item.label)}
              translateField={translations.realEventPayment.fieldName[item.name]}
              cvvInfo={item.name === 'cvc'}
              key={item.name}
              inputFormat={item.inputFormat}
            />
          ))}
        </Form>
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
          <p style={{ fontSize: 14, fontWeight: 700 }}>{t(realEventPayment.note)}</p>
          <Controller
            control={control}
            name="memo"
            render={({ field }) => (
              <TextArea
                id="memo"
                onChange={field.onChange}
                onBlur={field.onBlur}
                style={{
                  borderColor: 'rgba(180, 180, 180, 1)',
                  display: 'flex',
                  width: '100%',
                  borderRadius: 10,
                  padding: 8,
                  height: 120,
                }}
                placeholder={t(realEventPayment.note_placeholder)}
                maxLength={500}
                value={field.value}
              />
            )}
          />
        </div>
      </div>

      <div style={{ display: 'flex', width: '100%', color: 'red' }}>
        {paymentMethod === 1 && ticketOnlyCoin.length > 0 && (
          <Text type="danger" strong>
            {t(realEventPayment.not_pay_credit, { name: ticketOnlyCoin[0].name })}
          </Text>
        )}

        {paymentMethod === 2 && ticketOnlyCoin.length > 0 && (
          <Text type="danger" strong>
            {t(realEventPayment.not_pay_konbini, { name: ticketOnlyCoin[0].name })}
          </Text>
        )}
        {paymentMethod === 3 && ticketOnlyYen.length > 0 && (
          <Text type="danger" strong>
            {t(realEventPayment.not_pay_coin, { name: ticketOnlyYen[0].name })}
          </Text>
        )}
      </div>
      <Button disabled={disabled} className={isPreOrder ? 'btn-pre-order' : 'btn-purchase'} htmlType="submit">
        {isPreOrder ? t(common.button.pre_order) : t(common.button.purchase)}
      </Button>
    </>
  );
};
export default PaymentAllowsLogin;
