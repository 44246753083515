// Import Swiper styles
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/navigation';

// import required modules
import { SwiperSlide, Swiper } from 'swiper/react';
import { Swiper as SwiperType, Navigation, Grid } from 'swiper';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';

import ButtonComponent from '@components/button';
import { TYPE_LIST_EVENT } from '@constants/real-event';
import { Event } from '@type/Store/event';
import Ticket from '../tickets';
import { ListEvent } from './styles';
import NavigatorCustom from '../navigator';

type EventProps = {
  rows: number;
  typeList: number;
  data: Event[];
};

const Events: React.FC<EventProps> = ({ rows, data, typeList }) => {
  const swiperRef = useRef<SwiperType>();
  const { t } = useTranslation();
  const history = useHistory();
  const { common } = translations;

  const onClickShowMore = () => {
    if (typeList === TYPE_LIST_EVENT.LIST_EVENT) {
      history.push('/list-event');
    } else {
      history.push('/list-event-closed');
    }
  };

  return (
    <ListEvent>
      <Swiper
        spaceBetween={0}
        className="events"
        modules={[Grid, Navigation]}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        breakpoints={{
          320: { slidesPerView: 1.5 },
          768: { slidesPerView: 2.5 },
          1024: {
            slidesPerView: 3,
            grid: { fill: 'row', rows },
          },
        }}
      >
        {data?.map((ticket) => (
          <SwiperSlide key={ticket.id}>
            <Ticket data={ticket} />
          </SwiperSlide>
        ))}
        {data.length > 6 && <NavigatorCustom />}
      </Swiper>
      {data.length >= 10 && <ButtonComponent onClick={onClickShowMore}>{t(common.see_more)}</ButtonComponent>}
    </ListEvent>
  );
};
export default Events;
