import * as yup from 'yup';
import { STATUS_REGISTER } from '@constants/auth';

const registerSchema = (statusRegister: string) =>
  yup.object().shape({
    userName: yup
      .string()
      .trim()
      .required('register:error_message.nick_name_required')
      .max(100, 'register:error_message.user_name_max_length'),
    email: yup
      .string()
      .trim()
      .email('register:error_message.email_invalid')
      .required('register:error_message.email_required')
      .max(200, 'register:error_message.email_max_length'),
    phoneNumber: yup
      .string()
      .trim()
      .required('register:error_message.phone_number_required')
      .test('phonenumber', 'register:error_message.phoneValidFormat', (value = '') => {
        if (!value) return true;
        const pattern = /^[0-9]{7,15}$/;
        return pattern.test(value);
      }),
    countryPhone: yup.string().trim(),
    code:
      statusRegister === STATUS_REGISTER.REGISTER
        ? yup.string().trim()
        : yup.string().trim().required('register:error_message.verification_code_required'),
    password: yup
      .string()
      .trim()
      .required('register:error_message.passsword_required')
      .min(8, 'register:error_message.password_min_length')
      .max(16, 'register:error_message.password_max_length'),

    isAccept: yup.boolean().oneOf([true], 'register:error_message.must_be_agree_policy'),

    confirmPassword: yup
      .string()
      .trim()
      .required('register:error_message.confirm_passsword_required')
      .min(8, 'register:error_message.password_min_length')
      .max(16, 'register:error_message.password_max_length')
      .oneOf([yup.ref('password'), null], 'register:error_message.password_not_match'),
  });

export default registerSchema;
