import { useDispatch, useSelector } from 'react-redux';

import saga from '@modules/diamond-purchase/store/saga';
import reducer from '@modules/diamond-purchase/store/reducer';
import { getSignature, eventTransfer, resetState } from '@modules/diamond-purchase/store/action';
import { useInjectSaga, useInjectReducer } from '@stores';
import { makeSelectSignature } from '@modules/diamond-purchase/store/selectors';

export const useMetaMaskPayment = () => {
  useInjectSaga({ key: 'signature', saga });
  useInjectReducer({ key: 'signature', reducer });

  const { isLoading, error, signature, isSuccessGetSignature }: any = useSelector(makeSelectSignature());

  const dispatch = useDispatch();
  const paymentAction = (payload?: any) => dispatch(getSignature(payload));
  const eventTransferAction = (payload?: any) => dispatch(eventTransfer(payload));

  const resetStateAction = () => dispatch(resetState());
  return {
    isPaying: isLoading,
    isSuccessGetSignature,
    error,
    signature,
    paymentAction,
    eventTransferAction,
    resetStateAction,
  };
};
