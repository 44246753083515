/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 * @param  {state} login state
 * @param  {action} login action
 */
import { STATUS_TYPE } from '@constants';
import { FAILURE, REQUEST, SUCCESS, createReducer, updateObject } from '@stores';
import { updateReducer } from '@stores/redux';
import { Paginate } from '@type/Store/paginate';
import {
  IDonatePackage,
  LiveStream,
  Package,
  TopicData,
  TopicDetail,
  TopicResult,
  TopicsState,
  VideoComment,
  VideoPackage,
} from '@type/Store/topics';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  ERROR,
  GET_AGORA_TOKEN,
  GET_DONATE_PACKAGE,
  LIVESTREAM_DETAIL,
  LOADING,
  SEND_DONATE,
  TOPIC_CHAPTER_DETAIL,
  TOPIC_CHECK_PASSWORD,
  TOPIC_CREATE_COMMENT,
  TOPIC_DELETE_COMMENT,
  TOPIC_DETAIL,
  TOPIC_EDIT_COMMENT,
  TOPIC_LIST,
  TOPIC_PACKAGE_DETAIL,
  TOPIC_TOP,
  TOPIC_VIDEO_COMMENT,
  TOPIC_VIDEO_PACKAGE,
} from './constants';

export const initialState: TopicsState = {
  topicList: {
    data: [],
    paginate: {
      per_page: 12,
      prev: null,
      next: 1,
      last_page: 1,
      current_page: 1,
      total: 0,
    },
  },
  topicDetail: {
    account_type: '',
    chapters: [],
    favorite: false,
    id: 0,
    images: '',
    is_active: 0,
    is_living: false,
    public_end: null,
    public_start: null,
    publish_status: null,
    title: null,
  },
  topicTop: {
    account_type: '',
    chapters: [],
    favorite: false,
    id: 0,
    images: '',
    is_active: 0,
    is_living: false,
    public_end: null,
    public_start: null,
    publish_status: null,
    title: null,
  },
  password: {
    chapter_id: 0,
    status: false,
  },
  chapter: {
    amount: 0,
    chapter_fee_type: 0,
    chapter_status: 0,
    chapter_type: 0,
    description: null,
    duration: 0,
    id: 0,
    live_key: null,
    minutes_free: null,
    password_flag: 0,
    payment_status: 0,
    public_start: null,
    public_end: null,
    public_start_real: null,
    public_end_real: null,
    thumbnail: null,
    title: '',
    topic_id: 0,
    price: 0,
  },
  comments: {
    data: null,
    error: null,
    isLoading: false,
    page: 1,
    status: STATUS_TYPE.REQUEST,
  },
  commentStatus: {
    data: null,
    error: null,
    isLoading: false,
    status: STATUS_TYPE.REQUEST,
    paginate: null,
  },
  editStatus: {
    error: null,
    isLoading: false,
    status: STATUS_TYPE.REQUEST,
  },
  liveStream: {
    data: null,
    error: null,
    isLoading: false,
    status: STATUS_TYPE.REQUEST,
    roomToken: '',
  },
  donatePackage: {
    data: [],
    error: null,
    isLoading: false,
  },
  donate: {
    data: null,
    error: null,
    isLoading: false,
    status: STATUS_TYPE.REQUEST,
  },
  videoPackage: {
    data: null,
    error: null,
    isLoading: false,
    page: 1,
    status: STATUS_TYPE.REQUEST,
  },
  packageDetail: {
    description: '',
    diamond_price: 0,
    end_at: null,
    id: 0,
    payment_status: 0,
    purchase_type: 0,
    start_at: null,
    thumbnail: '',
    title: '',
    topic_description: '',
    topic_id: 0,
    topic_thumbnail: '',
    topic_title: '',
    video_package: [],
    yen_price: null,
  },
  loading: false,
  error: null,
};

function setLoading(state: TopicsState, { payload }) {
  return updateReducer({
    ...state,
    loading: payload,
  });
}

function setError(state: TopicsState, { payload }) {
  return updateReducer({
    ...state,
    error: payload,
  });
}

function getTopicListSuccess(state: TopicsState, { payload }: { payload: { data: TopicData; paginate: Paginate } }) {
  return updateObject(state, {
    topicList: {
      ...state.topicList,
      ...payload,
    },
  });
}

function getTopic(state) {
  return updateReducer<TopicsState>({
    ...state,
    password: initialState.password,
  });
}

function getTopicSuccess(state, { payload }: { payload: { data: TopicDetail } }) {
  return updateReducer<TopicsState>({
    ...state,
    topicDetail: payload.data,
  });
}

function getTopicTopSuccess(state, { payload }: { payload: { data: TopicDetail } }) {
  return updateReducer<TopicsState>({
    ...state,
    topicTop: payload.data,
  });
}

// post check password
function checkPassword(state: TopicsState) {
  return updateReducer<TopicsState>({
    ...state,
    password: initialState.password,
  });
}

function checkPasswordSuccess(state: TopicsState, { payload }: { payload: number }) {
  return updateReducer<TopicsState>({
    ...state,
    password: {
      status: false,
      chapter_id: payload,
    },
  });
}

function getLiveStreamDetail(state: TopicsState) {
  return updateReducer<TopicsState>({
    ...state,
    liveStream: {
      data: null,
      error: null,
      isLoading: true,
      status: STATUS_TYPE.REQUEST,
      roomToken: '',
    },
  });
}

function getLiveStreamDetailSuccess(
  state: TopicsState,
  { data, roomToken }: { data: LiveStream | null; roomToken: string },
) {
  return updateReducer<TopicsState>({
    ...state,
    liveStream: {
      data,
      error: null,
      isLoading: false,
      status: STATUS_TYPE.SUCCESS,
      roomToken,
    },
  });
}

function getLiveStreamDetailFailure(state: TopicsState, { error }) {
  return updateReducer<TopicsState>({
    ...state,
    liveStream: {
      data: null,
      error,
      isLoading: false,
      status: STATUS_TYPE.FAIL,
      roomToken: '',
    },
  });
}

function getAgoraToken(state: TopicsState) {
  return updateReducer<TopicsState>({
    ...state,
    liveStream: {
      ...state.liveStream,
      error: null,
      isLoading: true,
      status: STATUS_TYPE.REQUEST,
    },
  });
}

function getAgoraTokenSuccess(state: TopicsState, { roomToken }: { roomToken: string }) {
  return updateReducer<TopicsState>({
    ...state,
    liveStream: {
      ...state.liveStream,
      isLoading: false,
      status: STATUS_TYPE.SUCCESS,
      roomToken,
    },
  });
}

function getAgoraTokenFailure(state: TopicsState, { error }) {
  return updateReducer<TopicsState>({
    ...state,
    liveStream: {
      ...state.liveStream,
      isLoading: false,
      status: STATUS_TYPE.FAIL,
      roomToken: '',
      error,
    },
  });
}

function getDonatePackage(state: TopicsState) {
  return updateReducer<TopicsState>({
    ...state,
    donatePackage: {
      ...state.donatePackage,
      error: null,
      isLoading: true,
    },
  });
}

function getDonatePackageSuccess(state: TopicsState, { data }: { data: IDonatePackage[] }) {
  return updateReducer<TopicsState>({
    ...state,
    donatePackage: {
      data,
      error: null,
      isLoading: false,
    },
  });
}

function getDonatePackageFailure(state: TopicsState, { error }) {
  return updateReducer<TopicsState>({
    ...state,
    donatePackage: {
      ...state.donatePackage,
      error,
      isLoading: true,
    },
  });
}

function sendDonate(state: TopicsState) {
  return updateReducer<TopicsState>({
    ...state,
    donate: {
      ...state.donate,
      isLoading: true,
      error: null,
      status: STATUS_TYPE.REQUEST,
    },
  });
}

function sendDonateSuccess(state: TopicsState) {
  return updateReducer<TopicsState>({
    ...state,
    donate: {
      ...state.donate,
      isLoading: true,
      error: null,
      status: STATUS_TYPE.SUCCESS,
    },
  });
}

function sendDonateFailure(state: TopicsState, { error }) {
  return updateReducer<TopicsState>({
    ...state,
    donate: {
      ...state.donate,
      isLoading: true,
      error,
      status: STATUS_TYPE.FAIL,
    },
  });
}

function getVideoComment(state: TopicsState) {
  return updateObject(state, {
    comments: {
      ...state.comments,
      isLoading: true,
      error: null,
      status: STATUS_TYPE.REQUEST,
    },
  });
}

function getVideoCommentSuccess(
  state: { comments: TopicResult<VideoComment[]> },
  { payload }: { payload: { data: VideoComment[]; paginate: Paginate } },
) {
  return updateObject(state, {
    comments: {
      data:
        payload.paginate.current_page === 1
          ? payload.data
          : [...(state?.comments?.data || []), ...(payload?.data || [])],
      isLoading: false,
      error: null,
      paginate: payload.paginate,
      status: STATUS_TYPE.SUCCESS,
    },
  });
}

function getVideoCommentFailure(state: TopicsState, { error }) {
  return updateObject(state, {
    comments: {
      ...state.comments,
      isLoading: false,
      error,
      status: STATUS_TYPE.FAIL,
    },
  });
}

function createComment(state: TopicsState) {
  return updateObject(state, {
    commentStatus: {
      isLoading: true,
      error: null,
      status: STATUS_TYPE.REQUEST,
    },
  });
}

function createCommentSuccess(state: TopicsState) {
  return updateObject(state, {
    commentStatus: {
      isLoading: false,
      error: null,
      status: STATUS_TYPE.SUCCESS,
    },
  });
}

function createCommentFailure(state: TopicsState) {
  return updateObject(state, {
    commentStatus: {
      isLoading: false,
      error: null,
      status: STATUS_TYPE.FAIL,
    },
  });
}

function getChapterDetail(state: TopicsState) {
  return updateObject(state, {
    videoInfo: {
      isLoading: true,
      error: null,
      status: STATUS_TYPE.REQUEST,
    },
  });
}

function getChapterDetailSuccess(state: TopicsState, { payload }: { payload: { data: TopicData } }) {
  return updateReducer({
    ...state,
    chapter: payload.data,
  });
}

function editComment(state: TopicsState) {
  return updateObject(state, {
    editStatus: {
      isLoading: true,
      error: null,
      status: STATUS_TYPE.REQUEST,
    },
  });
}

function editCommentSuccess(state: TopicsState, { payload }: { payload: { data: { id: number; content: string } } }) {
  const arrState = state?.comments?.data ?? [];
  const dataPayload = payload?.data;

  const index = arrState?.findIndex((comment) => comment?.id === dataPayload?.id);
  if (index !== -1) {
    arrState[index].content = dataPayload.content;
  }

  return updateReducer({
    ...state,
    editStatus: {
      isLoading: false,
      error: null,
      status: STATUS_TYPE.SUCCESS,
    },
    comments: {
      ...state.comments,
      isLoading: true,
      error: null,
      test: false,
      comments: arrState,
    },
  });
}

function editCommentFailure(state: TopicsState) {
  return updateObject(state, {
    editStatus: {
      isLoading: false,
      error: null,
      status: STATUS_TYPE.FAIL,
    },
  });
}

function deleteComment(state: TopicsState) {
  return updateReducer({
    ...state,
    editStatus: {
      isLoading: true,
      error: null,
      status: STATUS_TYPE.REQUEST,
    },
  });
}

function deleteCommentSuccess(state: TopicsState) {
  return updateReducer({
    ...state,
    editStatus: {
      isLoading: false,
      error: null,
      status: STATUS_TYPE.SUCCESS,
    },
  });
}

function deleteCommentFailure(state: TopicsState) {
  return updateReducer({
    ...state,
    editStatus: {
      isLoading: false,
      error: null,
      status: STATUS_TYPE.FAIL,
    },
  });
}

function getVideoPackage(state: TopicsState) {
  return updateObject(state, {
    videoPackage: {
      isLoading: true,
      error: null,
      status: STATUS_TYPE.REQUEST,
    },
  });
}

function getVideoPackageSuccess(
  state: TopicsState,
  { payload }: { payload: { data: VideoPackage[]; paginate: Paginate } },
) {
  return updateObject(state, {
    videoPackage: {
      ...state.videoPackage,
      isLoading: false,
      error: null,
      status: STATUS_TYPE.SUCCESS,
      data: payload.data,
      paginate: payload.paginate,
    },
  });
}

function getVideoPackageFailure(state: TopicsState, { error }) {
  return updateObject(state, {
    videoPackage: {
      ...state,
      isLoading: false,
      error,
      status: STATUS_TYPE.FAIL,
    },
  });
}

function getPackageDetailSuccess(state: TopicsState, { payload }: { payload: Package }) {
  return updateReducer<TopicsState>({
    ...state,
    packageDetail: payload,
  });
}

function resetState(state: TopicsState) {
  return updateReducer({
    ...state,
    chapter: initialState.chapter,
    liveStream: initialState.liveStream,
    donate: initialState.donate,
    comments: initialState.comments,
    packageDetail: initialState.packageDetail,
  });
}
// end

// Slice reducer
export default createReducer(initialState, {
  [SUCCESS(TOPIC_LIST)]: getTopicListSuccess,

  [REQUEST(TOPIC_DETAIL)]: getTopic,
  [SUCCESS(TOPIC_DETAIL)]: getTopicSuccess,

  [REQUEST(TOPIC_TOP)]: getTopic,
  [SUCCESS(TOPIC_TOP)]: getTopicTopSuccess,

  [REQUEST(TOPIC_CHECK_PASSWORD)]: checkPassword,
  [SUCCESS(TOPIC_CHECK_PASSWORD)]: checkPasswordSuccess,

  [REQUEST(LIVESTREAM_DETAIL)]: getLiveStreamDetail,
  [SUCCESS(LIVESTREAM_DETAIL)]: getLiveStreamDetailSuccess,
  [FAILURE(LIVESTREAM_DETAIL)]: getLiveStreamDetailFailure,

  [REQUEST(GET_AGORA_TOKEN)]: getAgoraToken,
  [SUCCESS(GET_AGORA_TOKEN)]: getAgoraTokenSuccess,
  [FAILURE(GET_AGORA_TOKEN)]: getAgoraTokenFailure,

  [REQUEST(GET_DONATE_PACKAGE)]: getDonatePackage,
  [SUCCESS(GET_DONATE_PACKAGE)]: getDonatePackageSuccess,
  [FAILURE(GET_DONATE_PACKAGE)]: getDonatePackageFailure,

  [REQUEST(SEND_DONATE)]: sendDonate,
  [SUCCESS(SEND_DONATE)]: sendDonateSuccess,
  [FAILURE(SEND_DONATE)]: sendDonateFailure,

  [REQUEST(TOPIC_VIDEO_COMMENT)]: getVideoComment,
  [SUCCESS(TOPIC_VIDEO_COMMENT)]: getVideoCommentSuccess,
  [FAILURE(TOPIC_VIDEO_COMMENT)]: getVideoCommentFailure,

  [REQUEST(TOPIC_CREATE_COMMENT)]: createComment,
  [SUCCESS(TOPIC_CREATE_COMMENT)]: createCommentSuccess,
  [FAILURE(TOPIC_CREATE_COMMENT)]: createCommentFailure,

  [REQUEST(TOPIC_CHAPTER_DETAIL)]: getChapterDetail,
  [SUCCESS(TOPIC_CHAPTER_DETAIL)]: getChapterDetailSuccess,

  [REQUEST(TOPIC_EDIT_COMMENT)]: editComment,
  [SUCCESS(TOPIC_EDIT_COMMENT)]: editCommentSuccess,
  [FAILURE(TOPIC_EDIT_COMMENT)]: editCommentFailure,

  [REQUEST(TOPIC_DELETE_COMMENT)]: deleteComment,
  [SUCCESS(TOPIC_DELETE_COMMENT)]: deleteCommentSuccess,
  [FAILURE(TOPIC_DELETE_COMMENT)]: deleteCommentFailure,

  [REQUEST(TOPIC_VIDEO_PACKAGE)]: getVideoPackage,
  [SUCCESS(TOPIC_VIDEO_PACKAGE)]: getVideoPackageSuccess,
  [FAILURE(TOPIC_VIDEO_PACKAGE)]: getVideoPackageFailure,

  [SUCCESS(TOPIC_PACKAGE_DETAIL)]: getPackageDetailSuccess,

  [REQUEST(LOADING)]: setLoading,
  [REQUEST(ERROR)]: setError,
  [LOCATION_CHANGE]: resetState,
});
