import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import ConfirmComponent from '@components/modal/confirmModal';
import { RoutesName } from '@modules/auth/routes';
import ButtonComponent from '@components/button';
import { FormInput, FormPassword, FormLabel } from '@components';
import { useAuth } from '@hooks';
import { Wrapper, InputLogin } from '@themes/facit';
import { ResetPwScheme } from '@utils/messageValdate';
import ResetPwForm from '@type/Form/ResetPwForm';
import { LOGO_LOGIN } from '@assets';
import { Button } from 'antd';
import { isMobile, isTablet } from 'react-device-detect';
import { translations } from '@i18n/translations';
import { Row, Title } from './styled';

const ResetPasswordScreen = () => {
  const { t } = useTranslation();
  const form = useForm<ResetPwForm>({
    defaultValues: {
      authenticationCode: '',
      password: '',
      retypeNewPassword: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(ResetPwScheme(t)),
  });
  const { common } = translations;
  const { handleSubmit, watch } = form;
  const { resetPasswordAction, email, error, resetPwStatus, isLoading } = useAuth();
  const [failurePaymentVisible, setIsfailurePaymentVisible] = useState(false);
  const [successPaymentVisible, setIsSuccessPaymentVisible] = useState(false);
  const onSubmit = useCallback(
    (data) => {
      resetPasswordAction({
        password: data.password,
        email,
        authenticationCode: data.authenticationCode,
      });
    },
    [resetPasswordAction, email],
  );
  const resetPasswordOk = () => {
    window.location.replace(RoutesName.LOGIN);
  };
  useEffect(() => {
    if (resetPwStatus) {
      if (resetPwStatus.message && error?.message) {
        setIsfailurePaymentVisible(true);
      } else {
        setIsfailurePaymentVisible(false);
      }

      if (resetPwStatus.status === 200) {
        setIsSuccessPaymentVisible(true);
      }
    }
  }, [resetPwStatus, error]);
  const watchShowAge = watch();
  const watchAuCd = watchShowAge.authenticationCode;
  const watchPw = watchShowAge.password;
  const watchRetryPw = watchShowAge.retypeNewPassword;

  const failurePaymentConfirm = () => {
    setIsfailurePaymentVisible(false);
  };

  return (
    <Wrapper>
      <div className="form-login">
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)} encType="application/x-www-form-urlencoded">
            <div className="logo-header">
              {' '}
              <LOGO_LOGIN />
            </div>
            <div className="label-header">
              <label>{t(common.password_reset)}</label>
            </div>
            <ConfirmComponent
              visible={failurePaymentVisible}
              cancel={false}
              confirmClick={failurePaymentConfirm}
              subText={t(`api_error.${error?.message}`)}
              statusConfirm={false}
            />
            <Row className="form-controls-group">
              <Title>
                <FormLabel title={t(common.authentication_code)} star="*" />
              </Title>
              <InputLogin>
                <FormInput name="authenticationCode" placeholder={t(common.placeholder_authentication_code)} />
              </InputLogin>
            </Row>
            <Row className="form-controls-group">
              <Title>
                <FormLabel title={t(common.new_password_lable)} star="*" />
              </Title>
              <InputLogin>
                <FormPassword name="password" placeholderValue={t(common.placeholder_new_password_lable)} />
              </InputLogin>
            </Row>
            <Row className="form-controls-group">
              <Title>
                <FormLabel title={t(common.retype_new_password_lable)} star="*" />
              </Title>
              <InputLogin>
                <FormPassword
                  name="retypeNewPassword"
                  placeholderValue={t(common.placeholder_retype_new_password_lable)}
                />
              </InputLogin>
            </Row>
            <div className="form-action-group">
              {isMobile || isTablet ? (
                <Button
                  className="btn-login"
                  id="btn-Reset-Pw btn-Reset-Pw-Disable"
                  htmlType="submit"
                  style={
                    !watchAuCd || !watchPw || !watchRetryPw
                      ? {
                          opacity: 0.5,
                          margin: '24px auto 0',
                          display: 'block',
                          background: '#E3007F',
                          color: '#FFFFFF',
                        }
                      : {
                          margin: '24px auto 0',
                          display: 'block',
                          background: '#E3007F',
                          color: '#FFFFFF',
                        }
                  }
                  disabled={!watchAuCd || !watchPw || !watchRetryPw}
                >
                  {t(common.change_reset_password)}
                </Button>
              ) : (
                <ButtonComponent
                  type="default"
                  loading={isLoading}
                  className="btn-login"
                  id="btn-Reset-Pw"
                  htmlType="submit"
                  style={{
                    margin: '24px auto 0',
                    display: 'block',
                  }}
                  disabled={!watchAuCd || !watchPw || !watchRetryPw}
                >
                  {t(common.change_reset_password)}
                </ButtonComponent>
              )}
            </div>
            <ConfirmComponent
              visible={successPaymentVisible}
              cancel={false}
              confirmClick={resetPasswordOk}
              textContent={t(common.message.verification_success)}
              subTextContent={t(common.message.proceed_to_login)}
            />
          </form>
        </FormProvider>
      </div>
    </Wrapper>
  );
};

export default ResetPasswordScreen;
