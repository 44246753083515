import { Typography } from 'antd';
import styled from 'styled-components';

import Media from '@themes/media';

export const BoxPaymentStyled = styled.div`
  background-color: #ffffff;
  min-width: 720px;
  border-radius: 20px;
  margin-bottom: 150px;
  font-weight: 500;
  .box-top {
    display: flex;
    align-items: center;
    padding: 30px;
  }

  .box-bottom {
    border-top: 1px solid #f0f2f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  ${Media.lessThan(Media.SIZE.MD)} {
    min-width: 320px;
  }

  ${Media.lessThan(Media.SIZE.MD)} {
    min-width: 320px;
    margin-bottom: 32px;

    .box-bottom {
      padding: 0px 16px;
    }

    .box-top {
      padding: 16px;
    }
  }
`;

export const TextBackStyled = styled(Typography)<{ cursor: 'pointer' | 'default' }>`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  margin-left: 10px;
  cursor: ${({ cursor }) => cursor};
  font-family: 'Noto Sans JP';
  color: #282727;
  ${Media.lessThan(Media.SIZE.MD)} {
    font-size: 16px;
  }
`;
