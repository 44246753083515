export const STATUS = {
  ACTIVE: 1,
  SHOW: 2,
  FINISHED: 3,
  CANCELED: 4,
};

export const PAYMENT_UNIT = {
  YEN: 1,
  COIN: 2,
  DIAMOND: 3,
};

export const PAYMENT_METHOD = {
  CREDIT_CARD: 1,
  KONBINI: 2,
  COIN: 3,
  DIAMOND: 4,
  NOT_PURCHASE: null,
};

export const PAYMENT_STATUS = {
  SUCCESS: 1,
  FAIL: 2,
  PRE_ORDER: 3, //
  PROCESSING: 4,
  REFUND: 5,
};

export const PRE_ORDER = {
  YES: 1,
  NO: 0,
};

export const SALE_TYPE = {
  PRE_ORDER: 2,
  NORMAL: 1,
};

export const TYPE_LIST_EVENT = {
  LIST_EVENT: 1,
  LIST_EVENT_CLOSED: 2,
};

export const LIST_EVENT_SORT_BY = {
  LASTEST: 'latest',
  OLDEST: 'oldest',
};

export enum STATUS_PAYMENT_COIN {
  ENOUGH = 'ENOUGH',
  NOT_ENOUGH = 'NOT_ENOUGH',
  CONFIRM = 'CONFIRM',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  LOADING = 'LOADING',
}

export const ERROR_MESSAGE_REAL_EVENT_PURCHASE = {
  TICKET_SOLD_OUT: 'TICKET_SOLD_OUT',
  REAL_EVENT_NOT_FOUND: 'REAL_EVENT_NOT_FOUND',
  REAL_EVENT_CANCEL: 'REAL_EVENT_CANCEL',
};

export const KOBINI_STATUS = {
  SEVEN_ELEVEN: '00007',
  LAWSON: '10001',
  FAMILY_MART: '10002',
  MINISTOP: '10005',
  SEIKO_MART: '10008',
};
