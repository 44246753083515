/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import moment from 'moment-timezone';
import { DIAMOND } from '@assets';
import { useTranslation } from 'react-i18next';
import { isTablet } from 'react-device-detect';
import { Pagination, ConfigProvider } from 'antd';
import { numberWithCommas } from '@utils/number';
import { Wrapper } from './styled';

interface IProps {
  data: any;
  total: number;
  pageSize: number | undefined;
  languagePage: any;
  handleChangePage: any;
}

const convertToCurrentTime = (dayConvert = '', format = 'YYYY/MM/DD HH:mm') => {
  const currentZone = moment.tz.guess();
  const m = moment.tz(dayConvert, 'Asia/Tokyo');
  return m.tz(currentZone).format(format);
};

const Transaction: React.FC<IProps> = ({ data, total, pageSize, languagePage, handleChangePage }) => {
  const { t } = useTranslation(['common', 'error_message']);
  return (
    <>
      <Wrapper>
        {data &&
          data.length > 0 &&
          data.map((item: any) => (
            <div className="history-content" key={item.id}>
              <div className="history-title">
                <label className="history-label">{t('text_diamon_purchase')}</label>
                <label className="history-time">{convertToCurrentTime(item.created_at)}</label>
              </div>
              <div className="history-diamond">
                {item.status !== 1 ? (
                  <div className="status-warning">{t('transaction_failed')}</div>
                ) : (
                  <div className="amount-diamond-history">
                    <img
                      className="diamond-icon-history"
                      src={DIAMOND}
                      alt="diamond"
                      style={
                        isTablet
                          ? {
                              width: '22px',
                              height: '18px',
                            }
                          : {}
                      }
                    />
                    &ensp;
                    <span />
                    {numberWithCommas(item.amount)}
                  </div>
                )}
              </div>
            </div>
          ))}
      </Wrapper>
      {data && data.length && data.length < total && (
        <ConfigProvider locale={languagePage}>
          <Pagination
            pageSize={pageSize}
            total={total}
            onChange={handleChangePage}
            size="small"
            style={{ paddingTop: '0px' }}
            showSizeChanger={false}
          />
        </ConfigProvider>
      )}
    </>
  );
};

export default Transaction;
