import React, { useEffect } from 'react';
import QRCode from 'react-qr-code';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { FORMAT_TIME } from '@constants';
import { timeStampToDate } from '@utils/time';

import { PAYMENT_STATUS } from '@constants/my-ticket';
import { numberWithCommas } from '@utils/number';
import { COIN_ICON } from '@assets';
import { useHistories } from '@hooks/hisories';
import { ModalQrStyled, SpinStyled } from './styled';
import PaymentStatus from '../payment-status';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  serieCode: number;
  yenPrice?: string | null;
  coinPrice?: string | null;
}

const { Text } = Typography;

const ModalQr: React.FC<Props> = ({ onClose, isOpen, serieCode, coinPrice, yenPrice }) => {
  const { t } = useTranslation();
  const { getQRDetail, qrDetail, isLoading } = useHistories();

  useEffect(() => {
    if (serieCode) {
      getQRDetail({ seriesCode: serieCode });
    }
  }, [serieCode]);
  const renderPrice = () => {
    if (coinPrice && yenPrice) {
      return (
        <Typography>
          <span style={{ color: '#10AB52' }}>
            <COIN_ICON style={{ marginRight: '5px' }} />
            {numberWithCommas(Number(coinPrice), ',')}
          </span>
          <span>/</span>
          <span style={{ color: 'rgb(227, 0, 127)' }}>￥ {numberWithCommas(Number(yenPrice || 0), ',')}</span>
        </Typography>
      );
    }
    if (!coinPrice && yenPrice) {
      return (
        <Typography>
          <span style={{ color: 'rgb(227, 0, 127)' }}>￥ {numberWithCommas(Number(yenPrice || 0), ',')}</span>
        </Typography>
      );
    }
    if (coinPrice && !yenPrice) {
      return (
        <Typography>
          <span style={{ color: '#10AB52' }}>
            <COIN_ICON style={{ marginRight: '5px' }} />
            {numberWithCommas(Number(coinPrice), ',')}
          </span>
        </Typography>
      );
    }
    return null;
  };

  return (
    <ModalQrStyled
      width="auto"
      footer={false}
      open={isOpen}
      onCancel={onClose}
      centered
      maskStyle={{ backgroundColor: 'rgb(0 0, 0, 0.89)' }}
    >
      <SpinStyled spinning={isLoading}>
        <div className="box-left">
          <Typography className="text-message">{t('myTicket:my_ticket_detail.message_qr_detail')}</Typography>
          <QRCode value={serieCode?.toString() || ''} style={{ width: '254px', height: '254px' }} />
          <Typography className="label">{qrDetail?.ticket_name}</Typography>
          <Typography>
            {t('myTicket:ticketReceiptCode')}: <Text className="serie-text">{serieCode}</Text>
          </Typography>
          {!!qrDetail.ticket_receipt_code && (
            <Typography>
              {t('myTicket:my_ticket_detail.serial_number')}:{' '}
              <Text className="serie-text">{qrDetail.ticket_receipt_code || ''}</Text>
            </Typography>
          )}
          {qrDetail.payment_status === PAYMENT_STATUS.PRE_ORDER && renderPrice()}

          <Typography>
            <PaymentStatus status={qrDetail.payment_status} />
          </Typography>
        </div>
        <div className="box-right">
          <Typography className="name-event-text">{qrDetail.real_event_name}</Typography>
          {!!qrDetail.address && (
            <Typography>
              <Text className="label-address">{t('myTicket:my_ticket_detail.address')} :</Text>
              <span>{` ${qrDetail.address}`}</span>
            </Typography>
          )}
          <Typography className="time-text">
            {timeStampToDate(Number(qrDetail.start_at), FORMAT_TIME.DATE_HOUR_MINUTES)}
          </Typography>

          {qrDetail.checkin_status === 1 && (
            <Typography className="checked-text">{t('myTicket:my_ticket_detail.already_checked')}</Typography>
          )}
        </div>
      </SpinStyled>
    </ModalQrStyled>
  );
};

export default ModalQr;
