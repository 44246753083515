import React, { useState } from 'react';
import Paragraph from 'antd/lib/typography/Paragraph';
import { translations } from '@i18n/translations';
import { useTranslation } from 'react-i18next';

interface IProps {
  content: string;
  className?: string;
  rows?: number;
}
const Seemore: React.FC<IProps> = ({ content, className = '', rows = 2 }) => {
  const { t } = useTranslation();
  const [expandable, setExpandable] = useState(true);
  const { common } = translations;

  return (
    <>
      {!expandable && (
        <Paragraph className={className} ellipsis={false}>
          {content}
          <span
            onClick={(e) => {
              e.stopPropagation();
              setExpandable(true);
            }}
            className="ant-typography-expand"
          >
            {t(common.see_less)}
          </span>
        </Paragraph>
      )}
      {expandable && (
        <Paragraph
          className={className}
          ellipsis={{
            rows,
            expandable: true,
            symbol: t(common.see_more),
            onExpand: (e) => {
              e.stopPropagation();
              setExpandable(false);
            },
          }}
        >
          {content}
        </Paragraph>
      )}
    </>
  );
};
export default Seemore;
