import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';
import Image from '@components/imageFallback';
import { Tag } from 'antd';
import { convertToCurrentTimeZone } from '@utils/time';
import { FORMAT_TIME, PACKAGE_FEE_TYPE, PAYMENT_STATUS } from '@constants';
import { DIAMOND } from '@assets';
import { VideoPackage } from '@type/Store/topics';
import { useHistory } from 'react-router-dom';
import { useAuth } from '@hooks';
import { numberWithCommas } from '@utils/number';
import { ImageChapters, DateTime, Wrapper, Info, LeftInfo, RightInfo } from './styled';

interface Props {
  video: VideoPackage;
}
export const VideoPackageComponent: React.FC<Props> = ({ video }) => {
  const { t } = useTranslation();
  const { topic, topic_payment } = translations;
  const history = useHistory();
  const { isUser, authenticated } = useAuth();

  const startTime = useMemo(() => {
    if (video.start_at) {
      return convertToCurrentTimeZone(Number(video?.start_at) * 1000 || '', FORMAT_TIME.FULL_DATE);
    }

    return '';
  }, [video]);

  const endTime = useMemo(() => {
    if (video.end_at) {
      return ` ~ ${convertToCurrentTimeZone(Number(video?.end_at) * 1000 || '', FORMAT_TIME.FULL_DATE)}`;
    }
    return '';
  }, [video]);

  const renderPrice = (video: VideoPackage) => {
    if (Number(video?.diamond_price) === 0) return <div className="some-free">{t(topic?.free)}</div>;
    return (
      <div className="wrapper-price">
        {video?.yen_price ? (
          <span className="yen-price">
            ￥{numberWithCommas(Number(video?.yen_price), ',')} <span className="slash">/</span>{' '}
          </span>
        ) : (
          <span />
        )}
        <span className="price">
          <div className="content">
            <img src={DIAMOND} alt="diamond" width={13} />
            <span>{numberWithCommas(Number(video?.diamond_price), ',')}</span>
          </div>
        </span>
      </div>
    );
  };

  const handleRenderPackage = (video: VideoPackage) => {
    if (video.payment_status === PAYMENT_STATUS.SUCCEEDED) {
      return <Tag className="tag-topic">{t(topic.purchased)}</Tag>;
    }
    if (video.payment_status === PAYMENT_STATUS.PROCESSING) {
      return (
        <Tag style={{ background: '#F49A00' }} className="tag-topic">
          {t(topic_payment.processing)}
        </Tag>
      );
    }

    if (Number(video.purchase_type) === PACKAGE_FEE_TYPE.FREE) {
      return <div className="some-free">{t(topic?.free)}</div>;
    }
    if (Number(video.purchase_type) === PACKAGE_FEE_TYPE.MUST_PAID) {
      return renderPrice(video);
    }
    return <div />;
  };

  const handleOnClickedVideo = () => {
    const currentPath = history.location.pathname;
    const newPath = `${currentPath}/video-package/${video.id}`;
    history.push(newPath);
  };
  return (
    <Wrapper onClick={handleOnClickedVideo}>
      <ImageChapters>{video?.thumbnail && <Image src={video?.thumbnail} alt="Image topic" />}</ImageChapters>
      <Info>
        <LeftInfo>
          <DateTime>
            <div className="date">
              {startTime}
              {endTime}
            </div>
          </DateTime>
          <div className="chapter-content">{video.title}</div>
          {/* <div className="time">{video?.start_at && convertSecondsToHMS_V2(Number(video?.start_at) * 1000)}</div> */}
        </LeftInfo>
        <RightInfo>
          <div className="hide" style={{ height: 22 }} />
          {(isUser || !authenticated) && <div>{handleRenderPackage(video)}</div>}
        </RightInfo>
      </Info>
    </Wrapper>
  );
};
