const END_POINT = {
  TEST_API: 'api/0.4/',

  // auth
  LOGIN: '/api/login',
  REGISTER: '/api/v2/register',
  VERIFY_PHONE: '/api/v2/register/verify-phone',
  RESEND_CODE: '/api/v2/send-code',
  LOGOUT: '/api/logout',
  VERIFICATION: '/api/v2/reset-password',
  RESET_PASSWORD: '/api/v2/reset-password/',
  PROFILE_USER: '/profile-user',
  GET_ME: '/api/me',
  CHANGE_LANGUAGE: '/api/update-language',

  // common
  PRESIGNED: '/api/v1/file/pre-signed',

  // stripe
  PAYMENT_INTENT: '/api/verification-stripe/',
  PAYMENT_PACKAGE: '/api/check-payment-status',
  PAYMENT_LIST_PACKAGE: '/api/packages',
  PAYMENT_HISTORY: '/api/payment-history',

  // pay metamask
  SIGNATURE: '/api/package/signature',
  EVENT_TRANSFER: '/api/event-transfer',
  REAL_EVENT: '/api/real-events',
  PURCHASE: '/api/real-events/purchase',
  REAL_EVENT_PURCHASE: '/api/real-events/purchase',
  PURCHASE_CREDIT_CARD: '/api/gmo-payment/purchase',
  TOPICS: {
    LIST: '/api/v2/official-program',
    TOP: '/api/v2/topic-top',
    CHECK_PASSWORD: '/api/v2/official-program/chapter',
    LIVESTREAM: '/api/v3/livestream',
    BUY_CHAPTER: '/api/buy-chapter',
    GET_DONATE_PACKAGE: (object_id) => `/api/v2/packages?is_active=1&type=3&object_type=2&object_id=${object_id}`,
    SEND_DONATE: (liveKey) => `/api/livestream/${liveKey}/donate`,
    VIDEO_INFO: '/api/video',
    VIDEO_COMMENT: '/api/comment',
    CREATE_COMMENT: '/api/comment/create',
    CHAPTER_DETAIL: '/api/v2/official-program/chapter',
    EDIT_COMMENT: '/api/comment/update',
    DELETE_COMMENT: '/api/comment/delete',
    GET_FREE_POINT: (liveKey) => `/api/v2/livestream/${liveKey}/get-free-point`,
    TWEETS: `/api/x/tweets`,
    SIGN_IN_TWITTER: '/api/social-login',
    SIGN_IN_TWITTER_CALLBACK: '/api/social-login/callback',
    VIDEO_PACKAGE: (topicId) => `/api/v2/official-program/${topicId}/packages`,
    PACKAGE_DETAIL: (topicId, packageId) => `/api/v3/official-program/${topicId}/packages/${packageId}`,
    PACKAGE_LIST: (topicId, packageId) => `/api/v2/official-program/${topicId}/packages/${packageId}/video`,
    BUY_VIDEO_PACKAGE: (topicId, packageId) => `/api/v2/official-program/${topicId}/packages/${packageId}/buy`,
    BUY_CHAPTER_PAYMENT: (topicId, packageId) => `api/topics/${topicId}/chapters/${packageId}/purchase`,
    BUY_PACKAGE_PAYMENT: (topicId, packageId) => `api/topics/${topicId}/packages/${packageId}/purchase`,
    AGORA_TOKEN: '/api/agora_token',
  },
  HISTORY: {
    TICKETS: '/api/real-events/purchase-histories',
    TOPICS: '/api/official-program/purchase-histories',
    TOPIC_PURCHASE: '/api/official-program/purchase-histories',
    TICKET_PURCHASE: '/api/real-events/purchase-histories',
    QR_DETAIL: '/api/real-events/tickets',
  },
};

export default END_POINT;
