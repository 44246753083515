import React, { useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

import { TextInput, Button } from '@components';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { useAuth, useEvents, useRealEventPayment } from '@hooks';
import { STICK } from '@assets';
import { yupResolver } from '@hookform/resolvers/yup';
import { translations } from '@i18n/translations';
import { useTranslation } from 'react-i18next';
import { ERROR_MESSAGE_REAL_EVENT_PURCHASE } from '@constants/real-event';
import useExitPrompt from '@hooks/useExitPrompt';
import { convertStringToHalfWidth } from '@utils/string';
import { isMobile } from 'react-device-detect';
import Header from '../components/header';
import { FormWrapper, KobiniListWrapper, Form, Wrapper, KobiniItem, KobiniLogo } from '../styled';
import { FORM_KONBINI, KOBINI_LIST } from '../constants';
import { ContentStyled } from '../coin/styled';
import BoxPayment from '../coin/components/box-payment';
import PaymentConfirm from '../components/payment-confirm';
import PaymentCoin from '../coin/components/payment-coin-fail';
import scheme from './schema';
import KobiniSuccess from '../components/kobini-paid';
import { ModalStyled, TextComfirmStyled } from '../components/payment-confirm/styled';

interface FormValues {
  email?: string;
  phonenumber: string;
  firstKana: string;
  lastKana: string;
  firstKanji: string;
  lastKanji: string;
  lastName: string;
  firstName: string;
}
interface Params {
  id?: string;
}
interface Convenience {
  src: string;
  label: string;
  value: string;
}
type FieldName = keyof FormValues;
type PaymentStatus = 'INPUT_PAYMENT' | 'CONFIRM_PAYMENT' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILURE';
export const KonbiniPaymentScreen: React.FC = () => {
  const { id } = useParams<Params>();
  const { ticketsData, eventDetail, reset, saveCurrentSelectionAction } = useEvents();

  const { profile } = useAuth();
  const { purchaseWithKobini, error, paidKobini, balanceCode, receiptNo } = useRealEventPayment();
  const history = useHistory();
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>('INPUT_PAYMENT');
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [convenience, setConvenience] = useState<Convenience | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null | undefined>('');

  const { t } = useTranslation();
  const { realEventPayment, common, error_message } = translations;
  useExitPrompt(true);

  const form = useForm({
    defaultValues: {
      email: '',
      firstKanji: '',
      lastKanji: '',
      firstKana: '',
      lastKana: '',
      phonenumber: '',
      firstName: '',
      lastName: '',
    },
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    resolver: yupResolver(scheme),
  });
  const { handleSubmit, setValue, watch } = form;

  useEffect(() => {
    if (ticketsData?.lastKanji) {
      setValue('lastKanji', ticketsData?.lastKanji);
    }
    if (ticketsData?.firstKanji) {
      setValue('firstKanji', ticketsData?.firstKanji);
    }
    if (ticketsData?.lastKana) {
      setValue('firstKana', ticketsData?.lastKana);
    }
    if (ticketsData?.firstKana) {
      setValue('firstKana', ticketsData?.firstKana);
    }

    if (profile && profile.email) {
      setValue('email', profile.email);
    }
    if (ticketsData?.phoneNumber || profile?.phone) {
      const convertNumber = (profile?.phone || '').replace(/\D/g, '');
      const convertHaflWidth = convertStringToHalfWidth(convertNumber);
      setValue('phonenumber', ticketsData?.phoneNumber || convertHaflWidth);
    }
  }, [profile]);

  useEffect(() => {
    if (error) {
      if (
        error.message &&
        error.message.length > 0 &&
        error?.message?.[0] === ERROR_MESSAGE_REAL_EVENT_PURCHASE.TICKET_SOLD_OUT
      ) {
        setIsShowModal(true);
        setErrorMsg(t(realEventPayment.number_of_ticket_insufficent));
      } else if (
        error.message &&
        error.message.length > 0 &&
        error?.message?.[0] === ERROR_MESSAGE_REAL_EVENT_PURCHASE.REAL_EVENT_NOT_FOUND
      ) {
        setIsShowModal(true);
        setErrorMsg(t(error_message.REAL_EVENT_NOT_FOUND));
      } else if (
        error.message &&
        error.message.length > 0 &&
        error?.message?.[0] === ERROR_MESSAGE_REAL_EVENT_PURCHASE.REAL_EVENT_CANCEL
      ) {
        setIsShowModal(true);
        setErrorMsg(t(error_message.REAL_EVENT_NOT_FOUND));
      } else {
        setPaymentStatus('PAYMENT_FAILURE');
      }
    }
  }, [error]);

  useEffect(() => {
    if (paidKobini) {
      setPaymentStatus('PAYMENT_SUCCESS');
    }
  }, [paidKobini]);

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    if (ticketsData) {
      saveCurrentSelectionAction({
        ...ticketsData,
        lastKana: data.lastKana,
        firstKana: data.firstKana,
        lastName: data.lastKanji,
        firstName: data.firstKanji,
      });
    }
    setPaymentStatus('CONFIRM_PAYMENT');
  };
  const onCancel = () => {
    setIsShowModal(false);
    setErrorMsg('');
  };

  const tickets = useMemo(() => {
    if (ticketsData && ticketsData.tickets) {
      return ticketsData.tickets
        .filter((f) => !!f.quantity)
        .map((m) => ({ quantity: m.quantity, ticketId: m.ticketId, name: m.name }));
    }
    return [];
  }, [ticketsData]);

  const [firstKanji, lastKanji, firstKana, lastKana, email, phonenumber] = watch([
    'firstKanji',
    'lastKanji',
    'firstKana',
    'lastKana',
    'email',
    'phonenumber',
  ]);

  const onPurchase = () => {
    purchaseWithKobini({
      pre_order: 0,
      real_event_id: id,
      pay_unit: 1,
      payment_method: 2,
      ticket: tickets.map((m) => ({ ticket_type_id: m.ticketId, quantity: m.quantity })),
      amount: Number(ticketsData?.priceTotal || 0),
      card: {
        convenience: convenience?.value,
        email,
        phone_number: phonenumber,
        customer_name: `${firstKanji} ${lastKanji}`,
        customer_kana: `${firstKana} ${lastKana}`,
      },
      memo: ticketsData?.memo,
      address: ticketsData?.address,
      zip_code: ticketsData?.zipcode,
      phone_number: ticketsData?.phoneNumber,
      first_name: ticketsData?.firstKanji,
      last_name: ticketsData?.lastKanji,
    });
  };

  useEffect(() => {
    if (!ticketsData) {
      history.replace(`/events/${id}`);
    }
  }, [ticketsData]);

  const onNavigateError = () => {
    if (error?.message?.[0] === ERROR_MESSAGE_REAL_EVENT_PURCHASE.TICKET_SOLD_OUT) {
      history.push(`/events/${id}`);
    } else {
      reset();
      history.push('/list-event');
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, name: FieldName) => {
    const { target } = e;
    const { value } = target;
    // if (isFullWidth) {
    //   const convertFullWidth = convertStringToFullWidth(value);
    //   setValue(name, convertFullWidth);
    // } else {
    const convertHaflWidth = convertStringToHalfWidth(value || '');
    setValue(name, convertHaflWidth);
    // }
  };

  if (paymentStatus === 'CONFIRM_PAYMENT') {
    return (
      <Wrapper>
        <BoxPayment title={t(common.event.confirm_popup_title)} routeBack={`/events/${id}`} isShowIconBack>
          <ContentStyled>
            <PaymentConfirm
              totalCoinPrice={ticketsData?.priceTotal}
              eventDetail={eventDetail}
              totalTicket={Number(ticketsData?.totalTicket)}
              tickets={tickets}
              email={email}
              phonenumber={phonenumber}
              name={`${lastKanji} ${firstKanji}`}
              setPaymentStatus={setPaymentStatus}
              onConfirm={onPurchase}
              paymentMethod={t(realEventPayment.kobini.title)}
              storeName={convenience ? t(convenience?.label) : ''}
              yenFee={ticketsData?.yenFee}
              memo={ticketsData?.memo}
              nameKana={`${ticketsData?.lastKana} ${ticketsData?.firstKana}`}
              isKobining
            />
          </ContentStyled>
        </BoxPayment>
        <ModalStyled centered onCancel={onCancel} open={isShowModal} footer={false}>
          <TextComfirmStyled>{errorMsg}</TextComfirmStyled>
          <Button onClick={onNavigateError}>
            {error?.message?.[0] === ERROR_MESSAGE_REAL_EVENT_PURCHASE.TICKET_SOLD_OUT
              ? t(realEventPayment.re_select)
              : t(realEventPayment.go_to_list_event)}
          </Button>
        </ModalStyled>
        <Prompt when={!!ticketsData && !error} message={t(realEventPayment.goback)} />
      </Wrapper>
    );
  }

  if (paymentStatus === 'PAYMENT_FAILURE') {
    return (
      <Wrapper>
        <BoxPayment title={t(realEventPayment.payment_coin_fail)} isShowIconBack={false}>
          <ContentStyled>
            <PaymentCoin gmo_error_code={error?.gmo_error_code} onClick={() => history.replace(`/events/${id}`)} />
          </ContentStyled>
        </BoxPayment>
      </Wrapper>
    );
  }

  if (paymentStatus === 'PAYMENT_SUCCESS') {
    return (
      <Wrapper>
        <BoxPayment title={t(realEventPayment.kobini.purchase_completed)} isShowIconBack={false}>
          <ContentStyled>
            <KobiniSuccess
              totalPrice={ticketsData?.priceTotal}
              totalTicket={Number(ticketsData?.totalTicket)}
              tickets={tickets}
              email={email}
              phonenumber={phonenumber}
              name={`${lastKanji} ${firstKanji}`}
              onConfirm={() => {
                reset();
                history.push(`/histories/ticket/${id}/${balanceCode}`);
              }}
              receiptNo={receiptNo}
              storeName={convenience ? t(convenience?.label) : ''}
              yenFee={ticketsData?.yenFee}
            />
          </ContentStyled>
        </BoxPayment>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <FormProvider {...form}>
        <FormWrapper>
          <Header title={t(realEventPayment.kobini.title)} onClick={() => history.replace(`/events/${id}`)} />
          <Form>
            {FORM_KONBINI.map((item) => (
              <TextInput
                width={isMobile ? '100%' : '49%'}
                name={item.name}
                placeholder={item.placeholder}
                label={t(item.label)}
                inputType="required"
                key={item.name}
                translateField={realEventPayment.fieldName[item.name]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, item.name as FieldName)}
              />
            ))}
            <div style={{ fontSize: 16, fontWeight: 400, color: '#272828', marginTop: 12 }}>
              {t(realEventPayment.kobini.note)}
            </div>
          </Form>
          <KobiniListWrapper>
            {KOBINI_LIST.map((item) => (
              <KobiniItem onClick={() => setConvenience(item)}>
                <KobiniLogo>
                  <img
                    alt="ICON"
                    src={item.src}
                    style={{
                      width: 140,
                      border: convenience?.value === item.value ? '1px solid #E3007F' : '1px solid #D9D9D9',
                      borderRadius: 4,
                      padding: 1,
                    }}
                  />
                  {convenience?.value === item.value && (
                    <img
                      alt="stick"
                      src={STICK}
                      style={{ width: 18, height: 18, position: 'absolute', top: 4, right: 4 }}
                    />
                  )}
                </KobiniLogo>
                <p>{t(item.label)}</p>
              </KobiniItem>
            ))}
          </KobiniListWrapper>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 24, marginTop: 24 }}>
            <Button disabled={!convenience} onClick={handleSubmit(onSubmit)}>
              {t(realEventPayment.kobini.next)}
            </Button>
          </div>
        </FormWrapper>
      </FormProvider>
      <Prompt
        message={() => {
          return ticketsData && !error ? t(realEventPayment.goback) : true;
        }}
      />
    </Wrapper>
  );
};
