import React from 'react';
import { Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';

import { Button } from '@components';

import { numberWithCommas } from '@utils/number';
import { DIAMOND, WARNING_ICON } from '@assets';
import { Wrapper } from './styled';
import { TextCoin } from '../../styled';

interface Props {
  totalDiamond?: number;
  price: number;
}

const DiamondNotEnough: React.FC<Props> = ({ totalDiamond, price }) => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const { common, topic } = translations;
  const goToPurchase = () => push(`/diamond-purchase`);
  return (
    <Wrapper>
      <div className="box-warning">
        <div>
          {t(common.label_diamond_header)} <img src={DIAMOND} alt="diamond" width={22} />{' '}
          <span style={{ color: '#0076e3', margin: 4, fontWeight: 'bold', minWidth: 16, fontSize: 20 }}>
            {numberWithCommas(Number(totalDiamond), ',')}
          </span>
        </div>
        <br />
        <WARNING_ICON />
        <div className="box-message">
          <TextCoin className="text-coin-bottom">
            <img src={DIAMOND} alt="diamond" width={35} /> {numberWithCommas(price, ',')}
          </TextCoin>
        </div>
      </div>

      <Typography className="text-message">{t(topic.dont_enough_diamonds)}</Typography>

      <div className="box-btn">
        <Button onClick={goToPurchase}>{t(common.text_diamon_purchase)}</Button>
      </div>
    </Wrapper>
  );
};

export default DiamondNotEnough;
