import { useDispatch, useSelector } from 'react-redux';

import {
  clearCurrentSelectionTicket,
  getEndedEvents,
  getEventDetail,
  getEvents,
  getHighLightEvents,
  preOrderPurchase,
  reset,
  saveCurrentSelectionTicket,
  searchEvents,
} from '@modules/tickets-goods/list/store/action';
import reducer from '@modules/tickets-goods/list/store/reducer';
import saga from '@modules/tickets-goods/list/store/saga';
import { useInjectReducer, useInjectSaga } from '@stores';

import { makeSelectRegistrationEvent } from '@modules/tickets-goods/list/store/selector';
import { FormTicketData, payloadTicketData } from '@type/Form/BuyTicketForm';
import { EventState, PayloadListEvent, TicketsData } from '@type/Store/event';

interface ISearchParams {
  page?: string;
  perPage?: string;
  search?: string;
  sort?: 'oldest' | 'latest';
}

export const useEvents = () => {
  useInjectSaga({ key: 'event', saga });
  useInjectReducer({ key: 'event', reducer });

  const state: EventState = useSelector(makeSelectRegistrationEvent());

  const dispatch = useDispatch();

  const getHightLightEventAction = () => {
    dispatch(getHighLightEvents());
  };

  const getEventsAction = ({ perPage, page, sortBy }: PayloadListEvent) => {
    dispatch(getEvents({ perPage, page, sortBy }));
  };

  const getEndedEventsAction = ({ perPage, page, sortBy }: PayloadListEvent) => {
    dispatch(getEndedEvents({ perPage, page, sortBy }));
  };

  const getEventDetailAction = (payload: { id?: number | string | null }) => {
    dispatch(getEventDetail(payload));
  };

  const saveCurrentSelectionAction = (payload: TicketsData) => {
    dispatch(saveCurrentSelectionTicket(payload));
  };

  const preOrderPurchaseAction = (payload: FormTicketData & payloadTicketData) => {
    dispatch(preOrderPurchase(payload));
  };
  const resetAction = () => dispatch(reset());

  const clearCurrentSelectionAction = () => {
    dispatch(clearCurrentSelectionTicket());
  };

  const searchEventsAction = ({ page = '1', perPage = '15', search = '', sort = 'latest' }: ISearchParams) => {
    dispatch(
      searchEvents({
        page,
        perPage,
        search,
        sort,
      }),
    );
  };

  return {
    ...state,
    getHightLightEventAction,
    getEventsAction,
    getEndedEventsAction,
    getEventDetailAction,
    saveCurrentSelectionAction,
    clearCurrentSelectionAction,
    preOrderPurchaseAction,
    reset: resetAction,
    searchEvents: searchEventsAction,
  };
};
