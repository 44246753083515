import React from 'react';

import { EventNoDataStyled } from './styled';

interface Props {
  text: string;
}

const EventNoData: React.FC<Props> = ({ text }) => {
  return <EventNoDataStyled>{text}</EventNoDataStyled>;
};

export default EventNoData;
