import styled from 'styled-components';

import Media from '@themes/media';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 33.3333333%;
  padding: 8px;
  cursor: pointer;

  .tag-live {
    border: none;
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: ${({ theme }) => theme.bg_red};
    border-radius: 12px;
    font-size: ${({ theme }) => theme.size_10};
    font-weight: ${({ theme }) => theme.fw_700};
    color: white;
    z-index: 10;
  }

  .wrapper-price {
    justify-content: center;
    align-items: center;
    .slash {
      color: #000000;
    }
    .price {
      color: #0076e3;
      font-weight: ${({ theme }) => theme.fw_700};
      font-size: 12px;
      img {
        padding-bottom: 3px;
      }
      .content {
        display: inline-table;
        span {
          color: #0076e3;
        }
      }
    }
    .yen-price {
      font-weight: ${({ theme }) => theme.fw_700};
      font-size: 12px;
      color: #e3007f;
    }
  }

  .password {
    color: ${({ theme }) => theme.bg_red};
  }

  .some-free {
    line-height: 17px;
    letter-spacing: 0px;
    font-weight: ${({ theme }) => theme.fw_700};
    font-size: ${({ theme }) => theme.size_12};
    color: #0076e3;
  }

  .tag-topic {
    border: none;
    background-color: #e3007f;
    border-radius: 12px;
    font-weight: ${({ theme }) => theme.fw_700};
    font-size: ${({ theme }) => theme.size_10};
    color: white;
    margin: 0;
  }

  .chapter-content {
    font-size: ${({ theme }) => theme.size_16};
    color: ${({ theme }) => theme.dark};
    font-weight: ${({ theme }) => theme.fw_500};
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    ${Media.lessThan(Media.SIZE.MD)} {
      font-size: ${({ theme }) => theme.size_14};
    }
  }

  ${Media.lessThan(Media.SIZE.LG)} {
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid #d9d9d9;

    .hide {
      display: none;
      height: 0;
    }

    .tag-live {
      top: 2px;
      left: 2px;
      font-size: 8px;
      border-radius: 8px;
    }
  }
`;

export const TopListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;

  .tag-live {
    border: none;
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: ${({ theme }) => theme.bg_red};
    border-radius: 12px;
    font-size: ${({ theme }) => theme.size_10};
    font-weight: ${({ theme }) => theme.fw_700};
    color: white;
    z-index: 10;
  }

  .wrapper-price {
    justify-content: center;
    align-items: center;
    .slash {
      color: #000000;
    }
    .price {
      color: #0076e3;
      font-weight: ${({ theme }) => theme.fw_700};
      font-size: 12px;
      img {
        padding-bottom: 3px;
      }
      .content {
        display: inline-table;
        span {
          color: #0076e3;
        }
      }
    }
    .yen-price {
      font-weight: ${({ theme }) => theme.fw_700};
      font-size: 12px;
      color: #e3007f;
    }
  }

  .password {
    color: ${({ theme }) => theme.bg_red};
  }

  .some-free {
    line-height: 17px;
    letter-spacing: 0px;
    font-weight: ${({ theme }) => theme.fw_700};
    font-size: ${({ theme }) => theme.size_12};
    color: #0076e3;
  }

  .tag-topic {
    border: none;
    background-color: #e3007f;
    border-radius: 12px;
    font-weight: ${({ theme }) => theme.fw_700};
    font-size: ${({ theme }) => theme.size_10};
    color: white;
    margin: 0;
  }

  .chapter-content {
    font-size: ${({ theme }) => theme.size_14};
    color: ${({ theme }) => theme.dark};
    font-weight: ${({ theme }) => theme.fw_500};
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
  }
`;

export const Banner = styled.div`
  border-radius: 6px;
  width: 25%;
  aspect-ratio: 16 / 9;
  border-radius: 8px;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
  }
`;

export const TopInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  margin-left: 8px;
`;

export const TopLeftInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const TopLeftRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: end;
  width: 72px;
  font-size: 10px;
`;

export const ImageChapters = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 8px;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
  }

  ${Media.lessThan(Media.SIZE.LG)} {
    border-radius: 6px;
    width: 25%;
  }
`;

export const Info = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
  ${Media.lessThan(Media.SIZE.LG)} {
    margin: 0;
    margin-left: 8px;
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    margin: 0;
    margin-left: 4px;
  }
`;

export const LeftInfo = styled.div`
  display: flex;
  flex-direction: column;
  ${Media.lessThan(Media.SIZE.LG)} {
    flex: 1;
  }
`;

export const RightInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100px;
  text-align: end;
  ${Media.lessThan(Media.SIZE.LG)} {
    width: auto;
    font-size: 10px;
  }
`;

export const DateTime = styled.div`
  display: flex;
  .date {
    font-size: 14px;
  }
  ${Media.lessThan(Media.SIZE.SM)} {
    font-size: 12px;
  }
`;
