import { getEnv } from '@config/env';
import abiILive from './abi/ILive.json';
import abiERC20 from './abi/ERC20.json';

const ILIVE_CONTRACT_ADDRESS = getEnv('ILIVE_CONTRACT_ADDRESS', null);
const ERC20_CONTRACT_ADDRESS = getEnv('ERC20_CONTRACT_ADDRESS', null);

export const iLiveContract = {
  address: ILIVE_CONTRACT_ADDRESS,
  abi: abiILive,
};
export const erc20Contract = {
  address: ERC20_CONTRACT_ADDRESS,
  abi: abiERC20,
  approveSenderAddress: ILIVE_CONTRACT_ADDRESS,
};
export const options = {
  gasPrice: 20000000000,
  gas: 1000000,
};
