import React, { useEffect, useMemo, useState } from 'react';
import { Spin } from 'antd';
import { useParams, useLocation, useHistory, Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';

import { useAuth } from '@hooks';
import { useTopicPayment } from '@hooks/useTopicPayment';
import DiamondEnough from './components/diamond-enough';
import BoxPayment from './components/box-payment';

import { PaymentCoinStyled, ContentStyled } from './styled';
import Purchased from './components/success';
import DiamondNotEnough from './components/diamond-not-enough';
import PaymentFail from '../components/payment-fail';
import Confirm from './components/confirm';
import { DIAMOND_PAYMENT_STATUS } from '../constants';

interface Params {
  topicId: string;
  packageId: string;
}

export const PaymentDiamond: React.FC = () => {
  const { profile, loadProfileAction } = useAuth();
  const { t } = useTranslation();
  const { realEventPayment, common, topic } = translations;
  const { buyChapter, topic_info, payment_method, user_info, purchased, error } = useTopicPayment();

  const { topicId, packageId } = useParams<Params>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');
  const [statusPayment, setStatusPayment] = useState<DIAMOND_PAYMENT_STATUS>(DIAMOND_PAYMENT_STATUS.LOADING);
  const { replace } = useHistory();

  const handlPaymentDiamond = () => {
    buyChapter({
      topic_type: type,
      packageId,
      topicId,
      params: user_info,
    });
  };

  useEffect(() => {
    if (Number(profile?.diamond || 0) >= Number(topic_info.diamond_price || 0)) {
      setStatusPayment(DIAMOND_PAYMENT_STATUS.ENOUGH);
    } else {
      setStatusPayment(DIAMOND_PAYMENT_STATUS.NOT_ENOUGH);
    }
  }, [profile?.diamond, topic_info]);

  useEffect(() => {
    loadProfileAction();
  }, []);

  useEffect(() => {
    if (!payment_method) {
      replace(`/topics/${topicId}/payment/${packageId}?type=${type}`);
    }
  }, [payment_method]);

  useEffect(() => {
    if (purchased) {
      setStatusPayment(DIAMOND_PAYMENT_STATUS.SUCCESS);
    }
  }, [purchased]);
  useEffect(() => {
    if (error) {
      setStatusPayment(DIAMOND_PAYMENT_STATUS.FAILURE);
    }
  }, [error]);

  const pathBack = useMemo(() => {
    switch (statusPayment) {
      case DIAMOND_PAYMENT_STATUS.ENOUGH:
      case DIAMOND_PAYMENT_STATUS.NOT_ENOUGH:
      case DIAMOND_PAYMENT_STATUS.CONFIRM:
        return `/topics/${topicId}/payment/${packageId}?type=${type}`;
      default:
        return undefined;
    }
  }, [statusPayment, topicId, packageId, type]);

  const title = useMemo(() => {
    if (statusPayment === DIAMOND_PAYMENT_STATUS.NOT_ENOUGH) return t(topic.payment_with_diamond);
    if (statusPayment === DIAMOND_PAYMENT_STATUS.SUCCESS) return t(realEventPayment.payment_coin_completed);
    if (statusPayment === DIAMOND_PAYMENT_STATUS.FAILURE) return t(realEventPayment.payment_coin_fail);
    if (statusPayment === DIAMOND_PAYMENT_STATUS.ENOUGH) return t(topic.payment_with_diamond);
    if (statusPayment === DIAMOND_PAYMENT_STATUS.CONFIRM) return t(common.event.confirm_popup_title);

    return '';
  }, [statusPayment, t]);

  const promptWhen = useMemo(
    () =>
      !!payment_method &&
      statusPayment !== DIAMOND_PAYMENT_STATUS.NOT_ENOUGH &&
      statusPayment !== DIAMOND_PAYMENT_STATUS.FAILURE &&
      statusPayment !== DIAMOND_PAYMENT_STATUS.SUCCESS,
    [payment_method, statusPayment],
  );

  const renderByStatus = useMemo(() => {
    switch (statusPayment) {
      case DIAMOND_PAYMENT_STATUS.ENOUGH:
        return (
          <DiamondEnough
            topicId={topicId}
            packageId={packageId}
            onContinue={() => setStatusPayment(DIAMOND_PAYMENT_STATUS.CONFIRM)}
          />
        );
      case DIAMOND_PAYMENT_STATUS.CONFIRM:
        return <Confirm onConfirm={handlPaymentDiamond} topicId={topicId} packageId={packageId} />;
      case DIAMOND_PAYMENT_STATUS.NOT_ENOUGH:
        return (
          <DiamondNotEnough totalDiamond={Number(profile?.diamond)} price={Number(topic_info.diamond_price || 0)} />
        );
      case DIAMOND_PAYMENT_STATUS.SUCCESS:
        return <Purchased isPayDiamond />;
      case DIAMOND_PAYMENT_STATUS.FAILURE:
        return <PaymentFail packageId={packageId} topicId={topicId} />;
      default:
        return <Spin spinning />;
    }
  }, [statusPayment]);

  return (
    <PaymentCoinStyled>
      <BoxPayment title={title} routeBack={pathBack} isShowIconBack={!!pathBack}>
        <ContentStyled>{renderByStatus}</ContentStyled>
      </BoxPayment>
      <Prompt message={() => (promptWhen ? t(realEventPayment.goback) : true)} />
    </PaymentCoinStyled>
  );
};
