import Media from '@themes/media';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 32px 50px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  max-width: 1500px;
  font-family: 'Noto Sans JP';
  width: 100%;
  .ant-spin-nested-loading {
    width: 100%;
  }
  ${Media.lessThan(Media.SIZE.XL)} {
    display: block;
    padding: 0px;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  flex: 1;
  margin-right: 8px;
  border-radius: 6px;
  flex-direction: column;
  padding: 0;
  background-color: white;

  ${Media.lessThan(Media.SIZE.LG)} {
    margin: 0;
    border-radius: 0;
  }
`;

export const Video = styled.video`
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;
export const VideStream = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

export const VideBox = styled.div`
  padding: 32px 50px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  max-width: 1500px;
  font-family: 'Noto Sans JP';

  ${Media.lessThan(Media.SIZE.LG)} {
    display: block;
  }
`;
