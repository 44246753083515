/* eslint-disable import/no-anonymous-default-export */
import api_error from './api_error.json';
import common from './common.json';
import countries from './countries.json';
import errorMessage from './error_message.json';
import gmo_payment from './gmo_payment.json';
import listEvent from './list_event.json';
import menu from './menu.json';
import myTicket from './my_ticket.json';
import realEventPayment from './real_event_payment.json';
import register from './register.json';
import topic from './topic.json';
import livestream from './livestream.json';
import histories from './histories.json';
import topic_payment from './topic_payment.json';

export default {
  common,
  error_message: errorMessage,
  menu,
  register,
  myTicket,
  listEvent,
  realEventPayment,
  countries,
  gmo_payment,
  api_error,
  topic,
  livestream,
  histories,
  topic_payment,
};
