// import Media from '@themes/media';
import { CART, SEND } from '@assets';
import { Clickable } from '@components';
import Media from '@themes/media';
import styled from 'styled-components';

export const DonateWrapper = styled.div`
  display: flex;
  width: 480px;
  height: 300px;
  overflow: auto;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
    border-radius: 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background: #787878;
    }
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    width: calc(100vw - 32px);
    align-items: center;
    justify-content: flex-start;
    height: 150px;
    .item {
      width: calc(0.25 * (100vw - 36px));
    }
  }
`;

export const DonateButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 2px;
  width: calc(476px / 7);
  ${Media.lessThan(Media.SIZE.SM)} {
    width: calc(0.25 * (100vw - 36px));
  }
`;

export const Donate = styled(Clickable)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 2px;
  position: relative;
`;

export const Image = styled.img<{ active: boolean }>`
  width: 56px;
  height: 56px;
  object-fit: cover;
  border-radius: 10px;
  border: ${({ active }) => (active ? '1px solid #e3007f' : '1px solid transparent')};
  padding: 2px;
`;

export const Price = styled.span<{ color: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: ${({ theme, color }) => theme[color]};
  font-size: 12px;
  font-weight: 700;
`;

export const ActiveIcon = styled.div`
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
  /* ${Media.lessThan(Media.SIZE.SM)} {
    right: 16px;
  } */
`;

export const Cart = styled(CART)`
  fill: #0076e3;
`;

export const Send = styled(SEND)<{ isactive?: boolean }>`
  fill: ${({ isactive }) => (isactive ? '#E3007F' : '#AAAAAA')};
`;

export const TextDonate = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  padding: 24px 0px;
  color: #787878;
`;
