/* eslint-disable react/prop-types */
import { CVV_ICON, CVV_INFOR } from '@assets';
import Button from '@components/button';
import { translations } from '@i18n/translations';
import { Input, InputProps, Popover } from 'antd';
import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { UseControllerProps, useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PatternFormatProps, InputAttributes, PatternFormat, NumericFormat } from 'react-number-format';
import { CVV, Lable, Wrapper } from './styled';

interface FormInputProps extends UseControllerProps {
  label?: string;
  inputType?: 'required' | 'optional';
  wrapperStyle?: CSSProperties;
  translateField?: string;
  cvvInfo?: boolean;
  width?: number | string;
  numberFormatProps?: PatternFormatProps<InputAttributes>;
  inputFormat?: 'none' | 'credit_card' | 'date_expire' | 'custom_format' | 'number';
}

const TextInput: React.FC<FormInputProps & InputProps> = ({
  label,
  name,
  rules,
  inputType = 'optional',
  defaultValue = '',
  wrapperStyle = {},
  translateField,
  cvvInfo,
  width,
  numberFormatProps,
  inputFormat = 'none',
  ...rest
}) => {
  const { t, i18n } = useTranslation();
  const { control } = useFormContext();
  const [open, setOpen] = useState(false);
  const { common } = translations;
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });
  const cvvContent = () => {
    return (
      <CVV>
        <div className="title">
          <b>{t(common.cvv_modal_title)}</b>
        </div>
        <div className="description">{t(common.cvv_modal_content)}</div>
        <div className="ccv-image">
          <img src={CVV_ICON} alt="success" />
        </div>
        <Button onClick={() => setOpen(false)}>OK</Button>
      </CVV>
    );
  };
  useEffect(() => {
    const onHidePopover = () => setOpen(false);

    document.body.addEventListener('click', onHidePopover);
    return () => {
      document.body.removeEventListener('click', onHidePopover);
    };
  }, []);

  const textLabel = () => {
    if (!label) return <span />;
    if (!!label && inputType === 'required') {
      return (
        <Lable>
          <span>{label}</span>
          <span style={{ color: 'red' }}>*</span>

          {!!cvvInfo && (
            <img
              src={CVV_INFOR}
              style={{ width: 12, height: 12, marginLeft: 4, cursor: 'pointer' }}
              alt="icon"
              onClick={(event) => {
                event.stopPropagation();
                setOpen(true);
              }}
            />
          )}
        </Lable>
      );
    }
    return <Lable>{label}</Lable>;
  };
  const translateError = useMemo(() => {
    if (!!error && error.message && error.type?.includes('required'))
      return t(error.message, { key: t(translateField as any) });

    if (!!error && error.message && !error.type?.includes('required')) return t(error.message);

    return null;
  }, [error, i18n.language]);

  if (inputFormat === 'date_expire') {
    return (
      <Wrapper error={!!error} style={wrapperStyle} width={width}>
        {textLabel()}
        <PatternFormat
          format="##/##"
          name={name}
          value={value}
          onChange={onChange}
          placeholder={rest.placeholder}
          {...numberFormatProps}
        />
        {!!i18n.language && !!error && <div style={{ color: 'red' }}>{translateError}</div>}
      </Wrapper>
    );
  }

  if (inputFormat === 'credit_card') {
    return (
      <Wrapper error={!!error} style={wrapperStyle} width={width}>
        {textLabel()}
        <PatternFormat
          format="#### #### #### ####"
          name={name}
          value={value}
          onChange={onChange}
          placeholder={rest.placeholder}
          {...numberFormatProps}
        />
        {!!i18n.language && !!error && <div style={{ color: 'red' }}>{translateError}</div>}
      </Wrapper>
    );
  }
  if (inputFormat === 'number') {
    return (
      <Wrapper error={!!error} style={wrapperStyle} width={width}>
        {textLabel()}
        <NumericFormat
          name={name}
          value={value}
          onChange={onChange}
          placeholder={rest.placeholder}
          {...numberFormatProps}
        />
        {!!i18n.language && !!error && <div style={{ color: 'red' }}>{translateError}</div>}
      </Wrapper>
    );
  }

  if (inputFormat === 'custom_format') {
    return (
      <Wrapper error={!!error} style={wrapperStyle} width={width}>
        {textLabel()}
        <PatternFormat
          format={numberFormatProps?.format || ''}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={rest.placeholder}
          {...numberFormatProps}
        />
        {!!error && <div style={{ color: 'red' }}>{translateError}</div>}
      </Wrapper>
    );
  }

  return (
    <Wrapper style={wrapperStyle} width={width}>
      {textLabel()}
      <Popover placement="bottom" title={null} content={cvvContent()} open={open}>
        <Input
          type="text"
          status={error ? 'error' : ''}
          onBlur={onBlur}
          name={name}
          onChange={onChange}
          value={value}
          {...rest}
        />
      </Popover>
      {!!error && <div style={{ color: 'red' }}>{translateError}</div>}
    </Wrapper>
  );
};

export default TextInput;
