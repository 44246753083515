/* eslint-disable arrow-parens */
/* eslint-disable implicit-arrow-linebreak */

/**
 * The global state selectors
 */

import { Store } from '@type/Store';
import { HistoryState } from '@type/Store/histories';
import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectHistories = (store: Store) => store.histories || initialState;

const makeSelectHistories = () => createSelector(selectHistories, (state: HistoryState) => state);

export { selectHistories, makeSelectHistories };
