import styled from 'styled-components';
import Media from '@themes/media';

export const Wrapper = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;

  .intro-0-2-5 {
    width: 45vh;
    margin: 0 auto;
    z-index: 200;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 45px;
    background-color: #ffffff;
    .introImageWrapper-0-2-6 {
      z-index: 1;
      width: 50%;

      .introImage-0-2-7 {
        transform: translateX(10vw);
        background-position: center right;
        height: 100%;
        background-size: contain;
        background-image: url('https://facit-modern.omtanke.studio/static/media/richie.44ff154e0275feeae986.webp');
        background-repeat: no-repeat;
      }
    }

    .ant-tabs-bottom > .ant-tabs-nav,
    .ant-tabs-bottom > div > .ant-tabs-nav,
    .ant-tabs-top > .ant-tabs-nav,
    .ant-tabs-top > div > .ant-tabs-nav {
      margin: unset;
    }

    .ant-radio-group {
      font-size: 14px !important;
    }

    .wrapper-history {
      height: 60vh;
    }

    .ant-pagination-item-active a {
      color: #e3007f;
    }

    .ant-pagination-item-active {
      border-color: #e3007f;
    }

    .ant-pagination-item:hover a {
      color: #e3007f;
    }

    .status-success {
      color: green;
    }

    .payment-content {
      text-align: center;
      width: 100%;
      padding: 30px 0px;
    }

    .payment-top {
      text-align: left;
      padding: 4vh 4vh;
      height: 73vh;

      .primez-method {
        margin-top: 20px;
      }
    }

    .ant-tabs-tab {
      padding: 10px 10px;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #787878;
    }

    .ant-pagination-options {
      padding: 8px;
    }

    .payment-title {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #282727;
      padding: 5px 0px;
    }

    .payment-icon {
      margin-left: 25px;
    }

    .payment-btn {
      margin-top: 3vh;
      margin-bottom: 30px;
    }

    .btn-login {
      color: #ffffff;
      background-color: #e3007f;
      opacity: 0.5;
      border-radius: 24px;
      width: 85%;
      height: 5vh;
    }

    .list-diamond-title {
      text-align: center;
      display: flex;
    }

    .list-diamond-lable {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #787878;
    }

    .back-icon {
      width: 12.5vh;
      text-align: left;
      padding: 0px 2vh;
      cursor: pointer;
    }

    .amount-diamond-header {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      color: #0076e3;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #282727;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
    }

    .ant-tabs > .ant-tabs-nav,
    .ant-tabs > div > .ant-tabs-nav {
      position: unset !important;
    }

    .ant-tabs-ink-bar {
      background: #e3007f;
      max-width: 3vh;
      margin-left: 1.5vw;
      margin-bottom: 5px;
    }

    .diamond-present {
      width: 100%;
      text-align: left;
      margin-left: 2vh;
    }

    .diamond-package-icon {
      margin-bottom: 5px;
    }

    .package-diamond {
      border: 2px solid #00cebc;
      border-radius: 6px;
      width: 18vh; // 19.5vh;
      margin-top: 10px;
      cursor: pointer;
      overflow: hidden;

      .package-content-holder {
        flex-direction: column;
        display: flex;
      }
    }

    .list-package {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      overflow-y: auto;
      justify-content: space-between;
      padding: 0 64px;
      ${Media.lessThan(Media.SIZE.BL)} {
        padding: 0 16px;
      }
    }

    .ant-radio-button-wrapper-checked {
      background-color: #e3007f;
    }

    .ant-radio-button-wrapper {
      border-style: unset;
      border-width: unset;
      border-color: unset;
      position: unset;
      height: min-content;
    }

    .price-diamond {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 15px;
      text-align: center;
      color: #e3007f;
      margin-bottom: 8px;
    }

    .amount-diamond {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
      color: #0076e3;
    }

    .ant-radio-inner:after {
      background-color: #e3007f;
    }

    .ant-radio-checked .ant-radio-inner {
      border-color: #e3007f;
    }

    .introContentWrapper-0-2-8 {
      z-index: 2;
      width: 50%;

      .display-1 {
        font-size: calc(1.5rem + 4.5vw);
        font-weight: 300;
        line-height: 1.2;

        img {
          width: 250px;
        }
      }
      .display-6 {
        font-size: calc(1.375rem + 1.5vw);
        font-weight: 300;
        line-height: 1.2;
      }
      .h2 {
        font-size: calc(1.325rem + 0.9vw);
        .text-info {
          color: #4e68f9;
        }
      }
    }

    ${Media.lessThan(Media.SIZE.BL)} {
      .price-diamond {
      }
    }
  }

  .price-diamond {
    font-size: 14px;
  }

  .payment-top {
    height: 75vh;
  }

  .diamond-present {
    margin-left: unset;
  }

  ${Media.lessThan(Media.SIZE.XXL)} {
    font-size: 14px;
  }

  ${Media.lessThan(Media.SIZE.XS)} {
    .price-diamond {
    }
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    .intro-0-2-5 {
      width: 100%;
    }
  }

  ${Media.lessThan(Media.SIZE.XS)} {
    .intro-0-2-5 {
      .package-diamond {
        width: 18vh; // 23.4vh;
      }
    }
  }

  ${Media.lessThan(Media.SIZE.XXS + 1)} {
    .intro-0-2-5 {
      .package-diamond {
        width: 18vh;
      }

      .ant-tabs-ink-bar {
        margin-left: 4.5vh;
      }

      .price-diamond {
        height: 3.4vh;
        font-size: 10px;
        line-height: 10px;
      }

      .amount-diamond {
      }
    }

    .intro-0-2-5 {
      .payment-btn {
        margin-top: 2vh;
      }
    }
  }

  ${Media.lessThan(Media.SIZE.XXS)} {
    .intro-0-2-5 {
      .payment-btn {
        margin-top: 2vh;
      }
    }
  }
`;
