/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import HomeLayout from '@layouts/home';
import { useAuth } from '@hooks';

const PrivateRoute: React.FC<any> = ({ component: Component, layout: Layout = HomeLayout, ...rest }: any) => {
  const { authenticated } = useAuth();
  return (
    <Route
      {...rest}
      render={(props: any) =>
        authenticated ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to={{ pathname: '/login', state: { pathname: props.location.pathname } }} />
        )
      }
    />
  );
};

export default PrivateRoute;
