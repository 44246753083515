/* eslint-disable no-restricted-globals */
import { useDispatch, useSelector } from 'react-redux';

import saga from '@modules/auth/store/saga';
import reducer from '@modules/auth/store/reducer';
import {
  login,
  register,
  verifyRegister,
  logout,
  loadProfile,
  verification,
  resetPassword,
  reSendCode,
  changeLaguage,
} from '@modules/auth/store/actions';
import { useInjectSaga, useInjectReducer } from '@stores';
import { USER_ROLE } from '@constants/auth';
import { makeSelectAuthentication } from '@modules/auth/store/selectors';
import i18next from 'i18next';
import { useMemo } from 'react';

export const useAuth = () => {
  useInjectSaga({ key: 'auth', saga });
  useInjectReducer({ key: 'auth', reducer });

  const state = useSelector(makeSelectAuthentication());
  const { isLoading, error, authenticated, profile, resetPwStatus, email, verifyPass, isLogoutSuccess } = state;
  const dispatch = useDispatch();
  const loginAction = (payload?: any) => dispatch(login(payload));
  const registerAction = (payload?: any) => dispatch(register(payload));
  const verifyRegisterAction = (payload?: any) => dispatch(verifyRegister(payload));
  const reSendCodeAction = (payload?: any) => dispatch(reSendCode(payload));
  const logoutAction = (payload?: any) => dispatch(logout(payload));
  const verificationAction = (payload?: any) => dispatch(verification(payload));
  const resetPasswordAction = (payload?: any) => dispatch(resetPassword(payload));
  const loadProfileAction = () => dispatch(loadProfile());
  const changeLaguageAction = ({ language }) => {
    if (authenticated) {
      dispatch(changeLaguage({ language }));
    } else {
      i18next.changeLanguage(language);
    }
  };
  const isUser = useMemo(() => profile?.role === USER_ROLE.USER, [profile]);
  const isLiver = useMemo(() => profile?.role === USER_ROLE.LIVER, [profile]);
  return {
    isLoading,
    error,
    authenticated,
    profile,
    resetPwStatus,
    email,
    verifyPass,
    isUser,
    isLiver,
    isLogoutSuccess,
    loginAction,
    loadProfileAction,
    logoutAction,
    registerAction,
    verifyRegisterAction,
    reSendCodeAction,
    verificationAction,
    resetPasswordAction,
    changeLaguage: changeLaguageAction,
  };
};
