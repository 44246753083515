export const SET_USER_INFO = '@TOPIC_PAYMENT/SET_USER_INFO';
export const SET_KONBINI_INFO = '@TOPIC_PAYMENT/SET_KONBINI_INFO';
export const SET_CREDIT_CARD_INFO = '@TOPIC_PAYMENT/SET_CREDIT_CARD_INFO';
export const SET_DEEPLINK = '@TOPIC_PAYMENT/SET_DEEPLINK';

export const GET_PAYMENT_INFO = '@TOPIC_PAYMENT/GET_PAYMENT_INFO';

export const BUY_CHAPTER = '@TOPIC_PAYMENT/BUY_CHAPTER';
export const BUY_PACKAGE = '@TOPIC_PAYMENT/BUY_PACKAGE';

export const PAYMENT_CHAPTER = '@TOPIC_PAYMENT/PAYMENT_CHAPTER';
export const PAYMENT_PACKAGE = '@TOPIC_PAYMENT/PAYMENT_PACKAGE';

export const PURCHASED = '@TOPIC_PAYMENT/PURCHASED';

export const LOADING = '@TOPIC_PAYMENT/LOADING';
export const ERROR = '@TOPIC_PAYMENT/ERROR';
export const RESET_DATA = '@TOPIC_PAYMENT/RESET_DATA';
