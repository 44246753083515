import GIF_01 from './01.png';
import GIF_02 from './02.png';
import GIF_03 from './03.png';
import GIF_04 from './04.png';
import GIF_05 from './05.png';
import GIF_06 from './06.png';
import GIF_07 from './07.png';
import GIF_08 from './08.png';
import GIF_09 from './09.png';
import GIF_10 from './10.png';
import GIF_11 from './11.png';

import GIF_12 from './12.gif';
import GIF_13 from './13.gif';
import GIF_14 from './14.gif';
import GIF_15 from './15.gif';
import GIF_16 from './16.gif';
import GIF_17 from './17.gif';
import GIF_18 from './18.gif';
import GIF_19 from './19.gif';
import GIF_20 from './20.gif';
import GIF_21 from './21.gif';
import GIF_22 from './22.gif';
import GIF_23 from './23.gif';
import GIF_24 from './24.gif';
import GIF_25 from './25.gif';
import GIF_26 from './26.gif';
import GIF_27 from './27.gif';

import GIF_28 from './28.png';
import GIF_29 from './29.png';
import GIF_30 from './30.png';
import GIF_31 from './31.png';
import GIF_32 from './32.png';

export {
  GIF_01,
  GIF_02,
  GIF_03,
  GIF_04,
  GIF_05,
  GIF_06,
  GIF_07,
  GIF_08,
  GIF_09,
  GIF_10,
  GIF_11,
  GIF_12,
  GIF_13,
  GIF_14,
  GIF_15,
  GIF_16,
  GIF_17,
  GIF_18,
  GIF_19,
  GIF_20,
  GIF_21,
  GIF_22,
  GIF_23,
  GIF_24,
  GIF_25,
  GIF_26,
  GIF_27,
  GIF_28,
  GIF_29,
  GIF_30,
  GIF_31,
  GIF_32,
};

const GIF_IMAGE = {
  GIF_01,
  GIF_02,
  GIF_03,
  GIF_04,
  GIF_05,
  GIF_06,
  GIF_07,
  GIF_08,
  GIF_09,
  GIF_10,
  GIF_11,
  GIF_12,
  GIF_13,
  GIF_14,
  GIF_15,
  GIF_16,
  GIF_17,
  GIF_18,
  GIF_19,
  GIF_20,
  GIF_21,
  GIF_22,
  GIF_23,
  GIF_24,
  GIF_25,
  GIF_26,
  GIF_27,
  GIF_28,
  GIF_29,
  GIF_30,
  GIF_31,
  GIF_32,
};

export default GIF_IMAGE;
