import Media from '@themes/media';
import { Modal, Typography } from 'antd';
import styled from 'styled-components';

export const TicketDetailStyle = styled.div`
  width: 100%;
  display: flex;
  padding: 25px 100px;
  background: #f0f2f4;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  p {
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    color: #e3007f;
    white-space: pre-wrap;
  }
  ${Media.lessThan(Media.SIZE.SM)} {
    padding: 22px 7px;
  }
`;
export const UserInfoStyle = styled.div``;
export const Group = styled.div``;
export const PaymentInfoStyle = styled.div``;

export const Wrapper = styled.div`
  font-family: 'Noto Sans JP';

  max-width: 720px;
  ${UserInfoStyle},
  ${PaymentInfoStyle} {
    border-bottom: 1px solid #e5e6eb;
    padding: 15px 0;

    &:last-child {
      border-bottom: initial;
    }
    .yen {
      color: #e3007f;
      display: flex;
      align-items: center;
      font-weight: ${({ theme }) => theme.fw_700};
      font-size: 18px;
    }
    ${Group} {
      display: flex;
      font-size: 18px;
      font-weight: 400;
      font-family: 'Noto Sans JP';
      margin-bottom: 19px;
      .custom-width {
        max-width: 65%;
        word-wrap: break-word;
      }
      ${Media.lessThan(Media.SIZE.SM)} {
        .custom-width {
          max-width: 50%;
        }
      }

      label {
        color: ${({ theme }) => theme._787878};
        font-size: 18px;
        font-weight: 400;
        font-family: 'Noto Sans JP';
        width: 200px;
        white-space: nowrap;
        ${Media.lessThan(Media.SIZE.MD)} {
          font-size: ${({ theme }) => theme.size_14};
          padding-right: 5px;
          margin-bottom: 0;
          width: 140px;
          white-space: pre-wrap;
        }
      }
      p,
      div {
        margin: 0;
        padding: 0;
        word-wrap: break-word;
        display: flex;
        flex: 1;
        font-size: ${({ theme }) => theme.size_18};
        color: ${({ theme }) => theme.dark};

        ${Media.lessThan(Media.SIZE.SM)} {
          font-size: ${({ theme }) => theme.size_14};
        }
        .yen {
          color: #e3007f;
          display: flex;
          align-items: center;
          font-weight: ${({ theme }) => theme.fw_400};
          font-size: 18px;
        }

        .price {
          color: #0076e3;
          font-weight: ${({ theme }) => theme.fw_700};
        }

        a {
          text-decoration: underline;
        }

        ${Media.lessThan(Media.SIZE.MD)} {
          margin-bottom: 8px;

          label {
            width: 200px;
            margin-right: 16px;
            white-space: pre-wrap;
          }
        }

        ${Media.lessThan(Media.SIZE.SM)} {
          margin-bottom: 8px;

          label {
            width: 160px;
            margin-right: 16px;
            white-space: pre-wrap;
          }
        }
        .ticket-type {
          display: flex;
          gap: 20px;
          justify-content: space-between;
          width: 100%;
        }
      }
    }

    .box-btn {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      flex: 1;

      .ant-btn {
        flex: 1;
        padding: 6px;
      }
    }
  }
`;

export const TextComfirmStyled = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 45px;
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 24px;
  }

  .ant-modal-body {
    padding: 82px 58px 45px 58px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .ant-btn {
      height: 44px;
    }
  }
`;
