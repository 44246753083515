import { useTopics } from '@hooks';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Image from '@components/imageFallback';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';
import Paragraph from 'antd/lib/typography/Paragraph';
import { ImageBannerTop, TopicDetailStyle } from './styled';
import { ListChapters } from '../components';

interface Params {
  id?: string;
}

const TopicDetail: React.FC = () => {
  const { id } = useParams<Params>();
  const { getTopic, topicDetail, loading } = useTopics();

  const { t } = useTranslation();
  const { common } = translations;

  const [expandable, setExpandable] = useState(true);

  useEffect(() => {
    getTopic({ topicId: Number(id) });
  }, [id]);

  return (
    <TopicDetailStyle>
      <Spin spinning={loading}>
        <ImageBannerTop>{topicDetail && <Image src={topicDetail?.images || ''} alt="Image topic" />}</ImageBannerTop>
        <div className="content-top">
          <div className="title"> {topicDetail?.title}</div>
          {expandable && (
            <Paragraph
              className="content"
              ellipsis={{
                rows: 3,
                expandable: true,
                symbol: t(common.see_more),
                onExpand: () => setExpandable(false),
              }}
            >
              {topicDetail?.description}
            </Paragraph>
          )}
          {!expandable && (
            <Paragraph className="content" ellipsis={false}>
              {topicDetail?.description}
              <span onClick={() => setExpandable(true)} className="ant-typography-expand">
                {t(common.see_less)}
              </span>
            </Paragraph>
          )}
        </div>
        <div className="chapters">
          <Spin spinning={loading}>
            <ListChapters topicDetail={topicDetail} />
          </Spin>
        </div>
      </Spin>
    </TopicDetailStyle>
  );
};

export default TopicDetail;
