import React, { useMemo } from 'react';
import { Typography } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';

import { Button } from '@components';
import { FAILURE_ICON_SVG } from '@assets';
import { Wrapper } from './styled';

interface Params {
  eventId: string;
}
interface IProps {
  onClick?: () => void;
  gmo_error_code?: string[] | null;
}
const PaymentFail: React.FC<IProps> = ({ onClick, gmo_error_code }) => {
  const history = useHistory();
  const params: Params = useParams();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { realEventPayment, gmo_payment } = translations;

  const failed_message = useMemo(() => {
    if (gmo_error_code && gmo_error_code.length > 0) {
      return gmo_error_code.map((code) => t(gmo_payment[code])).join('\n');
    }

    return t(realEventPayment.payment_coin_mess_fail);
  }, [gmo_error_code, language]);

  return (
    <Wrapper>
      <FAILURE_ICON_SVG style={{ marginBottom: '24px' }} />

      <Typography className="text-message">{failed_message}</Typography>
      <div className="box-btn">
        <Button
          onClick={() => {
            if (onClick) {
              onClick();
            } else {
              history.push(`/events/${params?.eventId}`);
            }
          }}
        >
          {t(realEventPayment.back)}
        </Button>
      </div>
    </Wrapper>
  );
};

export default PaymentFail;
