/* eslint-disable radix */

import moment from 'moment';
import momentTz from 'moment-timezone';

import { FORMAT_TIME } from '@constants';
import { WEEK_DAYS } from '@constants/my-ticket';

const formatDate = (date: any, f = FORMAT_TIME.DATE_HOUR_MINUTES) =>
  moment(date).isValid() ? moment(date).format(f) : '';

const formatDateShort = (date: any, f = FORMAT_TIME.FULL_DATE) => moment(date).format(f);

const combineDateAndTime = (date: any, time: any) => {
  if (!date) return null;
  if (time) return new Date(`${date}T${time}`);
  return new Date(date);
};

const combineDateAndTimeV2 = (date: any, time: any) => {
  if (!date) return null;
  if (time) {
    return new Date(`${date.format(FORMAT_TIME.YEAR_MONTH_DATE)} ${time.format(FORMAT_TIME.HOUR_MINUTES)}`);
  }
  return moment(date).startOf('day');
};
const timeStampToDate = (time: number, format = FORMAT_TIME.YEAR_MONTH_DATE) => moment.unix(time).format(format);

const convertToCurrentTimeZone = (dayConvert, format = FORMAT_TIME.YEAR_MONTH_DATE) => {
  // const moment = require('moment-timezone');
  const currentZone = momentTz.tz.guess();
  const m = momentTz.tz(dayConvert, 'Asia/Tokyo');
  return m.tz(currentZone).format(format);
};

const formatDateTimeOfWeek = (time: number, language: string) => {
  moment.updateLocale('zh_cn', {
    weekdays: WEEK_DAYS.ZH_CN.split('_'),
  });

  moment.updateLocale('en', {
    weekdays: WEEK_DAYS.EN.split('_'),
  });

  moment.updateLocale('ja', {
    weekdays: WEEK_DAYS.JA.split('_'),
  });
  switch (language) {
    case 'ja':
      return moment.unix(time).locale('ja').format(FORMAT_TIME.DATE_HOUR_MINUTES_DAY_OF_WEEK);
    case 'en':
      return moment.unix(time).locale('en').format(FORMAT_TIME.DATE_HOUR_MINUTES_DAY_OF_WEEK);
    default:
      return moment.unix(time).locale('zh_cn').format(FORMAT_TIME.DATE_HOUR_MINUTES_DAY_OF_WEEK);
  }
};

const convertSecondsToHMS = (seconds: number): string => {
  const duration = moment.duration(seconds, 'seconds');
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const sec = duration.seconds();
  if (hours > 0) {
    return `${hours}:${minutes}:${sec}`;
  }
  return `${minutes}:${sec}`;
};

const convertSecondsToHMS_V2 = (seconds: number): string => {
  if (!seconds) return '00:00:00';
  const duration = moment.duration(seconds, 'seconds');
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes() < 10 ? `0${duration.minutes()}` : duration.minutes();
  const sec = duration.seconds() < 10 ? `0${duration.seconds()}` : duration.seconds();
  return `${hours}:${minutes}:${sec}`;
};

const pad = (num, size) => {
  return `000${num}`.slice(size * -1);
};

const sec2Time = (timeSecond) => {
  const hours = Math.floor(timeSecond / 60 / 60);
  const minutes = Math.floor(timeSecond / 60) % 60;
  const seconds = Math.floor(timeSecond - minutes * 60);
  let timeString;
  if (parseInt(pad(hours, 2)) > 0) {
    timeString = `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`;
  } else {
    timeString = `${pad(minutes, 2)}:${pad(seconds, 2)}`;
  }
  return timeString;
};

export {
  combineDateAndTime,
  combineDateAndTimeV2,
  convertSecondsToHMS,
  convertToCurrentTimeZone,
  formatDate,
  formatDateShort,
  formatDateTimeOfWeek,
  timeStampToDate,
  sec2Time,
  convertSecondsToHMS_V2,
};
