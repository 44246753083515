import { createReducer, updateObject, REQUEST, SUCCESS, FAILURE } from '@stores';
import { Action, SignatureState } from '@type/Store';
import { SIGNATURE, EVENT_TRANSFER, CONNECT_WALLET, RESET_STATE, ERC20_APPROVED } from './constants';

export const initialState: SignatureState = {
  isLoading: false,
  isSuccessGetSignature: false,
  isSuccessEventTransfer: false,
  error: null,
  signature: '',
  web3: null,
  erc20Contract: null,
  iLiveContract: null,
  walletAdress: '',
  walletConnected: false,
  isTransferSuccess: false,
  errorTransaction: false,
  erc20Approved: false,
};

/**
 * getSignature
 * @param state
 */

function getSignature(state: any) {
  return updateObject(state, {
    isLoading: true,
    isSuccessGetSignature: false,
    isTransferSuccess: false,
    errorTransaction: false,
    erc20Approved: false,
  });
}

function getSignatureSuccess(state: any, { payload }: Action) {
  const { signature, transactionHash } = payload;
  return updateObject(state, {
    isLoading: false,
    isSuccessGetSignature: true,
    signature,
    isTransferSuccess: true,
    transactionHash,
    errorTransaction: false,
  });
}

function getSignatureError(state: any, { error }: Action) {
  return updateObject(state, {
    error,
    isLoading: false,
    isTransferSuccess: false,
    errorTransaction: true,
    erc20Approved: false,
  });
}

/**
 * eventTransfer
 * @param state
 */

function eventTransfer(state: any) {
  return updateObject(state, {
    isLoading: true,
    isSuccessGetSignature: false,
  });
}

function eventTransferSuccess(state: any) {
  return updateObject(state, {
    isLoading: false,
    isSuccessEventTransfer: true,
  });
}

function eventTransferError(state: any, { error }: Action) {
  return updateObject(state, {
    error,
    isLoading: false,
    isSuccessEventTransfer: false,
  });
}

function connectWallet(state: any) {
  return updateObject(state, {
    isLoading: true,
    walletConnected: false,
  });
}

function connectWalletSuccess(state: any, { payload }: Action) {
  const { ilive, erc20, walletAdress, web3 } = payload;
  return updateObject(state, {
    isLoading: false,
    web3,
    erc20Contract: ilive,
    iLiveContract: erc20,
    walletAdress,
    walletConnected: true,
  });
}

function connectWalletError(state: any, { error }: Action) {
  return updateObject(state, {
    error,
    isLoading: false,
    walletConnected: false,
  });
}

function approveERC20Success(state: any) {
  return updateObject(state, {
    erc20Approved: true,
  });
}

function resetState() {
  return updateObject(initialState, {});
}

export default createReducer(initialState, {
  [REQUEST(SIGNATURE)]: getSignature,
  [SUCCESS(SIGNATURE)]: getSignatureSuccess,
  [FAILURE(SIGNATURE)]: getSignatureError,
  [REQUEST(EVENT_TRANSFER)]: eventTransfer,
  [SUCCESS(EVENT_TRANSFER)]: eventTransferSuccess,
  [FAILURE(EVENT_TRANSFER)]: eventTransferError,
  [REQUEST(CONNECT_WALLET)]: connectWallet,
  [SUCCESS(CONNECT_WALLET)]: connectWalletSuccess,
  [FAILURE(CONNECT_WALLET)]: connectWalletError,
  [REQUEST(RESET_STATE)]: resetState,
  [REQUEST(ERC20_APPROVED)]: approveERC20Success,
});
