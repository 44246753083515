import { REQUEST } from '@stores';
import { IAction, Payload } from '@type/Store';
import { CardInfo } from '@type/Store/real-event-purchase';
import {
  PURCHASE_WITH_CREDIT_CARD,
  PURCHASE_WITH_KOBINI,
  REAL_EVENT_PURCHASE,
  SET_CARD_INFO,
  CLEAR_CARD_INFO,
} from './constants';

export function realEventPaymentAction(payload: Payload) {
  return {
    type: REQUEST(REAL_EVENT_PURCHASE),
    payload,
  };
}

export function purchaseWithCreditCard(payload: Payload): IAction<Payload> {
  return {
    type: REQUEST(PURCHASE_WITH_CREDIT_CARD),
    payload,
  };
}

export function purchaseWithKobini(payload: Payload): IAction<Payload> {
  return {
    type: REQUEST(PURCHASE_WITH_KOBINI),
    payload,
  };
}

export function setCardInfo(payload: CardInfo): IAction<CardInfo> {
  return {
    type: REQUEST(SET_CARD_INFO),
    payload,
  };
}

export function clearCardInfo(): IAction<undefined> {
  return {
    type: REQUEST(CLEAR_CARD_INFO),
  };
}
