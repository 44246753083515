import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';

import { Button } from '@components';
import { translations } from '@i18n/translations';
import { numberWithCommas } from '@utils/number';
import { useTopicPayment } from '@hooks/useTopicPayment';
import { KOBINI_LIST } from '@modules/topic-payment/constants';
import { Group, PaymentInfoStyle, TicketDetailStyle, Wrapper, Title } from './styled';

const { realEventPayment, topic, common } = translations;

type Props = {
  onCancel: () => void;
  onPurchase: () => void;
};

const Confirm: React.FC<Props> = ({ onCancel, onPurchase }) => {
  const { t, i18n } = useTranslation();
  const { user_info, konbini_info, topic_info, loading } = useTopicPayment();
  const { convenience } = konbini_info;
  const storeName = useMemo(() => {
    if (convenience) {
      const findKobini = KOBINI_LIST.find((item) => item.value === convenience);
      if (findKobini) {
        return t(findKobini?.label);
      }
      return '';
    }

    return '';
  }, [convenience, i18n.language]);

  return (
    <Wrapper>
      <Spin spinning={loading}>
        <TicketDetailStyle>
          <Title> {t(realEventPayment.purchase_information)} </Title>
          <Group>
            <label>{t(topic.topics)}</label>
            <p>{topic_info.topic_title}</p>
          </Group>
          <Group>
            <label>{t(topic.episode)}</label>
            <p>{topic_info.title}</p>
          </Group>
          <Group>
            <label>{t(realEventPayment.total_amount_notFee)}</label>
            <p>
              <span className="yen">￥{numberWithCommas(topic_info.yen_price, ',')}</span>
            </p>
          </Group>
        </TicketDetailStyle>
        <PaymentInfoStyle>
          <Title> {t(realEventPayment.payment_information)} </Title>
          <Group>
            <label>{t(realEventPayment.payment_method)}</label>
            <p>{t(common.event.company_payment)}</p>
          </Group>
          {!!storeName && (
            <Group>
              <label>{t(realEventPayment.storeName)}</label>
              <p>{storeName}</p>
            </Group>
          )}
          {(user_info.last_name + user_info.first_name)?.length > 0 && (
            <Group>
              <label>{t(realEventPayment.user_name)}</label>
              <p>{`${user_info.last_name} ${user_info.first_name}`}</p>
            </Group>
          )}
          <Group>
            <label>{t(realEventPayment.name_kana)}</label>
            <p>{`${konbini_info.last_name_kana} ${konbini_info.first_name_kana}`}</p>
          </Group>
          <Group>
            <label>{t(realEventPayment.email)}</label>
            <p className="custom-width">{user_info.email}</p>
          </Group>
          <Group>
            <label>{t(realEventPayment.phone)}</label>
            <p>{user_info.phone_number}</p>
          </Group>
          <div className="box-btn">
            <Button onClick={onCancel} type="cancel" loading={loading}>
              {t(realEventPayment.cancel)}
            </Button>
            <Button onClick={onPurchase} loading={loading}>
              {t(realEventPayment.continue)}
            </Button>
          </div>
        </PaymentInfoStyle>
      </Spin>
    </Wrapper>
  );
};
export default Confirm;
