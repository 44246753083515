/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React from 'react';
import Modal from 'react-modal';
import { Spin, Button, ConfigProvider } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { SUCCESS_ICON, DIAMOND, FAILURE_ICON } from '@assets';
import Media from '@themes/media';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';

const customStyles = {
  overlay: {
    // backdropFilter: 'blur(0.5rem)',
    // willChange: 'backdrop-filter',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1200,
  },
};
interface IStyledModal {
  maxWidth: number | string | undefined | null;
  btnWidth: number | string | undefined | null;
  heightModal: number | string | undefined | null;
  mobilePadding: number | string | undefined | null;
}
export const ModalWrapper = styled(Modal)<IStyledModal>`
  box-sizing: border-box;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: 'tabular-nums';
  line-height: 1.5715;
  list-style: 'none';
  font-feature-settings: 'tnum', 'tnum';
  pointer-events: none;
  position: relative;
  top: 30vh;
  max-width: 440px;
  margin: 0 auto;
  padding-bottom: 24px;
  backdrop-filter: unset !important;
  backdrop-filter: blur;

  ${Media.lessThan(Media.SIZE.SM)} {
    .btn-confirm {
      width: ${({ btnWidth = '20vh' }) => btnWidth} !important;
    }
    .ant-modal-body {
      height: 20vh !important;
      font-size: 12px !important;
      padding: 0px 20px !important;
    }
  }

  ${Media.lessThan(Media.SIZE.XXL)} {
    /* max-width: ${isTablet ? 'auto' : '330px'}; */
    max-width: ${({ maxWidth }) => (isTablet ? 'auto' : maxWidth || '330px')};
    padding: ${({ mobilePadding = '0px' }) => mobilePadding};
  }

  width: ${(theme: any) => {
    switch (theme.size) {
      case 'large':
        return '100%';
      default:
        return '55%';
    }
  }};
  .diamond-amount {
    text-align: center;
    margin-right: 20px;
    margin-bottom: 10px;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 5px;
    color: #0076e3;

    /* Color 1 */

    color: #0076e3;
  }
  .success-icon {
    right: 20px;
    text-align: center;
    height: 5vh;
  }
  .content-modal {
    text-align: center;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }

  .btn-confirm {
    background: #e3007f;
    border-radius: 24px;
    width: 33vh;
    height: 5.8vh;
    color: #ffffff;
  }
  .ant-modal-content {
    backdrop-filter: unset !important;
    border-radius: 1rem;
    border: 1px solid #787878;
    max-height: 80vh;
    /* overflow: ${(theme: any) => theme.overflow || 'auto'}; */
    overflow: auto;
    .ant-modal-header {
      height: 20px;
      border-bottom: unset;
      padding: unset;
    }
    .ant-modal-body {
      //  overflow-x: ${(theme: any) => theme.overflow || 'auto'};
      // max-height: calc(80vh - 60vh)
      /* height: 20vh; */
      height: ${({ heightModal = '20vh' }) => heightModal} !important;
      padding: 10px 20px !important;
      overflow-y: auto;
    }
    .ant-modal-footer {
      display: flex;
      justify-content: center;
      height: 10vh;
      border-top: unset;

      #modal-confirm-popup-container {
        flex: auto;
        display: flex;
        justify-content: center;

        .btn-confirm {
          width: 100%;
        }
      }
      .button__cancel {
        margin-right: 10px;
      }
    }
  }
`;

const Error = styled.div`
  background-color: ${({ theme }) => theme.bg_error};
  border: 1px solid ${({ theme }) => theme.bd_error};
  padding: 8px 16px;
`;

const ConfirmComponent = ({
  title,
  visible,
  onCancel,
  children,
  onSubmit,
  disabledSubmit,
  onSubmitText,
  type,
  confirm = true,
  loadingSubmit = false,
  isLoadingSubmit,
  confirmClick,
  isNotFooterButton = false,
  onClose,
  textContent,
  subTextContent,
  subText,
  diamondFlg = false,
  diamondAmount = 0,
  statusConfirm = true,
  subTextPayment,
  subTextLoginSubTextForgot,
  ...rest
}: any) => {
  const { t } = useTranslation(['common']);
  const renderModalBodyBaseOnType = () => {
    switch (type) {
      case 'error':
        return <Error>{children}</Error>;
      case 'custom':
        return children;
      default:
        return (
          <>
            {statusConfirm ? (
              <>
                <div className="success-icon">
                  <img src={SUCCESS_ICON} alt="success" />
                </div>
                <br />
              </>
            ) : (
              <>
                <div className="success-icon">
                  <img src={FAILURE_ICON} alt="success" />
                </div>
                <br />
              </>
            )}
            {diamondFlg && (
              <div className="diamond-amount">
                <img src={DIAMOND} alt="success" />
                &ensp;<span>{diamondAmount}</span>
              </div>
            )}
            <div className="content-modal">
              {/* <h3>{t('message.verification_success')}</h3> */}
              <h3>
                <b style={isMobile ? { fontSize: '13px' } : {}}>{textContent}</b>
              </h3>
              {subTextContent && (
                <h3>
                  <b style={isMobile ? { fontSize: '13px' } : {}}>{subTextContent}</b>
                </h3>
              )}
              {subText && <p>{subText}</p>}
              {subTextLoginSubTextForgot && <p>{subTextLoginSubTextForgot}</p>}
              {subTextPayment && <p style={{ color: '#787878' }}>{subTextPayment}</p>}
            </div>
            <br />
          </>
        );
    }
  };

  const renderButtonSubmit = () => {
    if (confirm) {
      return (
        <div id="modal-confirm-popup-container">
          <ConfigProvider getPopupContainer={(): any => document.getElementById('modal-confirm-popup-container')}>
            <Button
              className="btn-confirm"
              htmlType="button"
              // style={isTablet ? { width: "28vh" } : {}}
              onClick={confirmClick}
              disabled={disabledSubmit}
            >
              {onSubmitText || t('ok')}
            </Button>
          </ConfigProvider>
        </div>
      );
    }
    if (loadingSubmit) {
      return (
        <Spin spinning={isLoadingSubmit} wrapperClassName="button">
          <Button type="primary" htmlType="button" disabled={disabledSubmit} onClick={() => onSubmit()}>
            {onSubmitText || t('ok')}
          </Button>
        </Spin>
      );
    }
    return (
      <Button type="primary" htmlType="button" disabled={disabledSubmit} onClick={() => onSubmit()}>
        {onSubmitText || t('ok')}
      </Button>
    );
  };

  return (
    <ModalWrapper
      isOpen={visible}
      onRequestClose={onClose}
      destroyOnClose={onClose}
      style={customStyles}
      ariaHideApp={false}
      {...rest}
    >
      <form>
        <div className="ant-modal-content">
          <div className="ant-modal-header">
            <button className="ant-modal-close" onClick={onClose || onCancel} type="button">
              {/* <div className="ant-modal-close-x"> */}
              {/*	<div className="anticon anticon-close ant-modal-close-icon"> */}
              {/*		<CloseOutlined /> */}
              {/*	</div> */}
              {/* </div> */}
            </button>
            {title && (
              <div
                style={{
                  height: 36,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: 0,
                  fontSize: 24,
                }}
              >
                <EditOutlined />
                <span
                  style={{
                    fontSize: 24,
                    fontWeight: 700,
                    padding: 0,
                    lineHeight: 1.2,
                    marginTop: 5,
                  }}
                >
                  &nbsp;
                  {title}
                </span>
              </div>
            )}
          </div>
          <div className="ant-modal-body">{renderModalBodyBaseOnType()}</div>

          {!isNotFooterButton && (
            <div className="ant-modal-footer">
              {/* {cancel && ( */}
              {/*	<button type="button" onClick={onCancel} className="ant-btn button__cancel"> */}
              {/*		<span>{onCancelText || t('cancel')}</span> */}
              {/*	</button> */}
              {/* )} */}
              {renderButtonSubmit()}
            </div>
          )}
        </div>
      </form>
    </ModalWrapper>
  );
};

export default ConfirmComponent;
