import styled from 'styled-components';
import Media from '@themes/media';

export const PaymentSuccessStyled = styled.div`
  padding-left: 34px;

  .row-btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  ${Media.lessThan(Media.SIZE.LG)} {
    padding-left: initial;
  }
`;
