import React from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { MenuProps } from 'antd';
import { NAVBAR_MOBILE } from '@constants/nav';

export interface MenuPropsCustom {
  key: React.Key | string;
  path?: string;
  display: string;
  hidden?: boolean;
}
export type MenuItem = Required<MenuProps & MenuPropsCustom>['items'][number];

export type IGetMenu = (t: TFunction) => MenuProps['items'];

export type RecursiveMenu = (
  menus: MenuProps['items'],
  menu: any,
  onFilter: (menu: MenuProps & MenuPropsCustom) => boolean,
) => MenuProps['items'];

export type SelectedType = Array<{
  key: string;
  path: string;
  display: string;
  label?: string;
}>;

function getItem(
  key: React.Key | string,
  label: React.ReactNode | string,
  display: string,
  path?: string,
  icon?: React.ReactNode,
  hidden?: boolean,
  subRoute?: MenuItem[],
): MenuItem {
  const menuItem = {
    key,
    icon,
    subRoute,
    path,
    label,
    display,
    hidden,
  };
  return menuItem;
}

const getMenus: IGetMenu = (t) => {
  const transalte = {
    list_event: t('common:navbar.list_event'),
    diamond_purchase: t('common:navbar.diamond_purchase'),
    history: t('common:navbar.history'),
    topics: t('common:navbar.topics'),
  };

  const listRouterPath = NAVBAR_MOBILE.map((item) =>
    getItem(item.key, <Link to={item.pathName}>{transalte[item.trans]}</Link>, transalte[item.trans], item.pathName),
  );

  return listRouterPath;
};

const useSiderBarHook = (): {
  getSelectedMenu: (menus: MenuProps['items']) => SelectedType;
  getDisplayMenu: (menus: MenuProps['items']) => MenuProps['items'];
  menuDisplay: MenuProps['items'];
  menuSelected: MenuProps['items'];
} => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const onGetShowedMenu: RecursiveMenu = (menus, menu, onFilter) => {
    menu.subRoute = (menu.subRoute ?? []).reduce((a, i) => onGetShowedMenu(a, i, onFilter), []);

    if (!menu.subRoute.length) delete menu.subRoute;

    if (onFilter(menu) || menu.subRoute) {
      menus?.push(menu);
    }

    return menus;
  };

  const getDisplayMenu = (listMenu): MenuProps['items'] => {
    return listMenu?.reduce((a, i) => onGetShowedMenu(a, i, (x) => !x?.hidden), []);
  };

  const selectedMenu: RecursiveMenu = (menus, menu, onFilter) => {
    menu.subRoute = (menu.subRoute || []).reduce((a, i) => selectedMenu(a, i, onFilter), []);

    if (pathname === '/') {
      if (menu.key === 'topics') {
        menus?.push(menu);
      }
    }

    if (!menu.subRoute.length) delete menu.subRoute;

    if ((onFilter(menu) || menu.subRoute) && menus) {
      menus.push(menu);
    }

    return menus;
  };

  const getKeyArray = (menus): SelectedType => {
    const keys: SelectedType = [];

    menus.forEach((item) => {
      if (item?.key) {
        keys.push({
          key: item?.key ?? '',
          path: item?.path ?? '',
          display: item?.display ?? '',
          label: item?.label ?? '',
        });

        if (item?.subRoute?.length) {
          keys.push(...getKeyArray(item?.subRoute));
        }
      }
    });

    return keys;
  };

  const getSelectedMenu = (menus): SelectedType => {
    const selectedMenus: MenuProps['items'] = menus.reduce(
      (a, i) =>
        selectedMenu(a, i, (x) => {
          return pathname.includes(x?.key as string);
        }),
      [],
    );

    const getKeySelected: SelectedType = getKeyArray(selectedMenus);

    return getKeySelected;
  };

  return {
    getSelectedMenu,
    getDisplayMenu,
    menuDisplay: getMenus(t),
    menuSelected: getMenus(t),
  };
};

export default useSiderBarHook;
