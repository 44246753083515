import React, { useEffect } from 'react';
import { Typography } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';
import { isMobile, isTablet } from 'react-device-detect';

import { Button } from '@components';
import { numberWithCommas } from '@utils/number';
import { useEvents, useRealEventPayment } from '@hooks';
import { SUCCESS_ICON_SVG, COIN_ICON } from '@assets';
import { PaymentCoinSuccessStyled } from './styled';
import { TextCoin } from '../../styled';

type Props = {
  totalCoinPrice?: number;
  paymentPreCode?: string;
  onClick?: () => void;
  isPayCoin?: boolean;
  isPayPre?: boolean;
};

interface Params {
  eventId?: string;
}

const PaymentCoinSuccess: React.FC<Props> = ({ totalCoinPrice, paymentPreCode, onClick, isPayCoin, isPayPre }) => {
  const history = useHistory();
  const { reset } = useEvents();
  const params: Params = useParams();
  const { dataPaymentSuccess } = useRealEventPayment();
  const { t } = useTranslation();
  const { realEventPayment } = translations;

  useEffect(() => {
    if (isMobile || isTablet) {
      const section = document.querySelector('#scroll-view');
      section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  return (
    <PaymentCoinSuccessStyled id="scroll-view">
      <SUCCESS_ICON_SVG style={{ marginBottom: '24px' }} />
      {isPayCoin && (
        <TextCoin className="text-coin-bottom">
          <COIN_ICON style={{ width: '35px', height: '35px', marginRight: '5px' }} />
          {numberWithCommas(totalCoinPrice, ',')}
        </TextCoin>
      )}

      <Typography className="text-message">
        {isPayPre ? t(realEventPayment.payment_pre_mess_success) : t(realEventPayment.payment_coin_mess_success)}
      </Typography>

      <div className="box-btn">
        <Button
          onClick={() => {
            reset();
            history.push('/list-event');
          }}
          type="outline"
        >
          {t(realEventPayment.go_to_list_event)}
        </Button>
        <Button
          onClick={() => {
            reset();
            if (onClick) {
              onClick();
            } else {
              history.push(`/histories/ticket/${params?.eventId}/${paymentPreCode || dataPaymentSuccess}`);
            }
          }}
        >
          {t(realEventPayment.see_ticket_detail)}
        </Button>
      </div>
    </PaymentCoinSuccessStyled>
  );
};

export default PaymentCoinSuccess;
