import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Colors from '@themes/colors';
import { PAYMENT_TOPIC_STATUS } from '@constants/histories';
import { translations } from '@i18n/translations';

interface Props {
  status: number | null;
  refund_status: number | null;
}

const { Text: TextAntd } = Typography;

const Text = styled(TextAntd)`
  font-size: 18px;
`;

const PaymentStatus: React.FC<Props> = ({ status, refund_status }) => {
  const { t } = useTranslation();
  const { histories } = translations;
  if (refund_status)
    return <Text style={{ color: Colors.text_process }}>{t(histories.topic_payment_status.refund)}</Text>;
  switch (status) {
    case PAYMENT_TOPIC_STATUS.SUCCEEDED:
      return <Text style={{ color: Colors.text_success }}>{t(histories.topic_payment_status.successed)}</Text>;
    case PAYMENT_TOPIC_STATUS.PROCESSING:
      return <Text style={{ color: Colors.text_process }}>{t(histories.topic_payment_status.processing)}</Text>;
    case PAYMENT_TOPIC_STATUS.REFUND:
      return <Text style={{ color: Colors.text_process }}>{t(histories.topic_payment_status.refund)}</Text>;
    case PAYMENT_TOPIC_STATUS.FAILED:
      return <Text style={{ color: '#D80027' }}>{t(histories.topic_payment_status.failed)}</Text>;
    default:
      return <div />;
  }
};

export default PaymentStatus;
