import {
  getEndedEvents,
  getEventDetail,
  getEvents,
  getHightLightEvent,
  preOrderPurchase,
  searchEvent,
} from '@apis/event.api';
import { FAILURE, REQUEST, SUCCESS } from '@stores';
import { Action } from '@type/Store';
import { put, takeLatest } from 'redux-saga/effects';
import {
  LOAD_ENDED_EVENTS,
  LOAD_EVENTS,
  LOAD_EVENT_DETAIL,
  LOAD_HIGHT_LIGHT_EVENTS,
  PRE_ORDER_PURCHASE,
  SEARCH_EVENT,
} from './constants';

export function* getHightLightEventsSaga() {
  try {
    const { data } = yield getHightLightEvent();
    yield put({
      type: SUCCESS(LOAD_HIGHT_LIGHT_EVENTS),
      payload: {
        hightLightEvents: data,
      },
    });
  } catch (error) {
    yield put({
      type: FAILURE(LOAD_HIGHT_LIGHT_EVENTS),
      error,
    });
  }
}

export function* getEventsSaga({ payload }: Action) {
  try {
    const { data, paginate } = yield getEvents(payload);

    yield put({
      type: SUCCESS(LOAD_EVENTS),
      payload: {
        events: data,
        paginate,
      },
    });
  } catch (error) {
    yield put({
      type: FAILURE(LOAD_EVENTS),
      error,
    });
  }
}

export function* getEndedEventsSaga({ payload }: Action) {
  try {
    const { data, paginate } = yield getEndedEvents(payload);
    yield put({
      type: SUCCESS(LOAD_ENDED_EVENTS),
      payload: {
        endEvents: data,
        paginate,
      },
    });
  } catch (error) {
    yield put({
      type: FAILURE(LOAD_ENDED_EVENTS),
      error,
    });
  }
}

export function* getEventDetailSaga({ payload }: Action) {
  try {
    const { data } = yield getEventDetail(payload);
    yield put({
      type: SUCCESS(LOAD_EVENT_DETAIL),
      payload: {
        eventDetail: data,
      },
    });
  } catch (error) {
    yield put({
      type: FAILURE(LOAD_EVENT_DETAIL),
      error,
    });
  }
}

// export function* clearCurrentSelectionTicketSaga() {
//   yield put({
//     type: SUCCESS(CLEAR_USER_SELECT_TICKET),
//     payload: {
//       formTicketData: undefined,
//     },
//   });
// }

export function* preOrderPurchaseSaga({ payload }: Action) {
  try {
    const { tickets, paymentMethod, id, unit, preOrder, memo, address, zip_code, first_name, last_name, phone_number } =
      payload;
    const res = yield preOrderPurchase({
      pay_unit: unit,
      ticket: tickets.map((item) => ({
        ticket_type_id: item.ticketId,
        quantity: item.quantity,
      })),
      payment_method: paymentMethod,
      real_event_id: id,
      pre_order: preOrder,
      memo,
      address,
      zip_code,
      first_name,
      last_name,
      phone_number,
    });

    yield put({
      type: SUCCESS(PRE_ORDER_PURCHASE),
      payload: res?.data,
    });
  } catch (error) {
    yield put({
      type: FAILURE(LOAD_EVENT_DETAIL),
      error,
    });
  }
}

export function* searchEventsSaga({ payload }: Action) {
  try {
    const { data, paginate } = yield searchEvent(payload);
    yield put({
      type: SUCCESS(SEARCH_EVENT),
      payload: {
        data,
        paginate,
      },
    });
  } catch (error) {
    yield put({
      type: FAILURE(LOAD_EVENTS),
      error,
    });
  }
}

export default function* eventSaga() {
  yield takeLatest(REQUEST(LOAD_HIGHT_LIGHT_EVENTS), getHightLightEventsSaga);
  yield takeLatest(REQUEST(LOAD_EVENTS), getEventsSaga);
  yield takeLatest(REQUEST(LOAD_HIGHT_LIGHT_EVENTS), getEndedEventsSaga);
  yield takeLatest(REQUEST(LOAD_EVENT_DETAIL), getEventDetailSaga);
  yield takeLatest(REQUEST(LOAD_ENDED_EVENTS), getEndedEventsSaga);
  // yield takeLatest(REQUEST(CLEAR_USER_SELECT_TICKET), clearCurrentSelectionTicketSaga);
  yield takeLatest(REQUEST(PRE_ORDER_PURCHASE), preOrderPurchaseSaga);
  yield takeLatest(REQUEST(SEARCH_EVENT), searchEventsSaga);
}
