import Media from '@themes/media';
import { Tabs as TabsAntd } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 300px;
  height: 360px;
  border-radius: 10px 10px 0px 0px;
  flex-direction: column;
  ${Media.lessThan(Media.SIZE.LG)} {
    height: 100%;
    border-radius: 0;
    width: 100vw;
  }
`;

export const Tabs = styled(TabsAntd)`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;

  .ant-tabs-nav {
    margin: 0;
    padding: 12px;
    ${Media.lessThan(Media.SIZE.LG)} {
      border-radius: 0;
      border-top: 1px solid #d9d9d9;
    }
    .ant-tabs-nav-wrap {
      justify-content: space-around;
      .ant-tabs-nav-list {
        .ant-tabs-tab,
        .ant-tabs-tab-active {
          padding: 6px;
          .ant-tabs-tab-btn {
            font-family: 'Noto Sans JP';
            font-size: 14px;
            font-weight: 500;
          }
        }
        .ant-tabs-tab {
          .ant-tabs-tab-btn {
            color: #787878;
          }
        }
        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: #282727;
          }
        }
      }
    }
    .ant-tabs-ink-bar {
      background: #e3007f;
    }
    &::before {
      border: none;
      ${Media.lessThan(Media.SIZE.LG)} {
        border-radius: 0;
        border-bottom: 1px solid #d9d9d9;
        /* padding: 0; */
        /* width: 100vw; */
      }
    }
  }
  .ant-tabs-content-holder {
    display: flex;
    flex: 1;
    height: 100%;
    background: #efeef3;
    padding: 12px 0px;

    ${Media.lessThan(Media.SIZE.LG)} {
      background: white;
    }
    .ant-tabs-content {
      height: 100%;
      width: 100%;
      display: flex;
      word-break: break-all;

      .ant-tabs-tabpane {
        height: 100%;
        width: 100%;
      }
    }
  }

  ${Media.lessThan(Media.SIZE.LG)} {
  }
`;
