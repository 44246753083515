import { initializeApp } from 'firebase/app';
import { getEnv } from '@config/env';

import { getDatabase, ref, remove, update, push } from 'firebase/database';

const FIRE_BASE_API_KEY = getEnv('FIRE_BASE_API_KEY', '');
const FIRE_BASE_DOMAIN = getEnv('FIRE_BASE_DOMAIN', '');
const DATABASE_URL = getEnv('DATABASE_URL', '');
const PROJECT_ID = getEnv('PROJECT_ID', '');
const STOGRAGE_BUCKET = getEnv('STOGRAGE_BUCKET', '');
const MESSAGEGING_SENDER_ID = getEnv('MESSAGEGING_SENDER_ID', '');
const APP_ID = getEnv('APP_ID', '');
const MEASUREMENT_ID = getEnv('MEASUREMENT_ID', '');

// use in development
const config = {
  apiKey: FIRE_BASE_API_KEY,
  authDomain: FIRE_BASE_DOMAIN,
  databaseURL: DATABASE_URL,
  projectId: PROJECT_ID,
  storageBucket: STOGRAGE_BUCKET,
  messagingSenderId: MESSAGEGING_SENDER_ID,
  appId: APP_ID,
  measurementId: MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(config);
export const database = getDatabase(app);

export const createItem = (path, body) => push(ref(database, path), body);
export const updateItem = (path, body) => update(ref(database, path), body);
export const removeItem = (path) => remove(ref(database, `${path}`));

export * from 'firebase/database';
