import React, { useEffect } from 'react';
import { Spin } from 'antd';
import BannerSlider from '@components/bannerSlider';
import { useEvents } from '@hooks';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';

import { TYPE_LIST_EVENT } from '@constants/real-event';
import Search from '@components/form/Search';
import { useHistory } from 'react-router-dom';
import EventNoData from './components/event-no-data';
import Events from './components/events';
import { ListEventOpening } from './components/list-event';
import { ListEventClosed } from './components/list-event-close';

import { Container, Wrapper } from './styles';

const ListEvent: React.FC = () => {
  const { t } = useTranslation();
  const { common, realEventPayment, listEvent } = translations;
  const history = useHistory();
  const {
    hightLightEvents,
    events,
    endEvents,
    isLoadingForEvent,
    isLoadingForEndEvent,
    getHightLightEventAction,
    getEventsAction,
  } = useEvents();

  useEffect(() => {
    getHightLightEventAction();
    getEventsAction({});
  }, []);
  const onSearch = ({ search }) => history.push(`/search-event?search=${search || ''}`);
  return (
    <Wrapper>
      <BannerSlider data={hightLightEvents} />
      <Search
        name="search"
        onSearch={onSearch}
        placeholder={t(realEventPayment.searchPlaceholder)}
        wrapperStyle={{ paddingTop: hightLightEvents?.length > 0 ? '0px' : '50px' }}
      />
      <Container>
        <h1 className="event-list-title">{t(realEventPayment.ticket_good_list)}</h1>
        <Spin spinning={isLoadingForEvent}>
          {events && events?.length > 0 ? (
            <Events key="grid" rows={2} data={events} typeList={TYPE_LIST_EVENT.LIST_EVENT} />
          ) : (
            <EventNoData text={t(listEvent.message_list_event_no_data)} />
          )}
        </Spin>

        <h2 className="event-end-title">{t(common.event.end_event)}</h2>
        <Spin spinning={isLoadingForEndEvent}>
          {endEvents && endEvents?.length > 0 ? (
            <Events key="flex" rows={1} data={endEvents} typeList={TYPE_LIST_EVENT.LIST_EVENT_CLOSED} />
          ) : (
            <EventNoData text={t(listEvent.message_list_event_close_no_data)} />
          )}
        </Spin>
      </Container>
    </Wrapper>
  );
};

export { ListEvent, ListEventOpening, ListEventClosed };
