/* eslint-disable react/prop-types */
import { Form, Input } from 'antd';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const WrapperFormItem = styled(Form.Item)`
  height: max-content;
  width: 100%;
  margin-bottom: 10px;

  .ant-input {
    min-height: 49px;
    border-radius: 10px;
  }
  .ant-input-affix-wrapper {
    padding: unset !important;
    width: 100%;
    color: #323232;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #787878;
    background-color: ${({ theme }) => theme.white};
    border-radius: 10px;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;

    &:focus,
    &:hover {
      color: #323232;
      border-color: rgba(120, 120, 120, 0.3);
      outline: 0;
      box-shadow:
        inset 0 1px 1px rgb(0 0 0 / 8%),
        0 0 0 0.1rem rgb(120 120 120 / 25%);
    }
  }
  .ant-input-affix-wrapper:focus {
    box-shadow:
      inset 0 1px 2px rgb(0 0 0 / 8%),
      0 0 0 0.25rem rgb(108 93 211 / 25%);
  }

  .ant-input-suffix {
    right: 12px !important;
    position: absolute;
    bottom: 16px;
  }

  .ant-form-item-label {
    font-size: 14px;
    overflow: unset;
    white-space: unset;
    .ant-form-item-no-colon {
      height: 100%;
    }
  }
`;

const WrapperLabel = styled.div`
  width: 100%;
  font-size: 13px;
`;

const FormPassword: React.FC<any> = ({
  label,
  name,
  rules,
  placeholderValue,
  defaultValue = '',
  wrapperProps,
  ...rest
}: any) => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  return (
    <WrapperFormItem
      {...wrapperProps}
      label={label && <WrapperLabel>{label}</WrapperLabel>}
      validateStatus={error ? 'error' : ''}
      help={error?.message ? t(`${error.message}`) : ''}
    >
      <Input.Password name={name} onChange={onChange} value={value} {...rest} placeholder={placeholderValue} />
    </WrapperFormItem>
  );
};

export default FormPassword;
