import { Modal, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TYPE_CONFIRM } from '@constants/auth';
import ButtonComponent from '@components/button';
import React from 'react';

const ModalStyled = styled(Modal)`
  max-width: 403px;
  max-height: 190px;

  .ant-modal-content {
    border-radius: 9px;

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .ant-typography {
        text-align: center;
        margin-bottom: 20px;
        font-size: 16px;
        color: #787878;
      }
    }
  }
`;

const ModalReSend: React.FC<any> = ({ open, onCancel, type }) => {
  const { t } = useTranslation();
  return (
    <ModalStyled open={open} footer={false} onCancel={onCancel} centered>
      {type === TYPE_CONFIRM.CONFIRM ? (
        <Typography>{t('register:over_times_resend_code')}</Typography>
      ) : type === TYPE_CONFIRM.RESEND ? (
        <Typography>{t('register:resent_code')}</Typography>
      ) : (
        <Typography>{t('register:verify_code_not_found')}</Typography>
      )}

      <ButtonComponent
        onClick={() => {
          onCancel();
        }}
      >
        OK
      </ButtonComponent>
    </ModalStyled>
  );
};

export default ModalReSend;
