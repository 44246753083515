/* eslint-disable no-console */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';
import { useAuth, useTopics } from '@hooks';
import { Row, Spin, Tabs } from 'antd';
import { convertToCurrentTimeZone } from '@utils/time';
import { CHAPTER_FEE_TYPE, CHAPTER_TYPE, LIVESTREAM_STATUS, MODAL_TYPE, PAYMENT_STATUS } from '@constants';
import { Chapter, TopicDetail } from '@type/Store/topics';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { isMobile, isTablet } from 'react-device-detect';
import { Wrapper, Container, NoData } from './styled';
import { ModalConfirm } from '../modal-confirm';
import { ChapterComponent } from '../chapter';
import { VideoPackageComponent } from '../video-package';

interface IChapters {
  topicDetail: TopicDetail;
  isTopList?: boolean;
}

const ListChapters: React.FC<IChapters> = ({ isTopList, topicDetail }) => {
  const { isUser, authenticated, isLiver } = useAuth();
  const { checkPassword, password, getVideoPackage, videoPackage } = useTopics();
  const { t } = useTranslation();
  const { push } = useHistory();
  const { topic, common, topic_payment } = translations;
  const [chapterItem, setChapterItem] = useState<Chapter | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<{ title?: string; textContent?: string; type?: MODAL_TYPE }>();
  const chapters = topicDetail?.chapters;
  useEffect(() => {
    if (password.chapter_id && chapterItem) {
      setShowModal(false);
      if (chapterItem && chapterItem.live_key && chapterItem.chapter_status === 2) {
        push(`/topics/watch-livestream?room=${chapterItem?.live_key}`, { state: null });
      } else {
        push(`/topics/watch-video-chapter/${chapterItem?.id}?type=TOPIC`, { state: null });
      }
    }
  }, [password, chapterItem]);

  const onSubmitPassword = (password) => {
    checkPassword({ id: chapterItem?.id || 0, password });
  };

  const handleProcessing = () => {
    setShowModal(true);
    setModalType({
      title: '',
      textContent: t(topic_payment.waiting_for_payment),
      type: MODAL_TYPE.CONFIRM,
    });
  };

  const handleBuyLiveStream = (chapter: Chapter) => {
    const purchased = chapter.payment_status === PAYMENT_STATUS.SUCCEEDED;
    const isLiving = chapter.chapter_status === LIVESTREAM_STATUS.LIVE;
    const liveIsComing = chapter.chapter_status === LIVESTREAM_STATUS.COMING;
    const isFree = Number(chapter?.amount) === 0 && !chapter.password_flag;
    if (liveIsComing) {
      const isSameOrAfterCurrent = moment().isSameOrAfter(convertToCurrentTimeZone(chapter.public_start));
      setShowModal(true);
      setModalType({
        title: '',
        textContent: isSameOrAfterCurrent ? t(topic.adminNotYetTurnOn) : t(topic.beforPublicStartTime),
        type: MODAL_TYPE.CONFIRM,
      });
    }
    if (isUser && isLiving && (isFree || purchased)) {
      push(`/topics/watch-livestream?room=${chapter?.live_key}`, { state: null });
    }

    if (isLiver && isLiving && !chapter.password_flag) {
      push(`/topics/watch-livestream?room=${chapter?.live_key}`, { state: null });
    }

    if (isLiving && !isFree && !purchased && !chapter.password_flag) {
      push(`/topics/${chapter.topic_id}/payment/${chapter.id}?type=LIVE`, { state: null });
    }
    if (isLiving && chapter.password_flag) {
      setShowModal(true);
      setModalType({ title: t(topic.popupEnterPasswordTitle), type: MODAL_TYPE.INPUT });
    }
  };

  const handleBuyChapter = (chapter: Chapter) => {
    const purchased = chapter.payment_status === PAYMENT_STATUS.SUCCEEDED;
    const isFree =
      chapter.chapter_fee_type === CHAPTER_FEE_TYPE.FREE || chapter.chapter_fee_type === CHAPTER_FEE_TYPE.FREE_A_PATH;
    if (chapter.password_flag) {
      setShowModal(true);
      setModalType({ title: t(topic.popupEnterPasswordTitle), type: MODAL_TYPE.INPUT });
    }

    if (isLiver && !chapter.password_flag) {
      push(`/topics/watch-video-chapter/${chapter?.id}?type=TOPIC`, { state: null });
    }

    if (isUser && !isFree && !purchased && !chapter.password_flag) {
      push(`/topics/${chapter.topic_id}/payment/${chapter?.id}?type=TOPIC`, { state: null });
    }

    if (!chapter.password_flag && isLiver) {
      push(`/topics/watch-video-chapter/${chapter?.id}?type=TOPIC`, { state: null });
    }

    if (isUser && !chapter.password_flag && (purchased || isFree)) {
      push(`/topics/watch-video-chapter/${chapter?.id}?type=TOPIC`, { state: null });
    }
  };

  const handleShowModal = (chapter: Chapter) => {
    setChapterItem(chapter);
    const isFree =
      chapter.chapter_fee_type === CHAPTER_FEE_TYPE.FREE || chapter.chapter_fee_type === CHAPTER_FEE_TYPE.FREE_A_PATH;
    const isLiveStream = chapter.live_key && chapter.chapter_type === CHAPTER_TYPE.LIVESTREAM;
    const waitingForPayment = chapter.payment_status === PAYMENT_STATUS.PROCESSING;
    if (!authenticated && !isFree) {
      push(`/topics/${chapter.topic_id}/payment/${chapter?.id}?type=TOPIC`, { state: null });
    }

    if (!authenticated && isFree) {
      push(`/topics/watch-video-chapter/${chapter?.id}?type=TOPIC`, { state: null });
    }

    if (!authenticated && chapter.password_flag) {
      push(`/topics/watch-video-chapter/${chapter?.id}?type=TOPIC`, { state: null });
    }

    if (waitingForPayment && authenticated) {
      handleProcessing();
    }
    if (!waitingForPayment && isLiveStream && authenticated) {
      handleBuyLiveStream(chapter);
    }
    if (!waitingForPayment && !isLiveStream && authenticated) {
      handleBuyChapter(chapter);
    }
  };

  const handleCancelModal = () => setShowModal(false);

  const onTabClick = (key: string) => {
    if (key === 'packages' && topicDetail?.id) {
      getVideoPackage({ id: topicDetail?.id });
    }
    const params = new URLSearchParams();
    params.set('page', key);
    push({ search: params.toString() });
  };

  const getQueryParamValue = (param: string) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(param);
  };
  const currentPage = useMemo(() => getQueryParamValue('page'), [topicDetail?.id]);

  useEffect(() => {
    if (currentPage === 'packages' && topicDetail?.id) {
      getVideoPackage({ id: topicDetail?.id });
    }
  }, [currentPage, topicDetail]);

  return (
    <Wrapper>
      {isTopList ? (
        <Container style={{ padding: '0px' }}>
          <div className="title">{t(topic.episode_list)}</div>
          <Row gutter={[16, 16]}>
            {chapters?.map((chapter) => (
              <ChapterComponent key={chapter.id} chapter={chapter} handleShowModal={handleShowModal} isTopList />
            ))}
          </Row>
        </Container>
      ) : (
        <Container>
          <Tabs defaultActiveKey={currentPage || 'episodes'} onChange={onTabClick} centered={!!(isMobile || isTablet)}>
            <Tabs.TabPane tab={t(topic.episode_list)} key="episodes">
              <Row gutter={[16, 16]}>
                {chapters?.map((chapter) => (
                  <ChapterComponent
                    key={chapter.id}
                    chapter={chapter}
                    handleShowModal={handleShowModal}
                    isTopList={false}
                  />
                ))}
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t(topic.packages)} key="packages" style={{ minHeight: '300px' }}>
              <Spin spinning={videoPackage.isLoading}>
                <Row gutter={[16, 16]}>
                  {videoPackage.data &&
                    videoPackage?.data?.map((chapter) => <VideoPackageComponent key={chapter.id} video={chapter} />)}
                </Row>
              </Spin>
              {videoPackage.data && !videoPackage.data.length && <NoData>{t(common.no_data)}</NoData>}
            </Tabs.TabPane>
          </Tabs>
        </Container>
      )}

      <ModalConfirm
        title={modalType?.title}
        onCancel={handleCancelModal}
        onConfirm={handleCancelModal}
        onSubmitPassword={onSubmitPassword}
        textContent={modalType?.textContent}
        type={modalType?.type}
        open={!!showModal}
      />
    </Wrapper>
  );
};

export { ListChapters };
