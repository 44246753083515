import React from 'react';
import { useUserJoinTopic } from '@hooks/useUserJoinTopic';
import { Wrapper } from './styled';
import User from '../user';

interface IProps {
  roomId?: string | null;
}

export const UserList: React.FC<IProps> = ({ roomId }) => {
  const { members } = useUserJoinTopic({ roomId });
  return (
    <Wrapper>
      {members.map((item, index) => (
        <User key={item.id} {...item} position={index + 1} />
      ))}
    </Wrapper>
  );
};

export default UserList;
