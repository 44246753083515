import React, { useMemo } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { CONBINI_CODE } from '@constants/my-ticket';

import { BoxCodeStyled } from '@modules/history/ticket-detail/styled';
import { translations } from '@i18n/translations';
import { PaymentProcessStyled } from './styled';

interface Props {
  konbiniCode: number | null;
  receiptCode: number;
  receiptUrl: string;
  type?: string;
  isConbini?: boolean;
  gmo_error_code?: string[] | null;
}

const PaymentProcess: React.FC<Props> = ({ konbiniCode, receiptCode, receiptUrl, type, isConbini, gmo_error_code }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { gmo_payment, myTicket } = translations;
  const failed_message = useMemo(() => {
    if (gmo_error_code && gmo_error_code.length > 0) {
      return gmo_error_code.map((code) => t(gmo_payment[code])).join('\n');
    }
    return t(myTicket.my_ticket_detail.message_payment_fail);
  }, [gmo_error_code, language]);

  return (
    <PaymentProcessStyled>
      {isConbini && (
        <>
          <Typography className="title">{t('myTicket:my_ticket_detail.conbini_info')}</Typography>
          <BoxCodeStyled>
            <Typography className="label">{t('myTicket:my_ticket_detail.receipt_number')}</Typography>
            <Typography className="code">{receiptCode}</Typography>
          </BoxCodeStyled>
        </>
      )}
      {type !== 'fail' && konbiniCode?.toString() === CONBINI_CODE.SEVEN_ELEVEN && (
        <Typography className="receipt-link">
          {t('myTicket:my_ticket_detail.online_payment')}
          <a href={receiptUrl} target="_blank" rel="noreferrer">
            {t('myTicket:my_ticket_detail.here')}
          </a>
        </Typography>
      )}

      {type === 'fail' && (
        <>
          <Typography className="text-fail">{t('myTicket:my_ticket_detail.status_fail')}</Typography>
          <Typography className="text-fail">{failed_message}</Typography>
        </>
      )}
    </PaymentProcessStyled>
  );
};

export default PaymentProcess;
