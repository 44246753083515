import Media from '@themes/media';
import styled from 'styled-components';

export const Title = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.size_40};
  font-weight: ${({ theme }) => theme.fw_700};
  line-height: 45px;
  padding: 50px 0px 32px 0px;

  ${Media.lessThan(Media.SIZE.MD)} {
    padding: 22px 0 0 0;
    line-height: normal;
    font-size: ${({ theme }) => theme.size_16};
  }
`;

export const Wrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding-bottom: 100px;

  .btn-show-more {
    text-align: center;
  }

  ${Media.lessThan(Media.SIZE.MD)} {
    padding: 0 12px;
    padding-bottom: 32px;
  }
`;
