import Media from '@themes/media';
import styled from 'styled-components';

export const EventTicketStyle = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  height: 330px;
  max-width: 384px;
  margin: 12px;
  padding: 16px;
  text-align: start;

  .ticket-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .cover-img {
    object-fit: cover;
    border-radius: 15px;
    max-width: 100%;
    max-height: 100%;
  }

  .image-container {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .content {
    flex: 1;
    flex-wrap: wrap;

    .title,
    .description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: ${({ theme }) => theme.size_14};
      color: ${({ theme }) => theme.time};
      overflow-wrap: break-word;
    }

    .title {
      font-weight: ${({ theme }) => theme.fw_700};
      font-size: ${({ theme }) => theme.size_16};
      color: ${({ theme }) => theme.dark};
      margin-top: 1rem;
    }
  }

  time {
    color: ${({ theme }) => theme.time};
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    padding: 8px;
    height: 250px;
    border-radius: 10px;

    .image-container {
      height: 84px;
      position: relative;
    }

    .cover-img {
      border-radius: 10px;
    }

    .content {
      .title {
        margin-top: 0.5rem;
        margin-bottom: 0.25rem;
        font-size: ${({ theme }) => theme.size_14};
      }
      .description {
        margin-bottom: 0.75rem;
      }
    }

    time {
      font-size: ${({ theme }) => theme.size_12};
      padding: 0 0.25rem;
    }
  }
`;

export const Marker = styled.div<{ background?: string }>`
  position: absolute;
  padding: 6px 25px;
  background: ${({ background = '#d80027' }) => background};
  color: white;
  top: 10px;
  left: 10px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;

  ${Media.lessThan(Media.SIZE.SM)} {
    padding: 2px 7px;
    font-size: 12px;
  }
`;
