/* eslint-disable arrow-parens */
/* eslint-disable implicit-arrow-linebreak */

/**
 * The global state selectors
 */

import { Store } from '@type/Store';
import { RealEventPaymentState } from '@type/Store/real-event-purchase';
import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectRealEventPayment = (store: Store) => store.realEventPayment || initialState;

const makeSelectRealEventPayment = () =>
  createSelector(selectRealEventPayment, (state: RealEventPaymentState) => state);

export { selectRealEventPayment, makeSelectRealEventPayment };
