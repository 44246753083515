import { Modal, Typography } from 'antd';
import styled from 'styled-components';

export const TicketDetailStyle = styled.div``;
export const UserInfoStyle = styled.div``;
export const Group = styled.div``;
export const PaymentInfoStyle = styled.div``;

export const TitleComfirmStyled = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 26px;
  font-family: 'Noto Sans JP';
`;

export const TextContentStyled = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 28px;
  font-family: 'Noto Sans JP';
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 24px;
    width: auto;
  }

  .ant-modal-body {
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .ant-btn {
      height: 44px;
    }
    form {
      width: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
    .password-input {
      color: ${({ theme }) => theme.red};
    }
  }
  .btn-group {
    margin-top: 32px;
    gap: 16px;
  }
`;
