import React from 'react';
import { Avatar } from '@components';
import { FORMAT_TIME } from '@constants';
import { convertToCurrentTimeZone } from '@utils/time';
import { useAuth } from '@hooks';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';
import { Wrapper, Message, UserName, Infor, TimeComment, ButtonGroup, BtnEdit, BtnDelete } from './styled';

interface IProps {
  user_name?: string | null;
  user_avatar?: string | null;
  created_at?: string | null;
  content?: string | null;
  user_id?: number | null;
  onEditMsg: () => void;
  onDeleteMsg: () => void;
}
export const ChatUser: React.FC<IProps> = ({
  content,
  created_at,
  user_name,
  user_avatar = '',
  user_id,
  onDeleteMsg,
  onEditMsg,
}) => {
  const { profile } = useAuth();
  const { t } = useTranslation();
  const { common } = translations;

  return (
    <Wrapper>
      <Avatar src={user_avatar || ''} width={28} isBorder={false} />
      <Message>
        <Infor>
          <UserName>{user_name} </UserName>
          <TimeComment> {convertToCurrentTimeZone(created_at, FORMAT_TIME.DATE_HOUR_MINUTES)}</TimeComment>
        </Infor>
        {content}
        {user_id === profile?.id && (
          <ButtonGroup>
            <BtnEdit onClick={onEditMsg}>{t(common.btn_edit)}</BtnEdit>
            <BtnDelete onClick={onDeleteMsg}>{t(common.delete)}</BtnDelete>
          </ButtonGroup>
        )}
      </Message>
    </Wrapper>
  );
};

export default ChatUser;
