import { REQUEST, SUCCESS, createReducer } from '@stores';
import { updateReducer } from '@stores/redux';

import { Store } from '@type/Store';
import { createSelector } from 'reselect';

import { CreditCardInfo, KobiniInfo, TopicInfo, TopicPaymentState, UserInfo } from '@type/Store/topic-payment';
import {
  GET_PAYMENT_INFO,
  SET_USER_INFO,
  SET_KONBINI_INFO,
  SET_CREDIT_CARD_INFO,
  PURCHASED,
  BUY_CHAPTER,
  ERROR,
  LOADING,
  RESET_DATA,
  SET_DEEPLINK,
} from './constants';

const initialState: TopicPaymentState = {
  topic_info: {
    diamond_price: '',
    yen_price: '',
    title: null,
    description: null,
    thumbnail: null,
    live_key: null,
    isLive: false,
    payment_status: 0,
    balance_code: null,
    topic_title: null,
    topic_description: null,
    topic_thumbnail: null,
  },
  user_info: {
    memo: '',
    phone_number: '',
    zip_code: '',
    address: '',
    first_name: '',
    last_name: '',
    email: '',
  },
  konbini_info: {
    first_name_kana: '',
    last_name_kana: '',
    convenience: '',
    receiptNo: '',
  },
  credit_card_info: {
    card_number: '',
    cvc: '',
    date_expiry: '',
    cardholder: '',
    token: '',
  },
  deeplink: null,
  payment_method: 0,
  loading: false,
  error: null,
  purchased: false,
};

const selectState = (store: Store) => store.topicPayment || initialState;

const makeSelectTopics = () => createSelector(selectState, (state: TopicPaymentState) => state);

export { makeSelectTopics };

function setLoading(state: TopicPaymentState, { payload }) {
  return updateReducer({
    ...state,
    loading: payload,
  });
}

function setError(state: TopicPaymentState, { payload }) {
  return updateReducer({
    ...state,
    error: payload,
  });
}

const getPaymentInfo = (state: TopicPaymentState) => {
  return updateReducer({
    ...state,
  });
};

const getPaymentInfoSuccess = (state: TopicPaymentState, { payload }: { payload: TopicInfo }) => {
  return updateReducer({
    ...state,
    topic_info: payload,
  });
};

const setUserInfo = (
  state: TopicPaymentState,
  { payload }: { payload: { user_info: UserInfo; payment_method: number } },
) => {
  return updateReducer({
    ...state,
    ...payload,
  });
};

const setKonbiniInfo = (
  state: TopicPaymentState,
  { payload }: { payload: { konbini_info: KobiniInfo; payment_method: number } },
) => {
  return updateReducer({
    ...state,
    ...payload,
  });
};

const setCreditCardInfo = (
  state: TopicPaymentState,
  { payload }: { payload: { credit_card_info: CreditCardInfo; payment_method: number } },
) => {
  return updateReducer({
    ...state,
    ...payload,
  });
};

const buyChapter = (state: TopicPaymentState) => {
  return updateReducer({
    ...state,
    purchased: false,
  });
};

const purchased = (state: TopicPaymentState, { payload }: { payload: { balance_code: string; receiptNo: string } }) => {
  return updateReducer({
    ...state,
    purchased: true,
    topic_info: {
      ...state.topic_info,
      balance_code: payload.balance_code,
    },
    konbini_info: {
      ...state.konbini_info,
      receiptNo: payload.receiptNo,
    },
  });
};

const setDeeplink = (state: TopicPaymentState, { payload }: { payload: string }) => {
  return updateReducer({
    ...state,
    deeplink: payload,
  });
};

const resetData = () => {
  return updateReducer({
    ...initialState,
  });
};

export default createReducer(initialState, {
  [REQUEST(LOADING)]: setLoading,
  [REQUEST(ERROR)]: setError,
  [REQUEST(RESET_DATA)]: resetData,

  [REQUEST(SET_USER_INFO)]: setUserInfo,
  [REQUEST(SET_KONBINI_INFO)]: setKonbiniInfo,
  [REQUEST(SET_CREDIT_CARD_INFO)]: setCreditCardInfo,
  [REQUEST(SET_DEEPLINK)]: setDeeplink,

  [REQUEST(GET_PAYMENT_INFO)]: getPaymentInfo,
  [SUCCESS(GET_PAYMENT_INFO)]: getPaymentInfoSuccess,

  [REQUEST(BUY_CHAPTER)]: buyChapter,
  [SUCCESS(PURCHASED)]: purchased,
});
