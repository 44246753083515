import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Spin } from 'antd';
import { translations } from '@i18n/translations';

import { useAuth } from '@hooks';
import { Button } from '@components';
import { DIAMOND } from '@assets';
import { numberWithCommas } from '@utils/number';
import { useTopicPayment } from '@hooks/useTopicPayment';
import { Group, TicketDetailStyle, Wrapper } from './styled';

type Props = {
  onConfirm: () => void;
  topicId: string | null;
  packageId: string | null;
};

const Confirm: React.FC<Props> = ({ topicId, packageId, onConfirm }) => {
  const { profile } = useAuth();
  const { topic_info, loading } = useTopicPayment();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');
  const { replace } = useHistory();
  const { t } = useTranslation();
  const { realEventPayment: realEventPaymentTrans, topic } = translations;

  const goBack = () => replace(`/topics/${topicId}/payment/${packageId}?type=${type}`);

  return (
    <Wrapper>
      <Spin spinning={loading}>
        <TicketDetailStyle>
          <Group>
            <label>{t(topic.topics)}</label>
            <p>{topic_info.topic_title}</p>
          </Group>
          <Group>
            <label>{t(topic.episode)}</label>
            <p>{topic_info.title}</p>
          </Group>
          <Group>
            <label>{t(realEventPaymentTrans.user_name)}</label>
            <p>{profile?.name}</p>
          </Group>
          <Group>
            <label>{t(realEventPaymentTrans.email)}</label>
            <p>{profile?.email}</p>
          </Group>
          <Group>
            <label>{t(realEventPaymentTrans.payment_method)}</label>
            <p>{t(topic.payment_diamond)}</p>
          </Group>
          <Group>
            <label>{t(realEventPaymentTrans.total_amount_notFee)}</label>
            <div className="price">
              <img src={DIAMOND} alt="diamond" width={22} />
              <span style={{ color: '#0076E3' }}>
                &ensp;
                {numberWithCommas(topic_info.diamond_price, ',')}
              </span>
            </div>
          </Group>
          <div className="box-btn">
            <Button onClick={goBack} type="cancel">
              {t(realEventPaymentTrans.cancel)}
            </Button>
            <Button onClick={onConfirm} loading={loading}>
              {t(realEventPaymentTrans.continue)}
            </Button>
          </div>
        </TicketDetailStyle>
      </Spin>
    </Wrapper>
  );
};
export default Confirm;
