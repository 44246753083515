import Media from '@themes/media';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 32px 50px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  max-width: 1500px;
  font-family: 'Noto Sans JP';

  ${Media.lessThan(Media.SIZE.LG)} {
    padding: 0;
    min-width: 320px;
    width: 100%;
    flex-direction: column;
  }
`;

export const Livestream = styled.div`
  display: flex;
  flex: 1;
  margin-right: 8px;
  border-radius: 6px;
  flex-direction: column;
  padding: 0;
  background-color: white;

  ${Media.lessThan(Media.SIZE.LG)} {
    margin: 0;
    border-radius: 0;
  }
`;

export const Video = styled.video`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  ${Media.lessThan(Media.SIZE.LG)} {
    margin: 0;
    border-radius: 0;
  }
`;

export const VideoStream = styled.div`
  display: flex;
  width: 100%;
  background: #3e2933;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  position: relative;
  ${Media.lessThan(Media.SIZE.LG)} {
    margin: 0;
    border-radius: 0;
  }
`;

export const BlurBackground = styled.div<{ background?: string }>`
  display: flex;
  width: 100%;
  height: 100%;
  background: ${({ background }) => `url("${background}")` || 'transparent'};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  filter: blur(6px);
  -webkit-filter: blur(6px);
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  ${Media.lessThan(Media.SIZE.LG)} {
    margin: 0;
    border-radius: 0;
  }
`;

export const EndLive = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;

  p {
    color: #ffffff;
    font-weight: bold;
    font-size: 18px;
    background: rgba(2, 2, 4, 0.3);
    margin-top: 4px;
    padding: 2px 12px;
    border-radius: 50px;
  }

  ${Media.lessThan(Media.SIZE.LG)} {
    margin: 0;
    border-radius: 0;
  }
`;
