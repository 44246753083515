import Media from '@themes/media';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 300px;
  border-radius: 10px;
  flex-direction: column;
  background-color: white;
  height: 720px;

  ${Media.lessThan(Media.SIZE.LG)} {
    max-height: 480px;
    border-radius: 0;
    width: 100vw;
    background: white;
  }
`;
