import React, { useState } from 'react';

import Paragraph from 'antd/lib/typography/Paragraph';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';
import { ImageBannerTop } from '@modules/topics/detail/styled';
import Image from '@components/imageFallback';
import { useTopicPayment } from '@hooks/useTopicPayment';
import { Content } from './styles';

const TopicContent: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const { common } = translations;
  const { topic_info } = useTopicPayment();

  const [expandable, setExpandable] = useState(true);
  return (
    <Content>
      <ImageBannerTop>
        <Image src={topic_info.thumbnail || ''} alt="Image topic" />
      </ImageBannerTop>
      <div className="content-top">
        <div className="title"> {topic_info.title}</div>
        {expandable && (
          <Paragraph
            className="content"
            ellipsis={{
              rows: 3,
              expandable: true,
              symbol: t(common.see_more),
              onExpand: () => setExpandable(false),
            }}
          >
            {topic_info.description}
          </Paragraph>
        )}
        {!expandable && (
          <Paragraph className="content" ellipsis={false}>
            {topic_info.description}
            <span onClick={() => setExpandable(true)} className="ant-typography-expand">
              {t(common.see_less)}
            </span>
          </Paragraph>
        )}
      </div>
      <div className="content-child">{children}</div>
    </Content>
  );
};
export default TopicContent;
