/* eslint-disable react/prop-types */
import { Form, Input, InputProps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseControllerProps, useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

interface FormInputProps extends UseControllerProps {
  label?: string;
}

const WrapperFormItem = styled(Form.Item)`
  height: max-content;
  width: 100%;
  margin-bottom: 10px;

  .ant-input {
    min-height: 49px;
    border-radius: 10px;
  }

  .ant-form-item-label {
    font-size: 14px;
    overflow: unset;
    white-space: unset;
    .ant-form-item-no-colon {
      height: 100%;
    }
    .ant-input-affix-wrapper:focus {
      box-shadow: unset !important;
    }
  }
`;

const WrapperLabel = styled.div`
  width: 100%;
  font-size: 13px;
`;

const FormInput: React.FC<FormInputProps & InputProps> = ({ label, name, rules, defaultValue = '', ...rest }) => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  return (
    <WrapperFormItem
      label={label && <WrapperLabel>{label}</WrapperLabel>}
      validateStatus={error ? 'error' : ''}
      help={error?.message ? t(`${error.message}`) : ''}
    >
      <Input onBlur={onBlur} name={name} onChange={onChange} value={value} {...rest} />
    </WrapperFormItem>
  );
};

export default FormInput;
