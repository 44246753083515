import React from 'react';
import { Event } from '@type/Store/event';
import { formatDateTimeOfWeek } from '@utils/time';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Image from '@components/imageFallback';
import { translations } from '@i18n/translations';
import { Marker, EventTicketStyle } from './styles';

type TicketProps = {
  data: Event;
};

enum EventStatus {
  'ACTIVE' = 1,
  'SHOWING' = 2,
  'FINISH' = 3,
  'CANCEL' = 4,
}

const Ticket: React.FC<TicketProps> = ({ data }) => {
  const { i18n, t } = useTranslation();
  const { realEventPayment } = translations;

  const eventType = () => {
    switch (data.status) {
      case EventStatus.ACTIVE:
        return <Marker background="#00CEBC">{t(realEventPayment.upcoming)}</Marker>;
      case EventStatus.SHOWING:
        return <Marker background="#E3007F">{t(realEventPayment.ongoing)}</Marker>;
      case EventStatus.FINISH:
        return <Marker>{t(realEventPayment.ended)}</Marker>;
      case EventStatus.CANCEL:
        return <Marker>{t(realEventPayment.Cancelled)}</Marker>;
      default:
        return null;
    }
  };

  return (
    <EventTicketStyle>
      <Link to={`events/${data.id}`} className="ticket-item">
        <div>
          <div className="image-container">
            <Image className="cover-img" src={data?.thumbnail} alt={data?.title} height={141} width={352} />
            {eventType()}
          </div>
          <div className="content">
            <h4 className="title">{data?.title}</h4>
            <p className="description">{data?.content}</p>
          </div>
        </div>

        <time>
          {formatDateTimeOfWeek(data?.start_at, i18n.language)} - {formatDateTimeOfWeek(data?.end_at, i18n.language)}
        </time>
      </Link>
    </EventTicketStyle>
  );
};
export default Ticket;
