import { Stylesheet } from '@themes';
import Colors from '@themes/colors';
import Media from '@themes/media';
import styled from 'styled-components';
import { Layout } from 'antd';

const { Content } = Layout;

export const Wrapper = styled(Content)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  font-family: 'Noto Sans JP';

  ${Media.lessThan(Media.SIZE.MD)} {
    padding: 20px 15px;
    min-width: 320px;
  }
`;

const FormWrapper = styled.form`
  display: flex;
  max-width: 1024px;
  width: 100%;
  background-color: ${({ theme }) => theme.white};
  justify-content: center;
  margin: 40px 20px;
  border-radius: 20px;
  flex-direction: column;
  .ant-input {
    padding: 0.5rem 1rem;
    color: #000000;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  ${Media.lessThan(Media.SIZE.MD)} {
    min-width: 320px;
    margin: 0;
    border-radius: 10px;

    .form-login {
      form {
        width: 100% !important;
      }
    }
    .ant-input {
      padding-left: 10px !important;
      color: #282727;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
    }
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  display: flex;
  white-space: pre;
  p {
    margin: 0;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 32px;
`;

const KobiniListWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  ${Media.lessThan(Media.SIZE.MD)} {
    flex-direction: column;
  }
`;

const KobiniItem = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${Media.lessThan(Media.SIZE.MD)} {
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    padding: 12px;
  }
`;

const KobiniLogo = styled.div`
  position: relative;
`;

const Form = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 320px;
  padding: 32px;
  flex-wrap: wrap;
  justify-content: space-between;

  ${Media.lessThan(Media.SIZE.MD)} {
    flex-direction: column;
    padding: 12px;
  }
`;

const styles: Stylesheet = {
  title: {
    color: Colors.dark,
    fontSize: 20,
    fontWeight: '500',
  },
  borderBottom: {
    borderBottom: '1px solid #E5E6EB',
  },
};

export { FormWrapper, Row, Title, Col, styles, Header, KobiniListWrapper, Form, KobiniItem, KobiniLogo };
