/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect } from 'react';
import { Switch, useLocation, useHistory } from 'react-router-dom';

import PrivateRoute from '@components/route/privateRoute';
import CustomRoute from '@components/route/customRoute';

import Loading from '@components/loading';

import { ROUTES } from '@modules/auth/routes';
import { useEvents, useRoot } from '@hooks';
import NotFoundPage from '@modules/page-not-found';
import PublicRoute from '@components/route/publicRoute';
import { useTopicPayment } from '@hooks/useTopicPayment';
import { PRIVATE_ROUTES, CUSTOME_ROUTE } from './constant';

const checkEventPath = ['/events/', '/payment-credit/', '/payment-kobini/', '/order-confirm/', 'payment-coin'];

const AppRoutes: React.FC = () => {
  const { reset: resetEvent } = useEvents();
  const { reset: resetTopicPayment } = useTopicPayment();
  const { is404 } = useRoot();
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const isNotReset = checkEventPath.filter((path) => pathname.includes(path)).length > 0;
    if (pathname && !isNotReset) {
      resetEvent();
    }
    if (pathname && !pathname.includes('/payment')) {
      resetTopicPayment();
    }
  }, [pathname]);
  useEffect(() => {
    if (is404) {
      history.replace('/page-not-found');
    }
  }, [is404]);

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {PRIVATE_ROUTES.map((routeConfig) => (
          <PrivateRoute key={routeConfig.path} {...routeConfig} />
        ))}
        {ROUTES.map((routeConfig) => (
          <PublicRoute key={routeConfig.path} {...routeConfig} />
        ))}
        {CUSTOME_ROUTE.map((routeConfig) => (
          <CustomRoute key={routeConfig.path} {...routeConfig} />
        ))}
        <CustomRoute path="*" component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
};
export default AppRoutes;
