// import { LOCATION_CHANGE } from 'connected-react-router';

import { FAILURE, REQUEST, SUCCESS, updateObject, createReducer } from '@stores';
import { Action } from '@type/Store';
import { EventState } from '@type/Store/event';

import {
  LOAD_HIGHT_LIGHT_EVENTS,
  LOAD_EVENTS,
  LOAD_ENDED_EVENTS,
  LOAD_EVENT_DETAIL,
  SAVE_USER_SELECT_TICKET,
  CLEAR_USER_SELECT_TICKET,
  PRE_ORDER_PURCHASE,
  RESET,
  SEARCH_EVENT,
} from './constants';

export const initialState: EventState = {
  isLoading: false,
  error: null,
  endEvents: [],
  hightLightEvents: [],
  eventsPaginate: { current_page: 1, last_page: 0, next: 0, per_page: 9, prev: 0, total: 0 },
  endEventsPaginate: { current_page: 1, last_page: 0, next: 0, per_page: 9, prev: 0, total: 0 },
  dataPaymentSuccess: '',
  events: [],
  eventDetail: {
    id: 0,
    title: '',
    thumbnail: '',
    content: '',
    status: 0,
    sale_type: 0,
    start_at: 0,
    end_at: 0,
    start_sale_at: 0,
    end_sale_at: 0,
    open_at: 0,
    close_at: 0,
    start_show_at: 0,
    end_show_at: 0,
    ticket: [],
    payment_methods: [],
  },
  ticketsData: undefined,
  isPaymentPreOrder: false,
  isLoadingForEvent: false,
  isLoadingForEndEvent: false,
  eventsSearch: {
    data: [],
    paginate: {
      current_page: 1,
      last_page: 0,
      next: 0,
      per_page: 9,
      prev: 0,
      total: 0,
    },
    loading: false,
  },
};

function getEventHightLight(state: EventState) {
  return updateObject(state, {
    isLoading: true,
    ticketsData: undefined,
    isPaymentPreOrder: false,
    dataPaymentSuccess: '',
    eventDetail: initialState.eventDetail,
  });
}
function getEventHightLightSuccess(state: EventState, { payload }: Action) {
  const { hightLightEvents } = payload;
  return updateObject(state, {
    isLoading: false,
    hightLightEvents,
  });
}
function getEventHightLightErrors(state: EventState, { error }: Action) {
  return updateObject(state, {
    error,
    isLoading: false,
  });
}
function getEvents(state: EventState) {
  return updateObject(state, {
    isLoading: true,
    eventDetail: initialState.eventDetail,
    ticketsData: undefined,
    isPaymentPreOrder: false,
    dataPaymentSuccess: '',
    isLoadingForEvent: true,
  });
}
function getEventsSuccess(state: EventState, { payload }: Action) {
  const { events, paginate } = payload;
  return updateObject(state, {
    isLoading: false,
    events,
    eventsPaginate: paginate,
    isLoadingForEvent: false,
  });
}
function getEventErrors(state: EventState, { error }: Action) {
  return updateObject(state, {
    error,
    isLoading: false,
    isLoadingForEvent: false,
  });
}
function getEndedEvents(state: EventState) {
  return updateObject(state, {
    isLoading: true,
    ticketsData: undefined,
    isPaymentPreOrder: false,
    dataPaymentSuccess: '',
    eventDetail: initialState.eventDetail,
    isLoadingForEndEvent: true,
  });
}
function getEndedEventSuccess(state: EventState, { payload }: Action) {
  const { endEvents, paginate } = payload;
  return updateObject(state, {
    isLoading: false,
    endEvents,
    endEventsPaginate: paginate,
    isLoadingForEndEvent: false,
  });
}
function getEndedEventErrors(state: EventState, { error }: Action) {
  return updateObject(state, {
    error,
    isLoading: false,
    isLoadingForEndEvent: false,
  });
}
function getEventDetail(state: EventState) {
  return updateObject(state, {
    isLoading: true,
  });
}
function getEventDetailErrors(state: EventState, { error }: Action) {
  return updateObject(state, {
    error,
    isLoading: false,
  });
}
function getEventDetailSuccess(state: EventState, { payload }: Action) {
  const { eventDetail } = payload;

  return updateObject(state, {
    isLoading: false,
    eventDetail,
  });
}

function saveCurrentSelectionTicket(state: EventState, { payload }: Action) {
  return updateObject(state, {
    ticketsData: payload,
    error: null,
  });
}

function clearCurrentSelectionTicket(state: EventState) {
  return updateObject(state, {
    ticketsData: undefined,
  });
}

function preOrderPurchase(state: EventState) {
  return updateObject(state, {
    isLoading: true,
    error: null,
    isPaymentPreOrder: false,
  });
}

function preOrderPurchaseSuccess(state: EventState, { payload }: Action) {
  return updateObject(state, {
    isLoading: false,
    isPaymentPreOrder: true,
    dataPaymentSuccess: payload,
  });
}

function resetState(state: EventState): EventState {
  return updateObject(state, {
    ...initialState,
    isLoadingForEvent: state.isLoadingForEvent,
    isLoadingForEndEvent: state.isLoadingForEndEvent,
    eventsSearch: state.eventsSearch,
  });
}

function searchEvents(state: EventState) {
  return updateObject(state, {
    error: null,
    eventsSearch: {
      ...initialState.eventsSearch,
      loading: true,
    },
  });
}

function searchEventsSuccess(state: EventState, { payload }: Action) {
  return updateObject(state, {
    isLoading: false,
    eventsSearch: {
      ...payload,
      loading: false,
    },
  });
}

export default createReducer(initialState, {
  [REQUEST(LOAD_HIGHT_LIGHT_EVENTS)]: getEventHightLight,
  [SUCCESS(LOAD_HIGHT_LIGHT_EVENTS)]: getEventHightLightSuccess,
  [FAILURE(LOAD_HIGHT_LIGHT_EVENTS)]: getEventHightLightErrors,

  [REQUEST(LOAD_EVENTS)]: getEvents,
  [SUCCESS(LOAD_EVENTS)]: getEventsSuccess,
  [FAILURE(LOAD_EVENTS)]: getEventErrors,

  [REQUEST(LOAD_ENDED_EVENTS)]: getEndedEvents,
  [SUCCESS(LOAD_ENDED_EVENTS)]: getEndedEventSuccess,
  [FAILURE(LOAD_ENDED_EVENTS)]: getEndedEventErrors,

  [REQUEST(LOAD_EVENT_DETAIL)]: getEventDetail,
  [SUCCESS(LOAD_EVENT_DETAIL)]: getEventDetailSuccess,
  [FAILURE(LOAD_EVENT_DETAIL)]: getEventDetailErrors,

  [REQUEST(SAVE_USER_SELECT_TICKET)]: saveCurrentSelectionTicket,
  [REQUEST(CLEAR_USER_SELECT_TICKET)]: clearCurrentSelectionTicket,

  [REQUEST(PRE_ORDER_PURCHASE)]: preOrderPurchase,
  [SUCCESS(PRE_ORDER_PURCHASE)]: preOrderPurchaseSuccess,

  [REQUEST(SEARCH_EVENT)]: searchEvents,
  [SUCCESS(SEARCH_EVENT)]: searchEventsSuccess,

  [REQUEST(RESET)]: resetState,

  // [LOCATION_CHANGE]: resetState,
});
