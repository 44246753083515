import styled from 'styled-components';

export const VideoStyled = styled.div<{ display: string }>`
  width: 100%;
  position: relative;

  .btn-action {
    transition: opacity 1s;
    display: none;
    display: ${({ display }) => display};
  }

  &:hover {
    .btn-action {
      display: flex;
    }
  }
`;

export const Video = styled.video`
  width: 100%;
  object-fit: cover;
  aspect-ratio: 16/9;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

export const Controls = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  position: absolute;
  bottom: 20px;
  .slider {
    display: flex;
    flex: 1;
    flex-direction: column;
    border-radius: 6px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-right: 8px;
    .ant-slider-track,
    .ant-slider-handle {
      background-color: rgb(227, 0, 127);
    }
  }
`;

export const VideoTime = styled.div`
  width: 75px;
  color: white;
  text-align: center;
  padding-top: 3px;
`;

export const FullScreenICon = styled.img`
  cursor: pointer;
`;

export const PlayVideoIcon = styled.img`
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s;
`;
