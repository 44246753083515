/* eslint-disable arrow-parens */
/* eslint-disable implicit-arrow-linebreak */

/**
 * The global state selectors
 */

import { Store } from '@type/Store';
import { createSelector } from 'reselect';
import { TopicsState } from '@type/Store/topics';
import { initialState } from './reducer';

const selectState = (store: Store) => store.topics || initialState;

const makeSelectTopics = () => createSelector(selectState, (state: TopicsState) => state);

export { makeSelectTopics };
