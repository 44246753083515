import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Wrapper } from './styled';
import ChatBox from '../chatbox';
import Tabbar from '../tabbar';

interface IProps {
  roomId?: string | null;
}
export const Sider: React.FC<IProps> = ({ roomId }) => {
  return (
    <Wrapper>
      <Tabbar roomId={roomId} />
      {!isMobile && !isTablet && <ChatBox roomId={roomId} />}
    </Wrapper>
  );
};

export default Sider;
