import { REQUEST } from '@stores';
import { SIGNATURE, EVENT_TRANSFER, CONNECT_WALLET, RESET_STATE, ERC20_APPROVED } from './constants';

interface ISignnaturePayload {
  wallet_address: string;
  package_id: string;
  account: string;
  erc20Contract: any;
  iliveContract: any;
  web3: any;
}

export function getSignature(payload: ISignnaturePayload) {
  return {
    type: REQUEST(SIGNATURE),
    payload,
  };
}

export function connectWallet(payload: any) {
  return { type: REQUEST(CONNECT_WALLET), payload };
}

export function approveERC20Success(payload: any) {
  return { type: REQUEST(ERC20_APPROVED), payload };
}

export function eventTransfer(payload: any) {
  return {
    type: REQUEST(EVENT_TRANSFER),
    payload,
  };
}

export function resetState() {
  return {
    type: REQUEST(RESET_STATE),
    payload: null,
  };
}
