import { useDispatch, useSelector } from 'react-redux';

import {
  createPaymentPackageDiamond,
  getListPackage,
  getListPaymentHistory,
  getPaymentIntent,
} from '@modules/payment/store/action';
import reducer from '@modules/payment/store/reducer';
import saga from '@modules/payment/store/saga';
import { makeSelectRegistrationPayment } from '@modules/payment/store/selectors';
import { useInjectReducer, useInjectSaga } from '@stores';

export const PaymentIntentStripe = () => {
  useInjectSaga({ key: 'payment', saga });
  useInjectReducer({ key: 'payment', reducer });

  const { isLoading, error, paymentIntent, paymentStatus, packageList, packageListHistory, status }: any = useSelector(
    makeSelectRegistrationPayment(),
  );

  const dispatch = useDispatch();
  const getPaymentIntentAction = (payload?: any) => dispatch(getPaymentIntent(payload));
  const createPaymentPackageAction = (payload?: any) => dispatch(createPaymentPackageDiamond(payload));
  const getPaymentListPackageAction = (payload?: any) => dispatch(getListPackage(payload));
  const getPaymentListHistoryAction = (payload?: any) => dispatch(getListPaymentHistory(payload));
  return {
    isLoading,
    error,
    paymentIntent,
    paymentStatus,
    status,
    packageList,
    packageListHistory,
    getPaymentIntentAction,
    createPaymentPackageAction,
    getPaymentListPackageAction,
    getPaymentListHistoryAction,
  };
};
