import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import { Select, Form } from 'antd';
import { useController, useFormContext } from 'react-hook-form';

import { CountrySelectProps } from '@type/Form/RegisterForm';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';

const CountrySelect: React.FC<CountrySelectProps> = ({ name, rules, defaultValue = '' }: CountrySelectProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });
  const { t } = useTranslation();
  const { countries } = translations;

  return (
    <Form.Item validateStatus={error ? 'error' : ''} help={error?.message}>
      <Select showSearch optionFilterProp="children" value={value} onChange={onChange}>
        {getCountries().map((country) => (
          <Select.Option key={country} value={getCountryCallingCode(country)}>
            {t(countries[country]).toUpperCase()} +{getCountryCallingCode(country)}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default CountrySelect;
