/* eslint-disable no-console */
import React, { useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Skeleton } from 'antd';

import { useChat, sendMessage } from '@hooks/useChat';
import { serverTimestamp } from 'firebase/database';
import { useAuth } from '@hooks';
import { Clickable } from '@components';
import { translations } from '@i18n/translations';
import { useTranslation } from 'react-i18next';
import ChatUser from '../chat-user';
import { ChatList, Wrapper, SendMessage, Send } from './styled';

interface IProps {
  roomId?: string | null;
}

export const ChatBox: React.FC<IProps> = ({ roomId }) => {
  const infiniteScrollRef = useRef(null);
  const [value, setValue] = useState<string>('');
  const { profile } = useAuth();
  const { messages, loadMoreData, isEnded, LIMIT_CHAT_ITEM } = useChat({ roomId });
  const { livestream } = translations;
  const { t } = useTranslation();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const onPressEnter = () => {
    if (value.trim()) {
      sendMessage(roomId, {
        senderId: profile?.id || null,
        createdAt: serverTimestamp(),
        senderName: profile?.name || '',
        msg: value,
        senderAvatar: profile?.avatar || null,
        type: 0,
      })
        .then(() => {
          setValue('');
          const scrollable = document.getElementById('scrollableDiv');
          if (scrollable) {
            scrollable.scrollTo(0, 0);
          }
        })
        .catch((err) => console.log('====> error: ', err));
    }
  };

  return (
    <Wrapper>
      <ChatList id="scrollableDiv">
        <InfiniteScroll
          ref={infiniteScrollRef}
          dataLength={messages.length}
          next={loadMoreData}
          className="flatlist"
          inverse
          hasMore={messages.length >= LIMIT_CHAT_ITEM && !isEnded}
          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          endMessage={null}
          scrollableTarget="scrollableDiv"
        >
          {messages.map((item) => (
            <ChatUser key={item.createdAt} {...item} />
          ))}
        </InfiniteScroll>
      </ChatList>
      <SendMessage
        placeholder={t(livestream.chat_placeholder)}
        value={value}
        maxLength={500}
        bordered={false}
        onChange={onChange}
        onPressEnter={onPressEnter}
        suffix={
          <Clickable onClick={onPressEnter}>
            <Send isactive={!!value.trim()} />
          </Clickable>
        }
      />
    </Wrapper>
  );
};

export default ChatBox;
