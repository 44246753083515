import React from 'react';
import { Wrapper } from './styled';
import ChatBox from '../chatbox';
import ChatHeader from '../chat-header';

export const VideoComment: React.FC = () => {
  return (
    <Wrapper>
      <ChatHeader />
      <ChatBox />
    </Wrapper>
  );
};

export default VideoComment;
