import styled from 'styled-components';

export const PaymnetCoinNotEnoughStyled = styled.div`
  .box-warning {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .box-message {
      margin-bottom: 16px;
      width: 322px;
      background-color: #f0f2f4;
      padding: 19px 44px;
      border-radius: 9px;
      text-align: center;
    }
  }

  .text-message {
    font-size: 20px;
    margin-bottom: 45px;
    white-space: pre-wrap;
    text-align: center;
  }

  .box-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
