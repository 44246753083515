import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio, Spin } from 'antd';

import { Button, FormTextArea, TextInput } from '@components';
import { translations } from '@i18n/translations';
import { DIAMOND } from '@assets';
import { numberWithCommas } from '@utils/number';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { isMobile } from 'react-device-detect';
import { CARDS, KONBINIS } from '@modules/tickets-goods/detail/constants';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useTopicPayment } from '@hooks/useTopicPayment';
import { FORM_USER_INFO } from '@modules/topic-payment/constants';
import { useAuth } from '@hooks';
import { convertStringToHalfWidth } from '@utils/string';
import { Banks, Diamond, Wrapper, RadioStyled, Form, Companies } from './styled';

interface FormValues {
  memo: string;
  last_name: string;
  first_name: string;
  phone_number: string;
  zip_code: string;
  address: string;
  payment_method: number;
}

interface Params {
  topicId?: string;
  packageId?: string;
}

const defaultValues = {
  memo: '',
  last_name: '',
  first_name: '',
  phone_number: '',
  zip_code: '',
  address: '',
  payment_method: 0,
};

const PaymentAllowsLogin: React.FC = () => {
  const { t } = useTranslation();
  const { topic_info, loading, user_info, payment_method: method_payment, setUserInfo } = useTopicPayment();
  const { profile } = useAuth();
  const { push } = useHistory();
  const { pathname, search } = useLocation();
  const { topicId, packageId } = useParams<Params>();
  const queryParams = new URLSearchParams(search);
  const type = queryParams.get('type');

  const form = useForm<FormValues>({
    defaultValues,
  });

  const { handleSubmit, watch, control, setValue } = form;

  useEffect(() => {
    const keys = Object.keys(defaultValues) as Array<keyof FormValues>;
    keys.forEach((key) => {
      if (key === 'payment_method') setValue(key, method_payment || 0);
      else setValue(key, user_info[key] || '');
    });
  }, [user_info]);

  const onSubmit = handleSubmit((data) => {
    const { payment_method, ...user_info } = data;
    const phone_number = user_info.phone_number || profile?.phone || '';
    const convertPhoneToNumber = convertStringToHalfWidth(phone_number.replace(/\D/g, ''));

    setUserInfo({
      payment_method,
      user_info: {
        ...user_info,
        email: profile?.email || '',
        phone_number: convertPhoneToNumber,
      },
    });
    if (payment_method === 3) {
      push(`/topics/${topicId}/payment/${packageId}/diamond?type=${type}`, { state: pathname });
    }
    if (payment_method === 1) {
      push(`/topics/${topicId}/payment/${packageId}/credit?type=${type}`, { state: pathname });
    }
    if (payment_method === 2) {
      push(`/topics/${topicId}/payment/${packageId}/kobini?type=${type}`, { state: pathname });
    }
  });

  const {
    field: { onChange },
  } = useController({ name: 'payment_method', control, rules: { required: true } });

  const [payment_method] = watch(['payment_method']);
  const { common, realEventPayment, topic, topic_payment } = translations;

  return (
    <FormProvider {...form}>
      <form
        style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 32 }}
        onSubmit={onSubmit}
      >
        <Wrapper>
          <Radio.Group onChange={onChange} value={payment_method} className="group-payment-methods">
            <Diamond>
              <RadioStyled value={3}>{t(topic.diamond_payment)}</RadioStyled>
              <div className="price-coin" style={{ color: '#0096DC' }}>
                <Spin spinning={loading}>
                  <img src={DIAMOND} alt="diamond" width={22} />
                  {numberWithCommas(topic_info.diamond_price || 0, ',')}
                </Spin>
              </div>
              <div className="descriptions">
                <p>
                  {t(topic_payment.goto_diamond[0])}
                  <Link to="/diamond-purchase">
                    <span style={{ color: '#0076E3' }}> {t(topic_payment.goto_diamond[1])}</span>
                  </Link>
                </p>
              </div>
            </Diamond>
            <Banks>
              <div>
                <RadioStyled value={1}>{t(common.credit_card)}</RadioStyled>
              </div>
              <Spin spinning={loading}>
                <div className="price">￥ {numberWithCommas(topic_info.yen_price || 0, ',')}</div>
              </Spin>
              <div className="methods">
                {CARDS.map((item) => (
                  <img src={item.src} alt={item.alt} key={item.alt} />
                ))}
              </div>
              <div className="descriptions">
                <p>{t(common.event.payment_credit_card)}</p>
              </div>
            </Banks>
            <Companies disabled={topic_info.isLive}>
              <div>
                <RadioStyled value={2} disabled={topic_info.isLive}>
                  {t(common.event.company_payment)}
                </RadioStyled>
              </div>
              <div className="price">￥ {numberWithCommas(topic_info.yen_price, ',')}</div>
              <div className="methods">
                {KONBINIS.map(({ isIcon, src, alt, Icon }) => {
                  if (isIcon) return <Icon key={alt} />;
                  return <img src={src} alt={alt} key={alt} />;
                })}
              </div>
              <div className="descriptions">
                {KONBINIS.map(({ label }, index) => {
                  if (index < KONBINIS.length - 1) return <p key={label}>{t(label)}</p>;
                  return <p key={label}>{t(label)}</p>;
                })}
              </div>
              {topic_info.isLive && <div className="kobini-note">{t(common.konbini_note)}</div>}
            </Companies>
          </Radio.Group>
        </Wrapper>
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
          <Form>
            {FORM_USER_INFO.map((item) => (
              <TextInput
                width={isMobile || item.isFullWidth ? '100%' : '49%'}
                name={item.name}
                placeholder={t(realEventPayment[item.placeholder])}
                label={t(item.label)}
                translateField={translations.realEventPayment.fieldName[item.name]}
                cvvInfo={item.name === 'cvc'}
                key={item.name}
                inputFormat={item.inputFormat}
              />
            ))}
          </Form>
          <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
            <p style={{ fontSize: 14 }}>{t(realEventPayment.note)}</p>
            <FormTextArea
              style={{
                borderColor: 'rgba(180, 180, 180, 1)',
                display: 'flex',
                width: '100%',
                borderRadius: 10,
                padding: 8,
                height: 120,
              }}
              name="memo"
              maxLength={500}
              placeholder={t(realEventPayment.note_placeholder)}
            />
          </div>
        </div>
        <Button className="btn-purchase" htmlType="submit" disabled={!payment_method}>
          {t(common.button.purchase)}
        </Button>
      </form>
    </FormProvider>
  );
};
export default PaymentAllowsLogin;
