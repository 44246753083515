import styled from 'styled-components';

export const TicketQrStyled = styled.div`
  width: 100%;
  padding: 15px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: #f5f8fc;
  border-radius: 5px;

  .label {
    font-size: 20px;
    font-weight: 700;
    color: #0076e3;
  }

  .serie-code {
    font-size: 14px;
    font-weight: 400;
  }

  .check-status {
    color: #009b51;
  }
`;
