/* eslint-disable no-console */

import { signInTwitter, signInTwitterWithMobile, tweets } from '@apis';
import { STORAGE, removeLocalStorage, setLocalStorage } from '@utils';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';
import { getEnv } from '@config/env';
import { useAuth } from './auth';

export const useTwitter = () => {
  const { loadProfileAction } = useAuth();
  const { push } = useHistory();
  const { t } = useTranslation();
  const { livestream } = translations;
  const shareTwitter = ({ name, title, description, liverId = '', avatar = '', room, isSignIn = false }) => {
    const WEB_URL = getEnv('WEB_URL', null);

    try {
      const { branch } = window;
      const linkData = {
        campaign: 'share twitter',
        channel: 'twitter',
        feature: 'share',
        tags: [],
        data: {
          $desktop_url: `${WEB_URL}/topics/watch-livestream?room=${room}`,
          $ios_url: `${WEB_URL}/download`,
          $ipad_url: `${WEB_URL}/download`,
          $android_url: `${WEB_URL}/download`,
          $og_app_id: room,
          $og_title: `${name}${title || ''}`,
          $og_description: description || '',
          $og_image_url: avatar || '',
          $canonical_identifier: 'identify',
          liveKey: room,
          liverId,
          liverName: name,
          liverAvatar: avatar,
        },
      };

      branch.setIdentity('identify', (err) => {
        branch.link(linkData, async (linkError, deeplink) => {
          if (!err) {
            const shareMessage = `『${name}』\n${title}${
              description ? ` - ${description}` : ''
            }\n#MetaLive #LiveStream`;
            const body = JSON.stringify({
              text: `${shareMessage} \n\n ${deeplink}`,
            });
            tweets(body)
              .then(() => {
                if (isSignIn) {
                  loadProfileAction();
                  push(`/topics/watch-livestream?room=${room || ''}`);
                  removeLocalStorage(STORAGE.TWITTER);
                }
                notification.success({
                  message: t(livestream.twitter.shared),
                  duration: 3,
                });
              })
              .catch((twitterErr) => {
                if (
                  twitterErr &&
                  twitterErr.status === 403 &&
                  twitterErr.message === 'You are not allowed to create a Tweet with duplicate content.'
                ) {
                  notification.error({
                    message: t(livestream.twitter.duplicateContent),
                    duration: 3,
                  });
                } else {
                  notification.error({
                    message: twitterErr.message || '',
                    duration: 3,
                  });
                }
                if (isSignIn) {
                  loadProfileAction();
                  push(`/topics/watch-livestream?room=${room || ''}`);
                  removeLocalStorage(STORAGE.TWITTER);
                }
              });
          } else {
            console.log('====> err: ', linkError);
            throw err;
          }
        });
      });
    } catch (error: any) {
      console.log('====> error', error);
    }
  };

  const loginTwitter = ({ name, title, description, liverId = '', avatar = '', room }) => {
    signInTwitter()
      .then(({ data: response }) => {
        setLocalStorage(
          STORAGE.TWITTER,
          JSON.stringify({
            name,
            title,
            description,
            liverId,
            avatar,
            room,
          }),
        );
        // const link = document.createElement('a');
        // link.href = response.data;
        // link.target = 'popup';
        // link.onclick = () => {
        //   window.open(response.data, 'popup', 'width=600,height=600,scrollbars=no,resizeable=no');
        // };
        // link.click();

        window.location.href = response.data;
      })
      .catch((error) => console.log('=====> ERROR', error));
  };

  const loginTwitterWithMobile = ({ userToken = '' }) => {
    signInTwitterWithMobile(userToken)
      .then(({ data: response }) => {
        setLocalStorage(STORAGE.OAUTH_TOKEN, userToken);
        window.location.href = response.data;
      })
      .catch((error) => {
        if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
          window.ReactNativeWebView?.postMessage(
            JSON.stringify({
              signin_status: 'ERROR',
              ...error,
            }),
          );
        }
        console.log('=====> ERROR', error);
      });
  };
  return {
    shareTwitter,
    loginTwitter,
    loginTwitterWithMobile,
  };
};
