import Media from '@themes/media';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  font-family: 'Noto Sans JP';
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
  }
  ${Media.lessThan(Media.SIZE.LG)} {
  }
`;
