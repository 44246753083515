import { put, takeLatest } from 'redux-saga/effects';

import { createPaymentPackage, getListPackage, getListPaymentHistory, getPaymentIntent } from '@apis';
import { FAILURE, REQUEST, SUCCESS } from '@stores';

import { Action } from '@type/Store';
import { PAYMENT_LIST_HISTORY, PAYMENT_LIST_PACKAGE, PAYMENT_PACKAGE, VERIFICATION } from './constants';

export function* getPaymentIntentSaga({ payload }: Action) {
  try {
    const { data } = yield getPaymentIntent(payload);
    yield put({
      type: SUCCESS(VERIFICATION),
      payload: {
        paymentIntent: data,
      },
    });
  } catch (error) {
    yield put({
      type: FAILURE(VERIFICATION),
      error,
    });
  }
}

export function* createPaymentPackageSaga({ payload }: Action) {
  try {
    const { data: result } = yield createPaymentPackage(payload);
    yield put({
      type: SUCCESS(VERIFICATION),
      payload: {
        paymentStatus: result,
      },
    });
  } catch (error) {
    yield put({
      type: FAILURE(PAYMENT_PACKAGE),
      error,
    });
  }
}

export function* getPaymentListPackageSaga({ payload }: Action) {
  try {
    const { data } = yield getListPackage({
      isActive: payload.isActive,
      type: payload.type,
    });
    yield put({
      type: SUCCESS(PAYMENT_LIST_PACKAGE),
      payload: {
        packageList: data,
      },
    });
  } catch (error) {
    yield put({
      type: FAILURE(PAYMENT_LIST_PACKAGE),
      error,
    });
  }
}

export function* getPaymentListHistorySaga({ payload }: Action) {
  try {
    const { data } = yield getListPaymentHistory({
      perPage: payload.perPage,
      page: payload.page,
    });
    yield put({
      type: SUCCESS(PAYMENT_LIST_HISTORY),
      payload: {
        packageListHistory: data,
      },
    });
  } catch (error) {
    yield put({
      type: FAILURE(PAYMENT_LIST_HISTORY),
      error,
    });
  }
}

export default function* paymentSaga() {
  yield takeLatest(REQUEST(VERIFICATION), getPaymentIntentSaga);
  yield takeLatest(REQUEST(PAYMENT_PACKAGE), createPaymentPackageSaga);
  yield takeLatest(REQUEST(PAYMENT_LIST_PACKAGE), getPaymentListPackageSaga);
  yield takeLatest(REQUEST(PAYMENT_LIST_HISTORY), getPaymentListHistorySaga);
}
