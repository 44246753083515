import Media from '@themes/media';
import styled from 'styled-components';

export const Wrapper = styled.div`
  .event-list-title {
    text-align: center;
    font-size: ${({ theme }) => theme.size_40};
    font-weight: ${({ theme }) => theme.fw_700};
    line-height: 45px;
    padding: 40px;
  }
  .event-end-title {
    font-size: ${({ theme }) => theme.size_24};
    font-weight: ${({ theme }) => theme.fw_700};
    line-height: 45px;
    padding: 0 12px;
  }

  ${Media.lessThan(Media.SIZE.MD)} {
    .event-list-title,
    .event-end-title {
      text-align: left;
      padding: 22px 0 0 0;
      line-height: normal;
      font-size: ${({ theme }) => theme.size_16};
    }
  }
`;
export const Container = styled.div`
  max-width: 1300px;
  padding: 0 50px;
  margin: 0 auto;
  padding-bottom: 100px;

  .btn-show-more {
    text-align: center;
  }

  ${Media.lessThan(Media.SIZE.MD)} {
    padding: 0 12px;
    padding-bottom: 32px;
  }
`;
