import styled from 'styled-components';
import { Drawer } from 'antd';
import Media from '@themes/media';

export const SiderBarCardStyle = styled(Drawer)`
  .ant-drawer-header {
    display: none;
  }

  .ant-drawer-body {
    padding: initial;
    padding: 0 26px 50px 26px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .icon-expand {
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .icon-collapse {
      font-size: 24px;
    }
  }

  .mobile-login {
    min-width: 165px;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo-img {
      width: 100px;
      height: 66px;
    }

    .ant-dropdown-trigger {
      background-color: transparent;
      border: initial;
    }
  }

  .layout-menu {
    border-right: none;
    margin-top: 20px;

    .ant-menu-item {
      height: 42px;
      font-weight: 700;
      padding-left: initial !important ;

      &::after {
        border: unset;
      }
    }

    .ant-menu-item-selected {
      background-color: transparent;
      color: #e3007f;

      .ant-menu-title-content a {
        color: #e3007f;

        &::before {
        }
      }

      svg {
        path,
        circle {
          stroke: #ffffff;
        }
      }
    }

    .ant-menu-submenu-selected {
      .ant-menu-submenu-title {
        color: #0e8cfb;
        border-radius: 5px;
      }

      svg {
        path {
          stroke: #0e8cfb;
        }
      }

      .ant-menu-submenu-arrow {
        color: #0e8cfb;
      }
    }

    .ant-menu-inline {
      background-color: #ffffff;
    }
  }

  .auth {
    display: flex;
    align-items: center;

    .ant-btn {
      font-weight: 700;
    }

    ${Media.lessThan(Media.SIZE.LG)} {
      .info-user {
        margin-right: 20px;

        img {
          margin-right: 20px;
          border-radius: 50%;
        }
      }

      .ant-btn-primary {
        margin-right: 20px;
      }
    }

    ${Media.lessThan(Media.SIZE.SM)} {
      justify-content: space-between;
    }
  }
  .logo-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    gap: 10px;
  }
`;
