import Media from '@themes/media';
import { Layout } from 'antd';
import styled from 'styled-components';

const { Content } = Layout;

export const TopicPaymentStyle = styled(Content)`
  max-width: 1012px;
  padding: 60px 0;
  margin: 0 auto;

  ${Media.lessThan(Media.SIZE.LG)} {
    padding: 30px 16px;
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    padding: 30px 8px;
  }
`;
