import AxiosClient from './api';
import END_POINT from './constants';

function getTicketsHistory({ page }) {
  return AxiosClient.get(`${END_POINT.HISTORY.TICKETS}?per_page=12&page=${page}`).then((res) => {
    return res;
  });
}

function getTopicsHistory({ page }) {
  return AxiosClient.get(`${END_POINT.HISTORY.TOPICS}?per_page=12&page=${page}`).then((res) => {
    return res;
  });
}

function getTicketHistoryDetail(data: any) {
  return AxiosClient.get(`${END_POINT.HISTORY.TICKET_PURCHASE}/${data.balanceCode}/${data.realEventId}`).then(
    (res) => res.data,
  );
}

function getTicketTransaction(data: any) {
  return AxiosClient.get(
    `${END_POINT.HISTORY.TICKET_PURCHASE}/${data.balanceCode}/${data.realEventId}/tickets?per_page=20&page=${
      data.page || 1
    }`,
  ).then((res) => {
    return res;
  });
}

function getTicketSericode(data: any) {
  return AxiosClient.get(`${END_POINT.HISTORY.QR_DETAIL}/${data.seriesCode}`).then((res) => {
    return res.data;
  });
}

function getTopicHistoryDetail({ balanceCode }) {
  return AxiosClient.get(`${END_POINT.HISTORY.TOPIC_PURCHASE}/${balanceCode}`).then((res) => {
    return res.data;
  });
}

export {
  getTicketsHistory,
  getTopicsHistory,
  getTicketHistoryDetail,
  getTicketTransaction,
  getTicketSericode,
  getTopicHistoryDetail,
};
