import Media from '@themes/media';
import { Radio } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1012px;
  padding: 60px 0;
  margin: 0 auto;

  ${Media.lessThan(Media.SIZE.LG)} {
    padding: 30px 16px;
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    padding: 30px 8px;
  }
`;

export const CancelMark = styled.div`
  position: absolute;
  padding: 6px 25px;
  background: #d80027;
  color: white;
  top: 20px;
  left: 20px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;

  ${Media.lessThan(Media.SIZE.SM)} {
    padding: 2px 7px;
    font-size: 12px;
  }
`;

export const ImageBanner = styled.div`
  height: 404px;
  width: 100%;
  border-radius: 30px;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 32px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    height: 123px;
    border-radius: 10px;
    margin-bottom: 24px;
  }
`;

export const Content = styled.div`
  padding: 36px;
  margin-bottom: 30px;
  border-radius: 30px;
  background: ${({ theme }) => theme.white};
  box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.1);

  h1 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 12px;
    color: ${({ theme }) => theme.dark};
  }

  .content-top {
    margin-top: 20px;
  }

  .content {
    margin-bottom: 12px;
    font-size: 20px;
    color: ${({ theme }) => theme.dark};
    margin-bottom: 0;
    .ant-typography-expand {
      color: #0076e3;
      text-decoration-line: underline;
    }

    .time {
      font-size: 16px;
      font-weight: 700;
      color: #787878;
      opacity: 0.8;
    }
  }

  .content-child {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 2rem;
    margin-top: 20px;

    .btn-cancel {
      width: 319px;
      height: 88px;
      padding: 15px 25px;
      border-radius: 5px;
      white-space: pre-wrap;
      font-weight: ${({ theme }) => theme.fw_700};
      font-size: ${({ theme }) => theme.size_20};
      background: #d80027;
      color: ${({ theme }) => theme.white};
      margin: 100px 0;
    }

    .btn-purchase {
      max-width: 456px;
      width: 100%;
      height: 50px;
      font-weight: ${({ theme }) => theme.fw_700};
      font-size: ${({ theme }) => theme.size_16};
    }

    .btn-pre-order {
      max-width: 456px;
      width: 100%;
      height: 50px;
      font-weight: ${({ theme }) => theme.fw_700};
      font-size: ${({ theme }) => theme.size_16};
      background-color: #00cebc;
    }

    .content-border-dash {
      width: 100%;
      text-align: center;
      border: 2px dashed #d9d9d9;
      margin: 1.5rem 0 0.5rem 0;

      .message {
        padding: 65px 0;
        color: #bababa;
        font-size: 20px;
        p {
          margin: 0;
        }
      }
    }
  }
  ${Media.lessThan(Media.SIZE.LG)} {
    border-radius: 10px;
    margin-bottom: 24px;
    padding: 10px;
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    border-radius: 10px;
    margin-bottom: 24px;
    padding: 10px;

    h1 {
      font-size: ${({ theme }) => theme.size_16};
    }
    .content {
      font-size: ${({ theme }) => theme.size_14};
      .time {
        font-size: 12px;
      }
    }
    .content-child {
      gap: 1rem;
      .btn-purchase {
        height: 45px;
      }
      .btn-cancel {
        white-space: initial;
        padding: initial;
        width: 100%;
        margin: 28px 0 16px 0;
        height: 60px;
        font-size: ${({ theme }) => theme.size_16};
        box-sizing: border-box;
      }
    }
  }
`;

export const TicketCounter = styled.div`
  display: flex;
  width: calc(50% - 10px);
  border-radius: 10px;
  background: #f6f6f6;
  padding: 9px 14px;

  .ticket-action {
    display: block;
  }

  .content-ticket {
    flex: 1;

    .price {
      display: flex;
      gap: 5px;
      align-items: center;
      margin-top: 0.25rem;
    }

    .ticket-name {
      font-size: ${({ theme }) => theme.size_16};
      font-weight: ${({ theme }) => theme.fw_500};
      color: #0076e3;
    }

    .currency {
      font-size: ${({ theme }) => theme.size_14};
      font-weight: ${({ theme }) => theme.fw_500};
      color: #e3007f;
    }

    .coin {
      font-size: ${({ theme }) => theme.size_14};
      font-weight: ${({ theme }) => theme.fw_500};
      color: #10ab52;
      svg {
        margin-right: 5px;
      }
    }

    .tax-title {
      font-size: ${({ theme }) => theme.size_10};
      color: #787878;
    }
  }

  .ticket-action {
    .counter {
      display: flex;
      justify-content: space-between;

      svg {
        cursor: pointer;
      }
    }

    .original_quantity {
      font-size: ${({ theme }) => theme.size_10};
      color: #787878;
      text-align: right;
      margin-top: 0.25rem;
      margin-bottom: 0;
    }

    .quantity {
      border: none;
      background-color: #f6f6f6;
      width: 41px;
      text-align: center;
      font-weight: ${({ theme }) => theme.fw_500};
      &:focus {
        outline: none;
      }
    }
  }

  ${Media.lessThan(Media.SIZE.LG)} {
    display: flex;
    width: 100%;
  }

  ${Media.lessThan(Media.SIZE.MD)} {
    width: 100%;
    padding: 8px;
    .content-ticket {
      .price {
        gap: 2px;
      }
      .coin {
        svg {
          margin-right: 2px;
        }
      }
    }
    .ticket-action {
      .quantity {
        border: none;
        background-color: #f6f6f6;
        width: 36px;
        text-align: center;
        font-weight: ${({ theme }) => theme.fw_500};
        &:focus {
          outline: none;
        }
      }
    }
  }
`;

export const TicketContainer = styled.div`
  width: 100%;

  .ticket-title {
    font-size: ${({ theme }) => theme.size_14};
    font-weight: ${({ theme }) => theme.fw_700};
    color: ${({ theme }) => theme.dark};
  }
`;

export const TicketList = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  ${Media.lessThan(Media.SIZE.SM)} {
    gap: 8px;
  }
`;

export const Description = styled.div`
  padding: 36px;
  border-radius: 30px;
  background: ${({ theme }) => theme.white};
  box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  font-size: ${({ theme }) => theme.size_14};
  color: ${({ theme }) => theme.time};
  overflow-wrap: break-word;

  h4 {
    font-size: ${({ theme }) => theme.size_16};
    font-weight: ${({ theme }) => theme.fw_700};
    color: ${({ theme }) => theme.dark};
    margin-bottom: 1.5rem;
    overflow-wrap: break-word;
  }

  .title {
    font-size: ${({ theme }) => theme.size_14};
    font-weight: ${({ theme }) => theme.fw_700};
    color: ${({ theme }) => theme.dark};
    margin-bottom: 1rem;
    overflow-wrap: break-word;
  }

  .event-time {
    font-size: ${({ theme }) => theme.size_14};
    font-weight: ${({ theme }) => theme.fw_700};
    color: ${({ theme }) => theme.time};
  }

  .address {
    font-size: ${({ theme }) => theme.size_14};
    color: ${({ theme }) => theme.time};
  }

  .title-label {
    font-size: ${({ theme }) => theme.size_14};
    font-weight: ${({ theme }) => theme.fw_700};
    color: ${({ theme }) => theme.time};
  }

  .address-group {
    margin: 0;
  }

  .event-url {
    margin-bottom: 1rem;
    display: block;
    width: 250px;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .time_sale {
    margin-bottom: 1rem;
    p {
      margin: 0;
    }
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    border-radius: 10px;
    margin-bottom: 24px;
    padding: 0.75rem;
  }
`;

export const PaymentAllows = styled.div`
  width: 456px;
  margin: 1rem auto 0 auto;

  ${Media.lessThan(Media.SIZE.SM)} {
    width: 100%;
  }
`;

export const Banks = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  img,
  svg {
    width: 45px;
    height: 30px;
  }
`;

export const Companies = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin-top: 1rem;
  flex-direction: row;
`;

export const Coin = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin-top: 1rem;
  color: #10ab52;
  font-size: ${({ theme }) => theme.size_16};
  font-weight: ${({ theme }) => theme.fw_500};
`;

export const PaymentAllowsLogin = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  width: 100%;
  font-family: 'Noto Sans JP';

  .fee {
    font-size: 12px;
    font-weight: 400;
    color: #787878;
  }

  .group-payment-methods {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1;
    gap: 24px;
  }

  ${Banks}, ${Companies}, ${Coin} {
    font-family: 'Noto Sans JP';
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 5px;
    border: 1px solid #b4b4b4;
    background: ${({ theme }) => theme.white};
    padding: 18px;
    margin: 0;
    gap: 0.75rem;

    .methods {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .descriptions {
      font-size: ${({ theme }) => theme.size_10};
      text-align: center;
      color: #787878;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      /* white-space: pre-wrap; */
    }

    .price {
      font-size: ${({ theme }) => theme.size_16};
      font-weight: ${({ theme }) => theme.fw_700};
      color: #e3007f;
    }

    .price-coin {
      font-size: ${({ theme }) => theme.size_16};
      font-weight: ${({ theme }) => theme.fw_700};
      svg {
        margin-right: 5px;
      }
    }
  }

  ${Media.lessThan(Media.SIZE.LG)} {
    .group-payment-methods {
      flex-direction: column;
      gap: 0.75rem;
    }
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    .group-payment-methods {
      flex-direction: column;
      gap: 0.75rem;
    }
  }
`;

export const RadioStyled = styled(Radio)`
  .ant-radio-checked {
    &:after {
      border: 1px solid #e3007f;
    }
    .ant-radio-inner {
      border-color: #e3007f;
      &:after {
        background-color: #e3007f;
      }
    }
  }
`;

export const TicketDetailStyle = styled.div``;
export const UserInfoStyle = styled.div``;
export const Group = styled.div``;
export const PaymentInfoStyle = styled.div``;
export const WrapperAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.75rem 0 3rem 0;
  gap: 1rem;
  button {
    width: 158px;
    height: 44px;
    font-size: ${({ theme }) => theme.size_16};
    font-weight: ${({ theme }) => theme.fw_700};
  }
`;

export const ConfirmOrderStyle = styled.div`
  max-width: 720px;
  width: 100%;
  margin: 60px auto 90px auto;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.1);

  .header-title {
    display: flex;
    align-items: center;
    padding: 42px 36px 30px 36px;
    gap: 12px;
    border-bottom: 1px solid #e5e6eb;
    img {
      cursor: pointer;
    }
    h3 {
      margin: 0;
      font-size: ${({ theme }) => theme.size_20};
      font-weight: ${({ theme }) => theme.fw_700};
      color: ${({ theme }) => theme.dark};
    }
  }

  hr {
    margin: 0 36px;
    color: #e5e6eb;
    opacity: 0.25;
  }

  ${TicketDetailStyle},
  ${UserInfoStyle},
  ${PaymentInfoStyle} {
    padding: 24px 36px;

    ${Group} {
      display: flex;
      .custom-width {
        max-width: 65%;
        word-wrap: break-word;
      }
      ${Media.lessThan(Media.SIZE.SM)} {
        .custom-width {
          max-width: 50%;
        }
      }
      .ticket-type {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        width: 100%;
      }
      label {
        color: ${({ theme }) => theme._787878};
        margin-bottom: 13px;
        font-size: 18px;
        width: 200px;
        min-width: 200px;
        ${Media.lessThan(Media.SIZE.SM)} {
          font-size: ${({ theme }) => theme.size_14};
          padding-right: 5px;
          width: 140px;
          min-width: 140px;
        }
      }
      .status-pre {
        font-size: 18px;
        font-weight: ${({ theme }) => theme.fw_700};
        color: #00cebc;
      }

      p,
      div {
        max-width: 100%;
        font-size: ${({ theme }) => theme.size_18};
        color: ${({ theme }) => theme.dark};
        margin-bottom: 13px;
        ${Media.lessThan(Media.SIZE.SM)} {
          font-size: ${({ theme }) => theme.size_14};
        }
        a {
          text-decoration: underline;
        }

        .price {
          color: #e3007f;
          font-weight: ${({ theme }) => theme.fw_700};
        }

        .coin {
          color: #10ab52;
          font-weight: ${({ theme }) => theme.fw_700};
        }

        .d-flex {
          display: flex;
          margin: 0;
        }

        span + span {
          padding-left: 5px;
        }

        span {
          svg {
            height: 16px;
            width: 16px;
            margin-right: 5px;
          }
        }
      }
    }
  }
`;

export const PaymentPreOrder = styled.div`
  display: flex;
  gap: 1rem;
  .price {
    font-size: ${({ theme }) => theme.size_16};
    font-weight: ${({ theme }) => theme.fw_700};
    color: #e3007f;
  }

  .price-coin {
    font-size: ${({ theme }) => theme.size_16};
    font-weight: ${({ theme }) => theme.fw_700};
    svg {
      margin-right: 8px;
    }
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 320px;
  flex-wrap: wrap;
  justify-content: space-between;
`;
