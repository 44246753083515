import { GIF_BOX, BOX_BACKGROUD, RUBY } from '@assets';
import { Button } from '@components';
import { translations } from '@i18n/translations';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  onClose: () => void;
  pointFree: number;
}

const Wrapper = styled.div`
  /* position: relative; */
  width: 315px;
  display: flex;
  flex-direction: column;
  border-radius: 9px;
`;
const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-size: 100% 100%;
  height: 156px;
  z-index: 1;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 16px;
`;
const Background = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-size: 100% 100%;
  height: 156px;
  z-index: 1;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: -1;
`;
const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: white;
  height: 156px;
  border-radius: 0px 0px 9px 9px;
`;

export const PopupBoxFree: FC<Props> = ({ onClose, pointFree }) => {
  const { t } = useTranslation();
  const { livestream, common } = translations;
  return (
    <Wrapper>
      <ImageWrapper>
        <Background>
          <img width={315} height={156} style={{ objectFit: 'contain' }} src={BOX_BACKGROUD} alt="" />
        </Background>
        <img width={94} height={94} style={{ objectFit: 'contain' }} src={GIF_BOX} alt="" />
      </ImageWrapper>
      <Content>
        <div style={{ height: 16 }} />

        <div style={{ textAlign: 'center' }}>{t(livestream.get_ruby.get_free)}</div>
        <div style={{ height: 8 }} />

        <div style={{ fontSize: 22, fontWeight: 700, color: '#E80054', display: 'flex', alignItems: 'center', gap: 6 }}>
          <img src={RUBY} width={20} alt="ruby" />
          <span>{pointFree}</span>
        </div>
        <div style={{ height: 24 }} />
        <Button type="primary" onClick={onClose}>
          {t(common.ok)}
        </Button>
      </Content>
    </Wrapper>
  );
};
