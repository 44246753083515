/* eslint-disable no-console */

import { signInTwitterWithCallback } from '@apis/twitter';
import { useAuth, useSearchParams } from '@hooks';
import { useTwitter } from '@hooks/useTwitter';

import Media from '@themes/media';
import { STORAGE, getLocalStorage, removeLocalStorage } from '@utils';
import { Spin, notification } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Noto Sans JP';
  width: 100%;
  height: 60vh;
  padding: 32px;

  ${Media.lessThan(Media.SIZE.MD)} {
    padding: 0px 15px;
    min-width: 320px;
    height: 50vh;
  }
`;

interface SearchParams {
  oauth_token?: string | null;
  oauth_verifier?: string | null;
}

const Twitter: React.FC = () => {
  const { oauth_token, oauth_verifier } = useSearchParams<SearchParams>();
  const { authenticated } = useAuth();
  const { shareTwitter } = useTwitter();
  React.useEffect(() => {
    if (oauth_token && oauth_verifier) {
      const userToken = getLocalStorage(STORAGE.OAUTH_TOKEN);
      signInTwitterWithCallback({
        oauth_token,
        oauth_verifier,
        userToken: userToken || null,
      })
        .then((response) => {
          if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
            window.ReactNativeWebView?.postMessage(
              JSON.stringify({
                signin_status: 'SUCCESS',
                ...response,
              }),
            );
            removeLocalStorage(STORAGE.OAUTH_TOKEN);
          }
          if (authenticated && response.status === 200 && response.data.length > 0) {
            const shareData = JSON.parse(getLocalStorage(STORAGE.TWITTER) || '');
            shareTwitter({
              name: shareData.name,
              title: shareData.title || '',
              description: shareData.description,
              room: shareData.room,
              avatar: shareData.avatar || '',
              liverId: shareData.liverId || '',
              isSignIn: true,
            });
          }
        })
        .catch((error) => {
          if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
            window.ReactNativeWebView?.postMessage(
              JSON.stringify({
                signin_status: 'ERROR',
                ...error,
              }),
            );
          } else {
            notification.error({
              message: error.message,
              duration: 3,
            });
          }
        });
    }
  }, [oauth_token, oauth_verifier, window.ReactNativeWebView]);

  return (
    <Spin spinning>
      <Wrapper />
    </Spin>
  );
};
export default Twitter;
