import styled from 'styled-components';
import Media from '@themes/media';

export const EventNoDataStyled = styled.div`
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  height: 331px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  margin-bottom: 100px;
  margin-top: 20px;

  ${Media.lessThan(Media.SIZE.LG)} {
    height: 200px;
    font-size: 24px;
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    height: 120px;
    font-size: 16px;
  }
`;
