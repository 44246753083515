import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Row, Col, Form, Typography, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';
import { useHistory } from 'react-router-dom';

import { InputLogin } from '@themes/facit';
import { FormInput, FormPassword, FormLabel, FormSelect } from '@components';
import ButtonComponent from '@components/button';
import { LOGO_FOOTER } from '@assets';
import { getEnv } from '@config/env';
import useRegister from './useRegister';
import FormCheckbox from './components/CheckBox';
import CountrySelect from './components/CountrySelect';
import ModalReSend from './components/ModalReSend';

import { WrapperRegisterStyled, Title, SelectPhoneStyled, PolicyStyled } from './styled';

const { Text } = Typography;
const Register: React.FC = () => {
  const {
    form,
    submitRegister,
    optionGender,
    STATUS_REGISTER,
    statusRegister,
    isLoading,
    handleResendCode,
    showModalResend,
    onCancelModal,
  } = useRegister();
  const { t, i18n } = useTranslation();
  const { register } = translations;
  const {
    handleSubmit,
    getValues,
    setValue,
    formState: { isValid },
  } = form;
  const history = useHistory();
  const s3Bucket = getEnv('S3_BUCKET', null);
  const onChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    const { value } = target;
    const convertNumber = (value || '').replace(/\D/g, '');

    setValue('phoneNumber', convertNumber);
  };
  return (
    <WrapperRegisterStyled>
      <Row className="wrapper-form">
        <Col span={24} className="col-header">
          <div className={statusRegister === STATUS_REGISTER.REGISTER ? 'form-logo' : 'confirm-logo'}>
            <Image src={LOGO_FOOTER} alt="logo-footer" preview={false} />
          </div>
          {statusRegister === STATUS_REGISTER.REGISTER && <Typography>{t('register:register')}</Typography>}
        </Col>

        <Col span={24} className="col-form">
          <FormProvider {...form}>
            {statusRegister === STATUS_REGISTER.REGISTER && (
              <Form layout="vertical" className="form" onFinish={handleSubmit(submitRegister)}>
                <Row>
                  <Title>
                    <FormLabel title={t('register:nick_name')} star="*" />
                  </Title>
                  <InputLogin>
                    <FormInput name="userName" placeholder={t('register:please_input_nick_name')} />
                  </InputLogin>
                </Row>

                <Row>
                  <Title>
                    <FormLabel title={t('register:gender')} />
                  </Title>
                  <InputLogin>
                    <FormSelect name="gender" options={optionGender} />
                  </InputLogin>
                </Row>

                <Row>
                  <Title>
                    <FormLabel title={t('register:email')} star="*" />
                  </Title>
                  <InputLogin>
                    <FormInput name="email" placeholder={t('register:please_input_email')} />
                  </InputLogin>
                </Row>

                <Row>
                  <Title>
                    <FormLabel title={t('register:phone_number')} star="*" />
                  </Title>
                  <InputLogin>
                    <SelectPhoneStyled>
                      <CountrySelect name="countryPhone" />
                      <FormInput
                        name="phoneNumber"
                        placeholder={t('register:please_input_phone_number')}
                        onChange={onChangePhone}
                      />
                    </SelectPhoneStyled>
                  </InputLogin>
                </Row>

                <Row>
                  <Title>
                    <FormLabel title={t('register:password')} star="*" />
                  </Title>
                  <InputLogin>
                    <FormPassword name="password" placeholderValue={t('register:please_input_password')} />
                  </InputLogin>
                </Row>

                <Row>
                  <Title>
                    <FormLabel title={t('register:password_confirm')} star="*" />
                  </Title>
                  <InputLogin>
                    <FormPassword
                      name="confirmPassword"
                      placeholderValue={t('register:please_input_password_confirm')}
                    />
                  </InputLogin>
                </Row>

                <Row>
                  <InputLogin>
                    <PolicyStyled>
                      <FormCheckbox name="isAccept">
                        {t(register.agree_to)}
                        <a
                          href={`${s3Bucket}/static_page/METALIVE_termOfUse_20230816_${i18n.language}.pdf`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t(register.terms_of_service)}
                        </a>
                        {t(register.and)}
                        <a
                          href={`${s3Bucket}/static_page/METALIVE_policy_20230816_${i18n.language}.pdf`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t(register.privacy_policy)}
                        </a>
                        {t(register.policy_end)}
                      </FormCheckbox>
                    </PolicyStyled>
                  </InputLogin>
                </Row>

                <Row className="row-btn">
                  <ButtonComponent
                    type="default"
                    htmlType="submit"
                    loading={isLoading}
                    className="btn-register"
                    style={{ opacity: isValid ? 'unset' : 0.5 }}
                  >
                    {t('register:register')}
                  </ButtonComponent>
                </Row>

                <Row className="row-btn">
                  <ButtonComponent
                    type="cancel"
                    className="btn-cancel"
                    onClick={() => {
                      history.push('/login');
                    }}
                  >
                    {t('register:cancel')}
                  </ButtonComponent>
                </Row>
              </Form>
            )}

            {statusRegister === STATUS_REGISTER.CONFIRM_PHONE && (
              <Form layout="vertical" className="form-confirm" onFinish={handleSubmit(submitRegister)}>
                <Row className="row-info-confirm">
                  <Text className="title">{t('register:complete_register')}</Text>
                  <Typography className="info-confirm">
                    {`+${getValues('countryPhone')}${getValues('phoneNumber').trim().replace(/^0+/, '')}`}{' '}
                    {t('register:text_confirm')}
                  </Typography>
                  <InputLogin>
                    <FormInput name="code" placeholder={t('register:please_input_code')} />
                  </InputLogin>
                  <div className="text-resend-code">
                    <Text
                      onClick={() => {
                        const phoneNumber = getValues('phoneNumber');
                        const country = getValues('countryPhone');
                        const phoneCountry = `+${country}${phoneNumber.trim().replace(/^0+/, '')}`;
                        handleResendCode(phoneCountry);
                      }}
                    >
                      {t('register:resend_code')}
                    </Text>
                  </div>
                </Row>
                <Row className="row-btn">
                  <ButtonComponent type="default" htmlType="submit" loading={isLoading} className="btn-confirm">
                    OK
                  </ButtonComponent>
                </Row>
              </Form>
            )}

            {statusRegister === STATUS_REGISTER.CONFIRM_SUCCESS && (
              <Row className="modal-confirm-success">
                <Text className="text-success">{t('register:confirm_success_message')}</Text>
                <Col span={24} className="row-btn">
                  <ButtonComponent
                    type="default"
                    htmlType="submit"
                    className="btn-confirm"
                    onClick={() => {
                      history.push('/login');
                    }}
                  >
                    OK
                  </ButtonComponent>
                </Col>
              </Row>
            )}
          </FormProvider>
        </Col>
      </Row>

      <ModalReSend open={showModalResend.show} onCancel={onCancelModal} type={showModalResend.type} />
    </WrapperRegisterStyled>
  );
};

export default Register;
