import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

import { InitRegisterValues } from '@type/Form/RegisterForm';
import { useAuth } from '@hooks';
import { STATUS_REGISTER, TYPE_CONFIRM, REGISTER_MESSAGE_ERROR } from '../../../Constants/auth';
import registerSchema from './schema';

const useRegister = () => {
  const { t } = useTranslation();
  const {
    i18n: { language },
  } = useTranslation();
  const { registerAction, verifyRegisterAction, reSendCodeAction, isLoading } = useAuth();
  const [statusRegister, setStatusRegister] = useState(STATUS_REGISTER.REGISTER);

  const [showModalResend, setShowModalResend] = useState({
    type: '',
    show: false,
  });

  const initValue: InitRegisterValues = {
    userName: '',
    gender: 'male',
    email: '',
    phoneNumber: '',
    countryPhone: '81',
    password: '',
    confirmPassword: '',
    isAccept: false,
    code: '',
  };

  const callBackRegisterSuccess = () => {
    setStatusRegister(STATUS_REGISTER.CONFIRM_PHONE);
  };

  const callBackRegisterFail = (message) => {
    const mess = message.message?.map((ms) => t(`api_error:${ms}`)).join('\n');
    notification.error({ message: mess });
  };

  const callBackConfirmPhoneSuccess = () => {
    setStatusRegister(STATUS_REGISTER.CONFIRM_SUCCESS);
  };

  const callBackConfirmPhoneFail = (message) => {
    if (message.message.length > 0) {
      if (message.message?.[0] === REGISTER_MESSAGE_ERROR.CODE_NOT_FOUND_PHONE) {
        setShowModalResend({ type: TYPE_CONFIRM.PHONE_NOT_FOUND, show: true });
      } else {
        setShowModalResend({ type: TYPE_CONFIRM.CONFIRM, show: true });
      }
    }
  };

  const callBackResendCodeSuccess = () => {
    setShowModalResend({ type: TYPE_CONFIRM.RESEND, show: true });
  };

  const callBackResendCodeFail = () => {
    setShowModalResend({ type: TYPE_CONFIRM.CONFIRM, show: true });
  };

  const submitRegister: SubmitHandler<InitRegisterValues> = (data) => {
    if (statusRegister === STATUS_REGISTER.REGISTER) {
      registerAction({
        data: {
          language,
          name: data.userName,
          gender: data.gender,
          email: data.email,
          password: data.password,
          phone: `+${data.countryPhone}${data.phoneNumber.trim().replace(/^0+/, '')}`,
        },
        callback: { done: callBackRegisterSuccess, fail: callBackRegisterFail },
      });
    } else {
      verifyRegisterAction({
        data: {
          phone: `+${data.countryPhone}${data.phoneNumber.trim().replace(/^0+/, '')}`,
          code: data.code,
        },
        callback: {
          done: callBackConfirmPhoneSuccess,
          fail: callBackConfirmPhoneFail,
        },
      });
    }
  };

  const optionGender = [
    { value: 'male', label: t('register:male') },
    { value: 'female', label: t('register:female') },
  ];

  const optionPhone = [{ value: 'jp', label: 'JP +81' }];

  const form = useForm<InitRegisterValues>({
    defaultValues: initValue,
    resolver: yupResolver(registerSchema(statusRegister)),
  });

  const handleResendCode = (phoneNumber) => {
    reSendCodeAction({
      data: {
        language,
        phone: phoneNumber,
      },
      callback: {
        done: callBackResendCodeSuccess,
        fail: callBackResendCodeFail,
      },
    });
  };

  const onCancelModal = () => {
    setShowModalResend({ type: '', show: false });
  };

  return {
    form,
    isLoading,
    submitRegister,
    optionGender,
    optionPhone,
    STATUS_REGISTER,
    statusRegister,
    showModalResend,
    onCancelModal,
    handleResendCode,
  };
};

export default useRegister;
