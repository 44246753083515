import styled from 'styled-components';

export const PaymentRefundStyled = styled.div`
  padding-left: 34px;

  .box-message {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const BoxMessage = styled.div`
  margin-top: 30px;
  border-radius: 5px;
  background-color: #d80027;
  color: #ffffff;
  padding: 30px;
  max-width: 319px;
  font-weight: 700;
  text-align: center;
`;
