export const USER_ROLE = {
  USER: 'USER',
  LIVER: 'LIVER',
  STAFF: 'STAFF',
  OTHER: 'OTHER',
};

export const STATUS_REGISTER = {
  REGISTER: 'REGISTER',
  CONFIRM_PHONE: 'CONFIRM_PHONE',
  CONFIRM_ERROR: 'CONFIRM_ERROR',
  CONFIRM_SUCCESS: 'CONFIRM_SUCCESS',
};

export const TYPE_CONFIRM = {
  RESEND: 'RESEND',
  PHONE_NOT_FOUND: 'PHONE_NOT_FOUND',
  CONFIRM: 'CONFIRM',
};

export const REGISTER_MESSAGE_ERROR = {
  EMAIL_EXISTED: 'EMAIL_EXISTED',
  PHONE_EXISTED: 'PHONE_EXISTED',
  CODE_NOT_FOUND_PHONE: 'CODE_NOT_FOUND_PHONE',
};
