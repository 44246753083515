export const TOPIC_LIST = '@TOPIC/TOPIC_LIST';
export const TOPIC_DETAIL = '@TOPIC/TOPIC_DETAIL';
export const TOPIC_TOP = '@TOPIC/TOPIC_TOP';
export const TOPIC_CHECK_PASSWORD = '@TOPIC/TOPIC_CHECK_PASSWORD';
export const LIVESTREAM_DETAIL = '@TOPIC/LIVESTREAM_DETAIL';
export const GET_DONATE_PACKAGE = '@TOPIC/GET_DONATE_PACKAGE';
export const SEND_DONATE = '@TOPIC/SEND_DONATE';
export const TOPIC_VIDEO_COMMENT = '@TOPIC/TOPIC_VIDEO_COMMENT';
export const TOPIC_CREATE_COMMENT = '@TOPIC/TOPIC_CREATE_COMMENT';
export const TOPIC_CHAPTER_DETAIL = '@TOPIC/TOPIC_CHAPTER_DETAIL';
export const TOPIC_EDIT_COMMENT = '@TOPIC/TOPIC_EDIT_COMMENT';
export const TOPIC_DELETE_COMMENT = '@TOPIC/TOPIC_DELETE_COMMENT';
export const TOPIC_VIDEO_PACKAGE = '@TOPIC/TOPIC_VIDEO_PACKAGE';
export const TOPIC_PACKAGE_DETAIL = '@TOPIC/TOPIC_PACKAGE_DETAIL';
export const GET_AGORA_TOKEN = '@TOPIC/GET_AGORA_TOKEN';

export const GET_VIDEO_DETAIL = '@TOPIC/GET_VIDEO_DETAIL';

export const LOADING = '@TOPIC/LOADING';
export const ERROR = '@TOPIC/ERROR';
