import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Colors from '@themes/colors';
import { PAYMENT_STATUS } from '@constants/my-ticket';

interface Props {
  status: number;
}

const { Text } = Typography;

const TextStyled = styled(Text)`
  font-size: 18px;
`;

const PaymentStatus: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation();

  switch (status) {
    case PAYMENT_STATUS.SUCCESS:
      return (
        <TextStyled style={{ color: Colors.text_success }}>{t('myTicket:my_ticket_detail.status_success')}</TextStyled>
      );
    case PAYMENT_STATUS.PRE_ORDER:
      return (
        <TextStyled style={{ color: Colors.text_pre_order }}>
          {t('myTicket:my_ticket_detail.status_pre_order')}
        </TextStyled>
      );
    case PAYMENT_STATUS.PROCESSING:
      return (
        <TextStyled style={{ color: Colors.text_process }}>{t('myTicket:my_ticket_detail.status_process')}</TextStyled>
      );
    case PAYMENT_STATUS.REFUND:
      return (
        <TextStyled style={{ color: Colors.text_process }}>{t('myTicket:my_ticket_detail.status_refund')}</TextStyled>
      );
    case PAYMENT_STATUS.FAIL:
      return <TextStyled style={{ color: '#D80027' }}>{t('myTicket:my_ticket_detail.status_fail')}</TextStyled>;
    default:
      return <div />;
  }
};

export default PaymentStatus;
