import { Layout } from 'antd';
import styled from 'styled-components';

import Media from '@themes/media';

const { Content } = Layout;

export const WrapperStyled = styled(Content)`
  max-width: 1300px;
  padding: 40px 50px 0px 50px;
  margin: 0 auto;
  .title {
    font-size: ${({ theme }) => theme.size_16};
    font-weight: ${({ theme }) => theme.fw_700};
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 8px;
  }

  .content-top {
    padding: 20px 16px 16px 16px;
    background: #ffffff;
    width: 100%;
    height: 450px;
    border-radius: 0px 10px 10px 0px;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: none;
      border-radius: 8px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 8px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .content {
      line-height: 20px;
      display: block;
      display: -webkit-box;
      max-height: 146px;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      visibility: visible;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #787878;
    }
  }
  .chapters {
    padding-top: 14px;
  }
  ${Media.lessThan(Media.SIZE.XL)} {
    .content-top {
      border-radius: 0px 0px 10px 10px;
      max-height: 87px;
      .content {
        -webkit-line-clamp: 1;
        display: -webkit-box;
        max-height: 146px;
        -webkit-box-orient: vertical;
        visibility: visible;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  ${Media.lessThan(Media.SIZE.MD)} {
    padding: 16px 12px;
    padding-bottom: 0px;
  }

  .col-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .text-sort {
      display: flex;
      align-items: center;

      .ant-divider-vertical {
        height: 28px;
      }
    }
  }
`;

export const ImageBannerTop = styled.div`
  width: 100%;
  border-radius: 10px 0px 0px 10px;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  aspect-ratio: 16 / 9;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  ${Media.lessThan(Media.SIZE.LG)} {
    border-radius: 10px 10px 0px 0px;
  }

  .tag-live {
    border: none;
    position: absolute;
    top: 15px;
    left: 15px;
    background-color: ${({ theme }) => theme.bg_red};
    border-radius: 12px;
    font-size: ${({ theme }) => theme.size_12};
    font-weight: ${({ theme }) => theme.fw_700};
    color: white;
    z-index: 10;
  }
`;
