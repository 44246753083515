import { Modal as ModalAntd } from 'antd';
import styled from 'styled-components';

const Modal = styled(ModalAntd)`
  width: 315px !important;
  height: 315px;
  overflow: hidden;
  border-radius: 0;
  .ant-modal-content {
    background: transparent;
    padding: 0;
    width: 315px;
    height: 100%;
    display: flex;
    overflow: hidden;
    border-radius: 0;
    .ant-modal-close {
      display: none;
    }
    .ant-modal-header {
      display: flex;
      justify-content: center;
      display: none;
      background: transparent;
    }
    .ant-modal-body {
      overflow: hidden;
      /* max-height: calc(80vh - 125px); */
      background: transparent;
      display: flex;
      padding: 0;
    }
    .ant-modal-footer {
      display: flex;
      justify-content: center;
      height: 55px;
      .button__cancel {
        margin-right: 10px;
      }
    }
  }
`;

export default Modal;
