import React, { useMemo } from 'react';
import { Avatar } from '@components';
import { COOL } from '@assets';
import { useTranslation } from 'react-i18next';
import { IMessage } from '@hooks/useChat';
import { Wrapper, Message, UserName, DonateMsg, DonateInfo } from './styled';

export const ChatUser: React.FC<IMessage> = ({ senderName, msg, senderAvatar = '', type = 1, msg_cn, msg_en }) => {
  const { i18n } = useTranslation();
  const msgDonate = useMemo(() => {
    if (i18n.language === 'en') return msg_en;
    if (i18n.language === 'cn') return msg_cn;

    return msg;
  }, [i18n.language]);
  if (type === 1) {
    return (
      <Wrapper>
        <DonateMsg>
          <DonateInfo>
            <Avatar src={senderAvatar || ''} width={40} isBorder />
            <Message style={{ color: 'white' }}>
              <UserName ellipsis style={{ color: 'white' }}>
                {senderName}
              </UserName>
              {msgDonate}
            </Message>
            <div style={{ width: 48 }} />
          </DonateInfo>
          <img
            alt=""
            src={COOL}
            style={{ height: '100%', width: 86, objectFit: 'contain', position: 'absolute', right: -36 }}
          />
        </DonateMsg>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <Avatar src={senderAvatar || ''} width={28} isBorder />
      <Message>
        <UserName ellipsis>{senderName}</UserName>
        {msg}
      </Message>
      <div style={{ width: 42 }} />
    </Wrapper>
  );
};

export default ChatUser;
