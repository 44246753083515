import {
  database,
  createItem,
  onValue,
  onChildAdded,
  query,
  ref,
  orderByChild,
  startAt,
  limitToLast,
  endBefore,
  off,
} from '@apis/firebase';
import { ChatType } from '@constants';
import { useEffect, useState } from 'react';
import { uniqBy } from 'lodash';

const LIMIT_CHAT_ITEM = 25;

interface IParams {
  roomId?: string | null;
}

export interface IMessage {
  senderId?: number;
  createdAt?: number;
  senderName?: string | null;
  msg?: string;
  msg_cn?: string | null;
  msg_en?: string | null;
  senderAvatar?: string | null;
  type: ChatType;
}

export const useChat = ({ roomId }: IParams) => {
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [error, setError] = useState<any>(null);
  const [lastMessage, setLastMessage] = useState<number | null | undefined>(null);
  const [loading, setLoading] = useState(false);
  const [isEnded, setIsEnded] = useState(false);

  /** loadmore messages */
  const loadMoreData = () => {
    if (!loading) {
      setLoading(true);
      const queryMsg = query(
        ref(database, `chat/${roomId}`),
        orderByChild('createdAt'),
        endBefore(lastMessage || null),
        limitToLast(LIMIT_CHAT_ITEM),
      );

      onValue(
        queryMsg,
        (snapshots) => {
          // on success
          if (snapshots.exists() && snapshots.size) {
            const data: IMessage[] = [];
            snapshots.forEach((item) => {
              if (item.exists()) {
                data.unshift(item.val());
              }
            });
            setMessages((state) => uniqBy([...state, ...data], 'createdAt'));
            if (error) {
              setError(() => null);
            }
            setLastMessage(data[data.length - 1]?.createdAt || null);
          }
          if (snapshots.size < LIMIT_CHAT_ITEM) {
            setIsEnded(true);
          }
          setLoading(false);
        },
        (err) => setError(() => err), // on error
        { onlyOnce: true },
      );
    }
  };

  useEffect(() => {
    let childAddedHandler;
    let childOnvalueHandler;
    const current = Date.now();
    const queryMsg = query(ref(database, `chat/${roomId}`), orderByChild('createdAt'), limitToLast(LIMIT_CHAT_ITEM));
    const queryAddMsg = query(ref(database, `chat/${roomId}`), orderByChild('createdAt'), startAt(current));
    if (roomId) {
      // /** get init messages */
      childOnvalueHandler = onValue(
        queryMsg,
        (snapshots) => {
          // on success
          if (snapshots.exists() && snapshots.size) {
            const data: IMessage[] = [];
            snapshots.forEach((item) => {
              if (item.exists()) {
                data.unshift(item.val());
              }
            });
            setMessages((state) => uniqBy([...state, ...data], 'createdAt'));
            if (error) {
              setError(() => null);
            }
            setLastMessage(data[data.length - 1]?.createdAt || null);
          }
          if (snapshots.size < LIMIT_CHAT_ITEM) {
            setIsEnded(true);
          }
        },
        (err) => setError(() => err), // on error
        { onlyOnce: true },
      );

      /** Listen data when data change */
      childAddedHandler = onChildAdded(
        queryAddMsg,
        (snapshot) => {
          if (snapshot.exists()) {
            setMessages((state) => [snapshot.val(), ...state]);
          }
        },
        (err) => setError(() => err), // on error
        {
          onlyOnce: false,
        },
      );
    }

    return () => {
      off(queryAddMsg, 'child_added', childAddedHandler);
      off(queryMsg, 'value', childOnvalueHandler);
    };
  }, [roomId]);
  return {
    messages,
    error,
    loadMoreData,
    loading,
    isEnded,
    LIMIT_CHAT_ITEM,
  };
};

export const sendMessage = (roomId, data) => createItem(`chat/${roomId}`, data);
