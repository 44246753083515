import { translations } from '@i18n/translations';
import * as yup from 'yup';

const { common, error_message, register } = translations;
export const ResetPwScheme = (t: any) =>
  yup.object().shape({
    authenticationCode: yup
      .string()
      .trim()
      .required(
        t(error_message.validation.required, {
          key: t(common.authentication_code),
        }),
      ),
    // .matches(
    // 	/^[A-Za-z0-9]*$/,
    // 	t('error_message:validation:authentication_code_regex')
    // )
    password: yup
      .string()
      .trim()
      .required(register.error_message.passsword_required)
      .min(8, register.error_message.password_min_length)
      .max(16, register.error_message.password_max_length),
    retypeNewPassword: yup
      .string()
      .trim()
      .required(register.error_message.confirm_passsword_required)
      .min(8, register.error_message.password_min_length)
      .max(16, register.error_message.password_max_length)
      .oneOf([yup.ref('password'), null], register.error_message.password_not_match),
  });

export const SendMailScheme = (t: any) =>
  yup.object().shape({
    email: yup
      .string()
      .trim()
      .email(t(error_message.EMAIL_INVALID))
      .required(t(error_message.validation.required, { key: t(common.email) }))
      .max(200, t(error_message.validation.max_length, { key: t(common.email), max: 200 })),
  });

export const LoginScheme = (t: any) =>
  yup.object().shape({
    email: yup
      .string()
      .trim()
      .email(t(error_message.EMAIL_INVALID))
      .required(t(error_message.validation.required, { key: t(common.email) }))
      .max(200, t(error_message.validation.max_length, { key: t(common.email), max: 200 })),
    password: yup
      .string()
      .trim()
      .required(t(error_message.validation.required, { key: t(common.password) }))
      .min(
        8,
        t(error_message.validation.new_password_rules, {
          key: t(common.password),
          min: 8,
          max: 16,
        }),
      )
      .max(
        16,
        t(error_message.validation.new_password_rules, {
          key: t(common.password),
          max: 16,
          min: 8,
        }),
      ),

    // .min(8, t('error_message:validation.max_length', { key: t('password'), min: 8,max: 16 }))
    // .max(16, t('error_message:validation.max_length', { key: t('password'), max: 16,min: 8 }))
  });

export const paymentScheme = (t: any) =>
  yup.object().shape({
    cardName: yup
      .string()
      .trim()
      .required(
        t(error_message.validation.card_name_rules, {
          key: t(common.payment.name_oncard_title),
        }),
      )
      .matches(/^[a-zA-Z0-9\-'!@#$%^&*.]+$|^$/, t(error_message.validation.card_name_rules)),
  });
