import React, { useMemo } from 'react';
import { Image, Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { PAYMENT_METHOD } from '@constants/my-ticket';
import { DIAMOND } from '@assets';
import { translations } from '@i18n/translations';
import { numberWithCommas } from '@utils/number';
import { Clickable, Seemore } from '@components';

import PaymentStatus from '@modules/history/list/components/payment-status';
import { useHistory } from 'react-router-dom';
import { useHistories } from '@hooks/hisories';
import { KOBINI_LIST } from '@modules/tickets-goods/real-event-payment/constants';
import { PAYMENT_TOPIC_STATUS } from '@constants/histories';
import { Label, Value } from '../../styled';
import { Wrapper, Title } from './styled';

const { Text } = Typography;

const TopicInfo: React.FC = () => {
  const { myTicket, topic, histories, gmo_payment } = translations;
  const { my_ticket_detail } = myTicket;
  const { t, i18n } = useTranslation();
  const { topicDetail } = useHistories();
  const {
    thumbnail,
    konbini_convenience,
    title,
    description,
    topic_title,
    payment_method,
    diamond_price,
    yen_price,
    payment_date,
    payment_status,
    receipt_code,
    type,
    package_id,
    chapter_id,
    topic_id,
    live_key,
    err_code,
    refund_status,
  } = topicDetail;
  const { push } = useHistory();

  const storeName = useMemo(() => {
    if (konbini_convenience) {
      const findStore = KOBINI_LIST.find((store) => store.value === topicDetail?.konbini_convenience);

      return findStore ? findStore.label : null;
    }
    return null;
  }, [topicDetail.receipt_code]);

  const renderPayment_method = (method) => {
    switch (method) {
      case PAYMENT_METHOD.KONBINI:
        return <Text>{t(my_ticket_detail.payment_method.conbini)}</Text>;
      case PAYMENT_METHOD.CREDIT_CARD:
        return <Text>{t(my_ticket_detail.payment_method.credit_card)}</Text>;
      case PAYMENT_METHOD.COIN:
        return <Text>{t(my_ticket_detail.payment_method.coin)}</Text>;
      case PAYMENT_METHOD.DIAMOND:
        return <Text>{t(my_ticket_detail.payment_method.diamond)}</Text>;
      default:
        return <Text />;
    }
  };

  const renderPrice = () => {
    if (payment_method === PAYMENT_METHOD.DIAMOND) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={DIAMOND} alt="diamond" width={13} height={13} />
          <span style={{ color: '#0076E3', fontSize: '18px', fontWeight: '700' }}>
            &ensp;{numberWithCommas(Number(diamond_price || 0), ',')}
          </span>
        </div>
      );
    }
    return (
      <Value>
        <Typography className="total" style={{ color: 'rgb(227, 0, 127)' }}>
          ￥ {numberWithCommas(Number(yen_price || 0), ',')}
        </Typography>
      </Value>
    );
  };

  const onGoToDetail = () => {
    if (payment_status === PAYMENT_TOPIC_STATUS.SUCCEEDED) {
      if (type === 'live') {
        push(`/topics/watch-livestream?room=${live_key}`);
      }
      if (type === 'video') push(`/topics/watch-video-chapter/${chapter_id}?type=TOPIC`);

      if (type === 'end_live') push(`/topics/${topic_id}`);
      if (type === 'package') push(`/topics/${topic_id}/video-package/${package_id}`);
    } else if (type === 'package') push(`/topics/${topic_id}/video-package/${package_id}`);
    else push(`/topics/${topic_id}`);
  };

  const renderTypeLabel = () => {
    if (type !== 'package' && type !== 'video') return <Label>{t(histories.topic_detail.live)}</Label>;

    return <Label>{t(histories.topic_detail[type])}</Label>;
  };
  const renderMarker = () => {
    switch (type) {
      case 'video':
        return <Tag className="tag tag-chapter">{t(histories.topic_mark.video)}</Tag>;
      case 'live':
        return <Tag className="tag tag-live">{t(histories.topic_mark.live)}</Tag>;
      case 'end_live':
        return <Tag className="tag tag-end">{t(histories.topic_mark.end_live)}</Tag>;
      case 'package':
        return <Tag className="tag tag-chapter">{t(histories.topic_mark.package)}</Tag>;
      default:
        return <div />;
    }
  };
  const errorMsg = useMemo(() => {
    if (payment_status === PAYMENT_TOPIC_STATUS.FAILED && err_code && payment_method === 2) {
      return err_code.map((code) => t(gmo_payment[code])).join('\n');
    }
    if (payment_status === PAYMENT_TOPIC_STATUS.FAILED && err_code && payment_method !== 2) {
      return t(histories.ERROR.other_error);
    }

    return null;
  }, [payment_status, err_code, payment_method, i18n.language]);
  return (
    <Wrapper>
      <div className="thumbnail">
        <Image src={thumbnail} alt="ticket-thumbnail" preview={false} />
      </div>
      <Title>{title}</Title>
      <Seemore rows={2} content={description} />
      {topic_title && (
        <div className="info">
          <Label>{t(topic.topics)}</Label>
          <Value style={{ textOverflow: 'unset', whiteSpace: 'pre-wrap' }}>{topic_title}</Value>
        </div>
      )}
      <div className="info">
        {renderTypeLabel()}
        <Value>
          <Typography className="total">
            <Clickable className="link-ticket" onClick={onGoToDetail}>
              {t(histories.topic_detail.here)}
            </Clickable>
            {renderMarker()}
          </Typography>
        </Value>
      </div>
      <div className="info">
        <Label>{t(histories.topic_detail.total)}</Label>
        {renderPrice()}
      </div>
      {!!payment_date && (
        <div className="info">
          <Label>{t(histories.date_of_acquisition)}</Label>
          <Value>{moment(payment_date).format('YYYY/MM/DD')}</Value>
        </div>
      )}
      <div className="info">
        <Label>{t(histories.topic_detail.payment_method)}</Label>
        <Value>{renderPayment_method(payment_method)}</Value>
      </div>
      {storeName && (
        <div className="info">
          <Label>{t(histories.topic_detail.storeName)}</Label>
          <Value>{t(storeName as any)}</Value>
        </div>
      )}
      <div className="info">
        <Label>{t(histories.topic_detail.status)}</Label>
        <Value>
          <PaymentStatus status={payment_status} refund_status={refund_status} />
        </Value>
      </div>
      {receipt_code && (
        <div className="info" style={{ alignItems: 'center' }}>
          <Label>{t(histories.topic_detail.receipt_number)}</Label>
          <Value className="receipt_code">{receipt_code}</Value>
        </div>
      )}
      {!!errorMsg && (
        <div className="info">
          <Label />
          <Value style={{ textOverflow: 'unset', whiteSpace: 'pre-wrap' }} className="error">
            {errorMsg}
          </Value>
        </div>
      )}
    </Wrapper>
  );
};

export default TopicInfo;
