import React, { useEffect, useState } from 'react';
import { Typography, List } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ButtonComponent from '@components/button';
import { translations } from '@i18n/translations';
import { useHistories } from '@hooks/hisories';
import TicketQR from '../ticket/TicketQR';
import ModalQr from '../modal-qr';

import { PaymentSuccessStyled } from './styled';

const PaymentSuccess: React.FC = () => {
  const { t } = useTranslation();
  const { getQRList, qrList, resetQRDetail, isLoading } = useHistories();
  const { myTicket } = translations;
  const [ticketSelected, setTicketSelected] = useState<number | null | any>(null);

  const { balanceCode, realEventId }: { balanceCode: string; realEventId: string } = useParams();

  const loadMoreData = () => {
    getQRList({ balanceCode, realEventId, page: qrList.paginate.next });
  };

  const loadMore = qrList.paginate.current_page < qrList.paginate.last_page && (
    <div className="row-btn">
      <ButtonComponent loading={isLoading} onClick={loadMoreData}>
        {t(myTicket.load_more)}
      </ButtonComponent>
    </div>
  );

  const onClickTicket = ({ serieCode, yenPrice, coinPrice }) => {
    setTicketSelected({
      serieCode,
      yenPrice,
      coinPrice,
    });
  };

  const onClose = () => {
    setTicketSelected(null);
    resetQRDetail();
  };

  useEffect(() => {
    getQRList({ balanceCode, realEventId, page: 1 });
  }, []);

  return (
    <PaymentSuccessStyled>
      <Typography className="title">{t('myTicket:my_ticket_detail.ticket_info')}</Typography>
      <List
        loadMore={loadMore}
        loading={isLoading}
        dataSource={qrList.data}
        renderItem={(item) => (
          <List.Item>
            <TicketQR
              onClickTicket={onClickTicket}
              serieCode={item?.series_code}
              checked={item?.checkin_status}
              ticketName={item?.ticket_name}
              yenPrice={item.ticket_type_price_yen_tax}
              coinPrice={item.ticket_type_price_coin_tax}
              ticketReceiptCode={item?.ticket_receipt_code}
            />
          </List.Item>
        )}
      />
      {ticketSelected && (
        <ModalQr
          onClose={onClose}
          isOpen={ticketSelected !== null}
          serieCode={ticketSelected.serieCode}
          coinPrice={ticketSelected.coinPrice}
          yenPrice={ticketSelected.yenPrice}
        />
      )}
    </PaymentSuccessStyled>
  );
};

export default PaymentSuccess;
