import { REQUEST } from '@stores';
import { Payload } from '@type/Store';
import { VERIFICATION, PAYMENT_PACKAGE, PAYMENT_LIST_PACKAGE, PAYMENT_LIST_HISTORY } from './constants';

export function getPaymentIntent(payload: Payload) {
  return {
    type: REQUEST(VERIFICATION),
    payload,
  };
}

export function getListPackage(payload: Payload) {
  return {
    type: REQUEST(PAYMENT_LIST_PACKAGE),
    payload,
  };
}

export function getListPaymentHistory(payload: Payload) {
  return {
    type: REQUEST(PAYMENT_LIST_HISTORY),
    payload,
  };
}

export function createPaymentPackageDiamond(payload: Payload) {
  return {
    type: REQUEST(PAYMENT_PACKAGE),
    payload,
  };
}
