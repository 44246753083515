import React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';
import { Seemore } from '@components';

import { useHistories } from '@hooks/hisories';
import { TicketInforStyle } from './styled';
import { Label, Value } from '../../styled';

const UserInfo: React.FC = () => {
  const { topicDetail } = useHistories();
  const { t } = useTranslation();
  const { histories } = translations;
  const { phone, zip_code, address, memo, first_name, last_name } = topicDetail;

  return (
    <TicketInforStyle>
      {(!!first_name || !!last_name) && (
        <div className="info">
          <Label>{t(histories.topic_detail.user_name)}</Label>
          <Value>{`${last_name || ''} ${first_name || ''}`}</Value>
        </div>
      )}
      {!!phone && (
        <div className="info">
          <Label>{t(histories.topic_detail.phone)}</Label>
          <Value>{phone}</Value>
        </div>
      )}
      {!!zip_code && (
        <div className="info">
          <Label>{t(histories.topic_detail.zipcode)}</Label>
          <Value>{zip_code}</Value>
        </div>
      )}
      {!!address && (
        <div className="info">
          <Label>{t(histories.topic_detail.address)}</Label>
          <Value>{address}</Value>
        </div>
      )}
      {!!memo && (
        <div className="info">
          <Label>{t(histories.topic_detail.note)}</Label>
          <Seemore rows={3} content={memo || ''} />
        </div>
      )}
    </TicketInforStyle>
  );
};

export default UserInfo;
