import AxiosClient from './api';
import END_POINT from './constants';

function createSignature(data: any) {
  return AxiosClient.post(END_POINT.SIGNATURE, data).then((res) => res.data);
}

function eventTransfer(data: any) {
  return AxiosClient.post(END_POINT.EVENT_TRANSFER, data).then((res) => res);
}
export { createSignature, eventTransfer };
