import { put, takeLatest } from 'redux-saga/effects';
import { REQUEST, SUCCESS } from '@stores';
import {
  getTopicsHistory,
  getTicketsHistory,
  getTicketHistoryDetail,
  getTicketTransaction,
  getTicketSericode,
  getTopicHistoryDetail,
} from '@apis';
import { Action } from '@type/Store';
import {
  GET_QR_DETAIL,
  GET_QR_LIST,
  GET_TICKET_DETAIL,
  GET_TICKET_LIST,
  GET_TOPIC_DETAIL,
  GET_TOPIC_LIST,
} from './constants';
import { sagaCustomize } from './sagaCustomize';
import { setError } from './actions';

export function* getTopicsSaga({ payload }: Action) {
  yield sagaCustomize(function* () {
    const { data: result } = yield getTopicsHistory(payload);
    yield put({
      type: SUCCESS(GET_TOPIC_LIST),
      payload: {
        topicList: result,
      },
    });
  });
}

export function* getTopicDetailSaga({ payload }: Action) {
  yield sagaCustomize(function* () {
    const { data: result } = yield getTopicHistoryDetail(payload);
    yield put({
      type: SUCCESS(GET_TOPIC_DETAIL),
      payload: {
        topicDetail: result,
      },
    });
  });
}

export function* getTicketsSaga({ payload }: Action) {
  yield sagaCustomize(function* () {
    const { data: result } = yield getTicketsHistory(payload);
    yield put({
      type: SUCCESS(GET_TICKET_LIST),
      payload: {
        ticketList: result,
      },
    });
  });
}

export function* getTicketDetailSaga({ payload }: Action) {
  yield sagaCustomize(function* () {
    const { data: result } = yield getTicketHistoryDetail(payload);
    yield put({
      type: SUCCESS(GET_TICKET_DETAIL),
      payload: {
        ticketDetail: result,
      },
    });
  });
}

export function* getQRListSaga({ payload }: Action) {
  try {
    const { data: result } = yield getTicketTransaction(payload);

    yield put({
      type: SUCCESS(GET_QR_LIST),
      payload: {
        qrList: result.data,
        paginate: result.paginate,
      },
    });
  } catch (error) {
    yield put(setError(error));
  }
}

export function* getQRDetailSaga({ payload }: Action) {
  yield sagaCustomize(function* () {
    const { data: result } = yield getTicketSericode(payload);
    yield put({
      type: SUCCESS(GET_QR_DETAIL),
      payload: {
        qrDetail: result,
      },
    });
  });
}

export default function* sagas() {
  yield takeLatest(REQUEST(GET_TOPIC_LIST), getTopicsSaga);
  yield takeLatest(REQUEST(GET_TICKET_LIST), getTicketsSaga);
  yield takeLatest(REQUEST(GET_TICKET_DETAIL), getTicketDetailSaga);
  yield takeLatest(REQUEST(GET_QR_LIST), getQRListSaga);
  yield takeLatest(REQUEST(GET_QR_DETAIL), getQRDetailSaga);
  yield takeLatest(REQUEST(GET_TOPIC_DETAIL), getTopicDetailSaga);
}
