/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 * @param  {state} login state
 * @param  {action} login action
 */
import { createReducer, updateObject } from '@stores';
import { GlobalState } from '@type/Store/global';
import { TOGGLE_SIDEBAR, HOVER_SIDEBAR, IS404, IS_WEB_VIEW, AUTH_BROADCAST } from './constants';

export const initialState: GlobalState = {
  sidebarCompact: false,
  sidebarHover: false,
  is404: false,
  isWebView: false,
  authBoardcastChannel: null,
};

function toggleSidebar(state: GlobalState) {
  return updateObject(state, {
    sidebarCompact: !state.sidebarCompact,
  });
}

function hoverSidebar(state: GlobalState) {
  return updateObject(state, {
    sidebarHover: !state.sidebarHover,
  });
}
export function set404Status(state: GlobalState, { payload }: { payload: boolean }) {
  return updateObject(state, {
    is404: payload,
  });
}

export function setIsWebView(state: GlobalState, { payload }: { payload: boolean }) {
  return updateObject(state, {
    isWebView: payload,
  });
}

export function setAuthBroadcastChannel(state: GlobalState, { payload }: { payload: BroadcastChannel }) {
  return updateObject(state, {
    authBoardcastChannel: payload,
  });
}

// Slice reducer
export default createReducer(initialState, {
  [TOGGLE_SIDEBAR]: toggleSidebar,
  [HOVER_SIDEBAR]: hoverSidebar,
  [IS404]: set404Status,
  [IS_WEB_VIEW]: setIsWebView,
  [AUTH_BROADCAST]: setAuthBroadcastChannel,
});
