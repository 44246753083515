import { Layout } from 'antd';
import styled from 'styled-components';

import Media from '@themes/media';

const { Content } = Layout;

export const Wrapper = styled(Content)`
  max-width: 1300px;
  .ant-tabs-nav {
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        font-weight: 700;
        font-size: 18px;
        padding: 0px;
        color: #787878;
        ${Media.lessThan(Media.SIZE.MD)} {
          font-size: 14px;
        }
      }
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: #282727;
          position: relative;
        }
        .ant-tabs-tab-btn::before {
          content: '';
          width: 50%;
          background-color: #e3007f;
          height: 3px;
          position: absolute;
          bottom: 0px;
          left: 25%;
          border-radius: 8px;
        }
      }
      .ant-tabs-ink-bar {
        display: none;
      }
    }
  }
  .ant-tabs-nav::before {
    display: none;
  }
`;

export const ImageChapters = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 8px;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    border-radius: 4px;
    /* border-radius: 4px; */
  }
`;

export const LiveStatus = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  ${Media.lessThan(Media.SIZE.SM)} {
  }
`;

export const DateTime = styled.div`
  display: flex;
  .date {
    font-size: 12px;
  }
  ${Media.lessThan(Media.SIZE.SM)} {
  }
`;

export const Container = styled.div`
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
`;

export const NoData = styled.div`
  font-size: 30px;
  font-weight: 400;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: center;
`;
