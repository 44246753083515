import { Spin as SpinAntd } from 'antd';
import styled from 'styled-components';

import Media from '@themes/media';

export const Spin = styled(SpinAntd)`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  max-height: initial !important;
  font-family: 'Noto Sans JP';
`;

export const Wrapper = styled.div`
  padding: 40px;
  justify-content: center;
  width: 100%;
  font-family: 'Noto Sans JP';
  align-items: center;
  display: flex;

  .info {
    display: flex;
    .ant-typography {
      font-size: 18px;
    }

    .info-label {
      margin-right: 8px;
      min-width: 180px;

      ${Media.lessThan(Media.SIZE.SM)} {
        min-width: 140px;
      }
    }
  }

  .info-text {
  }

  ${Media.lessThan(Media.SIZE.XXL)} {
    padding: 50px 114px;
  }

  ${Media.lessThan(Media.SIZE.XL)} {
    padding: 50px 24px;
  }

  ${Media.lessThan(Media.SIZE.LG)} {
    padding: 24px 12px;
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    padding: 24px 12px;
  }
`;

export const Content = styled.div`
  background-color: #ffffff;
  width: 960px;
  border-radius: 16px;
  padding: 16px 32px;
  font-family: 'Noto Sans JP';
  flex-direction: column;

  ${Media.lessThan(Media.SIZE.LG)} {
    width: 100%;
    /* padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center; */
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    padding: 16px;
  }
`;

export const Label = styled.div`
  margin-right: 8px;
  min-width: 220px;
  font-size: 18px;
  color: #787878;
  font-family: 'Noto Sans JP';

  ${Media.lessThan(Media.SIZE.SM)} {
    min-width: 120px;
    width: 120px;
  }
`;

export const Value = styled.div`
  font-size: 18px;
  font-family: 'Noto Sans JP';
  color: #282727;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .error {
    color: #d80027 !important;
    font-size: 18px;
    font-weight: 400;
  }

  .link-ticket {
    text-decoration: underline;
    color: #0076e3;
  }

  .custom-style {
    margin-bottom: 0;
  }

  .tag-live {
    border: none;
    margin-left: 20px;
    background-color: ${({ theme }) => theme.bg_red};
    border-radius: 12px;
    font-size: ${({ theme }) => theme.size_10};
    font-weight: ${({ theme }) => theme.fw_700};
    color: white;
  }

  .live-end {
    border: none;
    margin-left: 20px;
    background-color: #787878;
    border-radius: 12px;
    font-size: ${({ theme }) => theme.size_10};
    font-weight: ${({ theme }) => theme.fw_700};
    color: white;
  }

  .tag-comming {
    border: none;
    margin-left: 20px;
    background-color: #e3007f;
    border-radius: 12px;
    font-size: ${({ theme }) => theme.size_10};
    font-weight: ${({ theme }) => theme.fw_700};
    color: white;
  }

  .total {
    display: flex;
    align-items: center;

    &.coin-total {
      color: #10ab52;
    }
  }
`;
