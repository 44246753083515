import React from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '@i18n/translations';
import { EventDetail } from '@type/Store/event';

import EventContent from '../event-content';
import EventDescription from '../event-description';

type EventProps = {
  event: EventDetail;
};

const EventEndSale: React.FC<EventProps> = ({ event }) => {
  const { t } = useTranslation();
  const { common } = translations;
  return (
    <>
      <EventContent event={event}>
        <div className="content-border-dash">
          <div className="message">{t(common.event.endSale)}</div>
        </div>
      </EventContent>
      <EventDescription event={event} />
    </>
  );
};
export default EventEndSale;
