import { put, takeLatest } from 'redux-saga/effects';

import {
  checkPassword,
  createComment,
  deleteComment,
  editComment,
  getAgoraToken,
  getChapterDetail,
  getDonatePackage,
  getLiveStreamDetail,
  getPackageDetail,
  getTopic,
  getTopicList,
  getTopicTop,
  getVideoComment,
  getVideoPackage,
  sendDonate,
} from '@apis';
import { FAILURE, REQUEST, SUCCESS } from '@stores';

import { Action } from '@type/Store';
import { omit } from 'lodash';
import {
  GET_AGORA_TOKEN,
  GET_DONATE_PACKAGE,
  LIVESTREAM_DETAIL,
  SEND_DONATE,
  TOPIC_CHAPTER_DETAIL,
  TOPIC_CHECK_PASSWORD,
  TOPIC_CREATE_COMMENT,
  TOPIC_DELETE_COMMENT,
  TOPIC_DETAIL,
  TOPIC_EDIT_COMMENT,
  TOPIC_LIST,
  TOPIC_PACKAGE_DETAIL,
  TOPIC_TOP,
  TOPIC_VIDEO_COMMENT,
  TOPIC_VIDEO_PACKAGE,
} from './constants';
import { sagaCustomize } from './sagaCustomize';

export function* getTopicListSaga({ payload }: Action) {
  const { perPage, page } = payload;

  yield sagaCustomize(function* () {
    const { data, paginate } = yield getTopicList({ perPage, page });

    yield put({
      type: SUCCESS(TOPIC_LIST),
      payload: {
        data,
        paginate,
      },
    });
  });
}

export function* getTopicSaga({ payload }: Action) {
  const { topicId } = payload;
  yield sagaCustomize(function* () {
    const { data } = yield getTopic({ topicId });
    yield put({
      type: SUCCESS(TOPIC_DETAIL),
      payload: {
        data,
      },
    });
  });
}

// get api topic top
export function* getTopicTopSaga() {
  yield sagaCustomize(function* () {
    const { data } = yield getTopicTop();
    yield put({
      type: SUCCESS(TOPIC_TOP),
      payload: {
        data,
      },
    });
  });
}
// end

// post api check password topic
export function* checkPasswordSaga({ payload }: Action) {
  const { id, password } = payload;
  yield sagaCustomize(function* () {
    yield checkPassword(id, password);

    yield put({
      type: SUCCESS(TOPIC_CHECK_PASSWORD),
      payload: id,
    });
  });
}

export function* getVideoCommentSaga({ payload }: Action) {
  const { id, page } = payload;
  try {
    const { data, paginate } = yield getVideoComment({ id, page });
    yield put({
      type: SUCCESS(TOPIC_VIDEO_COMMENT),
      payload: {
        data,
        paginate,
      },
    });
  } catch (error) {
    yield put({
      type: FAILURE(TOPIC_VIDEO_COMMENT),
      error,
    });
  }
}

export function* createCommentSaga({ payload }: Action) {
  const { data } = payload;
  try {
    const { data: result } = yield createComment(data);
    yield put({
      type: SUCCESS(TOPIC_CREATE_COMMENT),
      payload: {
        comment: result,
      },
    });
  } catch (error) {
    yield put({
      type: FAILURE(TOPIC_CREATE_COMMENT),
      error,
    });
  }
}

export function* getChapterDetailSaga({ payload }: Action) {
  const { id } = payload;
  yield sagaCustomize(function* () {
    const { data } = yield getChapterDetail({ id });
    yield put({
      type: SUCCESS(TOPIC_CHAPTER_DETAIL),
      payload: {
        data,
      },
    });
  });
}

export function* editCommentSaga({ payload }: Action) {
  const { data } = payload;
  const { id, content } = data;

  try {
    const { data: result } = yield editComment(id, content);
    yield put({
      type: SUCCESS(TOPIC_EDIT_COMMENT),
      payload: {
        comment: result,
        data: {
          id,
          content,
        },
      },
    });
  } catch (error) {
    yield put({
      type: FAILURE(TOPIC_EDIT_COMMENT),
      error,
    });
  }
}

export function* deleteCommentSaga({ payload }: Action) {
  const { data } = payload;
  const { id, videoId } = data;
  try {
    const { data: result } = yield deleteComment(id);
    const { data: comments, paginate } = yield getVideoComment({ id: videoId, page: 1 });
    yield put({
      type: SUCCESS(TOPIC_DELETE_COMMENT),
      payload: {
        comment: result,
      },
    });
    yield put({
      type: SUCCESS(TOPIC_VIDEO_COMMENT),
      payload: {
        data: comments,
        paginate,
      },
    });
  } catch (error) {
    yield put({
      type: FAILURE(TOPIC_DELETE_COMMENT),
      error,
    });
  }
}

// start saga listen
export function* liveStreamSaga({ payload }: Action) {
  const { roomId, user_id } = payload;
  try {
    const { data } = yield getLiveStreamDetail({ roomId });
    const { data: agoraRes } = yield getAgoraToken({ user_id, channel_name: roomId });
    // data.roomToken = agoraRes.token || ''
    yield put({
      type: SUCCESS(LIVESTREAM_DETAIL),
      data: {
        ...data,
        // roomToken: agoraRes.token || '',
      },
      roomToken: agoraRes.token || '',
    });
  } catch (error) {
    yield put({
      type: FAILURE(LIVESTREAM_DETAIL),
      error,
    });
  }
}
// end

// start saga listen
export function* getAgoraTokenSaga({ payload }: Action) {
  const { channel_name, user_id } = payload;
  try {
    const { data: agoraRes } = yield getAgoraToken({ user_id, channel_name });
    yield put({
      type: SUCCESS(GET_AGORA_TOKEN),
      roomToken: agoraRes.token || '',
    });
  } catch (error) {
    yield put({
      type: FAILURE(GET_AGORA_TOKEN),
      error,
    });
  }
}
// end

// start saga listen
export function* getDonatePackageSaga({ payload }: Action) {
  try {
    const { data } = yield getDonatePackage(payload);
    const dataMapping = data
      .sort((a, b) => {
        if (a.price && b.price) return a.price - b.price;
        if (a.price_diamond && b.price_diamond) return a.price_diamond - b.price_diamond;
        if (a.price_coin && b.price_coin) return a.price_coin - b.price_coin;
        return false;
      })
      .map((item) => {
        const price = () => {
          if (item.price) return item.price;
          if (item.price_diamond) return item.price_diamond;

          return item.price_coin;
        };
        const type = () => {
          if (item.price) return 'ruby';
          if (item.price_diamond) return 'diamond';

          return 'coin';
        };
        const packageDonate = {
          ...item,
          price: price(),
          type: type(),
        };
        return omit(packageDonate, ['price_coin', 'price_diamond']);
      });

    yield put({
      type: SUCCESS(GET_DONATE_PACKAGE),
      data: dataMapping,
    });
  } catch (error) {
    yield put({
      type: FAILURE(GET_DONATE_PACKAGE),
      error,
    });
  }
}
// end

// start saga listen
export function* sendDonateSaga({ payload }: Action) {
  const { params, liveKey } = payload;
  try {
    const { data, message } = yield sendDonate(liveKey, params);
    yield put({
      type: SUCCESS(SEND_DONATE),
      data,
      message,
    });
  } catch (error) {
    yield put({
      type: FAILURE(SEND_DONATE),
      error,
    });
  }
}
// end

export function* getVideoPackageSaga({ payload }: Action) {
  const { id, page } = payload;
  try {
    const { data, paginate } = yield getVideoPackage({ id, page });
    yield put({
      type: SUCCESS(TOPIC_VIDEO_PACKAGE),
      payload: {
        data,
        paginate,
      },
    });
  } catch (error) {
    yield put({
      type: FAILURE(TOPIC_VIDEO_PACKAGE),
      error,
    });
  }
}

export function* getPackageDetailSaga({ payload }: Action) {
  const { topicId, packageId } = payload;
  yield sagaCustomize(function* () {
    const { data } = yield getPackageDetail({ topicId, packageId });
    yield put({
      type: SUCCESS(TOPIC_PACKAGE_DETAIL),
      payload: data,
    });
  });
}

export default function* topicsSaga() {
  yield takeLatest(REQUEST(TOPIC_CHAPTER_DETAIL), getChapterDetailSaga);
  yield takeLatest(REQUEST(TOPIC_LIST), getTopicListSaga);
  yield takeLatest(REQUEST(TOPIC_DETAIL), getTopicSaga);
  yield takeLatest(REQUEST(TOPIC_TOP), getTopicTopSaga);
  yield takeLatest(REQUEST(TOPIC_CHECK_PASSWORD), checkPasswordSaga);
  yield takeLatest(REQUEST(LIVESTREAM_DETAIL), liveStreamSaga);
  yield takeLatest(REQUEST(GET_DONATE_PACKAGE), getDonatePackageSaga);
  yield takeLatest(REQUEST(SEND_DONATE), sendDonateSaga);
  yield takeLatest(REQUEST(TOPIC_VIDEO_COMMENT), getVideoCommentSaga);
  yield takeLatest(REQUEST(TOPIC_CREATE_COMMENT), createCommentSaga);
  yield takeLatest(REQUEST(TOPIC_EDIT_COMMENT), editCommentSaga);
  yield takeLatest(REQUEST(TOPIC_DELETE_COMMENT), deleteCommentSaga);
  yield takeLatest(REQUEST(TOPIC_VIDEO_PACKAGE), getVideoPackageSaga);
  yield takeLatest(REQUEST(TOPIC_PACKAGE_DETAIL), getPackageDetailSaga);
  yield takeLatest(REQUEST(GET_AGORA_TOKEN), getAgoraTokenSaga);
}
