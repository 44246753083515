/* eslint-disable no-console */
/* eslint-disable no-alert */

// import { getEnv } from '@config/env';

export const useOpenApp = () => {
  // const WEB_URL = getEnv('WEB_URL', null);

  // const generateWebUrl = (type: string | null, topicId?: string, packageId?: string, liveKey?: string | null) => {
  //   switch (type) {
  //     case 'TOPIC':
  //       return `${WEB_URL}/topics/watch-video-chapter/${packageId}`;
  //     case 'PACKAGE':
  //       return `${WEB_URL}/topics/${topicId}/video-package/${packageId}`;

  //     case 'LIVE':
  //       return `${WEB_URL}/topics/watch-livestream?room=${liveKey || ''}`;
  //     default:
  //       return null;
  //   }
  // };

  const openApp = ({
    // name,
    // title,
    // description,
    // image_url = '',
    // topicId,
    // packageId,
    // type,
    // liveKey = '',
    // public_start,
    // public_end,
    deeplink,
  }) => {
    window.open(deeplink, '_blank');
    // try {
    //   const { branch } = window;
    //   const desktop_url = generateWebUrl(type, topicId, packageId, liveKey);
    //   const linkData = {
    //     campaign: 'topics payment',
    //     channel: 'topics',
    //     feature: 'payment',
    //     tags: [],
    //     data: {
    //       $desktop_url: desktop_url,
    //       $ios_url: `${WEB_URL}/download`,
    //       $ipad_url: `${WEB_URL}/download`,
    //       $android_url: `${WEB_URL}/download`,
    //       $og_app_id: `${topicId}${packageId || ''}`,
    //       $og_title: `${name}${title || ''}`,
    //       $og_description: description || '',
    //       $og_image_url: image_url || '',
    //       $canonical_identifier: 'topic',
    //       topicId,
    //       packageId,
    //       type,
    //       liveKey,
    //       public_start,
    //       public_end,
    //     },
    //   };
    //   branch.setIdentity('topic', (err) => {
    //     branch.link(linkData, async (linkError, deeplink) => {
    //       // if (!linkError) {
    //       alert(`deeplink: ${deeplink}`);

    //       // } else {
    //       //   throw err;
    //       // }
    //     });
    //   });
    // } catch (error: any) {
    //   console.log('====> error', error);
    // }
  };

  return {
    openApp,
  };
};
