import React from 'react';
import { useHistory } from 'react-router-dom';

import { BACK_ICON_SVG } from '@assets';
import { BoxPaymentStyled, TextBackStyled } from './styled';

interface BoxPaymentProps {
  children: JSX.Element;
  title: string;
  routeBack?: string;
  isShowIconBack: boolean;
}

const BoxPayment: React.FC<BoxPaymentProps> = ({ children, title, routeBack, isShowIconBack }) => {
  const history = useHistory();

  return (
    <BoxPaymentStyled>
      <div className="box-top">
        <TextBackStyled cursor={isShowIconBack ? 'pointer' : 'default'}>
          {isShowIconBack && (
            <BACK_ICON_SVG
              onClick={() => {
                history.push(routeBack || '');
              }}
              style={{ marginRight: '10px' }}
            />
          )}
          {title}
        </TextBackStyled>
      </div>

      <div className="box-bottom">{children}</div>
    </BoxPaymentStyled>
  );
};

export default BoxPayment;
