import Media from '@themes/media';
import styled from 'styled-components';
import { Typography } from 'antd';

const { Text } = Typography;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px;
  justify-items: flex-start;
  gap: 4px;
  border-radius: 25px;
  margin-top: 2px;
  position: relative;
  overflow: hidden;
`;

export const DonateMsg = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: center;
  gap: 4px;
  border-radius: 50px;
  background: #f745a0;
  position: relative;
  margin-top: 2px;
  height: 48px;
`;

export const DonateInfo = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 250px;
  ${Media.lessThan(Media.SIZE.LG)} {
    max-width: calc(100vw - 96px);
  }
`;

export const Message = styled.span`
  margin: 0;
  padding: 0;
  color: #282727;
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: pre-wrap;
  overflow: hidden;
  width: 100%;
  ${Media.lessThan(Media.SIZE.LG)} {
  }
`;

export const UserName = styled(Text)`
  margin: 0;
  padding: 0;
  color: #0076e3;
  font-size: 13px;
  font-weight: 500;
  overflow: hidden;

  ${Media.lessThan(Media.SIZE.LG)} {
  }
`;
