import React, { useMemo } from 'react';
import { Avatar } from '@components';
import { POINT } from '@assets';
import { numberWithCommas } from '@utils/number';
import { Wrapper, RankingNumber, Info, Point, PointNumber, Name } from './styled';

interface IProps {
  isRanking?: boolean;
  avatar?: string | null;
  position: number;
  name: string | null;
  donate?: number | null;
}

export const User: React.FC<IProps> = ({ isRanking = false, avatar, position, name, donate }) => {
  const background = useMemo(() => {
    if (position === 1 && isRanking) return '#FBAA37';
    if (position === 2 && isRanking) return '#89B0DD';
    if (position === 3 && isRanking) return '#DC9A78';
    return '';
  }, [position, isRanking]);

  const color = useMemo(() => {
    if (position < 4 && isRanking) return '#0076E3';
    return '#282727';
  }, [position, isRanking]);

  return (
    <Wrapper>
      <Info>
        <RankingNumber background={background}>{position}</RankingNumber>
        <Avatar src={avatar || ''} width={28} isBorder />
        <Name color={color} ellipsis>
          {name}
        </Name>
      </Info>
      {isRanking && (
        <Point>
          <img alt="point" src={POINT} width={12} height={12} />
          <PointNumber>{numberWithCommas(Math.round(donate || 0), ',')}</PointNumber>
        </Point>
      )}
    </Wrapper>
  );
};

export default User;
