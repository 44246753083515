import { FormRegisterValues, FormVerifyPhoneValues, FormResendCodeValues } from '@type/Form/RegisterForm';
import AxiosClient from './api';
import END_POINT from './constants';

/**
 * login
 * @param data
 */
function login(data: any) {
  return AxiosClient.post(END_POINT.LOGIN, data).then((res) => res);
}

function register(data: FormRegisterValues) {
  return AxiosClient.post(END_POINT.REGISTER, data, { isHideError: true }).then((res) => res);
}

function verifyRegister(data: FormVerifyPhoneValues) {
  return AxiosClient.put(END_POINT.VERIFY_PHONE, data, { isHideError: true }).then((res) => res);
}

function reSendCodeVerify(data: FormResendCodeValues) {
  return AxiosClient.post(END_POINT.RESEND_CODE, data, { isHideError: true }).then((res) => res);
}

/**
 * logout
 * @param data
 */
function logout(data: any) {
  return AxiosClient.post(END_POINT.LOGOUT, data).then((res) => res);
}

/**
 * verification
 * @param data
 */
function verification(data: any) {
  return AxiosClient.post(END_POINT.VERIFICATION, data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    isHideError: true,
  }).then((res) => res);
}

/**
 * resetPassword
 * @param codeVerification
 */
function resetPassword(code: any, data: any) {
  return AxiosClient.put(`${END_POINT.RESET_PASSWORD}${code}`, data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    isHideError: true,
  }).then((res) => res);
}

/**
 * getProfile
 * @param userId
 */
function getProfile({ userId }: any) {
  return AxiosClient.get(`${END_POINT.PROFILE_USER}?userId=${userId}`).then((res) => res);
}

function getUserProfile() {
  return AxiosClient.get(END_POINT.GET_ME).then((res) => res);
}

/**
 * logout
 * @param data
 */
function changeLanguage({ language }: { language: string }) {
  return AxiosClient.put(END_POINT.CHANGE_LANGUAGE, { language }).then((res) => res);
}

export {
  login,
  logout,
  register,
  reSendCodeVerify,
  verifyRegister,
  verification,
  resetPassword,
  getProfile,
  getUserProfile,
  changeLanguage,
};
