import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';

import { useEvents, useRealEventPayment } from '@hooks';
import { Button, Seemore } from '@components';
import { MAP_ICON } from '@assets';
import { formatDateTimeOfWeek } from '@utils/time';
import { EventDetail } from '@type/Store/event';
import { translations } from '@i18n/translations';
import { numberWithCommas } from '@utils/number';
import { Group, PaymentInfoStyle, TicketDetailStyle, PaymentCoinConfirmStyled, Title } from './styled';

interface Ticket {
  quantity: number;
  ticketId: number | string | undefined;
  name: string;
}
type PaymentStatus = 'INPUT_PAYMENT' | 'CONFIRM_PAYMENT' | 'PAYMENT_SUCCESS' | 'PAYMENT_FAILURE';

type PaymentConfirmProps = {
  eventDetail?: EventDetail;
  tickets: Ticket[];
  totalCoinPrice?: number;
  totalTicket?: number;
  setPaymentStatus: (status: PaymentStatus) => void;
  email: string;
  phonenumber: string;
  name: string;
  onConfirm: () => void;
  paymentMethod: string;
  storeName?: string;
  onCancel?: () => void;
  yenFee?: number;
  memo?: string | null;
  cardNumber?: string | null;
  dateExpiry?: string | null;
  nameKana?: string | null;
  isKobining?: boolean;
};

const PaymentConfirm: React.FC<PaymentConfirmProps> = ({
  eventDetail,
  totalCoinPrice,
  totalTicket,
  tickets = [],
  email = '',
  phonenumber = '',
  name = '',
  setPaymentStatus,
  onConfirm,
  onCancel,
  paymentMethod,
  storeName,
  yenFee,
  memo,
  cardNumber,
  dateExpiry,
  nameKana,
  isKobining,
}) => {
  const { isLoading } = useRealEventPayment();
  const { i18n, t } = useTranslation();
  const { realEventPayment } = translations;
  const { ticketsData } = useEvents();

  return (
    <PaymentCoinConfirmStyled>
      <Spin spinning={isLoading}>
        <TicketDetailStyle>
          <Title> {t(realEventPayment.purchase_information)} </Title>
          <Group>
            <label>{t(realEventPayment.ticket_good)}</label>
            <p className="custom-width">{eventDetail?.title}</p>
          </Group>
          <Group>
            <label>{t(realEventPayment.event_date)}</label>
            <p>{formatDateTimeOfWeek(eventDetail?.start_at || 0, i18n.language)}</p>
          </Group>
          <Group>
            {eventDetail?.url && (
              <>
                <label>{t(realEventPayment.address)}</label>
                <p>
                  <a href={eventDetail?.url} target="_blank" rel="noreferrer">
                    {t(realEventPayment.here)}
                  </a>
                  <MAP_ICON style={{ marginLeft: '5px' }} />
                </p>
              </>
            )}
          </Group>
          {(!!ticketsData?.firstKanji || !!ticketsData?.lastKanji) && (
            <Group>
              <label>{t(realEventPayment.user_name)}</label>
              <p>{`${ticketsData?.lastKanji} ${ticketsData?.firstKanji}`}</p>
            </Group>
          )}
          {!!ticketsData?.phoneNumber && (
            <Group>
              <label>{t(realEventPayment.phone)}</label>
              <p>{ticketsData?.phoneNumber}</p>
            </Group>
          )}
          {!!ticketsData?.zipcode && (
            <Group>
              <label>{t(realEventPayment.fieldName.zipcode)}</label>
              <p>{ticketsData?.zipcode}</p>
            </Group>
          )}

          {!!ticketsData?.address && (
            <Group>
              <label>{t(realEventPayment.fieldName.address)}</label>
              <p className="custom-width">{ticketsData?.address}</p>
            </Group>
          )}
          {!!memo && (
            <Group>
              <label>{t(realEventPayment.note)}</label>
              <Seemore rows={3} content={memo || ''} className="custom-width" />
            </Group>
          )}
          <Group>
            <label>{t(realEventPayment.ticket_type)}</label>
            <div>
              {tickets
                .filter((f) => !!f.quantity)
                .map((ticket) => (
                  <div key={ticket.ticketId} className="ticket-type">
                    <div>{ticket.name}</div>
                    <div>
                      {ticket.quantity}
                      {t(realEventPayment.sheet)}
                    </div>
                  </div>
                ))}
            </div>
          </Group>
          <Group>
            <label>{t(realEventPayment.number_of_tickets)}</label>
            <p>
              {totalTicket} {t(realEventPayment.number_of_tickets)}
            </p>
          </Group>
          {!!yenFee && (
            <>
              <Group>
                <label>{t(realEventPayment.subtotal)}</label>
                <p>
                  <span className="yen" style={{ color: '#282727' }}>
                    ￥{numberWithCommas((totalCoinPrice || 0) - (yenFee || 0), ',')}
                  </span>
                </p>
              </Group>
              <Group>
                <label>{t(realEventPayment.fee)}</label>
                <p>
                  <span className="yen" style={{ color: '#282727' }}>
                    ￥{numberWithCommas(yenFee, ',')}
                  </span>
                </p>
              </Group>
            </>
          )}
          <Group>
            <label>{t(realEventPayment.total_amount_notFee)}</label>
            <p>
              <span className="yen">￥{numberWithCommas(totalCoinPrice, ',')}</span>
            </p>
          </Group>
        </TicketDetailStyle>
        <PaymentInfoStyle>
          <Title> {t(realEventPayment.payment_information)} </Title>
          <Group>
            <label>{t(realEventPayment.payment_method)}</label>
            <p>{paymentMethod}</p>
          </Group>
          {!!storeName && (
            <Group>
              <label>{t(realEventPayment.storeName)}</label>
              <p>{storeName}</p>
            </Group>
          )}
          <Group>
            <label>{isKobining ? t(realEventPayment.user_name) : t(realEventPayment.name)}</label>
            <p>{name}</p>
          </Group>
          {!!cardNumber && (
            <Group>
              <label>{t(realEventPayment.fieldName.cardnumber)}</label>
              <p>{cardNumber}</p>
            </Group>
          )}
          {!!dateExpiry && (
            <Group>
              <label>{t(realEventPayment.fieldName.dateExpiry)}</label>
              <p>{dateExpiry}</p>
            </Group>
          )}
          {nameKana && nameKana?.length > 1 && (
            <Group>
              <label>{t(realEventPayment.name_kana)}</label>
              <p>{nameKana}</p>
            </Group>
          )}
          <Group>
            <label>{t(realEventPayment.email)}</label>
            <p className="custom-width">{email}</p>
          </Group>
          <Group>
            <label>{t(realEventPayment.phone)}</label>
            <p>{phonenumber}</p>
          </Group>
          <div className="box-btn">
            <Button
              onClick={() => {
                setPaymentStatus('INPUT_PAYMENT');
                if (onCancel) {
                  onCancel();
                }
              }}
              type="cancel"
            >
              {t(realEventPayment.cancel)}
            </Button>
            <Button onClick={onConfirm}> {t(realEventPayment.continue)}</Button>
          </div>
        </PaymentInfoStyle>
      </Spin>
    </PaymentCoinConfirmStyled>
  );
};
export default PaymentConfirm;
