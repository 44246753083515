import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

import { TicketQrStyled } from './styled';

interface Props {
  serieCode?: number | string | null;
  checked: number;
  ticketName: string;
  onClickTicket: ({ serieCode, yenPrice, coinPrice }) => void;
  yenPrice?: string | null;
  coinPrice?: string | null;
  ticketReceiptCode?: number | string | null;
}

const TicketQR: React.FC<Props> = ({
  serieCode,
  checked,
  onClickTicket,
  ticketName,
  coinPrice,
  yenPrice,
  ticketReceiptCode,
}) => {
  const { t } = useTranslation();

  return (
    <TicketQrStyled
      onClick={() => {
        onClickTicket({
          serieCode,
          yenPrice,
          coinPrice,
        });
      }}
    >
      <div>
        <Typography className="label">{ticketName}</Typography>
        <Typography className="serie-code">
          {t('myTicket.ticketReceiptCode')}: {serieCode}
        </Typography>
        <Typography className="serie-code">
          {t('myTicket:my_ticket_detail.serial_number')}: {ticketReceiptCode}
        </Typography>
        {checked === 1 && (
          <Typography className="check-status">{t('myTicket:my_ticket_detail.already_checked')}</Typography>
        )}
      </div>
      {serieCode && (
        <div>
          <QRCode style={{ width: '83px', height: '83px' }} value={serieCode.toString()} />
        </div>
      )}
    </TicketQrStyled>
  );
};

export default TicketQR;
