/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Header, Footer, SiderBarCard } from '@components';
import { isMobile, isTablet } from 'react-device-detect';
import { useRoot } from '@hooks';

const Wrapper: any = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  background: ${({ theme }) => theme.background_color};
`;
const Content = styled.div`
  overflow: hidden;
  /* width: 100%;
  height: 100%; */
`;

const HomeLayout: React.FC = ({ children }) => {
  const [isShowSideBar, setIsShowSideBar] = useState(false);
  const { isWebView } = useRoot();

  return (
    <Wrapper>
      {!isWebView && <Header setIsShowSideBar={setIsShowSideBar} />}
      {(isMobile || isTablet) && !isWebView && (
        <SiderBarCard isShowSideBar={isShowSideBar} setIsShowSideBar={setIsShowSideBar} />
      )}
      <Main>
        <Content>{children}</Content>
      </Main>
      <Footer />
    </Wrapper>
  );
};

export default HomeLayout;
