import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { Button } from '@components';
import { numberWithCommas } from '@utils/number';
import { translations } from '@i18n/translations';
import { useHistory } from 'react-router-dom';
import { useTopicPayment } from '@hooks/useTopicPayment';
import { KOBINI_LIST } from '@modules/topic-payment/constants';
import { useOpenApp } from '@hooks/useOpenApp';
import { Group, PaymentInfoStyle, TicketDetailStyle, UserInfoStyle, Wrapper } from './styled';

const Purchased: React.FC = () => {
  const { replace } = useHistory();
  const { t, i18n } = useTranslation();
  const { realEventPayment, topic_payment, topic } = translations;
  const { user_info, konbini_info, topic_info, loading, deeplink, reset } = useTopicPayment();
  const { openApp } = useOpenApp();

  const { convenience } = konbini_info;
  const storeName = useMemo(() => {
    if (convenience) {
      const findKobini = KOBINI_LIST.find((item) => item.value === convenience);
      if (findKobini) {
        return t(findKobini?.label);
      }
      return '';
    }

    return '';
  }, [convenience, i18n.language]);

  const gotoTopic = () => {
    reset();
    replace('/topics');
  };

  const handlePaymentSuccess = () => {
    if (deeplink) {
      openApp({
        deeplink,
      });
    } else {
      reset();
      replace(`/histories/topic/${topic_info.balance_code}`);
    }
  };

  return (
    <Wrapper>
      <Spin spinning={loading}>
        <TicketDetailStyle>
          <p>{t(realEventPayment.kobini.purchase_noti)}</p>
        </TicketDetailStyle>
        <UserInfoStyle>
          <Group>
            <label>{t(realEventPayment.user_name)}</label>
            <span>{`${user_info.last_name} ${user_info.last_name}`}</span>
          </Group>
          <Group>
            <label>{t(realEventPayment.email)}</label>
            <span className="custom-width">{user_info.email}</span>
          </Group>
          <Group>
            <label>{t(realEventPayment.phone)}</label>
            <span>{user_info.phone_number}</span>
          </Group>
        </UserInfoStyle>
        <PaymentInfoStyle>
          <Group>
            <label>{t(topic.topics)}</label>
            <p>{topic_info.title}</p>
          </Group>
          <Group>
            <label>{t(topic.episode)}</label>
            <p>{topic_info.description}</p>
          </Group>
          <Group>
            <label>{t(realEventPayment.payment_method)}</label>
            <span>{t(realEventPayment.kobini.title)}</span>
          </Group>
          {storeName && (
            <Group>
              <label>{t(realEventPayment.storeName)}</label>
              <span>{storeName}</span>
            </Group>
          )}
          <Group>
            <label>{t(realEventPayment.total_amount_notFee)}</label>
            <span className="yen">￥{numberWithCommas(topic_info.yen_price, ',')}</span>
          </Group>

          <Group>
            <label>{t(realEventPayment.kobini.transfer_slip)}</label>
            <span style={{ fontWeight: 700, color: '#0076E3' }}>{konbini_info.receiptNo}</span>
          </Group>

          <div className="box-btn">
            <Button onClick={gotoTopic} type="outline">
              {t(topic_payment.goto_topic)}
            </Button>
            <Button onClick={handlePaymentSuccess}> {t(topic_payment.goto_histories)}</Button>
          </div>
        </PaymentInfoStyle>
      </Spin>
    </Wrapper>
  );
};
export default Purchased;
