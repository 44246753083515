export const CARD_OPTIONS = {
  classes: {
    base: 'ant-input payment-input',
  },
  style: {
    base: {
      fontVariant: 'tabular-nums',
      fontWeight: 400,
      color: '#323232',
      fontSize: '14px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#C4C4C4',
      },
      '::placeholder': {
        color: '#C4C4C4',
        fontSize: '12px',
        fontWeight: 400,
      },
      textAlign: 'left',
    },
    complete: {
      color: '#323232',
    },
    invalid: {
      color: '#323232',
    },
  },
};
