import Media from '@themes/media';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  font-family: 'Noto Sans JP';
  flex-direction: row;
  justify-content: space-between;
  ${Media.lessThan(Media.SIZE.LG)} {
    margin: 0;
    border-radius: 0;
  }
`;

const Viewer = styled.div`
  display: flex;
  background: #e3007f;
  align-items: center;
  justify-content: center;
  min-width: 72px;
  height: 20px;
  padding: 0;
  border-radius: 50px;
  ${Media.lessThan(Media.SIZE.LG)} {
    min-width: 56px;
    height: 16px;
  }
`;

const Timer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 0 16px;
  border-radius: 50px;
  background: rgba(2, 2, 4, 0.3);
  ${Media.lessThan(Media.SIZE.LG)} {
    min-width: 56px;
    height: 16px;
  }
`;

const Donate = styled.div`
  display: flex;
  background: #8f1a5c;
  align-items: center;
  justify-content: center;
  min-width: 72px;
  height: 20px;
  padding: 0;
  border-radius: 50px;
  ${Media.lessThan(Media.SIZE.LG)} {
    min-width: 56px;
    height: 16px;
  }
`;

const Number = styled.span`
  font-size: 10px;
  font-weight: 700;
  margin-left: 4px;
  color: white;
`;
const Left = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  gap: 6px;
`;

export { Viewer, Donate, Number, Left, Timer };
