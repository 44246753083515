import styled from 'styled-components';
import { Modal, Typography } from 'antd';

import Media from '@themes/media';

export const TicketDetailStyle = styled.div``;
export const UserInfoStyle = styled.div``;
export const Group = styled.div``;
export const PaymentInfoStyle = styled.div``;

export const Wrapper = styled.div`
  max-width: 720px;

  ${TicketDetailStyle},
  ${UserInfoStyle},
  ${PaymentInfoStyle} {
    border-bottom: 1px solid #e5e6eb;
    padding: 15px 0;

    &:last-child {
      border-bottom: initial;
    }

    ${Group} {
      display: flex;
      font-size: 18px;
      font-weight: 400;
      font-family: 'Noto Sans JP';
      margin-bottom: 16px;

      .custom-width {
        max-width: calc(100% - 200px);
        word-wrap: break-word;
      }
      p {
        max-width: calc(100% - 200px);
        word-wrap: break-word;
      }
      ${Media.lessThan(Media.SIZE.SM)} {
        .custom-width {
          max-width: calc(100% - 140px);
        }

        p {
          max-width: calc(100% - 140px);
        }
      }

      label {
        color: ${({ theme }) => theme._787878};
        font-size: 18px;
        width: 200px;
        min-width: 200px;
        white-space: nowrap;
        ${Media.lessThan(Media.SIZE.MD)} {
          font-size: ${({ theme }) => theme.size_14};
          padding-right: 5px;
          margin-bottom: 0;
          width: 140px;
          min-width: 140px;
          white-space: pre-wrap;
        }
      }

      p,
      div {
        margin: 0;
        padding: 0;
        word-wrap: break-word;
        max-width: 100%;
        font-size: ${({ theme }) => theme.size_18};
        color: ${({ theme }) => theme.dark};

        ${Media.lessThan(Media.SIZE.SM)} {
          font-size: ${({ theme }) => theme.size_14};
        }

        a {
          text-decoration: underline;
        }

        .price {
          color: #0076e3;
          font-weight: ${({ theme }) => theme.fw_700};
        }

        .yen {
          color: #e3007f;
          display: flex;
          align-items: center;
          font-weight: ${({ theme }) => theme.fw_700};
        }

        .d-flex {
          display: flex;
          margin: 0;
        }

        span + span {
          padding-left: 45px;
        }

        span {
          svg {
            margin-right: 5px;
          }
        }
        .ticket-type {
          display: flex;
          gap: 20px;
          justify-content: space-between;
          width: 100%;
        }
      }

      ${Media.lessThan(Media.SIZE.SM)} {
        margin-bottom: 8px;
      }
    }

    .box-btn {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
    }
  }
`;

export const TextComfirmStyled = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 45px;
  font-family: 'Noto Sans JP';
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 24px;
  }

  .ant-modal-body {
    /* padding: 82px 58px 45px 58px; */
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .ant-btn {
      height: 44px;
    }
  }
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: #282727;
  margin-bottom: 16px;
`;
