import React, { useEffect, useMemo, useRef } from 'react';
import { Heart, useHearts } from '@hooks/useLikes';
import { isMobile, isTablet } from 'react-device-detect';
import { Wrapper } from './styled';

interface Props {
  liveKey?: string | null;
}

export const ShowHearts: React.FC<Props> = ({ liveKey }) => {
  const { hearts, removeHeart } = useHearts({ roomId: liveKey });
  const refW = useRef(null);

  const heart: Heart | null = useMemo(() => {
    if (hearts.length > 0) {
      return hearts[0];
    }
    return null;
  }, [hearts]);
  const sizeHeart = useMemo(() => {
    if (isMobile || isTablet) return 40;
    return 80;
  }, [isMobile, isTablet]);
  useEffect(() => {
    let removeGif;
    let createHeart;
    let count = 0;
    if (heart && refW.current) {
      removeGif = setTimeout(() => removeHeart(hearts[0].id), 2000);
      createHeart = setInterval(() => {
        if (count < 5) {
          count += 1;
          const wrap = document.getElementById('wrap');

          const heart = document.createElement('span');
          const size = Math.random() * sizeHeart;
          heart.style.width = `${20 + size}px`;
          heart.style.height = `${20 + size}px`;
          if (wrap) {
            wrap.append(heart);
          }
          setTimeout(() => heart.remove(), 2000);
        } else {
          clearInterval(createHeart);
        }
      }, 300);
    }

    return () => {
      clearInterval(createHeart);
      clearTimeout(removeGif);
    };
  }, [refW, heart, sizeHeart]);

  return <Wrapper ref={refW} id="wrap" />;
};

export default ShowHearts;
