export const fontSize = {
  size_10: '10px',
  size_14: '14px',
  size_16: '16px',
  size_18: '18px',
  size_20: '20px',
  size_22: '22px',
  size_24: '24px',
  size_26: '26px',
  size_40: '40px',
};

export const fontWeight = {
  fw_400: '400',
  fw_500: '500',
  fw_600: '600',
  fw_700: '700',
};
