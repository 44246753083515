import React from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from '@i18n/translations';
import { EventDetail } from '@type/Store/event';
import { formatDateTimeOfWeek } from '@utils/time';

import EventContent from '../event-content';
import EventDescription from '../event-description';

type EventProps = {
  event: EventDetail;
};

const EventActive: React.FC<EventProps> = ({ event }) => {
  const { t, i18n } = useTranslation();
  const { common } = translations;

  return (
    <>
      <EventContent event={event}>
        <div className="content-border-dash">
          <div className="message">
            <p>{t(common.event.sales_period_is)}</p>
            <time>
              {formatDateTimeOfWeek(event?.start_sale_at, i18n.language)} -
              {formatDateTimeOfWeek(event?.end_sale_at, i18n.language)}
            </time>
          </div>
        </div>
      </EventContent>
      <EventDescription event={event} />
    </>
  );
};
export default EventActive;
