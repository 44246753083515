import React, { useEffect, useMemo } from 'react';
import IMAGES_GIF from '@assets/gif-images';
import { useGifs, Gif } from '@hooks/useDonate';
import { Wrapper } from './styled';

interface Props {
  liveKey?: string | null;
}

export const ShowGif: React.FC<Props> = ({ liveKey }) => {
  const { gifs, removeGif } = useGifs({ roomId: liveKey });
  const gif: Gif | null = useMemo(() => {
    if (gifs.length > 0) {
      const gifsSorted = gifs.sort((a, b) => {
        if (a.diamond === 0 && b.diamond !== 0) {
          return 1;
        }
        if (a.diamond !== 0 && b.diamond === 0) {
          return -1;
        }
        if (a.diamond !== 0 && b.diamond !== 0) {
          return a.createdAt - a.createdAt;
        }
        return 0;
      });

      return gifsSorted[0];
    }
    return null;
  }, [gifs]);

  useEffect(() => {
    let timer;
    if (gif) {
      timer = setTimeout(
        () => {
          removeGif(gif.id);
        },
        Number(gif.duration) * 1000 || 3000,
      );
    }

    return () => {
      clearTimeout(timer);
    };
  }, [gif]);

  const src = useMemo(() => {
    if (gif) {
      const gifNumber = Number(gif.images);
      const gifPath = gifNumber < 10 ? `GIF_0${gifNumber}` : `GIF_${gifNumber}`;
      return `${IMAGES_GIF[`${gifPath}`]}?timestamp=${Date.now()}`;
    }

    return '';
  }, [gif]);

  return <Wrapper>{src && <img src={src} alt="gif" />}</Wrapper>;
};

export default ShowGif;
