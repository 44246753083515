import styled from 'styled-components';
import { Modal, Spin } from 'antd';

import Media from '@themes/media';

export const SpinStyled = styled(Spin)`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  max-height: initial !important;
`;

export const ModalQrStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 9px;
    max-width: 833px;
  }

  .ant-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-spin-container {
      display: flex;
      justify-content: center;

      ${Media.lessThan(Media.SIZE.SM)} {
        flex-direction: column;
        align-items: center;
      }
    }

    .box-left {
      border-right: 2px dashed #d9d9d9;
      padding-right: 64px;
      padding-left: 34px;

      ${Media.lessThan(Media.SIZE.SM)} {
        width: 100%;
        border-right: initial;
        padding-right: initial;
        padding-left: initial;
        padding-bottom: 30px;
        border-bottom: 2px dashed #d9d9d9;
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .ant-typography {
        text-align: center;
      }
    }

    .box-right {
      padding: 0 33px;

      .name-event-text {
        font-size: 16px;
        font-weight: 500;
        color: #e3007f;
      }

      .label-address {
        font-weight: 700;
      }

      .time-text {
        margin-top: 20px;
      }

      .checked-text {
        font-size: 15px;
        font-weight: 500;
        color: #009b51;
        margin-top: 20px;
      }

      ${Media.lessThan(Media.SIZE.SM)} {
        margin-top: 10px;

        .ant-typography {
          text-align: center;
        }
      }
    }

    .text-message {
      font-size: 14px;
      color: #adadad;
      margin-bottom: 27px;
    }

    .label {
      font-size: 20px;
      font-weight: 700;
      color: #0076e3;
      margin-top: 27px;
    }

    .serie-text {
      font-weight: 700;
    }
  }
`;
