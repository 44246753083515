import styled from 'styled-components';
import { Layout, Typography } from 'antd';
import Media from '@themes/media';

const { Content } = Layout;

export const Wrapper = styled(Content)`
  max-width: 1300px;
  margin: 0 auto;
  padding: 60px 30px;

  .ant-tabs-nav {
    margin: 0 0 40px;
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        font-weight: 700;
        font-size: 18px;
        padding: 0px;
        color: #787878;
        ${Media.lessThan(Media.SIZE.MD)} {
          font-size: 14px;
        }
      }
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: #282727;
          position: relative;
        }
        .ant-tabs-tab-btn::before {
          content: '';
          width: 50%;
          background-color: #e3007f;
          height: 3px;
          position: absolute;
          bottom: 0px;
          left: 25%;
          border-radius: 8px;
        }
      }
      .ant-tabs-ink-bar {
        display: none;
      }
    }
  }
  .ant-tabs-nav::before {
    display: none;
  }
  ${Media.lessThan(Media.SIZE.LG)} {
    padding: 50px 20px;
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    padding: 10px 20px;
  }

  .col-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }

  .ant-list-item {
    padding: 0 8px;

    ${Media.lessThan(Media.SIZE.SM)} {
      padding: 0;
    }
  }

  .col-list {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .row-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      .ant-btn {
        font-size: 16px;
        font-weight: 500;
        height: 40px;

        &:focus,
        &:active {
          color: rgba(0, 0, 0, 0.85);
          border-color: #d9d9d9;
        }
      }
    }

    .ant-list {
      width: 100%;
      .ant-row {
        display: flex;
      }

      .ant-list-empty-text {
        padding: initial;

        .col-no-data {
          padding: 50px 70px;

          ${Media.lessThan(Media.SIZE.LG)} {
            padding: 50px 20px;
          }

          ${Media.lessThan(Media.SIZE.SM)} {
            padding: 50px 10px;
          }
        }
      }
    }
  }
`;

export const WrapperCard = styled.div`
  display: flex;
  justify-content: center;
`;

export const TitleMyTicket = styled(Typography)`
  font-size: 40px;
  font-weight: 700;
`;
