/* eslint-disable react/prop-types */
import { Route, Redirect, useHistory, useLocation } from 'react-router-dom';

import AuthLayout from '@layouts/auth';
import { useAuth } from '@hooks';
import React, { useEffect } from 'react';

interface Props {
  state: { pathname: string };
}

const PublicRoute: React.FC<any> = ({ component: Component, layout: Layout = AuthLayout, ...rest }: any) => {
  const { authenticated } = useAuth();
  const history = useHistory();
  const { state }: Props = useLocation();

  useEffect(() => {
    if (authenticated && state?.pathname) {
      history.push(state?.pathname);
    }
  }, [authenticated]);

  return (
    <Route
      {...rest}
      render={(props: any) =>
        !authenticated ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to={{ pathname: '/', state: {} }} />
        )
      }
    />
  );
};

export default PublicRoute;
