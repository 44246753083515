import React, { useEffect } from 'react';
import { Row, ModalProps } from 'antd';
import { Button, FormInput, FormTextArea } from '@components';
// import { useTopics } from '@hooks';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { MODAL_TYPE } from '@constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalStyled, TextContentStyled, TitleComfirmStyled } from './styled';
import schema from './schema';

interface FormValuesPassword {
  password: string;
}
interface FormValuesComment {
  comment: string;
}
interface ModalConfirmProps extends Omit<ModalProps, 'okText' | 'onOk' | 'title' | 'cancelText'> {
  onCancel: () => void;
  onConfirm?: () => void;
  onSubmitPassword?: (password: string) => void;
  onSubmit?: (comment: string) => void;
  title?: string;
  type?: MODAL_TYPE;
  textContent?: string;
  textBtnCancel?: string;
  textBtnOk?: string | null;
  isLoading?: boolean;
  defaultValues?: string;
}

const ModalConfirm: React.FC<ModalConfirmProps> = ({
  title = '',
  onCancel,
  onConfirm,
  type = MODAL_TYPE.DEFAULT,
  textContent,
  textBtnCancel,
  textBtnOk,
  onSubmitPassword,
  isLoading,
  defaultValues,
  onSubmit,
  ...rest
}) => {
  // const { password } = useTopics();
  const { t } = useTranslation();
  const { common, topic } = translations;

  const formhandlePassword = useForm({
    defaultValues: { password: '' },
    resolver: yupResolver(schema),
  });

  const formHandleComment = useForm({ defaultValues: { comment: '' } });

  const handleConfirmForm: SubmitHandler<FormValuesPassword> = (data) => {
    if (onSubmitPassword) {
      onSubmitPassword(data.password);
    }
  };
  const [watchPassword] = formhandlePassword.watch(['password']);
  const [comment] = formHandleComment.watch(['comment']);

  useEffect(() => {
    formHandleComment.setValue('comment', defaultValues || '');
  }, [defaultValues]);

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
  };

  const onComment: SubmitHandler<FormValuesComment> = (data) => {
    if (onSubmit) {
      onSubmit(data.comment);
    }
  };
  const handleCancel = () => {
    if (onCancel) {
      formHandleComment.setValue('comment', '');
      formhandlePassword.setValue('password', '');
      onCancel();
    }
  };

  // useEffect(() => {
  //   if (password.status === STATUS_TYPE.FAIL) {
  //     formhandlePassword.setError('password', { message: `api_error:${password?.error?.message[0]}` });
  //   }
  // }, [password.status]);

  if (type === MODAL_TYPE.INPUT) {
    return (
      <ModalStyled centered onCancel={handleCancel} closeIcon={<div />} footer={false} {...rest}>
        <TitleComfirmStyled>{title}</TitleComfirmStyled>
        <FormProvider {...formhandlePassword}>
          <form onSubmit={formhandlePassword.handleSubmit(handleConfirmForm)}>
            <div className="password-input">
              <FormInput name="password" placeholder={t(topic.passwordPlaceholder)} maxLength={16} />
              <Row className="btn-group">
                <Button onClick={handleCancel} className="btn-cancel" type="cancel">
                  {textBtnCancel || t(common.cancel)}
                </Button>
                <Button disabled={!watchPassword} htmlType="submit" loading={isLoading}>
                  {textBtnOk || t(common.ok)}
                </Button>
              </Row>
            </div>
          </form>
        </FormProvider>
      </ModalStyled>
    );
  }

  if (type === MODAL_TYPE.TEXT_AREA) {
    return (
      <ModalStyled centered onCancel={handleCancel} closeIcon={<div />} footer={false} {...rest}>
        <TitleComfirmStyled>{title}</TitleComfirmStyled>
        <FormProvider {...formHandleComment}>
          <form onSubmit={formHandleComment.handleSubmit(onComment)}>
            <FormTextArea name="comment" autoSize={{ minRows: 3, maxRows: 5 }} maxLength={500} />
            <Row className="btn-group">
              <Button onClick={handleCancel} className="btn-cancel" type="cancel">
                {textBtnCancel || t(common.cancel)}
              </Button>
              <Button htmlType="submit" disabled={!comment} loading={isLoading}>
                {textBtnOk || t(common.ok)}
              </Button>
            </Row>
          </form>
        </FormProvider>
      </ModalStyled>
    );
  }

  if (type === MODAL_TYPE.CONFIRM) {
    return (
      <ModalStyled centered onCancel={onCancel} closeIcon={<div />} footer={false} {...rest}>
        <TitleComfirmStyled>{title}</TitleComfirmStyled>
        {textContent && <TextContentStyled>{textContent}</TextContentStyled>}
        <Row className="btn-group">
          <Button onClick={handleConfirm} loading={isLoading} disabled={isLoading}>
            {textBtnOk || t(common.ok)}
          </Button>
        </Row>
      </ModalStyled>
    );
  }

  return (
    <ModalStyled centered onCancel={onCancel} closeIcon={<div />} footer={false} {...rest}>
      <TitleComfirmStyled>{title}</TitleComfirmStyled>
      {textContent && <TextContentStyled>{textContent}</TextContentStyled>}
      <Row className="btn-group">
        <Button onClick={onCancel} className="btn-cancel" type="cancel" disabled={isLoading}>
          {textBtnCancel || t(common.cancel)}
        </Button>
        <Button onClick={handleConfirm} disabled={isLoading} loading={isLoading}>
          {textBtnOk || t(common.ok)}
        </Button>
      </Row>
    </ModalStyled>
  );
};

export { ModalConfirm };
