/* eslint-disable arrow-parens */
/* eslint-disable implicit-arrow-linebreak */

/**
 * The global state selectors
 */

import { Store } from '@type/Store';
import { SignatureState } from '@type/Store/signature';
import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectSignature = (store: Store) => store.signature || initialState;

const makeSelectSignature = () => createSelector(selectSignature, (state: SignatureState) => state);

export { selectSignature, makeSelectSignature };
