import React, { useEffect, useState } from 'react';
import { Col, List, Spin, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import ButtonComponent from '@components/button';
import { translations } from '@i18n/translations';
import { useHistories } from '@hooks/hisories';
import { Topic, Ticket } from '@type/Store/histories';
import CardTicket from './components/card-ticket/CardTicket';

import { Wrapper, WrapperCard } from './styled';
import CardTopic from './components/card-topic/CardTopic';
import Empty from './components/empty';

type TabName = 'topics' | 'tickets-goods';
const MyTicket: React.FC = () => {
  const { getTopics, getTickets, topicList, ticketList, isLoading } = useHistories();
  const history = useHistory();
  const { t } = useTranslation();
  const { myTicket, histories } = translations;
  const [currentTab, setCurrentTab] = useState<TabName>('topics');

  const loadMoreData = () => {
    getTickets({ page: ticketList.paginate.next });
  };

  const loadMoreDataTopic = () => {
    getTopics({ page: topicList.paginate.next });
  };

  const LoadMore = ticketList.paginate.current_page < ticketList.paginate.last_page && (
    <div className="row-btn">
      <ButtonComponent loading={isLoading} onClick={loadMoreData}>
        {t('common:see_more')}
      </ButtonComponent>
    </div>
  );

  const LoadMoreTopic = topicList.paginate.current_page < topicList.paginate.last_page && (
    <div className="row-btn">
      <ButtonComponent loading={isLoading} onClick={loadMoreDataTopic}>
        {t('common:see_more')}
      </ButtonComponent>
    </div>
  );

  useEffect(() => {
    if (currentTab === 'topics') {
      getTopics({ page: 1 });
    } else {
      getTickets({ page: 1 });
    }
  }, [currentTab]);

  const onTabClick = (key: string) => setCurrentTab(key as TabName);

  return (
    <Spin spinning={isLoading}>
      <Wrapper>
        <Tabs defaultActiveKey={currentTab || 'topics'} onChange={onTabClick} centered>
          <Tabs.TabPane tab={t('common:navbar.topics')} key="topics">
            <Col className="col-list">
              <List
                itemLayout="horizontal"
                dataSource={topicList.data}
                loadMore={LoadMoreTopic}
                locale={{
                  emptyText: (
                    <Empty
                      description={t(histories.topics_empty)}
                      textBtn={t(histories.go_topic)}
                      onClick={() => history.push('/topics')}
                    />
                  ),
                }}
                grid={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 3, xxl: 3 }}
                renderItem={(data: Topic) => (
                  <List.Item key={data.id}>
                    <WrapperCard>
                      <CardTopic data={data} />
                    </WrapperCard>
                  </List.Item>
                )}
              />
            </Col>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('common:navbar.list_event')} key="tickets-goods" style={{ minHeight: '300px' }}>
            <Col className="col-list">
              <List
                itemLayout="horizontal"
                dataSource={ticketList.data}
                loadMore={LoadMore}
                locale={{
                  emptyText: (
                    <Empty
                      description={t(myTicket.no_data)}
                      textBtn={t(myTicket.buy_ticket)}
                      onClick={() => history.push('/list-event')}
                    />
                  ),
                }}
                grid={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 3 }}
                renderItem={(item: Ticket) => (
                  <List.Item key={item.id}>
                    <WrapperCard>
                      <CardTicket
                        paymentStatus={item?.payment_status}
                        realEventId={item?.real_event_id}
                        purchaseAt={item?.purchase_at}
                        balanceCode={item?.balance_code}
                        thumbnail={item?.real_event_thumbnail}
                        title={item?.real_event_name}
                        timeAt={item?.real_event_start_at}
                        timeEnd={item?.real_event_end_at}
                        status={item?.real_event_status}
                      />
                    </WrapperCard>
                  </List.Item>
                )}
              />
            </Col>
          </Tabs.TabPane>
        </Tabs>
      </Wrapper>
    </Spin>
  );
};

export default MyTicket;
