/* eslint-disable react-hooks/exhaustive-deps */

import { useAuth, useRoot } from '@hooks';
import Loading from '@components/loading';
import { useLocation } from 'react-router-dom';
import { BroadcastChannel } from 'broadcast-channel';

import React, { useEffect } from 'react';
import { getLocalStorage, setLocalStorage, STORAGE } from '@utils';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { BROADCAST_CHANNEL } from '@constants/broadcast';
import Approutes from './Routes';

function App() {
  const { search }: any = useLocation();
  const { loadProfileAction, authenticated, profile } = useAuth();
  const { setIsWebView, setAuthBroadcastChannel, authBoardcastChannel } = useRoot();

  const [loading, setLoading] = React.useState(true);
  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const paramsToken = query.get('token');
    const userToken = getLocalStorage(STORAGE.USER_TOKEN);

    if (userToken && !paramsToken) {
      loadProfileAction();
    }
    if (paramsToken) {
      setLocalStorage(STORAGE.USER_TOKEN, paramsToken);
      loadProfileAction();
    }
    setTimeout(() => setLoading(false), 3000);
  }, []);

  useEffect(() => {
    if (authenticated && profile && profile.language !== language) {
      i18next.changeLanguage(profile.language || 'ja');
    }
  }, [profile, authenticated, language]);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const paramsWebView = query.get('webview');
    if (paramsWebView) {
      setIsWebView(true);
    } else {
      setIsWebView(false);
    }
  }, []);

  useEffect(() => {
    const broadcast = new BroadcastChannel('auth', {
      type: 'localstorage',
      webWorkerSupport: true,
    });
    setAuthBroadcastChannel(broadcast);

    return () => {
      broadcast.close();
    };
  }, []);

  useEffect(() => {
    if (authBoardcastChannel) {
      authBoardcastChannel.addEventListener('message', (event) => {
        const userToken = getLocalStorage(STORAGE.USER_TOKEN);
        if (event === BROADCAST_CHANNEL.LOGIN && userToken) {
          loadProfileAction();
        }

        if (event === BROADCAST_CHANNEL.LOGOUT) {
          window.location.reload();
        }
      });
    }
  }, [authBoardcastChannel]);

  // useEffect(() => {
  //   window.addEventListener('contextmenu', (e)=> {
  //     e.preventDefault();
  //   });

  //   window.addEventListener('keydown', (event: KeyboardEvent) => {
  //     if(event && event.keyCode) {
  //       console.log('=====> event', event.keyCode);
  //     }
  //     if (event && event.keyCode === 123 || (event.ctrlKey && event.shiftKey && event.keyCode == 73)) { // Prevent F12
  //       event.preventDefault();

  //       return false;
  //     }
  //   })
  // }, [])

  return loading ? <Loading /> : <Approutes />;
}

export default App;
