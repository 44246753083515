import React, { CSSProperties } from 'react';
import { AV_DEFAULT } from '@assets';
import styled from 'styled-components';

const Wrapper = styled.div<{ size?: number; isboder: boolean }>`
  min-width: ${({ size }) => (size ? `${size + 8}px` : '72px')};
  min-height: ${({ size }) => (size ? `${size + 8}px` : '72px')};
  width: ${({ size }) => (size ? `${size + 8}px` : '72px')};
  height: ${({ size }) => (size ? `${size + 8}px` : '72px')};
  background: white;
  border-radius: ${({ size }) => (size ? `${(size + 8) / 2}px` : '36px')};
  border: ${({ isboder }) => (isboder ? `solid 1px #e3007f` : 'none')};
  justify-content: center;
  align-items: center;
  display: flex;
  img {
    border-radius: ${({ size }) => (size ? `${size / 2}px` : '36px')};
    width: ${({ size }) => (size ? `${size}px` : '64px')};
    height: ${({ size }) => (size ? `${size}px` : '64px')};
    object-fit: cover;
  }
`;

type IProps = {
  src: string;
  width?: number;
  style?: CSSProperties;
  isBorder?: boolean;
};

const Avatar: React.FC<IProps> = ({ src, width = 68, style, isBorder = true }) => {
  const handleImgLoadingError = (e) => {
    e.target.src = AV_DEFAULT;
  };

  return (
    <Wrapper isboder={isBorder} style={style} size={width}>
      <img src={src || AV_DEFAULT} width={width} height={width} onError={handleImgLoadingError} alt="avatar" />
    </Wrapper>
  );
};

export { Avatar };
