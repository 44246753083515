import React from 'react';
import { ThemeProvider as StyledComponentsThemeProvider, createGlobalStyle, css } from 'styled-components';
import 'antd/dist/antd.min.css';

import colors from './colors';
import { fontSize, fontWeight } from './fonts';

export interface Stylesheet {
  [key: string]: React.CSSProperties;
}

export const MEDIA_WIDTHS: any = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
};

const mediaWidthTemplates = Object.keys(MEDIA_WIDTHS).reduce((accumulator: any, size: any) => {
  accumulator[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${MEDIA_WIDTHS[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return accumulator;
}, {});
const input = {
  input_small: '380px',
  input_medium: '512px',
  input_large: '810px',
};

const theme = () => ({
  ...colors,
  ...fontSize,
  ...fontWeight,
  grids: {
    sx: 8,
    sm: 16,
    md: 24,
    lg: 32,
  },
  // font size for text
  size_16: '16px',
  size_17: '17px',
  size_18: '18px',
  size_14: '14px',
  size_13: '13px',
  size_12: '12px',

  ...input,
  // media queries
  mediaWidth: mediaWidthTemplates,
});
interface IProps {
  children?: JSX.Element | JSX.Element[];
}
const ThemeProvider: React.FC<IProps> = ({ children }) => {
  return <StyledComponentsThemeProvider theme={theme()}>{children}</StyledComponentsThemeProvider>;
};

export const ThemedGlobalStyle = createGlobalStyle`

html, body {
  width: 100%;
  height: 100%;

  p {
    padding: 0;
    margin: 0;
    white-space: pre-wrap;
    font-family: 'Noto Sans JP';
  }

  span {
    padding: 0;
    margin: 0;
    font-family: 'Noto Sans JP';
  }
  
  a {
    text-decoration: none;
  }

  div {
    white-space: pre-wrap;
    font-family: 'Noto Sans JP';
  }

  h1 {
    padding: 0;
    margin: 0;
    white-space: pre-wrap;
    font-family: 'Noto Sans JP';
  }

  .walletconnect-modal__header {
    p {
      visibility: hidden;

      &::after {
      content: "モーダルQRコードのヘッダー"; 
      visibility: visible;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    }
  }

  .walletconnect-qrcode__text {
    position: relative;
    visibility: hidden;
    
    &::after {
      content: "MetaMaskアプリで下記のQRコードを読み取ってください。"; 
      visibility: visible;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      margin-bottom: 10px;
      height: 100%;
    }
  }

  .walletconnect-modal__footer {
    display: none;
  }
}

body {
  font-family: 'Noto Sans JP', 'Poppins', system-ui, -apple-system, 
  "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", 
  "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", 
  "Segoe UI Symbol", "Noto Color Emoji";
  background-color: ${({ theme }: any) => theme.white};
  min-height: 100vh;
  height: 100%;
  margin: 0;
  padding: 0;
  #root {
    width: 100%;
    height: 100%;
  }
  
  // remove unnecessary whitespace in formTimePicker
  .ant-picker-time-panel-column {
    &:after {
      content: none;
    }
  }
}

// antd custom
.ant-dropdown-menu {
    border-radius: 1rem;
    .ant-dropdown-menu-item {
      border-radius: 1rem;
      padding: 0.5rem;
      margin: .25rem .5rem;
      color: #282727;
      &:not(.ant-dropdown-menu-item-disabled) {
        &:hover {
          background-color: #f0effb;
          color: #6c5dd3;
          .logout-icon {
            fill: #6c5dd3;
          }
        }
      }
      .ant-dropdown-menu-title-content {
        display: flex;
        align-items: center;
      }
    }
    .ant-dropdown-menu-item-divider {
      width: 90%;
      margin: 0 auto;
    }
}

.ant-typography {
  font-family: 'Noto Sans JP';
}
/* .ant-btn {
  border-radius: .75rem;
  &.ant-btn-primary {
    background-color: #4d69fa;
    border-color: #4d69fa;
  }

  &.ant-btn-primary[disabled] {
    background-color: #f5f5f5;
    border-color: #d9d9d9;
  }
} */

.input-number {
  border: 1px solid #f8f9fa;
  background-color: #f8f9fa;
  border-radius: 1rem !important;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  &:focus, &:hover {
    color: #323232;
    background-color: #f8f9fa;
    border-color: #b6aee9;
    outline: 0;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%), 0 0 0 0.25rem rgb(108 93 211 / 25%);
  }

  & .prefix {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  & .ant-input-number {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    & .ant-input-number-input {
      color: ${({ theme }) => theme.text_primary};
      font-size: .8rem;
      font-weight: 600;
    }
    & .ant-input-number-handler-wrap {
      background-color: transparent;
    }
  }
}


.ant-select {
  color: #323232;
  background-color: ${({ theme }) => theme.white};
  font-size: 14px;
  font-weight: 400;
  min-height: 49px;
  border: 1px solid #787878;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  font-family: 'Noto Sans JP';

  &:focus, &:hover {
    color: #323232;
    border-color: rgba(120, 120, 120, 0.3);
    outline: 0;
    box-shadow:
      inset 0 1px 1px rgb(0 0 0 / 8%),
      0 0 0 0.1rem rgb(120 120 120 / 25%);
  }

  & .ant-select-selector {
    min-height: 48px;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;

    .ant-select-selection-item{
      line-height: 49px;
    }
  }
}

.ant-message {
  z-index: 1200;
  font-family: 'Noto Sans JP';
}
.ant-tree {
  border-radius: .75rem;
}

.ant-popover {
  .ant-popover-inner {
    border-radius: .75rem;
  }
}

.ant-input {
  width: 100%;
  color: #323232;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #787878;
  background-color: ${({ theme }) => theme.white};
  border-radius: 10px;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  &:focus,
  &:hover {
    color: #323232;
    border-color: rgba(120, 120, 120, 0.3);
    outline: 0;
    box-shadow:
      inset 0 1px 1px rgb(0 0 0 / 8%),
      0 0 0 0.1rem rgb(120 120 120 / 25%);
  }
}
`;

export default ThemeProvider;
