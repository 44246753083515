import Media from '@themes/media';
import { Input } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  height: 700px;
  border-radius: 0px 0px 10px 10px;
  flex-direction: column;
  position: relative;
  ${Media.lessThan(Media.SIZE.XL)} {
    height: auto;
    max-height: 500px;
  }
`;

export const ChatList = styled.div`
  display: flex;
  flex: 1;
  border-radius: 0px 0px 10px 10px;
  flex-direction: column-reverse;
  overflow: auto;
  padding: 8px 0;

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: none;
    border-radius: 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  ${Media.lessThan(Media.SIZE.MD)} {
  }
`;

export const SendMessageBox = styled.div`
  padding: 12px 8px;
`;

export const SendMessage = styled(Input)`
  height: 36px;
  background: #f3f3f3;
  border: none;
  &:focus,
  &:hover {
    border: none;
    box-shadow: none;
    background: #f3f3f3;
  }

  .ant-input {
    padding: 0;
    border: none;
    background: transparent;

    box-shadow: none;
    &:focus,
    &:hover {
      padding: 0;
      border: none;
      box-shadow: none;
    }

    ::placeholder {
      color: #787878;
      font-size: 14px;
      font-family: 'Noto Sans JP';
    }
  }
`;
