import styled from 'styled-components';
import { Layout } from 'antd';
import Media from '@themes/media';

const { Content } = Layout;

export const WrapperRegisterStyled = styled(Content)`
  padding: 40px 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper-form {
    background-color: #ffffff;
    width: 70%;
    padding: 46px 0;

    .col-header {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .form-logo {
        margin-bottom: 20px;
        width: 96px;
        height: 62px;
      }

      .confirm-logo {
        margin-bottom: 20px;
        width: 186px;
        height: 118px;
      }

      .ant-typography {
        font-size: 24px;
        font-weight: 700;
        color: #282727;
      }
    }

    .col-form {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 20px;

      .form {
        width: 50%;

        .ant-row {
          margin-bottom: 6px;
        }

        .form-group-controls + .form-group-controls {
          padding-top: 16px;
        }

        .confirm-registration {
          padding: 12px 0;
        }

        .ant-input {
          font-size: 14px;
          font-weight: 400;
          padding: 4px 11px;
        }

        .row-btn {
          padding: 0 10px;

          .btn-register,
          .btn-cancel {
            width: 100%;
            min-height: 48px;
          }
        }

        ${Media.lessThan(Media.SIZE.LG)} {
          width: 80%;
        }

        ${Media.lessThan(Media.SIZE.SM)} {
          width: 100%;
        }
      }

      .row-btn {
        padding: 0 10px;

        .btn-confirm {
          width: 100%;
          min-height: 48px;
          border-radius: 24px !important;
        }
      }

      .form-confirm {
        max-width: 300px;
        border: 1px solid #787878;
        border-radius: 6px;
        padding: 20px 5px;

        .row-info-confirm {
          display: flex;
          justify-content: center;

          .title {
            font-size: 24px;
            font-weight: 700;
            color: #e3007f;
            margin-bottom: 23px;
          }

          .info-confirm {
            font-size: 16px;
            font-weight: 400;
          }

          .text-resend-code {
            width: 100%;
            text-align: end;
            padding-right: 10px;
            margin-bottom: 21px;

            .ant-typography {
              cursor: pointer;
              font-size: 12px;
              color: #0096dc;
            }
          }
        }
      }

      .modal-confirm-success {
        max-width: 300px;
        border: 1px solid #787878;
        padding: 62px 30px 25px 30px;
        border-radius: 6px;

        .text-success {
          font-size: 16px;
          font-weight: 700;
          text-align: center;
          margin-bottom: 36px;
        }
      }
    }

    ${Media.lessThan(Media.SIZE.LG)} {
      width: 80%;
    }

    ${Media.lessThan(Media.SIZE.SM)} {
      padding: 20px 0px;
      width: 90%;
    }
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    padding: 20px 0px;
  }
`;

export const SelectPhoneStyled = styled.div`
  display: flex;

  .ant-select {
    width: 150px;
    height: 48px;
    font-size: 14px;
    margin-right: 10px;

    ${Media.lessThan(Media.SIZE.LG)} {
      width: 120px;
    }
  }

  .ant-select-selection-item {
    width: 150px;

    ${Media.lessThan(Media.SIZE.LG)} {
      width: 120px;
    }
  }

  .ant-select-selection-search {
    .ant-select-selection-search-input {
      height: 48px !important;
      width: 100%;
    }
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    flex-direction: column;
  }
`;

export const Title = styled.div`
  display: flex;
  white-space: pre;
  display: flex;
  flex: 1;

  p {
    margin-bottom: initial;
  }
`;

export const PolicyStyled = styled.div`
  display: flex;
  align-items: center;

  color: #282727;
  font-weight: 500;

  a {
    color: #e20080;
  }
  .ant-form-item {
    width: initial;

    .ant-checkbox {
      &:hover,
      &:active,
      &:focus {
        .ant-checkbox-inner {
          border-color: #e20080;
          background-color: transparent;

          &::after {
            border: 2px solid #e20080;
            border-top: 0;
            border-left: 0;
          }
        }
      }
    }

    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #e20080;
    }

    .ant-checkbox-checked {
      &::after {
        border: 1px solid #e20080;
      }
      .ant-checkbox-inner {
        border-color: #e20080;
        background-color: transparent;

        &::after {
          border: 2px solid #e20080;
          border-top: 0;
          border-left: 0;
        }
      }
    }
  }
`;
