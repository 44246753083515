import React, { useEffect, useRef } from 'react';
import { useAuth, useTopics } from '@hooks';
import { useHistory, useParams } from 'react-router-dom';
import { Spin } from 'antd';
import { PACKAGE_FEE_TYPE, PAYMENT_STATUS } from '@constants';
import VideoPlayer, { VideoPlayerMethods } from './components/video-player';
import { VideStream, VideoContainer, Wrapper } from './styled';
import { VideoComment } from './components';
import { TopicInfo } from '../components';

interface Params {
  packageId?: string;
  chapterId?: string;
  topicId?: string;
}

export const WatchVideoPackage: React.FC = () => {
  const videoRef = useRef<VideoPlayerMethods | null>(null);
  const { packageId, chapterId, topicId } = useParams<Params>();
  const { getChapterDetail, chapter, loading, getPackageDetail, packageDetail } = useTopics();
  const { replace } = useHistory();
  const { isUser } = useAuth();
  useEffect(() => {
    if (packageId && topicId) {
      getPackageDetail({ topicId: Number(topicId), packageId: Number(packageId) });
    }
  }, [packageId, topicId]);

  useEffect(() => {
    if (
      isUser &&
      packageDetail.id &&
      packageDetail.purchase_type === PACKAGE_FEE_TYPE.MUST_PAID &&
      packageDetail.payment_status !== PAYMENT_STATUS.SUCCEEDED &&
      packageDetail.payment_status !== PAYMENT_STATUS.PROCESSING &&
      packageId
    ) {
      replace(`/topics/${topicId}/payment/${packageDetail.id}?type=PACKAGE`);
    }
  }, [packageDetail]);

  useEffect(() => {
    if (
      (packageDetail.purchase_type !== PACKAGE_FEE_TYPE.MUST_PAID ||
        packageDetail.payment_status === PAYMENT_STATUS.SUCCEEDED) &&
      chapterId
    ) {
      getChapterDetail({ id: Number(chapterId) });
    }
  }, [chapterId, packageDetail]);

  return (
    <Spin spinning={loading}>
      <Wrapper>
        <VideoContainer>
          <VideStream>
            <VideoPlayer ref={videoRef} src={chapter?.video_url || ''} poster={chapter.thumbnail || ''} />
          </VideStream>
          <TopicInfo
            topicId={chapter.topic_id}
            title={chapter.title}
            description={chapter.description}
            src={chapter.topic_thumbnail}
            name={chapter.topic_title}
            createdAt={chapter?.public_start}
          />
        </VideoContainer>
        <VideoComment />
      </Wrapper>
    </Spin>
  );
};
