import { REQUEST } from '@stores';
import { CreditCardInfo, KobiniInfo, UserInfo } from '@type/Store/topic-payment';
import {
  GET_PAYMENT_INFO,
  LOADING,
  ERROR,
  SET_USER_INFO,
  SET_KONBINI_INFO,
  SET_CREDIT_CARD_INFO,
  BUY_CHAPTER,
  RESET_DATA,
  PAYMENT_CHAPTER,
  SET_DEEPLINK,
} from './constants';

export const setLoading = (payload: boolean) => ({
  type: REQUEST(LOADING),
  payload,
});

export const resetData = () => ({
  type: REQUEST(RESET_DATA),
});

export const setError = (payload: any) => ({
  type: REQUEST(ERROR),
  payload,
});

export const setUserInfo = (payload: { user_info: UserInfo; payment_method: number }) => ({
  type: REQUEST(SET_USER_INFO),
  payload,
});

export const setKonbiniInfo = (payload: { konbini_info: KobiniInfo; payment_method: number }) => ({
  type: REQUEST(SET_KONBINI_INFO),
  payload,
});

export const setCreditCardInfo = (payload: { credit_card_info: CreditCardInfo; payment_method: number }) => ({
  type: REQUEST(SET_CREDIT_CARD_INFO),
  payload,
});

export const getPaymentInfo = (payload: { type: string | null; topicId?: string; packageId?: string }) => {
  return {
    type: REQUEST(GET_PAYMENT_INFO),
    payload,
  };
};

export const buyChapter = (payload) => ({
  type: REQUEST(BUY_CHAPTER),
  payload,
});

export function paymentChapter(payload: { topicId: number; packageId: number }) {
  return {
    type: REQUEST(PAYMENT_CHAPTER),
    payload,
  };
}

export function setDeeplink(payload: string) {
  return {
    type: REQUEST(SET_DEEPLINK),
    payload,
  };
}
