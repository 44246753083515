import React, { useEffect, useMemo, useState } from 'react';
import { Image, Typography, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { PAYMENT_METHOD, PAYMENT_STATUS } from '@constants/my-ticket';
import { DEFAULT_EVENT_THUMBNAIL, COIN_ICON, MAP_ICON } from '@assets';
import { formatDateTimeOfWeek } from '@utils/time';

import { STATUS } from '@constants/real-event';
import { translations } from '@i18n/translations';
import { numberWithCommas } from '@utils/number';
import { KOBINI_LIST } from '@modules/tickets-goods/real-event-payment/constants';
import styled from 'styled-components';
import { Seemore } from '@components';
import { useHistories } from '@hooks/hisories';
import PaymentSuccess from './components/payment-success';
import PaymentProcess from './components/payment-process';
import PaymentRefund from './components/payment-refund';
import PaymentStatus from './components/payment-status';

import {
  WrapperStyled,
  RowWrapperStyled,
  ColLeftStyled,
  ColRightStyled,
  LabelStyled,
  SpinStyled,
  CancelMark,
  TicketInforStyle,
  UserInforStyle,
} from './styled';

const Cancelled = styled.div`
  width: 320px;
  padding: 15px 25px;
  height: 88px;
  border-radius: 5px;
  white-space: pre-wrap;
  font-weight: ${({ theme }) => theme.fw_700};
  font-size: ${({ theme }) => theme.size_20};
  background: #d80027;
  color: ${({ theme }) => theme.white};
  margin: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const { Text } = Typography;

const TicketDetail: React.FC = () => {
  const { balanceCode, realEventId }: { balanceCode: string; realEventId: string } = useParams();
  const { getTicketDetail, ticketDetail, qrList, isLoading } = useHistories();

  const [checkIsNullOfPrice, setCheckIsNullOfPrice] = useState({
    coinIsNull: false,
    yenIsNull: false,
  });
  const { t, i18n } = useTranslation();
  const { realEventPayment, myTicket, common } = translations;
  const { my_ticket_detail } = myTicket;
  useEffect(() => {
    getTicketDetail({ balanceCode, realEventId });
  }, []);

  const storeName = useMemo(() => {
    if (ticketDetail.konbini_code) {
      const findStore = KOBINI_LIST.find((store) => store.value === ticketDetail.konbini_code);

      return findStore ? findStore.label : null;
    }

    return null;
  }, [ticketDetail.konbini_code]);

  const renderPaymentMethod = (method, status) => {
    if (status === PAYMENT_STATUS.PRE_ORDER) {
      return <Text style={{ color: '#D80027' }}>{t(my_ticket_detail.payment_method.unpaid)}</Text>;
    }

    switch (method) {
      case PAYMENT_METHOD.KONBINI:
        return <Text>{t(my_ticket_detail.payment_method.conbini)}</Text>;
      case PAYMENT_METHOD.CREDIT_CARD:
        return <Text>{t(my_ticket_detail.payment_method.credit_card)}</Text>;
      case PAYMENT_METHOD.COIN:
        return <Text>{t(my_ticket_detail.payment_method.coin)}</Text>;
      default:
        return <Text />;
    }
  };

  const renderTicket = (paymentStatus, konbiniCode, receiptCode, receiptUrl) => {
    switch (paymentStatus) {
      case PAYMENT_STATUS.SUCCESS:
        return <PaymentSuccess />;
      case PAYMENT_STATUS.PRE_ORDER:
        return <PaymentSuccess />;
      case PAYMENT_STATUS.PROCESSING:
        return (
          <PaymentProcess
            konbiniCode={konbiniCode}
            receiptCode={receiptCode}
            receiptUrl={receiptUrl}
            isConbini={ticketDetail.payment_method === PAYMENT_METHOD.KONBINI}
          />
        );
      case PAYMENT_STATUS.REFUND:
        return <PaymentRefund />;

      case PAYMENT_STATUS.FAIL:
        return (
          <PaymentProcess
            konbiniCode={konbiniCode}
            receiptCode={receiptCode}
            receiptUrl={receiptUrl}
            type="fail"
            isConbini={ticketDetail.payment_method === PAYMENT_METHOD.KONBINI}
            gmo_error_code={ticketDetail.error_code}
          />
        );
      default:
        return <Spin spinning />;
    }
  };

  useEffect(() => {
    if (qrList.paginate.current_page === 1) {
      const checkPrice = qrList.data.reduce(
        (current, item) => {
          return {
            coinIsNull: item.ticket_type_price_coin_tax === null ? current.coinIsNull : false,
            yenIsNull: item.ticket_type_price_yen_tax === null ? current.yenIsNull : false,
          };
        },
        {
          coinIsNull: true,
          yenIsNull: true,
        },
      );
      setCheckIsNullOfPrice(checkPrice);
    }
  }, [qrList]);

  const renderPrice = ({ yenPrice, coinPrice, titleTotal = '', isTotal = false }) => {
    if (
      ticketDetail?.payment_method === PAYMENT_METHOD.COIN &&
      ticketDetail.payment_status !== PAYMENT_STATUS.PRE_ORDER
    ) {
      return (
        <div className="info">
          <div className="info-label">
            <LabelStyled>{t(titleTotal)}</LabelStyled>
          </div>
          <div className="info-text">
            <Typography className="total-text coin-total">
              <COIN_ICON style={{ marginRight: '5px' }} />
              {numberWithCommas(Number(coinPrice), ',')}
            </Typography>
          </div>
        </div>
      );
    }

    if (
      (ticketDetail.payment_method === PAYMENT_METHOD.CREDIT_CARD ||
        ticketDetail.payment_method === PAYMENT_METHOD.KONBINI) &&
      ticketDetail.payment_status !== PAYMENT_STATUS.PRE_ORDER
    ) {
      return (
        <div className="info">
          <div className="info-label">
            <LabelStyled>{t(titleTotal)}</LabelStyled>
          </div>
          <div className="info-text">
            <Typography className="total-text" style={{ color: isTotal ? 'rgb(227, 0, 127)' : 'rgba(0,0,0,.85)' }}>
              ￥ {numberWithCommas(Number(yenPrice || 0), ',')}
            </Typography>
          </div>
        </div>
      );
    }

    if (ticketDetail.payment_status === PAYMENT_STATUS.PRE_ORDER) {
      if (checkIsNullOfPrice.coinIsNull && !checkIsNullOfPrice.yenIsNull) {
        return (
          <div className="info">
            <div className="info-label">
              <LabelStyled>{t(titleTotal)}</LabelStyled>
            </div>
            <div className="info-text">
              <Typography className="total-text" style={{ color: isTotal ? 'rgb(227, 0, 127)' : 'rgba(0,0,0,.85)' }}>
                ￥ {numberWithCommas(Number(yenPrice || 0), ',')}
              </Typography>
            </div>
          </div>
        );
      }

      if (checkIsNullOfPrice.yenIsNull && !checkIsNullOfPrice.coinIsNull) {
        return (
          <div className="info">
            <div className="info-label">
              <LabelStyled>{t(titleTotal)}</LabelStyled>
            </div>
            <div className="info-text">
              <Typography className="total-text coin-total">
                <COIN_ICON style={{ marginRight: '5px' }} />
                {numberWithCommas(Number(coinPrice), ',')}
              </Typography>
            </div>
          </div>
        );
      }
      return null;
    }

    return (
      <div className="info">
        <div className="info-label">
          <LabelStyled>{t(titleTotal)}</LabelStyled>
        </div>
        <div className="info-text">
          <Typography className="total-text" style={{ color: isTotal ? 'rgb(227, 0, 127)' : 'rgba(0,0,0,.85)' }} />
        </div>
      </div>
    );
  };

  return (
    <WrapperStyled>
      <RowWrapperStyled>
        <ColLeftStyled lg={24} xl={11}>
          <SpinStyled spinning={isLoading}>
            <UserInforStyle>
              <div className="thumbnail">
                <Image
                  src={ticketDetail?.thumbnail ? ticketDetail?.thumbnail : DEFAULT_EVENT_THUMBNAIL}
                  alt="ticket-thumbnail"
                  preview={false}
                />
                {ticketDetail.status === STATUS.CANCELED && <CancelMark>{t(realEventPayment.Cancelled)}</CancelMark>}
              </div>
              <Typography className="title">{ticketDetail?.title}</Typography>
              <div className="info">
                <div className="info-label">
                  <LabelStyled>{t(my_ticket_detail.event_date)}</LabelStyled>
                </div>
                <div className="info-text">
                  <Typography>{formatDateTimeOfWeek(ticketDetail?.start_at, i18n.language)}</Typography>
                </div>
              </div>
              {ticketDetail?.url && (
                <div className="info">
                  <div className="info-label">
                    <LabelStyled>{t(my_ticket_detail.venue)}</LabelStyled>
                  </div>
                  <div className="info-text">
                    <Typography className="total-text">
                      <a className="link-ticket" target="_blank" href={ticketDetail?.url || ''} rel="noreferrer">
                        {t(my_ticket_detail.here)}
                      </a>
                      <MAP_ICON style={{ marginLeft: '5px' }} />
                    </Typography>
                  </div>
                </div>
              )}
              <div className="info">
                <div className="info-label">
                  <LabelStyled>{t(my_ticket_detail.number_of_sheets)}</LabelStyled>
                </div>
                <div className="info-text">
                  <Typography>{ticketDetail?.quantity}</Typography>
                </div>
              </div>
              {renderPrice({
                yenPrice: ticketDetail.total_yen_original,
                coinPrice: ticketDetail.total_coin_original,
                titleTotal: realEventPayment.subtotal,
                isTotal: false,
              })}
              {renderPrice({
                yenPrice: ticketDetail.total_yen_tax,
                coinPrice: ticketDetail.total_coin_tax,
                titleTotal: realEventPayment.fee,
                isTotal: false,
              })}
              {renderPrice({
                yenPrice: ticketDetail.total_yen,
                coinPrice: ticketDetail.total_coin,
                titleTotal: my_ticket_detail.total,
                isTotal: true,
              })}
              <div className="info">
                <div className="info-label">
                  <LabelStyled>{t(my_ticket_detail.date_of_acquisition)}</LabelStyled>
                </div>
                <div className="info-text">
                  <Typography>{moment.unix(ticketDetail?.purchase_at).format('YYYY/MM/DD')}</Typography>
                </div>
              </div>
              <div className="info">
                <div className="info-label">
                  <LabelStyled>{t(my_ticket_detail.payment_method_label)}</LabelStyled>
                </div>
                <div className="info-text">
                  <Typography>
                    {renderPaymentMethod(ticketDetail?.payment_method, ticketDetail?.payment_status)}
                  </Typography>
                </div>
              </div>

              {storeName && (
                <div className="info">
                  <div className="info-label">
                    <LabelStyled>{t(realEventPayment.storeName)}</LabelStyled>
                  </div>
                  <div className="info-text">
                    <Typography>{t(storeName as any)}</Typography>
                  </div>
                </div>
              )}
              <div className="info">
                <div className="info-label">
                  <LabelStyled>{t(my_ticket_detail.status)}</LabelStyled>
                </div>
                <div className="info-text">
                  <Typography>
                    <PaymentStatus status={ticketDetail?.payment_status} />
                  </Typography>
                </div>
              </div>
            </UserInforStyle>
            <TicketInforStyle>
              {!!(ticketDetail.first_name || ticketDetail.last_name) && (
                <div className="info">
                  <div className="info-label">
                    <LabelStyled>{t(realEventPayment.user_name)}</LabelStyled>
                  </div>
                  <div className="info-text">
                    <Typography>{`${ticketDetail?.last_name || ''} ${ticketDetail?.first_name || ''}`}</Typography>
                  </div>
                </div>
              )}
              {!!ticketDetail.phone_number && (
                <div className="info">
                  <div className="info-label">
                    <LabelStyled>{t(realEventPayment.phone)}</LabelStyled>
                  </div>
                  <div className="info-text">
                    <Typography>{ticketDetail?.phone_number}</Typography>
                  </div>
                </div>
              )}
              {!!ticketDetail.zip_code && (
                <div className="info">
                  <div className="info-label">
                    <LabelStyled>{t(realEventPayment.fieldName.zipcode)}</LabelStyled>
                  </div>
                  <div className="info-text">
                    <Typography>{ticketDetail?.zip_code}</Typography>
                  </div>
                </div>
              )}
              {!!ticketDetail.customer_address && (
                <div className="info">
                  <div className="info-label">
                    <LabelStyled>{t(realEventPayment.fieldName.address)}</LabelStyled>
                  </div>
                  <div className="info-text">
                    <Typography>{ticketDetail?.customer_address}</Typography>
                  </div>
                </div>
              )}
              {!!ticketDetail?.memo && (
                <div className="info">
                  <div className="info-label">
                    <LabelStyled>{t(realEventPayment.note)}</LabelStyled>
                  </div>
                  <div className="info-text">
                    <Seemore rows={3} content={ticketDetail?.memo || ''} />
                  </div>
                </div>
              )}
            </TicketInforStyle>
          </SpinStyled>
        </ColLeftStyled>
        {ticketDetail.status === 4 && (
          <ColRightStyled lg={24} style={{ justifyContent: 'center', display: 'flex' }} xl={13}>
            <Cancelled> {t(common.event.event_cancel)}</Cancelled>
          </ColRightStyled>
        )}

        {ticketDetail.status !== 4 && (
          <ColRightStyled lg={24} xl={13}>
            {renderTicket(
              ticketDetail?.payment_status,
              ticketDetail?.konbini_code,
              ticketDetail.receipt_code,
              ticketDetail.receipt_url,
            )}
          </ColRightStyled>
        )}
      </RowWrapperStyled>
    </WrapperStyled>
  );
};

export default TicketDetail;
