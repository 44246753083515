/* eslint-disable prefer-const */

import React, { useEffect, useState } from 'react';
import { EYE, POINT, TIME } from '@assets';
import { useTotalUserJoinTopic } from '@hooks/useUserJoinTopic';
import { usePoints } from '@hooks/useDonate';
import { LiveStatus } from '@constants';
import { useTopics } from '@hooks';
import moment from 'moment';
import { convertToCurrentTimeZone, sec2Time } from '@utils/time';
import { numberWithCommas } from '@utils/number';
import { BoxFree } from '../box-free';
import { Wrapper, Viewer, Donate, Number, Left, Timer } from './styled';

interface Props {
  roomId?: string | null;
}

interface TimerProps {
  liveStatus: LiveStatus;
  timeLive?: string | null;
}

const LiverStreamTimer: React.FC<TimerProps> = ({ timeLive, liveStatus }) => {
  const [timeCount, setTimeCount] = useState(0);

  useEffect(() => {
    if (timeLive) {
      const current = Date.now();
      const convertTimeJP = convertToCurrentTimeZone(timeLive, 'YYYY-MM-DD HH:mm:ss');
      const timePublic = moment(convertTimeJP).valueOf();
      const diff = current - timePublic;
      setTimeCount(diff / 1000);
    }
  }, [timeLive]);

  useEffect(() => {
    let timeLiveId;

    timeLiveId = setInterval(() => {
      setTimeCount((state) => state + 1);
    }, 1000);

    return () => {
      clearInterval(timeLiveId);
    };
  }, [liveStatus]);

  return (
    <Timer>
      <img width={10} src={TIME} alt="" />
      <Number>{sec2Time(timeCount)}</Number>
    </Timer>
  );
};

export const ViewLayer: React.FC<Props> = ({ roomId }) => {
  const { total } = useTotalUserJoinTopic({ roomId });
  const { liveStream } = useTopics();

  const { points } = usePoints({
    roomId,
  });

  return (
    <Wrapper>
      <Left>
        <Viewer>
          <img width={12} src={EYE} alt="" />
          <Number>{numberWithCommas(total, ',')}</Number>
        </Viewer>
        <Donate>
          <img width={12} src={POINT} alt="" />
          <Number>{numberWithCommas(points, ',')}</Number>
        </Donate>
        {liveStream.data && (
          <LiverStreamTimer timeLive={liveStream.data.public_start_real} liveStatus={liveStream.data?.status} />
        )}
      </Left>
      {liveStream.data && (
        <BoxFree
          receivedFree={liveStream.data?.receivedFree}
          liveStatus={liveStream.data.status}
          roomId={roomId || ''}
        />
      )}
    </Wrapper>
  );
};

export default ViewLayer;
