import { Layout } from 'antd';
import styled from 'styled-components';

import Media from '@themes/media';

const { Content } = Layout;

export const PaymentCoinStyled = styled(Content)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  font-family: 'Noto Sans JP';

  ${Media.lessThan(Media.SIZE.MD)} {
    padding: 30px 16px;
  }
`;

export const ContentStyled = styled.div`
  padding: 30px 36px;
  width: 100%;

  ${Media.lessThan(Media.SIZE.MD)} {
    padding: 30px 0px;
  }

  .text-balance {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

    .label {
      font-size: 18px;
      margin-right: 10px;
    }
  }

  .ant-btn {
    font-size: 16px;
    height: 44px;
    font-weight: 700;

    ${Media.lessThan(Media.SIZE.MD)} {
      font-size: 14px;
      height: 36px;
    }
  }
`;

export const TextCoin = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: #0076e3;
  display: flex;
  align-items: center;

  &.text-coin-bottom {
    font-size: 24px;
    display: flex;
    justify-content: center;
  }
`;
