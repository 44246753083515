import { REQUEST } from '@stores';
import {
  GET_TOPIC_LIST,
  LOADING,
  ERROR,
  GET_TICKET_LIST,
  GET_TICKET_DETAIL,
  GET_QR_LIST,
  GET_QR_DETAIL,
  RESET_QR,
  GET_TOPIC_DETAIL,
} from './constants';

export function setLoading(payload: boolean) {
  return {
    type: REQUEST(LOADING),
    payload,
  };
}

export function setError(payload: any) {
  return {
    type: REQUEST(ERROR),
    payload,
  };
}

export function getTopicList(payload: { page: number }) {
  return {
    type: REQUEST(GET_TOPIC_LIST),
    payload,
  };
}

export function getTopicDetail(payload: { balanceCode: string }) {
  return {
    type: REQUEST(GET_TOPIC_DETAIL),
    payload,
  };
}

export function getTicketList(payload: { page: number }) {
  return {
    type: REQUEST(GET_TICKET_LIST),
    payload,
  };
}

export function getTicketDetail(payload: { balanceCode: string; realEventId: string }) {
  return {
    type: REQUEST(GET_TICKET_DETAIL),
    payload,
  };
}

export function getQRList(payload: { balanceCode: string; realEventId: string; page: number | undefined }) {
  return {
    type: REQUEST(GET_QR_LIST),
    payload,
  };
}

export function getQRDetail(payload: { seriesCode: number }) {
  return {
    type: REQUEST(GET_QR_DETAIL),
    payload,
  };
}

export function resetQr() {
  return {
    type: REQUEST(RESET_QR),
  };
}
