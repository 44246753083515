import { REQUEST } from '@stores';
import { Payload } from '@type/Store';
import {
  GET_DONATE_PACKAGE,
  LIVESTREAM_DETAIL,
  SEND_DONATE,
  TOPIC_CHAPTER_DETAIL,
  TOPIC_CHECK_PASSWORD,
  TOPIC_CREATE_COMMENT,
  TOPIC_DELETE_COMMENT,
  TOPIC_DETAIL,
  TOPIC_EDIT_COMMENT,
  TOPIC_LIST,
  TOPIC_PACKAGE_DETAIL,
  TOPIC_TOP,
  TOPIC_VIDEO_COMMENT,
  TOPIC_VIDEO_PACKAGE,
  GET_AGORA_TOKEN,
  LOADING,
  ERROR,
} from './constants';

export const setLoading = (payload: boolean) => ({
  type: REQUEST(LOADING),
  payload,
});

export const setError = (payload: boolean) => ({
  type: REQUEST(ERROR),
  payload,
});

export function getTopicList(payload: { page: number; perPage: number }) {
  return {
    type: REQUEST(TOPIC_LIST),
    payload,
  };
}

export function getTopic(payload: { topicId: number }) {
  return {
    type: REQUEST(TOPIC_DETAIL),
    payload,
  };
}

export function getTopicTop() {
  return {
    type: REQUEST(TOPIC_TOP),
  };
}

export function checkPassword(payload: Payload) {
  return {
    type: REQUEST(TOPIC_CHECK_PASSWORD),
    payload,
  };
}

export function getLiveStreamDetail(payload: { roomId: string; user_id: string | number }) {
  return {
    type: REQUEST(LIVESTREAM_DETAIL),
    payload,
  };
}

export function getDonatePackage(payload: string | number) {
  return {
    type: REQUEST(GET_DONATE_PACKAGE),
    payload,
  };
}

export function sendDonate(payload) {
  return {
    type: REQUEST(SEND_DONATE),
    payload,
  };
}

export function getVideoComment(payload: { id: number }) {
  return {
    type: REQUEST(TOPIC_VIDEO_COMMENT),
    payload,
  };
}

export function createComment(payload: Payload) {
  return {
    type: REQUEST(TOPIC_CREATE_COMMENT),
    payload,
  };
}

export function getChapterDetail(payload: Payload) {
  return {
    type: REQUEST(TOPIC_CHAPTER_DETAIL),
    payload,
  };
}

export function editComment(payload: Payload) {
  return {
    type: REQUEST(TOPIC_EDIT_COMMENT),
    payload,
  };
}

export function deleteComment(payload: Payload) {
  return {
    type: REQUEST(TOPIC_DELETE_COMMENT),
    payload,
  };
}

export function getVideoPackage(payload: { id: number }) {
  return {
    type: REQUEST(TOPIC_VIDEO_PACKAGE),
    payload,
  };
}

export function getPackageDetail(payload: { topicId: number; packageId: number }) {
  return {
    type: REQUEST(TOPIC_PACKAGE_DETAIL),
    payload,
  };
}

export function getAgoraToken(payload: { user_id: number | string; channel_name: string }) {
  return {
    type: REQUEST(GET_AGORA_TOKEN),
    payload,
  };
}
