import { useAuth, useTopics } from '@hooks';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Image from '@components/imageFallback';
import { Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';
import Paragraph from 'antd/lib/typography/Paragraph';
import { DIAMOND } from '@assets';
import { Button } from '@components';
import { numberWithCommas } from '@utils/number';
import { MODAL_TYPE, PACKAGE_FEE_TYPE, PAYMENT_STATUS } from '@constants';
import {
  ImageBannerTop,
  VideoPackageDetailStyle,
  Chapters,
  ButtonBuy,
  Diamond,
  ButtonContainer,
  NoData,
} from './styled';
import { ChapterPackageComponent, ModalConfirm } from '../components';

interface Params {
  topicId?: string;
  packageId?: string;
}

const PackageDetail: React.FC = () => {
  const { authenticated, isUser, isLiver } = useAuth();
  const { topicId, packageId } = useParams<Params>();
  const { getPackageDetail, packageDetail, loading } = useTopics();
  const { push } = useHistory();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);

  const { common, topic, topic_payment } = translations;

  const [expandable, setExpandable] = useState(true);

  useEffect(() => {
    getPackageDetail({ topicId: Number(topicId), packageId: Number(packageId) });
  }, [topicId, packageId]);

  const onNavigatePurchase = () => {
    push(`/topics/${topicId}/payment/${packageId}?type=PACKAGE`, { state: null });
  };

  const onClickItem = (videoId?: number) => {
    if (
      (isUser && packageDetail?.payment_status === PAYMENT_STATUS.SUCCEEDED) ||
      packageDetail?.purchase_type === PACKAGE_FEE_TYPE.FREE
    ) {
      push(`/topics/watch-video-package/${topicId}/${packageId}/${videoId}`);
    } else if (isUser && packageDetail?.payment_status === PAYMENT_STATUS.PROCESSING) {
      setShowModal(true);
    } else if (isLiver) {
      push(`/topics/watch-video-package/${topicId}/${packageId}/${videoId}`);
    } else onNavigatePurchase();
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <VideoPackageDetailStyle>
      <Spin spinning={loading}>
        <ImageBannerTop>
          {packageDetail && <Image src={packageDetail?.thumbnail || ''} alt="Image topic" />}
        </ImageBannerTop>
        <div className="content-top">
          <div className="title"> {packageDetail?.title}</div>
          {expandable && (
            <Paragraph
              className="content"
              ellipsis={{
                rows: 3,
                expandable: true,
                symbol: t(common.see_more),
                onExpand: () => setExpandable(false),
              }}
            >
              {packageDetail?.description}
            </Paragraph>
          )}
          {!expandable && (
            <Paragraph className="content" ellipsis={false}>
              {packageDetail?.description}
              <span onClick={() => setExpandable(true)} className="ant-typography-expand">
                {t(common.see_less)}
              </span>
            </Paragraph>
          )}
          {(isUser || !authenticated) &&
            packageDetail?.payment_status !== PAYMENT_STATUS.PROCESSING &&
            packageDetail?.payment_status !== PAYMENT_STATUS.SUCCEEDED &&
            Number(packageDetail?.purchase_type) === PACKAGE_FEE_TYPE.MUST_PAID && (
              <ButtonContainer>
                <ButtonBuy>
                  <Diamond>
                    <img src={DIAMOND} alt="diamond" width={22} />
                    <span>
                      &ensp;
                      {numberWithCommas(Number(packageDetail?.diamond_price), ',')}
                    </span>
                  </Diamond>
                  <Button type="outline" onClick={onNavigatePurchase}>
                    {' '}
                    {t(common.button.purchase)}{' '}
                  </Button>
                </ButtonBuy>
              </ButtonContainer>
            )}
        </div>
        <Chapters>
          <div className="title">{t(topic.package_video_list)}</div>
          <Spin spinning={loading}>
            <Row gutter={[16, 16]}>
              {packageDetail.video_package?.map((chapter) => (
                <ChapterPackageComponent
                  key={chapter.id}
                  chapter={chapter}
                  onVideoClick={() => onClickItem(chapter.id)}
                />
              ))}
            </Row>
          </Spin>
          {!packageDetail.video_package.length && <NoData>{t(common.no_data)}</NoData>}
        </Chapters>
      </Spin>
      <ModalConfirm
        title=""
        textContent={t(topic_payment.waiting_for_payment)}
        onCancel={handleCloseModal}
        onConfirm={handleCloseModal}
        open={showModal}
        type={MODAL_TYPE.CONFIRM}
      />
    </VideoPackageDetailStyle>
  );
};

export default PackageDetail;
