import React from 'react';
import { Row, Col, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';

import ListComponent from '@components/list';
import { useTopics } from '@hooks';
import EventNoData from '@modules/tickets-goods/list/components/event-no-data';
import { WrapperStyled } from './styled';
import { Topic } from '../topic';

const ListTopic: React.FC = () => {
  const { topicList, getTopicList, loading } = useTopics();
  const { t } = useTranslation();
  const { listEvent } = translations;
  const { paginate, data } = topicList;
  const perPage = 12;

  const onChange = (value: number) => {
    getTopicList({ perPage, page: value });
  };

  return (
    <WrapperStyled>
      <Row>
        <Col span={24}>
          <Spin spinning={loading}>
            <ListComponent
              listProps={{
                dataSource: data,
                grid: { gutter: 16, xs: 2, sm: 2, md: 2, lg: 2, xl: 3, xxl: 3 },
                renderItem: (item) => <Topic data={item} />,
                locale: {
                  emptyText: <EventNoData text={t(listEvent.message_list_event_no_data)} />,
                },
              }}
              paginationProps={{
                total: paginate?.total,
                current: paginate?.current_page,
                pageSize: perPage,
              }}
              pages={paginate?.last_page}
              onChange={onChange}
              pagination
            />
          </Spin>
        </Col>
      </Row>
    </WrapperStyled>
  );
};

export { ListTopic };
