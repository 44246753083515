// import Media from '@themes/media';
import Media from '@themes/media';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 18px;
  box-sizing: border-box;
`;

export const InfomationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: 56px;

  ${Media.lessThan(Media.SIZE.MD)} {
    font-size: 14px;
    margin-left: 0;
  }
`;

export const TopicName = styled.p`
  font-size: 18px;
  font-weight: 500;
  margin-left: 6px;
  color: #282727;
  ${Media.lessThan(Media.SIZE.MD)} {
    font-size: 14px;
  }
`;

export const Social = styled.div`
  display: flex;
  flex-direction: row;
  width: 180px;
  margin-left: 56px;
  gap: 16px;
  align-items: center;
  margin-top: 4px;

  ${Media.lessThan(Media.SIZE.MD)} {
    font-size: 14px;
    margin-left: 0;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 56px;
  margin-top: 8px;
  ${Media.lessThan(Media.SIZE.MD)} {
    font-size: 14px;
    margin-left: 0;
  }

  .description {
    font-size: 20px;
    font-weight: 400;

    color: #282727;
    ${Media.lessThan(Media.SIZE.MD)} {
      font-size: 14px;
    }
  }
`;

export const RoomName = styled.p`
  font-size: 22px;
  font-weight: 700;
  color: #282727;
`;

export const TitleName = styled.p`
  font-size: 22px;
  font-weight: 700;
  color: #282727;
  ${Media.lessThan(Media.SIZE.MD)} {
    font-size: 14px;
  }
`;

export const CreateAt = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
`;

export const TitleTopic = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 700;
  color: #282727;
  ${Media.lessThan(Media.SIZE.LG)} {
    display: block;
  }
`;
