import {
  createItem,
  database,
  off,
  onChildAdded,
  onValue,
  orderByChild,
  query,
  ref,
  startAt,
  updateItem,
} from '@apis/firebase';
import { useState, useEffect } from 'react';

interface IParams {
  roomId?: string | null;
}

export interface Gif {
  createdAt: number;
  diamond: number | null;
  duration: number;
  freeDiamond: number | null;
  freeRuby: number | null;
  gifFullScreen?: number | null;
  gifTransparent?: number | null;
  images: number | string | null;
  ruby: number | null;
  stampGif?: string | null;
  stampId?: number | null;
  id: string;
}

export const useGifs = ({ roomId }: IParams) => {
  const [gifs, setGifs] = useState<Gif[]>([]);
  const [error, setError] = useState<any>(null);
  const removeGif = (id: string) => setGifs((state) => state.filter((f) => f.id !== id));

  useEffect(() => {
    let childAddedHandler;
    const current = Date.now();
    const queryDonate = query(ref(database, `/stamps/${roomId}`), orderByChild('createdAt'), startAt(current));

    if (roomId) {
      childAddedHandler = onChildAdded(
        queryDonate,
        (snapshot) => {
          if (snapshot.exists()) {
            setGifs((state) => [
              ...state,
              {
                ...snapshot.val(),
                id: snapshot.key,
              },
            ]);
          }
        },
        (err) => setError(() => err), // on error
        {
          onlyOnce: false,
        },
      );
    }
    return () => {
      off(queryDonate, 'child_added', childAddedHandler);
    };
  }, [roomId]);

  return {
    gifs,
    error,
    removeGif,
  };
};
export const usePoints = ({ roomId }) => {
  const [points, setPoints] = useState(0);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    let childAddedHandler;
    const current = Date.now();

    const queryAddPoint = query(ref(database, `/stamps/${roomId}`), orderByChild('createdAt'), startAt(current));

    if (roomId) {
      const queryPoints = query(ref(database, `/stamps/${roomId}`), orderByChild('createdAt'));
      onValue(
        queryPoints,
        (snapshots) => {
          let totalDiamond = 0;
          let totalRuby = 0;

          if (snapshots.exists()) {
            snapshots.forEach((item) => {
              if (item.exists()) {
                const dataItem = item.val();
                totalDiamond += dataItem.diamond;
                totalRuby += dataItem.ruby;
              }
            });

            const totalPoints = totalDiamond + totalRuby;

            setPoints(Math.round(totalPoints));
          }
        },
        (err) => {
          setError(err);
        },
        { onlyOnce: true },
      );

      onChildAdded(
        queryAddPoint,
        (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();

            setPoints((state) => {
              return state + Math.round(data.diamond + data.ruby);
            });
          }
        },
        (err) => setError(() => err), // on error
        {
          onlyOnce: false,
        },
      );
    }

    return () => {
      off(queryAddPoint, 'child_added', childAddedHandler);
    };
  }, [roomId]);

  return { points, error };
};

export const sendDonateWithFirebase = (roomId, data) => createItem(`stamps/${roomId}`, data);
export const updateDonate = ({ roomId, data, userId }) => updateItem(`topic/${roomId}/donate/${userId}`, data);
