import { BroadcastChannel } from 'broadcast-channel';
import { Payload } from '@type/Store';
import { TOGGLE_SIDEBAR, HOVER_SIDEBAR, IS404, IS_WEB_VIEW, AUTH_BROADCAST } from './constants';

export function toggleSidebar(payload: Payload) {
  return {
    type: TOGGLE_SIDEBAR,
    payload,
  };
}

export function hoverSidebar(payload: Payload) {
  return {
    type: HOVER_SIDEBAR,
    payload,
  };
}

export function set404Status(payload: boolean) {
  return {
    type: IS404,
    payload,
  };
}

export function setIsWebView(payload: boolean) {
  return {
    type: IS_WEB_VIEW,
    payload,
  };
}

export function setAuthBroadcastChannel(payload: BroadcastChannel | null) {
  return {
    type: AUTH_BROADCAST,
    payload,
  };
}
