import React from 'react';

import { EventDetail } from '@type/Store/event';
import { timeStampToDate } from '@utils/time';
import { FORMAT_TIME } from '@constants';
import { translations } from '@i18n/translations';
import { useTranslation } from 'react-i18next';
import { Description } from '../../styles';

type EventProps = {
  event: EventDetail;
};

const EventDescription: React.FC<EventProps> = ({ event }) => {
  const { t } = useTranslation();
  const { common } = translations;

  return (
    <Description>
      <h4>{t(common.event.event_details)}</h4>
      <h5 className="title">{event?.title}</h5>
      <p>
        <time className="event-time">
          {timeStampToDate(event?.start_at, FORMAT_TIME.DATE_HOUR_MINUTES)} ~{' '}
          {timeStampToDate(event?.end_at, FORMAT_TIME.DATE_HOUR_MINUTES)}
        </time>
      </p>
      {event?.address && (
        <div className="address-group">
          <label className="title-label">{t(common.event.address)}: </label>
          <span className="address">{event?.address}</span>
        </div>
      )}
      {event?.url && (
        <a className="event-url" href={event?.url} target="_blank" rel="noreferrer">
          <p>{event?.url}</p>
        </a>
      )}
      <div className="time_sale">
        {!!event?.open_at && (
          <p>
            <label className="title-label">{t(common.event.opening_time)}: </label>
            <time>
              {timeStampToDate(event?.open_at, FORMAT_TIME.DATE_HOUR_MINUTES)}
              {!!event?.close_at && <span>~ {timeStampToDate(event?.close_at, FORMAT_TIME.DATE_HOUR_MINUTES)}</span>}
            </time>
          </p>
        )}
        {!!event?.start_sale_at && (
          <p>
            <label className="title-label">{t(common.event.sales_period)}: </label>
            <time>
              {timeStampToDate(event?.start_sale_at, FORMAT_TIME.DATE_HOUR_MINUTES)}
              {!!event?.end_sale_at && (
                <span>~ {timeStampToDate(event?.end_sale_at, FORMAT_TIME.DATE_HOUR_MINUTES)}</span>
              )}
            </time>
          </p>
        )}
      </div>
      <p>{event?.content}</p>
    </Description>
  );
};
export default EventDescription;
