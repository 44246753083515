import React from 'react';
import { List, Pagination, ListProps, PaginationProps } from 'antd';

import { ICON_PAGINATION_FIRST, ICON_PAGINATION_LAST, ICON_PAGINATION_PREV, ICON_PAGINATION_NEXT } from '@assets';
import { WrapperList, ButtonPaginationStyled } from './styled';

interface Props {
  isLoading?: boolean;
  pages: number;
  pagination?: boolean;
  onChange?: (value: number) => void;
  listProps?: ListProps<any>;
  paginationProps?: PaginationProps;
}

const ListComponent: React.FC<Props> = ({ pagination, isLoading, onChange, paginationProps, listProps, pages }) => {
  const itemRender = (itemRender, type, originalElement) => {
    if (!itemRender) {
      if (type === 'prev') {
        return (
          <ButtonPaginationStyled>
            <img src={ICON_PAGINATION_PREV} alt="icon-prev" width={16} />
          </ButtonPaginationStyled>
        );
      }

      if (type === 'next') {
        return (
          <ButtonPaginationStyled>
            <img src={ICON_PAGINATION_NEXT} alt="icon-next" width={16} />
          </ButtonPaginationStyled>
        );
      }
    }

    return originalElement;
  };

  return (
    <WrapperList>
      <List {...listProps} loading={isLoading} />
      {pagination &&
        !!paginationProps?.total &&
        !!paginationProps.pageSize &&
        paginationProps.total > paginationProps.pageSize && (
          <div className="pagination">
            {pages > 1 && (
              <ButtonPaginationStyled
                className="btn-left"
                onClick={() => {
                  if (onChange) {
                    onChange(1);
                  }
                }}
              >
                <img src={ICON_PAGINATION_FIRST} alt="icon-first" width={16} />
              </ButtonPaginationStyled>
            )}

            <Pagination
              {...paginationProps}
              itemRender={(_, type, originalElement) => {
                return itemRender(paginationProps?.itemRender, type, originalElement);
              }}
              onChange={(value) => {
                if (onChange) {
                  onChange(value);
                }
              }}
              showSizeChanger={false}
            />

            {pages > 1 && (
              <ButtonPaginationStyled
                className="btn-right"
                onClick={() => {
                  if (onChange) {
                    onChange(pages);
                  }
                }}
              >
                <img src={ICON_PAGINATION_LAST} alt="icon-last" width={16} />
              </ButtonPaginationStyled>
            )}
          </div>
        )}
    </WrapperList>
  );
};

export default ListComponent;
