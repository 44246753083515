import React, { useRef, forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Slider } from 'antd';
import { sec2Time } from '@utils/time';
import { FULL_SCREEN, PAUSE_VIDEO, PLAY_VIDEO } from '@assets';
import { Controls, FullScreenICon, PlayVideoIcon, Video, VideoStyled, VideoTime } from './styled';

interface VideoPlayerProps {
  src: string;
  onTimeUpdate?: (time: number) => void;
  poster: string;
}

export interface VideoPlayerMethods {
  playVideo: () => void;
  pauseVideo: () => void;
}

const VideoPlayer: React.ForwardRefRenderFunction<VideoPlayerMethods, VideoPlayerProps> = (
  { src, onTimeUpdate, poster },
  ref,
) => {
  const videoRef = useRef<any>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlay, setIsPlay] = useState(false);
  const [displayIcon, setDisplayIcon] = useState<'flex' | 'none'>('flex');

  useEffect(() => {
    const video = videoRef.current;
    const handleLoadedMetadata = () => {
      setDuration(video?.duration || 0);
    };
    if (video) {
      video.addEventListener('loadedmetadata', handleLoadedMetadata);
    }
    return () => {
      if (video) {
        video.removeEventListener('loadedmetadata', handleLoadedMetadata);
      }
    };
  }, []);

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      if (onTimeUpdate) {
        onTimeUpdate(videoRef.current.currentTime);
      }
      setCurrentTime(videoRef.current?.currentTime || 0);
    }
  };

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current?.play();
      setIsPlay(true);
      setDisplayIcon('none');
    }
  };
  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current?.pause();
      setIsPlay(false);
      setDisplayIcon('flex');
    }
  };

  const handleSliderChange = (value: number | [number, number]) => {
    if (videoRef.current) {
      const newTime = (Number(value) / 100) * duration;
      videoRef.current.currentTime = newTime;
    }
  };

  const onFullScreen = () => {
    if (videoRef.current) {
      const video = videoRef.current;
      if (video?.webkitEnterFullscreen) {
        video?.webkitEnterFullscreen();
      } else if (video.requestFullscreen) {
        video.requestFullscreen();
      }
    }
  };

  useImperativeHandle(ref, () => ({
    playVideo,
    pauseVideo,
  }));

  const handlePlayVideo = (e: React.ChangeEvent<HTMLVideoElement>) => {
    if (e?.type === 'pause') {
      pauseVideo();
    } else {
      playVideo();
    }
  };

  return (
    <VideoStyled display={displayIcon} className="video-container">
      <Video
        ref={videoRef}
        onTimeUpdate={handleTimeUpdate}
        onPlay={handlePlayVideo}
        onPause={handlePlayVideo}
        playsInline
        poster={poster}
        src={src}
        disablePictureInPicture
        controlsList="nodownload noremoteplayback noplaybackrate foobar"
      />
      <Controls className="controls">
        <VideoTime> {sec2Time(currentTime)} </VideoTime>
        <Slider
          className="slider"
          onChange={handleSliderChange}
          step={0.01}
          value={(currentTime / duration) * 100}
          tooltip={{ open: false }}
        />
        <VideoTime> {sec2Time(duration)}</VideoTime>
        <FullScreenICon src={FULL_SCREEN} alt="zoom_in" onClick={onFullScreen} />
      </Controls>
      <PlayVideoIcon
        src={isPlay ? PAUSE_VIDEO : PLAY_VIDEO}
        alt="btn_action"
        onClick={isPlay ? pauseVideo : playVideo}
        className="btn-action"
        width={60}
      />
    </VideoStyled>
  );
};

export default forwardRef<VideoPlayerMethods, VideoPlayerProps>(VideoPlayer);
