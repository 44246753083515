import styled from 'styled-components';
import Media from '@themes/media';

export const Wrapper = styled.div<{ actived: boolean }>`
  border: ${({ actived }) => (!actived ? '2px solid #00CEBC' : '2px solid #E3007F')};
  border-radius: 6px;
  width: 48%;
  padding-top: 16px;
  margin-top: 10px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;

  .status-package {
    text-align: right;
    visibility: ${({ actived }) => (!actived ? 'hidden' : 'inherit')};
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .amount-diamond {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #0076e3;
    margin-top: 16px;
  }

  .purchase-diamond {
    background: ${({ actived }) => (!actived ? '#00CEBC' : '#E3007F')};
    padding: 8px;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    width: 100%;
  }

  ${Media.lessThan(Media.SIZE.XXS + 1)} {
    .price-diamond {
      /* height: 3.4vh; */
      font-size: 10px;
      line-height: 10px;
    }
  }
`;
