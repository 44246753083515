import Media from '@themes/media';
import styled from 'styled-components';

export const ListEvent = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;

  .swiper {
    width: 100%;
    height: auto;
    margin: 0;
    background-color: ${({ theme }) => theme.background_color};
    padding-bottom: 20px;

    ${Media.lessThan(Media.SIZE.SM)} {
      padding-bottom: 30px;
      .image-container {
        height: 84px;
      }
    }
  }
  .custom-slider-prev,
  .custom-slider-next {
    position: 'absolute' !important;
    top: 51%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    margin-top: calc(0px - 2.5rem);
    z-index: 10;
    border-radius: 100px;
    background: ${({ theme }) => theme.white};
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 30%);
    svg {
      width: 18px;
      height: 18px;
    }

    ${Media.lessThan(Media.SIZE.LG)} {
      display: none;
    }
  }
  .custom-slider-prev {
    position: absolute;
    left: 0;
  }
  .custom-slider-next {
    position: absolute;
    right: 0;
  }
`;
