import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSwiper, useSwiperSlide } from 'swiper/react';

type SliderWrapperProps = {
  ticketId: number;
};

const SliderWrapper: React.FC<SliderWrapperProps> = ({ ticketId, children }) => {
  const swiper = useSwiper();
  const swiperSlide = useSwiperSlide();
  const history = useHistory();

  return (
    <div
      onClick={() => {
        if (swiperSlide.isNext) {
          swiper.slideNext();
        }

        if (swiperSlide.isPrev) {
          swiper.slidePrev();
        }

        if (swiperSlide.isActive) {
          history.replace(`./events/${ticketId}`);
        }
      }}
      style={{ position: 'relative' }}
      className="div"
    >
      {children}
    </div>
  );
};
export default SliderWrapper;
