import React from 'react';
import { Col, Typography } from 'antd';

import Button from '@components/button';
import styled from 'styled-components';
import Media from '@themes/media';

export const Text = styled(Typography)`
  text-align: center;
  margin-bottom: 20px;
  font-size: 30px;
  white-space: pre-wrap;

  ${Media.lessThan(Media.SIZE.LG)} {
    font-size: 25px;
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    font-size: 16px;
  }
`;

export const Box = styled.div`
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  border-radius: 20px;

  ${Media.lessThan(Media.SIZE.LG)} {
    padding: 20px;
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    padding: 10px;
  }
`;

interface IProps {
  description: string;
  textBtn: string;
  onClick: () => void;
}

const Empty: React.FC<IProps> = ({ description, textBtn, onClick }) => {
  return (
    <Col span={24} className="col-no-data">
      <Box>
        <Text>{description}</Text>
        <Button onClick={onClick}>{textBtn}</Button>
      </Box>
    </Col>
  );
};

export default Empty;
