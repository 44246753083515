import styled from 'styled-components';
import { Typography } from 'antd';

import Colors from '@themes/colors';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color: #ffffff;
  cursor: pointer;
  box-shadow: rgba(255, 255, 255, 0.1);
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .tag {
    position: absolute;
    top: 13px;
    left: 9px;
    border-radius: 12px;
    z-index: 10;
    border: none;
    font-size: ${({ theme }) => theme.size_10};
    font-weight: ${({ theme }) => theme.fw_700};
    color: white;
  }

  .tag-end {
    background-color: #787878;
  }

  .tag-chapter {
    background-color: #e3007f;
  }

  .tag-live {
    background-color: ${({ theme }) => theme.bg_red};
  }

  .yen {
    color: #e3007f;
    display: flex;
    align-items: center;
    font-weight: ${({ theme }) => theme.fw_700};
  }

  .tag-end {
    border: none;
    position: absolute;
    top: 13px;
    left: 9px;
    background-color: #787878;
    border-radius: 12px;
    font-size: ${({ theme }) => theme.size_10};
    font-weight: ${({ theme }) => theme.fw_700};
    color: white;
    z-index: 10;
  }

  .price {
    display: flex;
    align-items: center;
  }

  .ant-image {
    margin-bottom: 16px;
    position: relative;
    aspect-ratio: 16 / 9;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;

    .ant-image-img {
      border-radius: 15px 15px 0px 0px;
      width: 100%;
      position: absolute;
      top: 0px;
      object-fit: cover;
      height: 100%;
    }
  }
`;

export const Title = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const Descriptions = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #787878;
`;

export const TimeCardStyled = styled(Typography)`
  color: ${Colors.text_date_time};

  &.text-time-end {
    text-align: right;
  }
`;
