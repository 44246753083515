import React from 'react';
import { Typography } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';

import { Button } from '@components';

import { numberWithCommas } from '@utils/number';
import { COIN_ICON, WARNING_ICON } from '@assets';
import { PaymnetCoinNotEnoughStyled } from './styled';
import { TextCoin } from '../../styled';

interface Props {
  totalCoin?: number;
}

interface Params {
  eventId: string;
}

const PaymentCoinNotEnough: React.FC<Props> = ({ totalCoin }) => {
  const history = useHistory();
  const params: Params = useParams();
  const { t } = useTranslation();
  const { realEventPayment } = translations;

  return (
    <PaymnetCoinNotEnoughStyled>
      <div className="box-warning">
        <WARNING_ICON />
        <div className="box-message">
          <TextCoin className="text-coin-bottom">
            <COIN_ICON style={{ width: '35px', height: '35px', marginRight: '5px' }} />
            {numberWithCommas(totalCoin, ',')}
          </TextCoin>
        </div>
      </div>

      <Typography className="text-message">{t(realEventPayment.coin_held_are_insufficient)}</Typography>

      <div className="box-btn">
        <Button
          onClick={() => {
            history.push(`/events/${params?.eventId}`);
          }}
        >
          {t(realEventPayment.other_payment_method)}
        </Button>
      </div>
    </PaymnetCoinNotEnoughStyled>
  );
};

export default PaymentCoinNotEnough;
