import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useAuth, useTopics } from '@hooks';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';
import { CHAPTER_FEE_TYPE, MODAL_TYPE } from '@constants';
import { Spin } from 'antd';
import VideoPlayer, { VideoPlayerMethods } from './components/video-player';
import { VideStream, VideoContainer, Wrapper } from './styled';
import { VideoComment } from './components';
import { ModalConfirm, TopicInfo } from '../components';

interface Params {
  id?: string;
}

const TIME_EXPIRED = 180;

export const WatchVideoChapter: React.FC = () => {
  const videoRef = useRef<VideoPlayerMethods | null>(null);
  const { id } = useParams<Params>();
  const { getChapterDetail, chapter, password, loading } = useTopics();
  const [showModal, setShowModal] = useState<'buyChapter' | 'tryFree' | false>(false);
  const [isPlayVideo, setPlayVideo] = useState<boolean>(false);
  const { t } = useTranslation();
  const { topic } = translations;
  const { push, goBack, replace } = useHistory();
  const { isUser } = useAuth();
  useEffect(() => {
    getChapterDetail({ id: Number(id) });
  }, [id]);

  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pauseVideo();
    }
  };

  useEffect(() => {
    if (chapter.password_flag && !password.chapter_id) {
      replace(`/topics/${chapter.topic_id}?page=episodes`);
    }

    if (
      isUser &&
      !chapter.password_flag &&
      chapter.chapter_fee_type === CHAPTER_FEE_TYPE.MUST_PAID &&
      !chapter.payment_status &&
      Number(chapter.amount)
    ) {
      replace(`/topics/${chapter.topic_id}/payment/${chapter.id}?type=TOPIC`);
    }
  }, [chapter, password]);

  const handleTimeUpdate = (time: number) => {
    if (
      isUser &&
      !isPlayVideo &&
      time >= TIME_EXPIRED &&
      Number(chapter?.chapter_fee_type) === CHAPTER_FEE_TYPE.FREE_A_PATH &&
      Number(chapter?.amount) > 0 &&
      !chapter?.payment_status &&
      !chapter.password_flag
    ) {
      pauseVideo();
      setShowModal('tryFree');
      setPlayVideo(false);
    }
  };

  const handleCancelModal = () => {
    setShowModal(false);
    goBack();
  };

  const handleConfirmPriceModal = () => push(`/topics/${chapter?.topic_id}/payment/${chapter.id}?type=TOPIC`);

  const textContentModal = useMemo(() => {
    if (showModal === 'buyChapter') {
      return t(topic?.are_you_ok, { price: chapter.amount });
    }
    return t(topic?.continue_watch_video, { price: chapter.amount });
  }, [showModal]);

  const onConfirm = () => {
    if (showModal === 'buyChapter') {
      handleConfirmPriceModal();
    }

    if (showModal === 'tryFree') {
      handleConfirmPriceModal();
    }
  };

  const btnConfirm = useMemo(() => {
    if (showModal === 'buyChapter') {
      return t(topic.payment);
    }
    if (showModal === 'tryFree') {
      return t(topic.payment);
    }
    return undefined;
  }, [showModal]);

  return (
    <Spin spinning={loading}>
      <Wrapper>
        <VideoContainer>
          <VideStream>
            <VideoPlayer
              ref={videoRef}
              src={chapter?.video_url || ''}
              onTimeUpdate={handleTimeUpdate}
              poster={chapter.thumbnail || ''}
            />
          </VideStream>
          <TopicInfo
            topicId={chapter.topic_id}
            title={chapter.title}
            description={chapter.description}
            src={chapter.topic_thumbnail}
            name={chapter.topic_title}
            createdAt={chapter?.public_start}
          />
        </VideoContainer>
        <VideoComment />
        <ModalConfirm
          onCancel={handleCancelModal}
          onConfirm={onConfirm}
          textContent={textContentModal}
          type={MODAL_TYPE.DEFAULT}
          isLoading={false}
          open={!!showModal}
          textBtnOk={btnConfirm}
        />
      </Wrapper>
    </Spin>
  );
};
