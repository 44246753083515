import React from 'react';

import { NoDataStyled } from './styled';

interface Props {
  text?: string;
}

const NoData: React.FC<Props> = ({ text }) => {
  return <NoDataStyled>{text}</NoDataStyled>;
};

export default NoData;
