import React, { useMemo, Dispatch, SetStateAction, useEffect } from 'react';
import { Dropdown, Menu } from 'antd';
import Icon from '@ant-design/icons';
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { NAVBAR } from '@constants/nav';
import { useAuth, useTopics } from '@hooks';
import { trimString } from '@utils/utils';
import ButtonComponent from '@components/button';
import { isMobile, isTablet } from 'react-device-detect';

import { LOGO_HEADER, NEW_LOGOUT_ICON, STROKE, AV_DEFAULT, MENU_MOBILE_ICON } from '@assets';
import { Wrapper, NavBarStyled } from './styled';

interface Props {
  setIsShowSideBar: Dispatch<SetStateAction<boolean>>;
}

interface Params {
  id?: string;
}

const Header: React.FC<Props> = ({ setIsShowSideBar }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { profile: userProfile, logoutAction, authenticated, changeLaguage, isLogoutSuccess } = useAuth();
  const { getTopicList, getTopicTop, getTopic } = useTopics();

  const location = useLocation();
  const history = useHistory();

  const handleLogout = () => {
    logoutAction();
  };
  const languageIcon = useMemo(() => {
    switch (language) {
      case 'en':
        return 'English';
      case 'ja':
        return '日本語';
      case 'cn':
        return '中文(简体)';
      default:
        return null;
    }
  }, [language]);

  const transalte = {
    list_event: t('common:navbar.list_event'),
    diamond_purchase: t('common:navbar.diamond_purchase'),
    history: t('common:navbar.history'),
    topics: t('common:navbar.topics'),
  };
  const { id } = useParams<Params>();
  useEffect(() => {
    if (isLogoutSuccess && location.pathname === '/') {
      history.push('/login');
    }
    if (isLogoutSuccess && location.pathname.includes('/topics')) {
      if (id) {
        getTopic({ topicId: Number(id) });
      } else {
        getTopicList({
          page: 1,
          perPage: 12,
        });
        getTopicTop();
      }
    }
  }, [isLogoutSuccess, location.pathname]);
  const changeLanguage = (lang) =>
    changeLaguage({
      language: lang,
    });

  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={() => changeLanguage('en')}>
        <span>&nbsp;English</span>
      </Menu.Item>
      <Menu.Item key="1" onClick={() => changeLanguage('ja')}>
        <span>&nbsp;日本語</span>
      </Menu.Item>
      <Menu.Item key="2" onClick={() => changeLanguage('cn')}>
        <span>&nbsp;中文(简体)</span>
      </Menu.Item>
    </Menu>
  );

  const dropdownMenu = (
    <Menu>
      <Menu.Item key="1" onClick={handleLogout}>
        <img src={NEW_LOGOUT_ICON} className="logout-icon" alt="logout-icon" />
        <span>&nbsp;&nbsp;{t('common:logout')}</span>
      </Menu.Item>
    </Menu>
  );

  const headerRight = 'header-right';

  return (
    <Wrapper>
      <div className="container">
        <div className="row">
          <div className="header-left">
            <NavLink to="/">
              <img className="logo" src={LOGO_HEADER} alt="logo" />
            </NavLink>
          </div>

          {isMobile || isTablet ? (
            <Icon
              component={MENU_MOBILE_ICON}
              style={{ fontSize: '24px' }}
              onClick={() => {
                setIsShowSideBar(true);
              }}
            />
          ) : (
            <div className={headerRight}>
              <div className="row g-3">
                <div className="nav-bar">
                  <NavBarStyled>
                    <ul className="navbar-list__item" style={{ listStyle: 'none' }}>
                      {NAVBAR.map((item) => (
                        <li className="navbar-list__item__link" key={item.key}>
                          <NavLink
                            exact={item.pathName === '/'}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '8px',
                            }}
                            className={({ isActive }: any) => {
                              if (item.subRoute.includes(location.pathname?.split('/')?.[1])) return 'active';
                              return isActive;
                            }}
                            to={item.pathName}
                          >
                            {transalte[item.trans]}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </NavBarStyled>
                </div>
                <div className="col-auto">
                  <Dropdown overlay={menu} trigger={['click']}>
                    <button
                      type="button"
                      className="btn-action dropdown-toggle"
                      aria-label="Change language"
                      data-tour="lang-selector"
                      aria-expanded="false"
                    >
                      <span style={{ color: '#D9D9D9' }}>|</span>
                      <b
                        className={`cs-hidden-mb ${!authenticated ? 'cs-show-mb-not-login' : ''}`}
                        style={{ marginLeft: '10px' }}
                      >
                        {languageIcon}
                      </b>
                      &emsp;
                      <img
                        className={`logo2 cs-hidden-mb ${!authenticated ? 'cs-show-mb-not-login' : ''}`}
                        src={STROKE}
                        alt="logo"
                      />
                    </button>
                  </Dropdown>
                </div>

                {authenticated ? (
                  <div className="col user-info" role="presentation">
                    <Dropdown overlay={dropdownMenu} trigger={['click']}>
                      <a
                        href="true"
                        className="ant-dropdown-link"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        onClick={(e) => e.preventDefault()}
                      >
                        <span style={{ color: '#D9D9D9' }}>| &nbsp;</span>
                        <img
                          className="avatar rounded-circle bg-lo25-warning img-prf-new"
                          src={userProfile?.avatar || AV_DEFAULT}
                          alt="Avatar"
                          width="30"
                          height="30"
                        />
                        <div className="me-3">
                          <div
                            className="text-end"
                            style={{
                              fontWeight: '600',
                              fontSize: '.75rem',
                              color: '#282727',
                            }}
                          >
                            {trimString(userProfile?.name, 12)}
                          </div>
                        </div>
                        <img className="logo2" src={STROKE} alt="logo" />
                      </a>
                    </Dropdown>
                  </div>
                ) : (
                  <div className="btn-auth">
                    <span style={{ color: '#D9D9D9' }}>|</span>
                    <ButtonComponent
                      type="outline"
                      className="btn-left"
                      onClick={() => {
                        history.push('/register');
                      }}
                    >
                      {t('common:navbar.register')}
                    </ButtonComponent>
                    <ButtonComponent
                      onClick={() => {
                        history.push('/login');
                      }}
                    >
                      {t('common:navbar.login')}
                    </ButtonComponent>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default Header;
