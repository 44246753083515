/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react';
import { Radio, Space, Button } from 'antd';
import type { RadioChangeEvent } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { VISA_ICON, MASTER_CARD_ICON, RUBY_POPUP } from '@assets';
import Modal from '@components/modal/confirmModal';
import { useAuth, useWeb3 } from '@hooks';
import { Wrapper } from './styled';

const HomeScreen: React.FC = () => {
  const [value, setValue] = useState(0);
  const { connectWallet, walletConnected } = useWeb3();
  const history = useHistory();
  const { t } = useTranslation(['common']);
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
  const { profile, loadProfileAction } = useAuth();

  const onChange = (e: RadioChangeEvent) => {
    const dombtnConfirm = document.getElementById('btn-Confirm-method');
    if (dombtnConfirm) dombtnConfirm.style.opacity = 'unset';
    setValue(e.target.value);
  };

  useEffect(() => {
    setIsShowPopup(profile?.free_ruby_login === 1);
  }, [profile?.free_ruby_login]);

  useEffect(() => {
    if (walletConnected) {
      history.push('/diamond-purchase/list-diamond/2');
    }
  }, [walletConnected]);

  const listPackageDiamond = async () => {
    if (value === 2) {
      connectWallet();
    } else {
      history.push(`/diamond-purchase/list-diamond/${value}`);
    }
  };

  const onConfirm = () => {
    setIsShowPopup(false);
    loadProfileAction();
  };

  return (
    <Wrapper id="intro">
      <div className="row h-fluid-min-100 intro-0-2-5">
        <div className="payment-content">
          <div className="payment-top">
            <div className="payment-title">
              <label>{t('payment_title')}</label>
            </div>
            <div className="payment-method">
              <Radio.Group onChange={onChange} value={value}>
                <Space direction="vertical">
                  <Radio value={1}>{t('credit_card')}</Radio>
                </Space>
              </Radio.Group>
            </div>
            <div className="payment-icon">
              <img src={VISA_ICON} alt="visa" />
              &emsp;
              <img src={MASTER_CARD_ICON} alt="master-card" />
            </div>
          </div>
          <div className="payment-btn">
            <Button
              className="btn-login"
              id="btn-Confirm-method"
              htmlType="submit"
              onClick={listPackageDiamond}
              disabled={value === 0}
            >
              {t('payment_confirm')}
            </Button>
          </div>
        </div>
      </div>
      <Modal
        maxWidth="352px"
        visible={isShowPopup}
        type="custom"
        size="large"
        btnWidth="calc(100% - 50px)"
        onSubmitText={t('ruby_popup_btn')}
        heightModal="auto"
        mobilePadding="0px"
        confirmClick={onConfirm}
      >
        <div
          style={{
            marginTop: 42,
            marginBottom: 45,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <p
            style={{
              fontSize: 22,
              color: '#E3007F',
              fontWeight: 'bold',
            }}
          >
            {t('ruby_popup_title')}
          </p>
          <p
            style={{
              fontSize: 18,
              color: '#282727',
              textAlign: 'center',
            }}
          >
            {t('ruby_popup_des')}
          </p>
          <div style={{ position: 'relative', width: '100%' }}>
            <img src={RUBY_POPUP} alt="" style={{ width: '100%' }} />
            <div
              style={{
                position: 'absolute',
                width: '100%',
                top: '70%',
                left: -5,
                display: 'flex',
                justifyContent: 'center',
                fontSize: 22,
                fontWeight: 'bold',
                textAlign: 'center',
                color: '#E3007F',
              }}
            >
              <span>100</span>
            </div>
          </div>
        </div>
      </Modal>
    </Wrapper>
  );
};

export default HomeScreen;
