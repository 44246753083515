import Media from '@themes/media';
import { Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 6px;
  margin-top: 4px;
  ${Media.lessThan(Media.SIZE.LG)} {
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
  justify-items: flex-start;
  gap: 4px;
  ${Media.lessThan(Media.SIZE.LG)} {
    img {
      width: 28px;
      height: 28px;
    }
  }
`;

export const RankingNumber = styled.div<{ background?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 12px;
  background: ${({ background }) => background || '#D9D9D9'};
  ${Media.lessThan(Media.SIZE.LG)} {
  }
`;

export const Point = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-width: 20%;
  ${Media.lessThan(Media.SIZE.LG)} {
  }
`;

export const PointNumber = styled.p`
  margin-left: 4px;
  font-size: 12px;
  font-weight: 700;
  color: #e3007f;

  ${Media.lessThan(Media.SIZE.LG)} {
  }
`;

export const Name = styled(Text)<{ color?: string }>`
  margin-left: 4px;
  font-size: 13px;
  color: ${({ color }) => color || '#282727'};

  ${Media.lessThan(Media.SIZE.LG)} {
  }
`;
