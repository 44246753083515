import { useDispatch, useSelector } from 'react-redux';

import saga from '@modules/history/store/saga';
import reducer from '@modules/history/store/reducer';
import {
  getTopicList,
  getTicketList,
  getTicketDetail,
  getQRList,
  getQRDetail,
  resetQr,
  getTopicDetail,
} from '@modules/history/store/actions';
import { useInjectSaga, useInjectReducer } from '@stores';
import { makeSelectHistories } from '@modules/history/store/selectors';
import { HistoryState } from '@type/Store/histories';

export const useHistories = () => {
  useInjectSaga({ key: 'histories', saga });
  useInjectReducer({ key: 'histories', reducer });

  const state: HistoryState = useSelector(makeSelectHistories());

  const dispatch = useDispatch();

  const getTopics = ({ page }: { page: number }) => {
    dispatch(getTopicList({ page }));
  };

  const getTopicDetailAction = ({ balanceCode }: { balanceCode: string }) => {
    dispatch(getTopicDetail({ balanceCode }));
  };

  const getTickets = ({ page }: { page: number }) => {
    dispatch(getTicketList({ page }));
  };

  const getTicketDetailAction = ({ balanceCode, realEventId }: { balanceCode: string; realEventId: string }) => {
    dispatch(getTicketDetail({ balanceCode, realEventId }));
  };

  const getQRs = ({ balanceCode, realEventId, page }: { balanceCode: string; realEventId: string; page?: number }) => {
    dispatch(getQRList({ balanceCode, realEventId, page }));
  };

  const getQR = ({ seriesCode }: { seriesCode: number }) => {
    dispatch(getQRDetail({ seriesCode }));
  };

  const resetQRDetail = () => dispatch(resetQr());

  return {
    ...state,
    getTopics,
    getTopicDetail: getTopicDetailAction,
    getTickets,
    getTicketDetail: getTicketDetailAction,
    getQRList: getQRs,
    getQRDetail: getQR,
    resetQRDetail,
  };
};
