export const PAYMENT_TOPIC_STATUS = {
  // NOT_PURCHASED: 0,
  SUCCEEDED: 1,
  PROCESSING: 2,
  FAILED: 3,
  CANCEL: 4,
  REFUND: 5,
  EXPIRED: 6,
};

export const PAYMENT_TICKET_STATUS = {
  NOT_PURCHASED: 0,
  SUCCESS: 1,
  FAIL: 2,
  PRE_ORDER: 3,
  PROCESSING: 4,
  REFUND: 5,
};
