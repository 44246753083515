// import { KEYS, storage } from '@/utils';
import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { get, isString } from 'lodash';
import { getEnv } from '@config/env';
import { STORAGE, getLocalStorage } from '@utils';

const BASE_URL = getEnv('REACT_APP_API_SERVER', null);

const getToken = () => {
  const userToken = getLocalStorage(STORAGE.USER_TOKEN);
  return userToken || null;
};

const requestConfig = {
  baseURL: BASE_URL,
  timeout: 30000, // 30s
};

const request = axios.create(requestConfig);
request.interceptors.request.use(
  async function (config: AxiosRequestConfig) {
    // console.log('CONFIG: ', config);
    try {
      const token = getToken();
      config.headers = {
        Authorization: `Bearer ${token}`,
        ...config.headers,
      };

      return config;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  function (error) {
    return Promise.reject(error);
  },
);

request.interceptors.response.use(
  function (response: AxiosResponse) {
    const { data, status } = response;
    const serverDateTime = get(response, ['headers', 'date']);

    if (isString(data)) {
      return {
        data,
        status,
        serverDateTime,
      };
    }

    return {
      data,
      serverDateTime,
      status,
    };
  },
  function (error: AxiosError) {
    return Promise.reject(error);
  },
);

export default request;
