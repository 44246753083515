import styled from 'styled-components';
import { isTablet } from 'react-device-detect';

export const Wrapper = styled.div`
  height: 60vh;

  .history-content {
    display: flex;
    border-bottom: 1px solid #d9d9d9;
    width: 90%;
    margin-left: 2vh;
    margin-top: 0.2vh;
  }
  .history-title {
    width: 21vh;
    text-align: left;
  }
  .history-label {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #282727;
  }

  .history-time {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 3vh;
    display: flex;
    align-items: center;
    color: #787878;
  }

  .history-diamond {
    width: 21vh;
    text-align: right;
  }

  .status-warning {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    text-align: right;
    color: #d80027;
    margin-top: 2vh;
  }

  .amount-diamond-history {
    margin-top: 2vh;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: ${isTablet ? '16px' : '12px'};
    line-height: 17px;
    color: #0076e3;
  }

  .diamond-icon-history {
    margin-bottom: 4px;
    height: 10px;
  }
`;
