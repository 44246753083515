import React, { SetStateAction, Dispatch } from 'react';
import { Typography } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';

import { numberWithCommas } from '@utils/number';
import { STATUS_PAYMENT_COIN } from '@constants/real-event';
import { Button } from '@components';
import { COIN_ICON } from '@assets';
import { PaymnetCoinEnoughStyled } from './styled';

const { Text } = Typography;

interface Props {
  totalCoin?: number;
  setStatusPayment: Dispatch<SetStateAction<STATUS_PAYMENT_COIN>>;
}

interface Params {
  eventId: string;
}

const PaymentCoinEnough: React.FC<Props> = ({ totalCoin, setStatusPayment }) => {
  const params: Params = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const { realEventPayment } = translations;

  return (
    <PaymnetCoinEnoughStyled>
      <div className="box-message">
        <Typography className="text-message">
          <Text className="text-message-top">
            {t(realEventPayment.would_you_like_pay_coin)}
            <COIN_ICON style={{ width: 32, height: 32, marginLeft: 2, marginRight: 2 }} />
            <span style={{ color: '#10ab52', marginRight: 2, fontWeight: 'bold', minWidth: 16 }}>
              {numberWithCommas(Number(totalCoin), ',')}
            </span>{' '}
            {t(realEventPayment.is_this_good_br)}
          </Text>
        </Typography>
      </div>

      <div className="box-btn">
        <Button
          type="cancel"
          onClick={() => {
            history.push(`/events/${params.eventId}`);
          }}
        >
          {t(realEventPayment.cancel)}
        </Button>
        <Button
          onClick={() => {
            setStatusPayment(STATUS_PAYMENT_COIN.CONFIRM);
          }}
        >
          {t(realEventPayment.continue)}
        </Button>
      </div>
    </PaymnetCoinEnoughStyled>
  );
};

export default PaymentCoinEnough;
