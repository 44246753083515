import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 30px;
  .logo-page {
    text-align: center;
  }
  .header-page {
    text-align: center;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #282727;
  }
  .content-page {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: #282727;
  }
`;
