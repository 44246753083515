import React from 'react';
import { useTopRanking } from '@hooks/useTopRanking';
import { Wrapper } from './styled';
import User from '../user';

interface IProps {
  roomId?: string | null;
}

export const TopRanking: React.FC<IProps> = ({ roomId }) => {
  const { rankings } = useTopRanking({ roomId });
  return (
    <Wrapper>
      {rankings.map((item, index) => (
        <User key={item.id} isRanking {...item} position={index + 1} />
      ))}
    </Wrapper>
  );
};

export default TopRanking;
