import React, { useMemo, Dispatch, SetStateAction } from 'react';
import { Menu, Image, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Icon from '@ant-design/icons';

import { trimString } from '@utils/utils';
import { useAuth } from '@hooks';
import ButtonComponent from '@components/button';

import { EXPAND_ICON, LOGO_FOOTER, EN_ICON, CH_ICON, JP_ICON, STROKE, AV_DEFAULT, LOGO_ILIVE } from '@assets';
import useSiderBarHook from './useSiderBarHook';
import { SiderBarCardStyle } from './SiderBarCard.style';

interface Props {
  isShowSideBar: boolean;
  setIsShowSideBar: Dispatch<SetStateAction<boolean>>;
}

const SiderBarCard: React.FC<Props> = ({ isShowSideBar, setIsShowSideBar }) => {
  const history = useHistory();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { menuDisplay, menuSelected, getDisplayMenu, getSelectedMenu } = useSiderBarHook();

  const { profile: userProfile, logoutAction, isLoading, authenticated, changeLaguage } = useAuth();

  const handleLogout = () => {
    logoutAction();
  };

  const languageIcon = useMemo(() => {
    switch (language) {
      case 'en':
        return 'English';
      case 'ja':
        return '日本語';
      case 'cn':
        return '中文(简体)';
      default:
        return null;
    }
  }, [language]);

  const changeLanguage = (lang) =>
    changeLaguage({
      language: lang,
    });
  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={() => changeLanguage('en')}>
        <EN_ICON />
        <span>&nbsp;English</span>
      </Menu.Item>
      <Menu.Item key="1" onClick={() => changeLanguage('ja')}>
        <JP_ICON />
        <span>&nbsp;日本語</span>
      </Menu.Item>
      <Menu.Item key="2" onClick={() => changeLanguage('cn')}>
        <CH_ICON />
        <span>&nbsp;中文(简体)</span>
      </Menu.Item>
    </Menu>
  );

  const onClose = () => {
    setIsShowSideBar(false);
  };

  return (
    <SiderBarCardStyle
      placement="left"
      onClose={onClose}
      open={isShowSideBar}
      className="layout-sider"
      width="100%"
      height="100%"
    >
      <div>
        <div className="icon-expand">
          <Icon
            component={EXPAND_ICON}
            className="icon-collapse"
            onClick={() => {
              setIsShowSideBar(false);
            }}
          />
        </div>
        <div className="logo">
          <Image
            className="logo-img"
            src={LOGO_FOOTER}
            alt="logo-footer"
            preview={false}
            onClick={() => {
              history.push('/');
            }}
          />

          <Dropdown overlay={menu} trigger={['click']}>
            <button
              type="button"
              className="btn-action dropdown-toggle"
              aria-label="Change language"
              data-tour="lang-selector"
              aria-expanded="false"
            >
              <span
                className={`cs-hidden-mb ${!authenticated ? 'cs-show-mb-not-login' : ''}`}
                style={{ marginLeft: '10px' }}
              >
                {languageIcon}
              </span>
              &emsp;
              <img
                className={`logo2 cs-hidden-mb ${!authenticated ? 'cs-show-mb-not-login' : ''}`}
                src={STROKE}
                alt="logo"
              />
            </button>
          </Dropdown>
        </div>

        <Menu
          className="layout-menu"
          mode="inline"
          defaultOpenKeys={getSelectedMenu(menuSelected).map((item) => item.key)}
          selectedKeys={getSelectedMenu(menuSelected).map((item) => item.key)}
          items={getDisplayMenu(menuDisplay)}
          onClick={() => {
            setIsShowSideBar(false);
          }}
        />
      </div>
      <div>
        {authenticated ? (
          <div className="auth">
            <div className="info-user">
              <img
                className="avatar rounded-circle bg-lo25-warning img-prf-new"
                src={userProfile?.avatar || AV_DEFAULT}
                alt="Avatar"
                width="40"
                height="40"
              />
              <span> {trimString(userProfile?.name, 12)}</span>
            </div>

            <ButtonComponent type="primary" className="btn-left" loading={isLoading} onClick={handleLogout}>
              {t('common:logout')}
            </ButtonComponent>
          </div>
        ) : (
          <div className="auth">
            <ButtonComponent
              type="primary"
              className="btn-left mobile-login"
              onClick={() => {
                history.push('/register');
                setIsShowSideBar(false);
              }}
            >
              {t('common:navbar.register')}
            </ButtonComponent>
            <ButtonComponent
              className="mobile-login"
              onClick={() => {
                history.push('/login');
                setIsShowSideBar(false);
              }}
            >
              {t('common:navbar.login')}
            </ButtonComponent>
          </div>
        )}
        <div className="logo-icon">
          <Image
            style={{ width: 40, height: 40 }}
            className="icon-footer"
            src={LOGO_ILIVE}
            preview={false}
            alt="logo-icon"
          />
          <div>
            <div>JASRAC許諾番号</div>
            <div>9039449001Y45037</div>
          </div>
        </div>
      </div>
    </SiderBarCardStyle>
  );
};

export default SiderBarCard;
