import React from 'react';
import { MESSAGE_ICON } from '@assets';
import { Divider } from 'antd';
import { useTopics } from '@hooks';
import { Wrapper, MessageIcon, Header } from './styled';

export const ChatHeader = () => {
  const { comments } = useTopics();
  return (
    <Wrapper>
      <Header>
        <MessageIcon src={MESSAGE_ICON} alt="MESSAGE_ICON" />
        <div>コメント ({comments?.paginate?.total || 0})</div>
      </Header>
      <Divider className="line" />
    </Wrapper>
  );
};

export default ChatHeader;
