import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { translations } from '@i18n/translations';
import { PaymentRefundStyled, BoxMessage } from './styled';

const PaymentRefund: React.FC = () => {
  const { t } = useTranslation();
  const { common } = translations;
  return (
    <PaymentRefundStyled>
      <Typography className="title" />
      <div className="box-message">
        <BoxMessage>{t(common.event.event_cancel)}</BoxMessage>
      </div>
    </PaymentRefundStyled>
  );
};

export default PaymentRefund;
