import React, { useEffect } from 'react';
import { useTopics } from '@hooks/topics';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';
import { NoData } from '@components';
import { Spin } from 'antd';
import { Wrapper, Title } from '../styled';
import { ListTopic, TopicTop } from '../components';

const Topics: React.FC = () => {
  const { getTopicList, getTopicTop, topicList, loading } = useTopics();
  const { t } = useTranslation();
  const { topic } = translations;

  useEffect(() => {
    getTopicList({
      page: 1,
      perPage: 12,
    });
    getTopicTop();
  }, []);

  if (topicList && topicList.data?.length > 0) {
    return (
      <Wrapper>
        <TopicTop />
        <Title>{t(topic.list_topic)}</Title>
        <ListTopic />
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <Spin spinning={loading}>
        <NoData text={t(topic.no_data)} />
      </Spin>
    </Wrapper>
  );
};

export default Topics;
