export const LIVESTREAM_STATUS = {
  COMING: 1,
  LIVE: 2,
  END: 3,
};

export const AGORA_CONNECTION_STATUS = {
  DISCONNECTED: 'DISCONNECTED',
  CONNECTING: 'CONNECTING',
  RECONNECTING: 'RECONNECTING',
  CONNECTED: 'CONNECTED',
  DISCONNECTING: 'DISCONNECTING',
};

export const CHAPTER_TYPE = {
  LIVESTREAM: 1,
  VIDEO: 0,
};

export const ERROR_MESSAGE_TOPICS = {
  YOU_PURCHASED_THIS_PRODUCT: 'YOU_PURCHASED_THIS_PRODUCT',
  YOU_PURCHASED_THIS_PACKAGE: 'YOU_PURCHASED_THIS_PACKAGE',
};

export const CHAPTER_FEE_TYPE = {
  FREE: 0,
  MUST_PAID: 1,
  FREE_A_PATH: 2,
};

export const PAYMENT_STATUS = {
  NOT_PURCHASED: 0,
  SUCCEEDED: 1,
  PROCESSING: 2,
  FAILED: 3,
  CANCEL: 4,
  REFUND: 5,
};

export const PACKAGE_FEE_TYPE = {
  FREE: 1,
  MUST_PAID: 2,
};
