export const GET_TOPIC_LIST = '@HISTORIES/GET_TOPIC_LIST';
export const GET_TOPIC_DETAIL = '@HISTORIES/GET_TOPIC_DETAIL';
export const GET_TICKET_LIST = '@HISTORIES/GET_TICKET_LIST';
export const GET_TICKET_DETAIL = '@HISTORIES/GET_TICKET_DETAIL';
export const GET_QR_LIST = '@HISTORIES/GET_QR_LIST';
export const GET_QR_DETAIL = '@HISTORIES/GET_QR_DETAIL';
export const RESET_QR = '@HISTORIES/RESET_QR';

export const LOADING = '@HISTORIES/LOADING';
export const ERROR = '@HISTORIES/ERROR';
