import AxiosClient from './api';
import END_POINT from './constants';

function getTopicList({ perPage = 12, page = 1 }) {
  return AxiosClient.get(`${END_POINT.TOPICS.LIST}?per_page=${perPage ?? ''}&page=${page ?? ''}`).then(
    (res) => res.data,
  );
}

function getTopic({ topicId }: { topicId: number }) {
  return AxiosClient.get(`${END_POINT.TOPICS.LIST}/${topicId}`).then((res) => res.data);
}

function getTopicTop() {
  return AxiosClient.get(END_POINT.TOPICS.TOP).then((res) => res.data);
}

function getLiveStreamDetail({ roomId }: { roomId: string }) {
  return AxiosClient.get(`${END_POINT.TOPICS.LIVESTREAM}/${roomId}`).then((res) => res.data);
}

function getAgoraToken({ channel_name, user_id }: { channel_name: string; user_id: string | number }) {
  return AxiosClient.get(
    `${END_POINT.TOPICS.AGORA_TOKEN}?channel_name=${channel_name}&user_id=${user_id}&expired_second=6000`,
  ).then((res) => res.data);
}

function checkPassword(id: string, password: string) {
  return AxiosClient.post(
    `${END_POINT.TOPICS.CHECK_PASSWORD}/${id}/password-check`,
    { password },
    // { isHideError: true },
  ).then((res) => res.data);
}

function buyChapter(data) {
  return AxiosClient.post(END_POINT.TOPICS.BUY_CHAPTER, { ...data }, { isHideError: true }).then((res) => res.data);
}

function getVideoInfor({ id }: { id: number }) {
  return AxiosClient.get(`${END_POINT.TOPICS.VIDEO_INFO}/${id}`).then((res) => res.data);
}

function getVideoComment({ id, perPage = 25, page }) {
  return AxiosClient.get(`${END_POINT.TOPICS.VIDEO_COMMENT}/${id}?per_page=${perPage ?? ''}&page=${page ?? ''}`).then(
    (res) => res.data,
  );
}

function getDonatePackage(object_id) {
  return AxiosClient.get(`${END_POINT.TOPICS.GET_DONATE_PACKAGE(object_id)}`).then((res) => res.data);
}

function sendDonate(liveKey: string, body) {
  return AxiosClient.put(`${END_POINT.TOPICS.SEND_DONATE(liveKey)}`, body, { isHideError: true }).then(
    (res) => res.data,
  );
}

function createComment(data: { parent_id: number; video_id: number; type: string; content: string }) {
  return AxiosClient.post(`${END_POINT.TOPICS.CREATE_COMMENT}`, data, { isHideError: true }).then((res) => res.data);
}

function getChapterDetail({ id }: { id: number }) {
  return AxiosClient.get(`${END_POINT.TOPICS.CHAPTER_DETAIL}/${id}`).then((res) => res.data);
}

function editComment(id: string, content: string) {
  return AxiosClient.put(
    `${END_POINT.TOPICS.EDIT_COMMENT}/${id}`,
    { content },
    {
      isHideError: true,
    },
  ).then((res) => res.data);
}

function deleteComment(id: number) {
  return AxiosClient.delete(`${END_POINT.TOPICS.DELETE_COMMENT}/${id}`).then((res) => res.data);
}

function getPointFree(liveKey: string) {
  return AxiosClient.put(`${END_POINT.TOPICS.GET_FREE_POINT(liveKey)}`, {});
}

function tweets(body) {
  return AxiosClient.post(`${END_POINT.TOPICS.TWEETS}`, body, { isHideError: true });
}

function signInTwitter() {
  return AxiosClient.get(`${END_POINT.TOPICS.SIGN_IN_TWITTER}`, '', { isHideError: true });
}

function signInTwitterWithCallback({ oauth_token = '', oauth_verifier = '' }) {
  return AxiosClient.get(
    `${END_POINT.TOPICS.SIGN_IN_TWITTER_CALLBACK}?oauth_token=${oauth_token}&oauth_verifier=${oauth_verifier}`,
    '',
    { isHideError: true },
  );
}
function signInTwitterWithMobile(userToken) {
  AxiosClient.setHeader(userToken);
  return AxiosClient.get(`${END_POINT.TOPICS.SIGN_IN_TWITTER}`, '', { isHideError: true });
}

function getVideoPackage({ id, perPage = 1000, page = 1 }) {
  return AxiosClient.get(`${END_POINT.TOPICS.VIDEO_PACKAGE(id)}?per_page=${perPage ?? ''}&page=${page ?? ''}`).then(
    (res) => res.data,
  );
}
function getPackageDetail({ topicId, packageId }) {
  return AxiosClient.get(`${END_POINT.TOPICS.PACKAGE_DETAIL(topicId, packageId)}`).then((res) => res.data);
}

function getPackageList({ topicId, packageId, perPage = 1000, page = 1 }) {
  return AxiosClient.get(
    `${END_POINT.TOPICS.PACKAGE_LIST(topicId, packageId)}?per_page=${perPage ?? ''}&page=${page ?? ''}`,
  ).then((res) => res.data);
}

function buyVideoPackage({ topicId, packageId, ...body }) {
  return AxiosClient.post(
    END_POINT.TOPICS.BUY_VIDEO_PACKAGE(topicId, packageId),
    { ...body },
    { isHideError: true },
  ).then((res) => res.data);
}

function buyTopicPayment({ topicId, packageId }, data) {
  return AxiosClient.post(END_POINT.TOPICS.BUY_CHAPTER_PAYMENT(topicId, packageId), data, { isHideError: true }).then(
    (res) => res.data,
  );
}
function buyPackagePayment({ topicId, packageId }, data) {
  return AxiosClient.post(END_POINT.TOPICS.BUY_PACKAGE_PAYMENT(topicId, packageId), data, { isHideError: true }).then(
    (res) => res.data,
  );
}

export {
  buyChapter,
  buyPackagePayment,
  buyTopicPayment,
  buyVideoPackage,
  checkPassword,
  createComment,
  deleteComment,
  editComment,
  getChapterDetail,
  getDonatePackage,
  getLiveStreamDetail,
  getPackageDetail,
  getPackageList,
  getPointFree,
  getTopic,
  getTopicList,
  getTopicTop,
  getVideoComment,
  getVideoInfor,
  getVideoPackage,
  sendDonate,
  signInTwitter,
  signInTwitterWithCallback,
  signInTwitterWithMobile,
  tweets,
  getAgoraToken,
};
