import { LOCATION_CHANGE, LocationChangePayload } from 'connected-react-router';
import { createReducer, updateObject, REQUEST, SUCCESS } from '@stores';
import { Action } from '@type/Store';
import { HistoryState, TopicDetail } from '@type/Store/histories';
import { updateReducer } from '@stores/redux';
import {
  ERROR,
  GET_QR_DETAIL,
  GET_QR_LIST,
  GET_TICKET_DETAIL,
  GET_TICKET_LIST,
  GET_TOPIC_LIST,
  LOADING,
  RESET_QR,
  GET_TOPIC_DETAIL,
} from './constants';

const paginate = {
  current_page: 1,
  last_page: 0,
  next: 0,
  per_page: 12,
  prev: 0,
  total: 0,
};
const dataList = {
  data: [],
  paginate,
};

const topicDetail: TopicDetail = {
  address: '',
  first_name: '',
  last_name: '',
  konbini_convenience: '',
  konbini_customer_kana: '',
  konbini_customer_name: '',
  konbini_phone_number: '',
  memo: '',
  receipt_code: '',
  topic_gmo_status: '',
  topic_description: '',
  topic_id: null,
  topic_title: '',
  zip_code: '',
  balance_code: '',
  chapter_id: null,
  description: '',
  diamond_price: 0,
  package_id: null,
  payment_date: '',
  payment_method: null,
  payment_status: 0,
  refund_status: 0,
  thumbnail: '',
  title: '',
  type: null,
  yen_price: 0,
  id: 0,
  phone: '',
  live_key: null,
  err_code: null,
};

export const initialState: HistoryState = {
  topicList: dataList,
  topicDetail,
  ticketList: dataList,
  ticketDetail: {
    id: 0,
    address: '',
    konbini_code: 0,
    payment_method: 0,
    payment_status: 0,
    payment_unit: 0,
    purchase_at: 0,
    quantity: 0,
    receipt_code: '',
    receipt_url: '',
    start_at: 0,
    status: 0,
    thumbnail: '',
    title: '',
    total: '',
    url: '',
  },
  qrList: dataList,
  qrDetail: {
    address: null,
    checkin_status: 0,
    id: 0,
    payment_status: 0,
    payment_unit: 0,
    price: '',
    real_event_name: '',
    series_code: '',
    start_at: 0,
    ticket_name: '',
  },
  isLoading: false,
  error: null,
};

function setLoading(state: HistoryState, { payload }) {
  return updateReducer({
    ...state,
    isLoading: payload,
  });
}

function setError(state: HistoryState, { payload }) {
  return updateObject(state, {
    error: payload,
  });
}

function getTopics(state: HistoryState) {
  return updateReducer(state);
}

function getTopicsSuccess(state: HistoryState, { payload }: Action) {
  const { topicList } = payload;
  const data = topicList.paginate.current_page > 1 ? [...state.topicList.data, ...topicList.data] : [...topicList.data];
  return updateReducer({
    ...state,
    topicList: {
      ...state.topicList,
      data,
      paginate: topicList.paginate,
    },
  });
}

function getTopicDetail(state: HistoryState) {
  return updateReducer(state);
}

function getTopicDetailSuccess(state: HistoryState, { payload }: Action) {
  const { topicDetail } = payload;

  return updateReducer<HistoryState>({
    ...state,
    topicDetail,
  });
}

function getTickets(state: HistoryState) {
  return updateReducer(state);
}

function getTicketsSuccess(state: HistoryState, { payload }: Action) {
  const { ticketList } = payload;
  const data =
    ticketList.paginate.current_page > 1 ? [...state.ticketList.data, ...ticketList.data] : [...ticketList.data];

  return updateReducer({
    ...state,
    isLoading: false,
    ticketList: {
      ...state.ticketList,
      data,
      paginate: ticketList.paginate,
    },
  });
}

function getTicketDetail(state: HistoryState) {
  return updateReducer({
    ...state,
  });
}

function getTicketDetailSuccess(state: HistoryState, { payload }: Action) {
  const { ticketDetail } = payload;

  return updateReducer({
    ...state,
    ticketDetail,
  });
}

function getQRList(state: HistoryState) {
  return updateReducer({
    ...state,
  });
}

function getQRListSuccess(state: HistoryState, { payload }: Action) {
  const { qrList, paginate } = payload;
  return updateReducer({
    ...state,
    qrList: {
      data: [...qrList],
      paginate,
    },
  });
}

function getQRDetail(state: HistoryState) {
  return updateReducer({
    ...state,
  });
}

function getQRDetailSuccess(state: HistoryState, { payload }: Action) {
  const { qrDetail } = payload;
  return updateReducer({
    ...state,
    qrDetail,
  });
}

function resetQr(state: HistoryState) {
  return updateReducer({
    ...state,
    qrDetail: initialState.qrDetail,
  });
}

function locationChange(state: HistoryState, { payload }: { payload: LocationChangePayload }) {
  if (payload.location.pathname.includes('histories')) {
    return updateReducer<HistoryState>({
      ...state,
    });
  }

  return updateReducer<HistoryState>({
    ...initialState,
  });
}

// Slice reducer
export default createReducer(initialState, {
  [REQUEST(GET_TOPIC_LIST)]: getTopics,
  [SUCCESS(GET_TOPIC_LIST)]: getTopicsSuccess,

  [REQUEST(GET_TOPIC_DETAIL)]: getTopicDetail,
  [SUCCESS(GET_TOPIC_DETAIL)]: getTopicDetailSuccess,

  [REQUEST(GET_TICKET_LIST)]: getTickets,
  [SUCCESS(GET_TICKET_LIST)]: getTicketsSuccess,

  [REQUEST(GET_TICKET_DETAIL)]: getTicketDetail,
  [SUCCESS(GET_TICKET_DETAIL)]: getTicketDetailSuccess,

  [REQUEST(GET_QR_LIST)]: getQRList,
  [SUCCESS(GET_QR_LIST)]: getQRListSuccess,

  [REQUEST(GET_QR_DETAIL)]: getQRDetail,
  [SUCCESS(GET_QR_DETAIL)]: getQRDetailSuccess,

  [REQUEST(LOADING)]: setLoading,
  [REQUEST(ERROR)]: setError,

  [RESET_QR]: resetQr,
  [LOCATION_CHANGE]: locationChange,
});
