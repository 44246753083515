/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';
import styled from 'styled-components';
import { Button, Col, Row, Radio, Space } from 'antd';
import { CARD_OPTIONS } from '@config/stripe';
import { FormInput, FormLabel } from '@components';
import { FormProvider, useForm } from 'react-hook-form';
import { CVV_INFOR } from '@assets/index';
import CvvComponent from '@components/modal/cvvModal';
import PaymentConfirmComponent from '@components/modal/paymentConfirmModal';
import ConfirmComponent from '@components/modal/confirmModal';
import { PaymentIntentStripe, useAuth } from '@hooks';
import { VISA_ICON, MASTER_CARD_ICON, DIAMOND, BACK_ICON } from '@assets';
import { useHistory, useParams } from 'react-router-dom';
import { RootStateOrAny, useSelector } from 'react-redux';
import * as yup from 'yup';
import { STATUS_TYPE } from '@constants';
import { STATUS_SUCCEEDED, STATUS_PROCESSING, STATUS_FAILED } from './store/constants';
import { Wrapper } from './styled';

const PaymentSignUpScreenStyled = styled.div`
  .text,
  .policy-content {
    margin-bottom: 19px;
    text-align: left;

    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    color: #616161;

    p {
      margin: 0;
    }

    span {
      font-weight: 500;
    }
  }

  .text {
    white-space: nowrap;
  }

  .policy-content {
    margin-bottom: 8px;
  }

  .submit-button {
    text-align: center;
    margin: auto auto 20px;

    &.secondary {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 100%;

      span {
        margin-left: 13px;
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;
  }
  .form-group {
    margin-bottom: 16px;
  }
  .ant-alert-content {
    font-size: 12px;
  }
  .form-label {
    padding: 10px 0px !important;
    height: 4vh;
    min-height: unset !important;
  }
  .form-wrapper {
    text-align: left;
    padding: 0px 4vh;
  }
  .ant-input {
    background: #ffffff;
    border: 1px solid #787878;
    border-radius: 10px !important;
    min-height: 38px;
    padding: 10px;
  }
`;

export const InputPayment = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-input {
    background: #ffffff;
    border: 1px solid #787878;
    border-radius: 10px !important;
  }

  &.form-editor-content-question {
    .ant-row {
      margin-bottom: 0 !important;
    }
    p {
      margin-bottom: 0;
    }
  }
`;
const PaymentInformationScreen: React.FC = () => {
  const { t } = useTranslation();
  const { common } = translations;
  const { loadProfileAction } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorCardName, setErrorCardName] = useState('');
  const [typError, setTypeerror] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmPaymentVisible, setIsconfirmPaymentVisible] = useState(false);
  const [successPaymentVisible, setIssuccessPaymentVisible] = useState(false);
  const [failurePaymentVisible, setIfailurePaymentVisible] = useState(false);
  const [connectErrorVisible, setConnectErrorVisible] = useState(false);
  const [connectErrorVisibleBegin, setConnectErrorVisibleBegin] = useState(true);
  const {
    createPaymentPackageAction,
    getPaymentIntentAction,
    getPaymentListPackageAction,
    error: errorPayment,
    status,
  } = PaymentIntentStripe();
  const form = useForm({
    defaultValues: { cardName: '' },
    mode: 'onSubmit',
  });
  const { handleSubmit, watch } = form;

  const [paymentField, setPaymentField] = useState({
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
  });
  const { packageId } = useParams<{ packageId: string }>();
  useEffect(() => {
    getPaymentListPackageAction({ isActive: 1, type: 2 });
  }, []);
  const watchShowAge = watch();
  const watchCardName = watchShowAge.cardName;
  const paymentIntentData = useSelector((state: RootStateOrAny) => state?.payment?.paymentIntent || []);
  const clientSecret = paymentIntentData?.client_secret;
  const packageData = useSelector((state: RootStateOrAny) => state?.payment?.packageList?.data || []);
  const packageFilter = packageData.filter((data: any) => data.id === Number(packageId));
  const diamondAmount = packageFilter[0]?.price_diamond || 0;

  const stripe = useStripe();
  const elements = useElements();

  const yupObject = yup.object().shape({
    cardName: yup
      .string()
      .trim()
      .required(
        t('error_message:validation.card_name_rules', {
          key: t('payment.name_oncard_title'),
        }),
      )
      .matches(/^[^\s]+(\s+[^\s]+)*$/, t('error_message:validation:card_name_rules')),
  });

  const cancelConfirmPayment = () => {
    setIsconfirmPaymentVisible(false);
  };

  const handleConfirmPayment = () => {
    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements?.getElement(CardNumberElement);

    if (cardNumberElement && clientSecret) {
      return stripe
        .confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardNumberElement,
          },
        })
        .then((result) => {
          if (result.error) {
            cancelConfirmPayment();
            // let mes = result.error.message || ""
            const typeError = result.error.code || '';
            const allowShowError = [
              'invalid_expiry_year_past',
              'incomplete_expiry',
              'incomplete_cvc',
              'invalid_number',
              'incomplete_number',
              'incorrect_cvc',
              'expired_card',
            ];
            const status = result.error.payment_intent?.status;
            const transactionCd = result.error.payment_intent?.id;
            setTypeerror(typeError);
            if (!allowShowError.includes(typeError)) {
              if (status === 'requires_action' || status === 'canceled' || status === 'requires_payment_method') {
                createPaymentPackageAction({
                  fee: 0, // to do
                  payment_type: 1, // 1 card
                  transaction_code: transactionCd, // id payment_intent
                  status: STATUS_FAILED, // 1 :succeeded ,2 process, 3 failure
                  action_from: 1, // 1 web, 2 app
                  package_id: packageId,
                });
              } else if (status === 'processing') {
                createPaymentPackageAction({
                  fee: 0, // to do
                  payment_type: 1, // 1 card
                  transaction_code: transactionCd, // id payment_intent
                  status: STATUS_PROCESSING, // 1 :succeeded ,2 process, 3 failure
                  action_from: 1, // 1 web, 2 app,
                  package_id: packageId,
                });
              }
              if (status === 'succeeded' && typeError === 'payment_intent_unexpected_state') {
                createPaymentPackageAction({
                  transaction_code: transactionCd, // id payment_intent
                });
                setIssuccessPaymentVisible(true);
              } else {
                setIfailurePaymentVisible(true);
              }
            }
            setLoading(false);
          } else if (result.paymentIntent.status === 'succeeded') {
            createPaymentPackageAction({
              fee: 0, // to do
              payment_type: 1, // 1 card
              transaction_code: result.paymentIntent.id, // id payment_intent
              status: STATUS_SUCCEEDED, // 1 :succeeded ,2 process, 3 failure
              action_from: 1, // 1 web, 2 app
              package_id: packageId,
            });
            setIssuccessPaymentVisible(true);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
          setConnectErrorVisible(true);
        });
    }
    setLoading(false);
    setConnectErrorVisible(true);
  };

  useEffect(() => {
    if (status === STATUS_TYPE.SUCCESS) {
      handleConfirmPayment();
    }
  }, [status]);

  const onSubmit = () => {
    setIsconfirmPaymentVisible(true);
  };
  const handleCardDetailsChange = (event: any) => {
    const btnPayment = document.getElementById('btn-payment');
    if (event.elementType) {
      setPaymentField({
        ...paymentField,
        [event.elementType]: event.empty === false,
      });
    }
    if (event.target) {
      setPaymentField({
        ...paymentField,
        [event.target.name]: event.target.value,
      });
    }
    if (watchCardName && paymentField.cardNumber && paymentField.cardCvc && paymentField.cardExpiry) {
      if (btnPayment) btnPayment.style.opacity = 'unset';
    } else if (btnPayment) btnPayment.style.opacity = '0.5';
  };

  const connectErrorStripeConfirm = () => {
    setConnectErrorVisible(false);
  };

  const connectErrorStripeBeginScreen = () => {
    setConnectErrorVisibleBegin(false);
    window.location.href = '/';
  };

  const showModalCvvInfor = () => {
    setIsModalVisible(true);
  };

  const cancelCvvInfor = () => {
    setIsModalVisible(false);
  };

  const confirmPayment = async () => {
    // cancelConfirmPayment();
    setErrorCardName('');
    setLoading(true);
    if (!yupObject.isValidSync({ cardName: watchCardName })) {
      yupObject.validate({ cardName: watchCardName }).catch(function (err) {
        setErrorCardName(err.message);
      });
      setLoading(false);
      return;
    }
    getPaymentIntentAction({ packageId, actionFrom: 1 });
  };
  const successPaymentConfirm = () => {
    setIssuccessPaymentVisible(false);
    loadProfileAction();
    history.push('/diamond-purchase');
  };
  const failurePaymentConfirm = () => {
    history.push('/diamond-purchase');
  };

  return (
    <>
      <Wrapper id="intro">
        <div className="row h-fluid-min-100 intro-0-2-5">
          <div className="payment-content">
            <div className="payment-top">
              <div className="back-icon">
                <img
                  className="logo"
                  src={BACK_ICON}
                  alt="logo"
                  onClick={() => history.push('/diamond-purchase/list-diamond/1')}
                />
              </div>
              <div className="payment-header">
                <div className="payment-amount">
                  <img src={DIAMOND} alt="dimond" className="payment-diamond-icon" />
                  {packageData.length > 0 ? diamondAmount : ''}
                </div>
              </div>
              <div className="payment-title">
                <label>{t(common.payment_title)}</label>
              </div>
              <div className="payment-method">
                <Radio.Group value={1}>
                  <Space direction="vertical">
                    <Radio value={1}>{t(common.credit_card)}</Radio>
                  </Space>
                </Radio.Group>
              </div>
              <div className="payment-icon">
                <img src={VISA_ICON} alt="visa" />
                &emsp;
                <img src={MASTER_CARD_ICON} alt="master-card" />
              </div>
            </div>
            <div className="payment-information">
              <PaymentSignUpScreenStyled>
                <div className="form-wrapper">
                  <FormProvider {...form}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Row className="form-group" gutter={24}>
                        <Col span={24}>
                          <FormLabel classCss="form-label" width={100} title={t('common:payment.name_oncard_title')} />
                          <InputPayment>
                            <FormInput
                              className="payment-input"
                              name="cardName"
                              placeholder={t(common.placeholder_card_name)}
                            />
                          </InputPayment>
                          {errorCardName && (
                            <div className="ant-form-item-explain ant-form-item-explain-connected">
                              <div role="alert" className="ant-form-item-explain-error">
                                {t('error_message:validation:card_name_rules')}
                              </div>
                            </div>
                          )}
                        </Col>
                        <Col span={12}>
                          <FormLabel classCss="form-label" width={100} title={t('common:payment.expiry_date_title')} />
                          <CardExpiryElement
                            options={{ ...CARD_OPTIONS, placeholder: 'MM/YY' }}
                            onChange={handleCardDetailsChange}
                          />
                          {(typError === 'invalid_expiry_year_past' ||
                            typError === 'incomplete_expiry' ||
                            typError === 'expired_card') && (
                            <div className="ant-form-item-explain ant-form-item-explain-connected">
                              <div role="alert" className="ant-form-item-explain-error">
                                {typError === 'expired_card'
                                  ? t('error_message:expired_card')
                                  : t('error_message:incomplete_expiry')}
                              </div>
                            </div>
                          )}
                        </Col>

                        <Col span={12}>
                          <FormLabel
                            classCss="form-label"
                            width={100}
                            title={t('common:payment.security_code_title')}
                          />
                          <CardCvcElement
                            options={{ ...CARD_OPTIONS, placeholder: '----' }}
                            onChange={handleCardDetailsChange}
                          />
                          <img
                            onClick={showModalCvvInfor}
                            className="cvv-icon"
                            style={{ color: 'red' }}
                            src={CVV_INFOR}
                            alt="cvv"
                          />
                          {(typError === 'incomplete_cvc' || typError === 'incorrect_cvc') && (
                            <div className="ant-form-item-explain ant-form-item-explain-connected">
                              <div role="alert" className="ant-form-item-explain-error">
                                {t('error_message:incorrect_cvc')}
                              </div>
                            </div>
                          )}
                        </Col>

                        <Col span={24}>
                          <FormLabel classCss="form-label" width={100} title={t('common:payment.card_number_title')} />
                          <CardNumberElement
                            onChange={handleCardDetailsChange}
                            options={{
                              ...CARD_OPTIONS,
                              placeholder: '---- ---- ---- ----',
                              showIcon: true,
                            }}
                          />
                          {(typError === 'invalid_number' || typError === 'incomplete_number') && (
                            <div className="ant-form-item-explain ant-form-item-explain-connected">
                              <div role="alert" className="ant-form-item-explain-error">
                                {t('error_message:invalid_number')}
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>

                      <div className="payment-btn">
                        <Button
                          id="btn-payment"
                          className="btn-login"
                          htmlType="submit"
                          loading={loading}
                          disabled={
                            !paymentField.cardExpiry ||
                            !watchCardName ||
                            !paymentField.cardCvc ||
                            !paymentField.cardNumber
                          }
                        >
                          {t(common.payment_confirm)}
                        </Button>
                      </div>
                      <div className="payment-btn-cancel">
                        <Button
                          className="btn-cancel"
                          htmlType="submit"
                          onClick={() => history.push('/diamond-purchase/list-diamond/1')}
                        >
                          {t(common.cancel)}
                        </Button>
                      </div>
                    </form>
                  </FormProvider>
                </div>
              </PaymentSignUpScreenStyled>
            </div>
          </div>
        </div>
      </Wrapper>
      <CvvComponent visible={isModalVisible} cancel={false} confirmClick={cancelCvvInfor} />
      <PaymentConfirmComponent
        visible={confirmPaymentVisible}
        onCancel={cancelConfirmPayment}
        confirmClick={confirmPayment}
        isLoadingSubmit={loading}
      />
      <ConfirmComponent
        visible={successPaymentVisible}
        cancel={false}
        confirmClick={successPaymentConfirm}
        textContent={t(common.confirm_payment_modal_success)}
        diamondFlg
        diamondAmount={diamondAmount}
      />
      <ConfirmComponent
        visible={failurePaymentVisible}
        cancel={false}
        confirmClick={failurePaymentConfirm}
        textContent={t(common.confirm_payment_modal_failed)}
        subTextPayment={t(common.please_try_again)}
        diamondFlg
        diamondAmount={diamondAmount}
        statusConfirm={false}
      />
      <ConfirmComponent
        visible={connectErrorVisible}
        cancel={false}
        confirmClick={connectErrorStripeConfirm}
        subText={t(common.no_internet_connection)}
        statusConfirm={false}
      />
      {errorPayment?.message === 'Network Error' && (
        <ConfirmComponent
          visible={connectErrorVisibleBegin}
          cancel={false}
          confirmClick={connectErrorStripeBeginScreen}
          subText={t(common.no_internet_connection)}
          statusConfirm={false}
        />
      )}
    </>
  );
};

export default PaymentInformationScreen;
