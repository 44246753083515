import styled from 'styled-components';
import Media from '@themes/media';

export const Wrapper = styled.header`
  position: sticky;
  z-index: 999;
  top: 0;
  display: flex;
  align-items: center;
  height: 60px;
  border-bottom: 0 solid #f8f9fa;
  backdrop-filter: blur(0.5rem);
  will-change: backdrop-filter;
  width: 100%;
  background-color: #ffffff;

  .container {
    padding: 0 2%;
    width: 100%;
    .row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      height: 60px;

      ${Media.lessThan(Media.SIZE.LG)} {
        flex-direction: row-reverse;
        justify-content: space-between;
      }

      .header-left {
        flex: 1 0;

        ${Media.lessThan(Media.SIZE.LG)} {
          flex: initial;
        }

        .search {
          display: flex;
          align-items: center;
          label.cursor-pointer {
            cursor: pointer;
            svg.svg-icon--material {
              font-size: 1.5rem;
              fill: #6c5ed3;
            }
          }
          input.search-input {
            border: none;
            background-color: transparent;
            width: 100%;
            font-weight: 500;
            padding: 0.3rem 0.75rem;

            &:focus-visible {
              outline: none;
            }
          }
        }
        .logo {
          width: 60px;
        }
      }
      .header-title {
        flex: 2.8 0;
        text-align: center;
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        color: #282727;
      }
      .header-right {
        flex: 0 0 auto;
        width: auto;

        .nav-bar {
          height: calc(28px + 1.25rem);
        }

        .row {
          flex-wrap: wrap;

          .col-auto {
            margin: 0rem;
            button {
              cursor: pointer;
            }
          }

          .btn-action {
            padding: 0.625rem;
            height: calc(28px + 1.25rem);
            background-color: transparent;
            border: none;
            border-radius: 1.25rem;
            white-space: pre;
          }

          @media only screen and (max-width: 450px) {
            .btn-action {
              padding: 0px;
            }
          }

          .user-info {
            cursor: pointer;
            display: flex;
            align-items: center;
            width: 12vw;

            .text-end {
              text-align: end;
              margin-right: 0.7rem;
              white-space: nowrap;
              max-width: 120px;
              overflow: hidden;
              margin-left: 10px;
              margin-right: 30px;
              text-overflow: ellipsis;
              text-align: left;

              .name {
                font-weight: 600;
                font-size: 0.75rem;
                color: #808191;
              }

              .title {
                font-weight: 300;
                font-size: 0.75rem;
                color: #6c757d;
              }
            }

            .avatar {
              margin-left: 33px;
              background-color: #fff3d4;
              border-radius: 50%;
              object-fit: cover;
              object-position: center;
            }
          }

          .btn-auth {
            .btn-left {
              margin-left: 32px;
              margin-right: 8px;
            }
          }

          ${Media.lessThan(Media.SIZE.BL)} {
            .header-title {
              flex: 2 0;
            }

            .col-auto {
              width: auto;
            }
            .user-info {
              width: auto;
            }
          }

          ${Media.lessThan(Media.SIZE.XL)} {
            .col-auto {
              width: auto; // 10vw ;
            }
            .user-info {
              width: auto; // 20vw;
            }
          }
          ${Media.lessThan(Media.SIZE.MD)} {
            .col-auto {
              width: 14vh; //15vw ;
            }
            .user-info {
              width: 25vw;
            }
          }
          ${Media.lessThan(Media.SIZE.SM)} {
            .col-auto {
              margin: unset;
              width: auto; // 25vw;
            }

            .img-prf-new {
              width: 32px;
              height: 32px;
            }
            .container {
              height: 5vh;
            }
            .header-title {
              margin-right: 12vh;
              white-space: nowrap;
            }
            .user-info {
              width: auto;
            }
          }

          ${Media.lessThan(Media.SIZE.XS)} {
          }
        }
      }
      ${Media.lessThan(Media.SIZE.XXL)} {
        .col-auto {
          width: 10vw;
        }
        .user-info {
          width: 15vw;
        }
        .header-title {
          flex: 1.7 0;
        }
      }

      .vector-translate {
        width: 20px;
        height: 20px;
      }

      @media only screen and (max-width: 450px) {
        .cs-hidden-mb {
          display: none;
        }
        .cs-show-mb-not-login {
          display: inherit !important;
        }

        .col-auto {
          width: auto;
        }
        .user-info {
          width: auto;
          margin-left: 0 !important;
        }
        .text-end {
          margin-right: 0;
          width: 16px;
        }
        .btn-action {
          margin-left: -2vh; // -3vh;
          margin-right: -10px;
        }
      }
    }
  }
`;

export const NavBarStyled = styled.nav`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .navbar-list__item {
    display: flex;
    align-items: center;
    margin-bottom: initial;

    .navbar-list__item__link {
      margin-right: 42px;
      color: #787878;
      position: relative;
      display: flex;
      align-items: center;

      a.active {
        color: #282727;
        &::before {
          content: '';
          width: 60%;
          background-color: #e3007f;
          height: 3px;
          position: absolute;
          bottom: -4px;
        }
      }

      a {
        width: 100%;
        justify-content: center;
      }
    }
  }

  ul > li > a {
    color: inherit;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
  }
`;
