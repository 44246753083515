/* eslint-disable no-console */

import React, { useEffect, useMemo, useState } from 'react';
import { Clickable } from '@components';
import { Popover } from 'antd';
import { GIF, CHECKED, COIN, RUBY, DIAMOND } from '@assets';
import { useAuth, useTopics } from '@hooks';
import { IDonatePackage } from '@type/Store/topics';
import { numberWithCommas } from '@utils/number';
import { CHAT_TYPE, STATUS_TYPE } from '@constants';
import { sendDonateWithFirebase, updateDonate } from '@hooks/useDonate';
import { increment, serverTimestamp } from 'firebase/database';
import { sendMessage } from '@hooks/useChat';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';
import { ModalConfirm } from '../modal/confirm';
import { DonateWrapper, Donate, Image, Price, ActiveIcon, Cart, Send, DonateButton, TextDonate } from './styled';

interface IProps {
  data: IDonatePackage[];
  roomId?: string | null;
  isDonate?: boolean;
}
interface ContentPopup {
  isHaveCancel: boolean;
  message: string;
  type: 'coin' | 'diamond' | 'ruby' | '';
}
const MONEY = {
  COIN,
  RUBY,
  DIAMOND,
};
const DonatePackage: React.FC<IProps> = ({ data, roomId, isDonate }) => {
  const { profile, loadProfileAction } = useAuth();
  const { push } = useHistory();
  const { sendDonate, liveStream, donate } = useTopics();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { livestream: livestreamTrans, topic } = translations;
  const [openPopup, setOpenPopup] = useState(false);
  const [contentPopup, setContentPopup] = useState<ContentPopup>({
    isHaveCancel: true,
    message: '',
    type: '',
  });
  const [donateSelected, setDonateSelected] = useState<IDonatePackage | null>(null);
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
    if (donateSelected && newOpen) {
      setDonateSelected(null);
    }
  };

  const onClose = () => {
    setOpen(false);
  };
  const goToCart = () => {
    onClose();
    setDonateSelected(null);
    push('/diamond-purchase');
  };
  const handleCancelPopup = () => {
    setOpen(false);
    setOpenPopup(false);
    setContentPopup({
      isHaveCancel: false,
      message: '',
      type: '',
    });
  };

  const handleDonate = () => {
    if (liveStream.data?.live_key && donateSelected) {
      if (donateSelected.type === 'diamond' && donateSelected.price > Number(profile?.diamond || 0)) {
        setOpenPopup(true);
        setContentPopup({
          isHaveCancel: true,
          message: t(livestreamTrans.buy_diamond_confirm),
          type: 'diamond',
        });
      }
      if (donateSelected.type === 'coin' && donateSelected.price > Number(profile?.coins || 0)) {
        setOpenPopup(true);
        setContentPopup({
          isHaveCancel: false,
          message: t(livestreamTrans.get_coins),
          type: 'coin',
        });
      } else if (donateSelected.type === 'ruby' && donateSelected.price > Number(profile?.ruby || 0)) {
        setOpenPopup(true);
        setContentPopup({
          isHaveCancel: false,
          message: t(livestreamTrans.ruby_not_enough),
          type: 'ruby',
        });
      } else {
        const params = {};
        params[`${donateSelected.type}`] = donateSelected.price;
        sendDonate({ liveKey: liveStream.data?.live_key, params });
      }
      onClose();
    }
  };
  const onConfirmPopup = () => {
    setDonateSelected(null);
    setOpenPopup(false);
    setContentPopup({
      isHaveCancel: false,
      message: '',
      type: '',
    });
    if (contentPopup.type === 'diamond') {
      push('/diamond-purchase');
    }
  };

  const yenRatio = useMemo(() => {
    if (liveStream.data && liveStream.data.yen_ratio) return liveStream.data.yen_ratio;
    return 1;
  }, [liveStream.data]);

  useEffect(() => {
    if (donate.status === STATUS_TYPE.SUCCESS && yenRatio) {
      const donate = () => {
        if (donateSelected?.type === 'coin') return { ruby: 0, diamond: donateSelected.price / yenRatio };
        if (donateSelected?.type === 'diamond') return { ruby: 0, diamond: donateSelected.price };
        return { ruby: donateSelected?.price || 0, diamond: 0 };
      };

      sendDonateWithFirebase(roomId, {
        createdAt: serverTimestamp(),
        stampGif: donateSelected?.title || '',
        stampId: donateSelected?.id || 0,
        images: donateSelected?.images || '',
        duration: donateSelected?.gif_duration || 3,
        gifFullScreen: donateSelected?.gif_fullscreen || null,
        gifTransparent: donateSelected?.transparent || null,
        freeRuby: 0,
        freeDiamond: 0,
        mediaType: donateSelected?.animation_type === 1 ? 'GIF' : 'PNG',
        ...donate(),
      })
        .then(() => {
          return sendMessage(roomId, {
            senderId: profile?.id,
            senderAvatar: profile?.avatar || null,
            senderName: profile?.name || '',
            createdAt: serverTimestamp(),
            msg: donateSelected?.title || '',
            msg_en: donateSelected?.title_en || '',
            msg_cn: donateSelected?.title_cn || '',
            type: CHAT_TYPE.DONATE,
          }).catch((err) => console.log('====> error', err));
        })
        .then(() => {
          const donate = () => {
            if (donateSelected?.type === 'ruby') return Number(donateSelected?.price || 0);
            if (donateSelected?.type === 'diamond') return Number(donateSelected?.price || 0);
            if (donateSelected?.type === 'coin') return Number((donateSelected?.price || 0) / yenRatio);
            return 0;
          };
          updateDonate({
            userId: profile?.id,
            roomId,
            data: {
              donate: increment(donate()),
              id: profile?.id,
              name: profile?.name,
              avatar: profile?.avatar,
            },
          }).catch((err) => console.log('====> error', err));
          loadProfileAction();
        })
        .catch((err) => console.log('====> error', err));
    }
  }, [donate.status, yenRatio]);

  const donateList = useMemo(() => {
    if (data) {
      const rubyDonate = data.filter((f) => f.type === 'ruby').sort((a, b) => a.price - b.price);
      const diamondDonate = data.filter((f) => f.type === 'diamond').sort((a, b) => a.price - b.price);
      const coinDonate = data.filter((f) => f.type === 'coin').sort((a, b) => a.price - b.price);

      return [...rubyDonate, ...diamondDonate, ...coinDonate];
    }
    return [];
  }, [data]);

  const content = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'row', padding: 8, gap: 4 }}>
        <img src={GIF} style={{ width: 22 }} alt="" />
        <p style={{ color: '#272828', fontSize: 16, fontWeight: 700, fontFamily: 'Noto Sans JP' }}>
          {t(livestreamTrans.donateTitle)}
        </p>
      </div>
      <div style={{ display: 'flex', height: 1, background: '#EEEEEE' }} />
      <div style={{ display: 'flex', flexDirection: 'row', padding: 8, gap: 4 }}>
        <p style={{ color: '#272828', fontSize: 16, fontWeight: 700, fontFamily: 'Noto Sans JP' }}>
          {t(livestreamTrans.myPoints)}
        </p>
        <Price color="diamond">
          <img width={18} src={MONEY.DIAMOND || ''} alt="" />
          {numberWithCommas(profile?.diamond || 0, ',')}
        </Price>
        <Price color="ruby">
          <img width={18} src={MONEY.RUBY || ''} alt="" />
          {numberWithCommas(profile?.ruby || 0, ',')}
        </Price>
        <Price color="coin">
          <img width={18} src={MONEY.COIN || ''} alt="" />
          {numberWithCommas(profile?.coins || 0, ',')}
        </Price>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', flexDirection: 'row', gap: 12 }}>
          <Clickable onClick={goToCart}>
            <Cart />
          </Clickable>
          <Clickable disabled={!donateSelected} onClick={handleDonate}>
            <Send isactive={!!donateSelected} />
          </Clickable>
        </div>
      </div>
      {isDonate ? (
        <DonateWrapper>
          {donateList.map((item) => (
            <DonateButton className="item" onClick={() => setDonateSelected(item)} key={item.id}>
              <Donate>
                <Image active={donateSelected?.id === item.id} src={item.thumbnail || ''} alt="" />
                <Price color={item.type}>
                  <img width={12} src={MONEY[`${item.type.toUpperCase()}`] || ''} alt="" />
                  {item.price}
                </Price>
                {donateSelected?.id === item.id && (
                  <ActiveIcon>
                    <CHECKED />
                  </ActiveIcon>
                )}
              </Donate>
            </DonateButton>
          ))}
        </DonateWrapper>
      ) : (
        <TextDonate> {t(livestreamTrans.livestream_not_donate)}</TextDonate>
      )}
    </div>
  );

  return (
    <>
      <Popover open={open} placement="bottom" content={content} onOpenChange={handleOpenChange} trigger="click">
        <Clickable>
          <img width={20} src={GIF} alt="" />
        </Clickable>
      </Popover>
      <ModalConfirm
        onConfirm={onConfirmPopup}
        open={openPopup}
        closeIcon={<div />}
        isHaveCancel={contentPopup.isHaveCancel}
        onCancel={handleCancelPopup}
        textBtnOk={contentPopup.type === 'diamond' ? t(topic.go_diamond_purchase) : undefined}
      >
        <span style={{ margin: '16px 0', fontSize: 16, fontWeight: 400 }}>{contentPopup.message}</span>
      </ModalConfirm>
    </>
  );
};

export { DonatePackage };
