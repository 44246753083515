import React, { useEffect } from 'react';
import { Button, Avatar, Clickable, Seemore } from '@components';
import { HEART, SHARE } from '@assets';
import { formatDate } from '@utils';
import { FORMAT_TIME } from '@constants';
import { useAuth, useTopics } from '@hooks';
import { translations } from '@i18n/translations';
import { useTranslation } from 'react-i18next';
import { sendLike } from '@hooks/useLikes';
import { serverTimestamp } from 'firebase/database';
import { useHistory } from 'react-router-dom';
import { useTwitter } from '@hooks/useTwitter';
import {
  Wrapper,
  InfomationWrapper,
  TopicName,
  Social,
  RoomName,
  Info,
  TitleTopic,
  TitleName,
  CreateAt,
} from './styled';

import { DonatePackage } from '../../watch-livestream/components/donatePackage';

interface IProps {
  isShowSocial?: boolean;
  title?: string | null;
  roomName?: string | null;
  description?: string | null;
  src?: string | null;
  name?: string | null;
  createdAt?: string | null;
  roomId?: string | null;
  topicId?: number | null;
  isDonate?: boolean;
}

const TopicInfo: React.FC<IProps> = ({
  isShowSocial = false,
  title = '',
  roomName = '',
  description = '',
  src,
  name = '',
  createdAt,
  roomId,
  topicId,
  isDonate,
}) => {
  const { isUser, profile } = useAuth();
  const { getDonatePackage, donatePackage, liveStream } = useTopics();
  const { livestream } = translations;
  const { t } = useTranslation();
  const { push } = useHistory();
  const { shareTwitter, loginTwitter } = useTwitter();

  useEffect(() => {
    if (liveStream.data?.program_id) {
      getDonatePackage(liveStream.data?.program_id);
    }
  }, [liveStream.data?.program_id]);

  const goTopic = () => {
    if (topicId) {
      push(`/topics/${topicId}`);
    }
  };

  const shareWithTwitter = () => {
    if (profile?.twitter) {
      shareTwitter({
        name: profile.name,
        title: liveStream.data?.title || '',
        description: liveStream.data?.description,
        room: roomId,
        avatar: profile.avatar || '',
        liverId: profile.id.toString() || '',
      });
    } else {
      loginTwitter({
        name: profile?.name,
        title: liveStream.data?.title || '',
        description: liveStream.data?.description,
        room: roomId,
        avatar: profile?.avatar || '',
        liverId: profile?.id.toString() || '',
      });
    }
  };

  return (
    <Wrapper>
      <InfomationWrapper>
        <Avatar width={44} src={src || ''} />
        <Clickable onClick={goTopic}>
          <TopicName>{name}</TopicName>
        </Clickable>
      </InfomationWrapper>
      {isShowSocial && (
        <Social id="twitter">
          <Button
            onClick={shareWithTwitter}
            type="outline"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', minWidth: 100 }}
          >
            <img width={18} src={SHARE} alt="" />
            <p>{t(livestream.share)}</p>
          </Button>
          {isUser && <DonatePackage roomId={roomId} data={donatePackage.data} isDonate={isDonate} />}
          <Clickable onClick={() => sendLike(roomId, { createdAt: serverTimestamp() })}>
            <img width={20} src={HEART} alt="" />
          </Clickable>
        </Social>
      )}
      <Info>
        <TitleTopic>
          <TitleName>{title}</TitleName>
          {!!createdAt && <CreateAt>{formatDate(createdAt, FORMAT_TIME.YEAR_MONTH_DATE)}</CreateAt>}
        </TitleTopic>
        {!!roomName && <RoomName>{roomName}</RoomName>}
        {!!description && <Seemore className="description" rows={3} content={description} />}
      </Info>
    </Wrapper>
  );
};

export { TopicInfo };
