import { Store } from '@type/Store';
import { EventState } from '@type/Store/event';
import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectRegistrationEvent = (store: Store) => store.event || initialState;

const makeSelectRegistrationEvent = () => createSelector(selectRegistrationEvent, (state: EventState) => state);

export { selectRegistrationEvent, makeSelectRegistrationEvent };
