import React, { useEffect, useState } from 'react';
import { Col, Typography, Divider, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { translations } from '@i18n/translations';

import { useEvents } from '@hooks';
import ListComponent from '@components/list';
import Search from '@components/form/Search';
import { useQuery } from '@hooks/useQuery';
import { useHistory } from 'react-router-dom';
import Ticket from './components/tickets';

import { WrapperStyled, TitleStyled, TextSortStyled } from './styled';
import EventNoData from './components/event-no-data';

export const SearchEvent: React.FC = () => {
  const { searchEvents, eventsSearch } = useEvents();
  const [searchValue, setSearchValue] = useState<string>('');
  const [sortBy, setSortBy] = useState<'latest' | 'oldest'>('latest');
  const history = useHistory();
  const { t } = useTranslation();
  const { listEvent, realEventPayment } = translations;
  const query = useQuery();
  const searchQuery: string = query.get('search') || '';
  const onChangeOrder = (sortByValue: 'latest' | 'oldest') => {
    setSortBy(sortByValue);
    searchEvents({
      search: searchValue,
      sort: sortByValue,
    });
  };

  const onSearch = ({ search }) => history.replace(`/search-event?search=${search || ''}`);

  const onChangePage = (page) => {
    searchEvents({
      search: searchValue,
      sort: sortBy,
      page,
    });
  };

  useEffect(() => {
    searchEvents({
      search: searchQuery,
      sort: sortBy,
    });
    setSearchValue(searchQuery);
  }, [searchQuery]);

  return (
    <WrapperStyled>
      <Col span={24} className="col-title">
        <TitleStyled>
          {t(realEventPayment.searchResult)}
          {!eventsSearch.loading && (
            <span style={{ marginLeft: 24, color: '#E3007F' }}>
              {t(realEventPayment.result, { total: eventsSearch.paginate.total })}
            </span>
          )}
        </TitleStyled>
        <Typography className="text-sort">
          <TextSortStyled onClick={() => onChangeOrder('latest')} className={sortBy === 'latest' ? 'active' : ''}>
            {t(listEvent.latest_event)}
          </TextSortStyled>
          <Divider type="vertical" />
          <TextSortStyled
            onClick={() => {
              onChangeOrder('oldest');
            }}
            className={sortBy === 'oldest' ? 'active' : ''}
          >
            {t(listEvent.old_event)}
          </TextSortStyled>
        </Typography>
      </Col>
      <Search onSearch={onSearch} defaulValue={searchQuery} placeholder={t(realEventPayment.searchPlaceholder)} />
      <Col span={24}>
        <Spin spinning={eventsSearch.loading}>
          <ListComponent
            listProps={{
              dataSource: eventsSearch.data,
              grid: { gutter: 16, xs: 1, sm: 2, md: 2, lg: 2, xl: 3, xxl: 3 },
              renderItem: (item) => <Ticket data={item} />,
              locale: {
                emptyText: <EventNoData text={t(listEvent.message_list_event_no_data)} />,
              },
            }}
            paginationProps={{
              total: eventsSearch?.paginate.total,
              current: eventsSearch?.paginate.current_page,
              pageSize: 15,
            }}
            pages={eventsSearch?.paginate.last_page}
            onChange={onChangePage}
            pagination
          />
        </Spin>
      </Col>
    </WrapperStyled>
  );
};
