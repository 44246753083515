import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Image from '@components/imageFallback';
import { translations } from '@i18n/translations';
import { TopicData } from '@type/Store/topics';
import { Tag } from 'antd';
import { EventTopicStyle } from './styles';

type TopicProps = {
  data: TopicData;
};
const Topic: React.FC<TopicProps> = ({ data }) => {
  const { t } = useTranslation();
  const { topic } = translations;

  return (
    <EventTopicStyle>
      <Link to={`/topics/${data.id}`} className="topic-item">
        <div className="line">
          <div className="image-container">
            {data.is_living && <Tag className="tag-live">{t(topic.now_on_live)}</Tag>}
            <Image className="cover-img" src={data?.images} alt={data.title} />
          </div>
          <div className="content">
            <span className="title">{data?.title}</span>
            <span className="description">{data?.description}</span>
            <Tag className="tag-topic">{t(topic.topics)}</Tag>
          </div>
        </div>
      </Link>
    </EventTopicStyle>
  );
};
export { Topic };
