import styled from 'styled-components';
import Media from '@themes/media';

export const Wrapper = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .ant-dropdown-menu ant-dropdown-menu-root ant-dropdown-menu-vertical ant-dropdown-menu-light {
    display: flex;
  }
  .logo-page {
    text-align: center;
    height: 43.5vh;
  }

  .lable-btn-app {
    padding: 10px;
  }
  .content {
    margin-left: 35.5%;
  }
  .logo-app {
    width: 46vh;
    height: 48vh;
  }
  .google-play-icon {
    padding-right: 15px;
  }
  .language-switch {
    text-align: right;
    padding: 20px;
    margin-right: 14px;
  }
  .us-icon {
    padding: 2px;
  }
  .ja-icon {
    padding: 2px;
  }
  .cn-icon {
    padding: 2px;
  }

  .text-content {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    color: #e3007f;
    text-align: left;
  }

  .note {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #282727;
  }
  .btn-app {
    color: #ffffff;
    background-color: #e3007f;
    border-radius: 24px;
    width: 210px;
    height: 50px;
  }
  .btn-action {
    background-color: #efeef3;
    border-radius: 11px;
    border: unset;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
    padding: 3px;
    width: 8vh;
    text-align: left;
    white-space: nowrap;
  }

  ${Media.lessThan(Media.SIZE.BL)} {
    .content {
      margin-left: 31.5%;
    }
  }
  ${Media.lessThan(Media.SIZE.XS)} {
    .text-content {
      font-size: 18px;
    }
    .language-switch {
      position: absolute;
      margin-left: 33vh;
      padding: 15px;
    }
    .logo-app {
      margin-top: 6vh;
    }
    .logo-page {
      height: 50vh;
    }
    .note {
      font-size: 14px;
      width: 40vh;
    }
    .content {
      margin-left: 4.5%;
    }
    .btn-app {
      width: 95.5%;
    }
    .btn-action {
      padding: unset;
    }
  }
`;
export const LOGO_LOGIN = styled.div`
  width: 11vh;
`;
