import { Layout, Typography } from 'antd';
import styled from 'styled-components';

import Media from '@themes/media';

const { Content } = Layout;
const { Text } = Typography;

export const WrapperStyled = styled(Content)`
  max-width: 1300px;
  padding: 40px 50px;
  margin: 0 auto;
  padding-bottom: 100px;

  ${Media.lessThan(Media.SIZE.MD)} {
    padding: 16px 12px;
    padding-bottom: 32px;
  }

  .col-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .text-sort {
      display: flex;
      align-items: center;

      .ant-divider-vertical {
        height: 28px;
      }
    }
  }
`;
export const TitleStyled = styled(Typography)`
  font-size: 24px;
  font-weight: 700;

  ${Media.lessThan(Media.SIZE.SM)} {
    font-size: 16px;
  }
`;

export const TextSortStyled = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  color: #b4b4b4;
  cursor: pointer;

  ${Media.lessThan(Media.SIZE.SM)} {
    font-size: 12px;
  }

  &.active {
    color: #e3007f;
  }
`;
