/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
import ja_JP from 'antd/es/locale/ja_JP';
import en_US from 'antd/es/locale/en_US';
import zh_CN from 'antd/es/locale/zh_CN';
import React, { useState, useEffect, useMemo } from 'react';
import { Button, Tabs, Spin, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { removeLocalStorage, STORAGE } from '@utils';
import { numberWithCommas } from '@utils/number';
import ConfirmComponent from '@components/modal/confirmModal';
import { PaymentIntentStripe, useWeb3 } from '@hooks';
import { DIAMOND, BACK_ICON } from '@assets';
import { PAY_METHOD } from '@constants';
import { useMetaMaskPayment } from '@hooks/signature';
import { iLiveContract, erc20Contract } from '../../ABI';
import { makeSelectSignature } from './store/selectors';
import DiamondItem from './components/buyDiamond';
import Transaction from './components/transaction';
import { Wrapper } from './styled';

const { TabPane } = Tabs;

const ListPackageDiamondScreen: React.FC = () => {
  const [value, setValue] = useState<number | string>(0);
  const history = useHistory();
  const { t, i18n } = useTranslation(['common', 'error_message']);
  const { isActive } = useParams<{ isActive: string }>();
  const [connectErrorVisibleBegin, setConnectErrorVisibleBegin] = useState(true);
  const { signature }: any = useSelector(makeSelectSignature());
  const { isPaying, paymentAction, eventTransferAction, resetStateAction } = useMetaMaskPayment();
  const {
    walletAdress,
    web3: web3Instance,
    getContract,
    isTransferSuccess,
    transactionHash,
    errorTransaction,
    erc20Approved,
    error: transferError,
    walletConnected,
  } = useWeb3();

  const {
    getPaymentListPackageAction,
    getPaymentListHistoryAction,
    error,
    isLoading,
    packageList,
    packageListHistory,
  } = PaymentIntentStripe();

  useEffect(() => {
    if (!web3Instance && isActive === PAY_METHOD.METAMASK) {
      history.push('/diamond-purchase');
    }
  }, [web3Instance, isActive]);

  useEffect(() => {
    if (transferError) {
      message.error(t(transferError.message));
    }
  }, [transferError]);

  const languagePage = useMemo(() => {
    if (i18n.language === 'cn') return zh_CN;
    if (i18n.language === 'en') return en_US;
    return ja_JP;
  }, [i18n.language]);

  const items = useMemo(() => {
    if (packageList && packageList.data && packageList.data.length > 0) {
      return packageList.data.filter((item: any) => {
        if (isActive === '2') return item.currency === 4;

        if (languagePage === zh_CN) return item.currency === 3;
        if (languagePage === en_US) return item.currency === 2;
        return item.currency === 1;
      });
    }
    return [];
  }, [packageList?.data, languagePage]);

  useEffect(() => {
    if (errorTransaction && transactionHash && signature) {
      const price_erc20 = items.find((item: any) => item.id === value).price;
      eventTransferAction({
        package_id: signature.packageId,
        price_erc20,
        wallet_address: signature.walletAddress,
        user_id: signature.userId,
        transaction_code: transactionHash,
        status: 'failed',
      });
    }
  }, [errorTransaction]);

  useEffect(() => {
    if (!walletConnected && isActive === PAY_METHOD.METAMASK) {
      history.push('/diamond-purchase');
      removeLocalStorage(STORAGE.WALLETCONNECT);
    }
  }, [walletConnected]);

  const handlePayMetmaskSuccess = () => resetStateAction();

  const onChange = (data: any) => setValue(data.id);

  useEffect(() => {
    getPaymentListPackageAction({
      isActive: 1,
      type: 2,
    }); // 1 package app , 2 package web
    getPaymentListHistoryAction({ perPage: 9, page: 1 });
  }, []);

  const pageSize = packageListHistory?.paginate?.per_page || 0;

  const diamondUser = useMemo(() => parseInt(packageList?.diamond, 10) || 0, [packageList?.diamond]);

  useEffect(() => {
    if (erc20Approved) {
      message.info(t('accept_transaction_instructions'));
    }
  }, [erc20Approved]);

  const getErc20 = getContract(web3Instance, erc20Contract.abi, erc20Contract.address);
  const getiLive = getContract(web3Instance, iLiveContract.abi, iLiveContract.address);

  const payment = async () => {
    if (isActive === PAY_METHOD.METAMASK) {
      if (walletAdress) {
        paymentAction({
          wallet_address: walletAdress,
          package_id: value,
          erc20Contract: getErc20,
          iLiveContract: getiLive,
          web3: web3Instance,
        });
      }
    } else {
      history.push(`/diamond-purchase/payment/${value}`);
    }
  };

  const handleChangePage = (page: any) => {
    getPaymentListHistoryAction({ perPage: 9, page });
  };
  const connectErrorStripeBeginScreen = () => {
    setConnectErrorVisibleBegin(false);
    window.location.href = '/diamond-purchase';
  };

  return (
    <Spin spinning={isLoading || isPaying}>
      <Wrapper id="intro">
        <div className="row h-fluid-min-100 intro-0-2-5">
          <div className="payment-content">
            <div className="list-diamond-title">
              <div className="back-icon">
                <img
                  className="logo"
                  src={BACK_ICON}
                  alt="logo"
                  onClick={() => {
                    if (isActive === PAY_METHOD.METAMASK) {
                      resetStateAction();
                    } else {
                      history.push('/diamond-purchase');
                    }
                  }}
                />
              </div>
              <div className="diamond-present">
                <label className="list-diamond-lable">
                  {t('label_diamond_header')}:&ensp;
                  <img className="diamond-package-icon" src={DIAMOND} alt="diamond" />
                </label>
                <b style={{ color: '#0076E3' }}>
                  &ensp;
                  <span className="amount-diamond-header">{numberWithCommas(diamondUser)}</span>
                </b>
              </div>
            </div>
            <Tabs defaultActiveKey="1" centered>
              <TabPane className="list-package-tab" tab={t('label_tab_list_diamond')} key="1">
                <div className="list-package">
                  {items.map((item: any) => {
                    return (
                      <DiamondItem
                        key={item.id}
                        data={item}
                        isActive={isActive}
                        handleClick={onChange}
                        currentValue={value}
                      />
                    );
                  })}
                </div>
                <div className="payment-btn">
                  <Button
                    className="btn-login"
                    style={{ opacity: value ? 1 : 0.5 }}
                    htmlType="submit"
                    onClick={payment}
                    disabled={!value}
                  >
                    {t('payment_confirm')}
                  </Button>
                </div>
              </TabPane>
              <TabPane tab={t('label_tab_list_history')} key="2">
                <Transaction
                  data={packageListHistory.data}
                  total={packageListHistory?.paginate?.total || 0}
                  pageSize={pageSize}
                  languagePage={languagePage}
                  handleChangePage={handleChangePage}
                />
              </TabPane>
            </Tabs>
          </div>
        </div>
        {error?.message === 'Network Error' && (
          <ConfirmComponent
            visible={connectErrorVisibleBegin}
            cancel={false}
            confirmClick={connectErrorStripeBeginScreen}
            subText={t('no_internet_connection')}
            statusConfirm={false}
          />
        )}
        {isTransferSuccess && (
          <ConfirmComponent
            visible={isTransferSuccess}
            cancel={handlePayMetmaskSuccess}
            confirmClick={handlePayMetmaskSuccess}
            subText={t('buy_with_metamask_success')}
            statusConfirm={handlePayMetmaskSuccess}
          />
        )}
      </Wrapper>
    </Spin>
  );
};

export default ListPackageDiamondScreen;
