import AxiosClient from './api';
import END_POINT from './constants';

function realEventPurchase(data: any) {
  return AxiosClient.post(`${END_POINT.REAL_EVENT_PURCHASE}`, data, { isHideError: true }).then((res) => {
    return res;
  });
}

function purchase(data: any) {
  return AxiosClient.post(`${END_POINT.PURCHASE_CREDIT_CARD}`, data, { isHideError: true }).then((res) => {
    return res;
  });
}

export { realEventPurchase, purchase };
