import Media from '@themes/media';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 300px;
  max-height: 765px;
  border-radius: 10px;
  flex-direction: column;
  background-color: white;
  ${Media.lessThan(Media.SIZE.XL)} {
    padding: 0px 14px;
    width: 100%;
    margin-bottom: 20px;
  }
`;
