import {
  // ELEVEN,
  FAMILY,
  LAWSON_3X,
  MINISTOP,
} from '@assets';
import { translations } from '@i18n/translations';

type InputFormat = 'none' | 'credit_card' | 'date_expire' | 'custom_format';
const { realEventPayment } = translations;
const { fieldName, kobini } = realEventPayment;
export const FORM_CREDIT_CARD = [
  {
    name: 'cardholder',
    placeholder: 'YAMADA TAROU',
    label: fieldName.cardholder,
    inputFormat: 'none' as InputFormat,
  },
  {
    name: 'cardnumber',
    placeholder: '1234  5678  9101  1121',
    label: fieldName.cardnumber,
    inputFormat: 'credit_card' as InputFormat,
  },
  {
    name: 'dateExpiry',
    placeholder: 'YY/MM',
    label: fieldName.dateExpiry,
    inputFormat: 'date_expire' as InputFormat,
  },
  {
    name: 'cvc',
    placeholder: '123',
    label: fieldName.cvc,
    inputFormat: 'none' as InputFormat,
  },
  {
    name: 'email',
    placeholder: 'eemirisuzuhar@gmail.com',
    label: fieldName.email,
    inputFormat: 'none' as InputFormat,
  },
  {
    name: 'phonenumber',
    placeholder: '0905111222',
    label: fieldName.phonenumber,
    inputFormat: 'none' as InputFormat,
  },
];

export const FORM_KONBINI = [
  {
    name: 'lastKanji',
    placeholder: '山田',
    label: fieldName.lastKanji,
    isFullWidth: true,
  },
  {
    name: 'firstKanji',
    placeholder: '太郎',
    label: fieldName.firstKanji,
    isFullWidth: true,
  },
  {
    name: 'lastKana',
    placeholder: 'ヤマダ',
    label: fieldName.lastKana,
    isFullWidth: true,
  },
  {
    name: 'firstKana',
    placeholder: 'タロウ',
    label: fieldName.firstKana,
    isFullWidth: true,
  },
  {
    name: 'email',
    placeholder: 'mail@example.com',
    label: fieldName.email,
    isFullWidth: false,
  },
  {
    name: 'phonenumber',
    placeholder: '0905111222',
    label: fieldName.phonenumber,
    isFullWidth: false,
  },
];

export const KOBINI_LIST = [
  // {
  //   src: ELEVEN,
  //   label: kobini.seven,
  //   value: '00007',
  // },
  {
    src: FAMILY,
    label: kobini.family,
    value: '10002',
  },
  {
    src: LAWSON_3X,
    label: kobini.lawson,
    value: '10001',
  },
  {
    src: MINISTOP,
    label: kobini.miniStop,
    value: '10005',
  },
  // {
  //   src: SECOMA_3X,
  //   label: kobini.secoma,
  //   value: '10008',
  // },
];

export const FORM_CREDIT_CARD_CUSTOM = [
  {
    name: 'lastKanji',
    placeholder: 'lastKanji_placeholder',
    label: fieldName.lastKanji,
  },
  {
    name: 'firstKanji',
    placeholder: 'firstKanji_placeholder',
    label: fieldName.firstKanji,
  },
  {
    name: 'phoneNumber',
    placeholder: 'phone_placeholder',
    label: fieldName.phonenumber,
    inputFormat: 'number' as InputFormat,
  },
  {
    name: 'zipcode',
    placeholder: 'post_code_placeholder',
    label: fieldName.zipcode,
    inputFormat: 'number' as InputFormat,
  },
  {
    name: 'address',
    placeholder: 'address_placeholder',
    label: fieldName.address,
    inputFormat: 'none' as InputFormat,
    isFullWidth: true,
  },
];
