import * as yup from 'yup';
import { translations } from '@i18n/translations';

const { realEventPayment } = translations;
const { payment_validate } = realEventPayment;
const scheme = yup.object().shape({
  first_name: yup
    .string()
    .trim()
    .required(payment_validate.required)
    .test('first_name', payment_validate.kanji_name, (value, context) => {
      if (!value) return true;
      const { parent } = context;
      return value.length + parent.last_name.length <= 20;
    }),
  last_name: yup
    .string()
    .trim()
    .required(payment_validate.required)
    .test('last_name', '', (value, context) => {
      if (!value) return true;
      const { parent } = context;

      return value.length + parent.first_name.length <= 20;
    }),
  first_name_kana: yup
    .string()
    .trim()
    .required(payment_validate.required)
    .test('first_name_kana', payment_validate.kana_name, (value, context) => {
      if (!value) return true;
      const { parent } = context;

      return value.length + parent.last_name_kana.length <= 20;
    }),
  last_name_kana: yup
    .string()
    .trim()
    .required(payment_validate.required)
    .test('last_name_kana', '', (value, context) => {
      if (!value) return true;
      const { parent } = context;

      return value.length + parent.first_name_kana.length <= 20;
    }),
  email: yup
    .string()
    .trim()
    .required(payment_validate.required)
    .email(payment_validate.email_incorrect)
    .max(200, payment_validate.email_max_length),
  phone_number: yup
    .string()
    .trim()
    .required(payment_validate.required)
    .test('phone_number', payment_validate.phoneNumber_format, (value = '') => {
      if (!value) return true;
      const pattern = /^[0-9]{0,13}$/;
      return pattern.test(value);
    }),
});

export default scheme;
